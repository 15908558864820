import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainButton, Text, Title } from 'shared/ui';
import { ListItem } from '../components';
import { IIncomeProps } from '../interfaces';
import { BUTTON, INFO, INFO_TITLE, INNER, LIST, TITLE, WRAPPER } from '../styles';

export const Income = (props: IIncomeProps) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        {t('invite.payments.income.title')}
      </Title>

      <Stack sx={INNER}>
        <Stack sx={LIST}>
          <ListItem
            title={t('invite.payments.income.items.total')}
            text={`${props.total.toFixed(2).toString()} USDT`}
          />

          <ListItem
            title={t('invite.payments.income.items.withdrawan')}
            text={`${props.withDrawn.toFixed(2)} USDT`}
          />

          <ListItem
            title={t('invite.payments.income.items.available')}
            text={`${props.available.toFixed(2)} USDT`}
            primary={!!props.available}
          />
        </Stack>

        <Stack sx={INFO}>
          <MainButton
            type='primary'
            size='large'
            styles={BUTTON}
            onClick={props.openModal}
          >
            {t('invite.payments.income.button')}
          </MainButton>

          <Text styles={INFO_TITLE}>
            {t('invite.payments.income.text')}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};
