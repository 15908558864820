import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Copy, Tabs } from 'widgets';
import { Text, Title } from 'shared/ui';
import { IAlertSource } from '../interfaces';
import {
  description,
  modal,
  modalChildren,
  title,
  titleWrapper,
  wrapper,
} from '../styles';
import { Indicator, Other, Strategy } from '../views';

export const AlertSource = (props: IAlertSource) => {
  const {
    isOpen,
    handleClose,
    sourceUuid,
    secretToken,
  } = props;

  const {
    t, 
  } = useTranslation();

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Title styles={title}>
            {t('modal.alertSource.title')}
          </Title>

          <Text styles={description}>
            {t('modal.alertSource.description')}
          </Text>
        </Box>

        <Copy
          label={t('modal.alertSource.copy.title')}
          value='https://panel.skyrexio.com/api/v1/deal/alert'
        />

        <Tabs
          tabs={[{
            label: t('modal.alertSource.tabs.indicator'),
            value: 0,
          }, {
            label: t('modal.alertSource.tabs.strategy'),
            value: 1,
          }, {
            label: t('modal.alertSource.tabs.other'),
            value: 2,
          }]}
          styles={{
            justifyContent: 'center',
          }}
          content={[(
            <Indicator
              sourceUuid={sourceUuid!}
              secretToken={secretToken!}
            />
          ), (
            <Strategy
              sourceUuid={sourceUuid!}
              secretToken={secretToken!}
            />
          ), (
            <Other
              sourceUuid={sourceUuid!}
              secretToken={secretToken!}
            />
          )]}
        />
      </Box>
    </Modal>
  );
};
