export const CIS = [
  'ru',
  'uk',
  'be',
  'kk',
  'uz',
  'tg',
  'ky',
  'tk',
  'az',
  'hy',
  'mo',
];
