import { Stack } from '@mui/material';
import { ErrorMessage } from 'pages/manual-trading/trading-terminal/components/error-message';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabelInput } from 'shared/ui';
import { useOrderPrice } from '../../hooks/use-order-price';

interface OrderPriceFieldProps {
    currentSymbol: any;
    onOrderPriceChange: (value: string) => void;
    disabled?: boolean;
    forceUpdating?: boolean;
    preventPriceUpdate?: boolean; 
  }
  
export const OrderPriceField = memo(({ 
  currentSymbol, 
  onOrderPriceChange,
  disabled,
  forceUpdating,
  preventPriceUpdate,
}: OrderPriceFieldProps) => {
  const {
    control,
    trigger,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
  
  useOrderPrice({ 
    currentSymbol, 
    onOrderPriceChange, 
    forceUpdating,
    preventPriceUpdate,
  });
  
  return (
    <Controller
      name='orderPrice'
      control={control}
      rules={{
        required: t('validation.funds.price.origin.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.funds.price.trigger.number');
          }

          if (numValue < Number(currentSymbol.priceMin)) {
            return t('validation.funds.price.trigger.min', {
              priceMin: getTrailedZeroCutted(currentSymbol.priceMin),
            });
          }

          
          if (numValue > Number(currentSymbol.priceMax)) {
            return t('validation.funds.price.trigger.max', {
              priceMax: getTrailedZeroCutted(currentSymbol.priceMax),
            });
          }

          return true;
        },
      }}
      render={({
        field,
        fieldState: {
          error, 
        },
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('form.funds.price.origin.title')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onOrderPriceChange(value);
              }
              trigger('orderPrice');
              trigger('onAddFundsTotal');
              trigger('onAddFundsUnits');
              trigger('onAddFundsSlider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value,
                currentSymbol.quoteAssetPrecision,
                Number(currentSymbol.priceMin),
                Number(currentSymbol.priceMax),
              );
              field.onChange(formattedValue);
              onOrderPriceChange(formattedValue);
              trigger('orderPrice');
              trigger('onAddFundsSlider');
              trigger('onAddFundsTotal');
              trigger('onAddFundsUnits');
            }}
            icon={currentSymbol.quoteAsset}
            disabled={disabled}
            status={error ? 'error' : undefined}
          />

          <ErrorMessage message={error?.message} />
        </Stack>
      )}
    />
  );
});

OrderPriceField.displayName = 'OrderPriceField';
