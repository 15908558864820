import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { WhatIncluded } from 'widgets';
import { getTitle } from 'shared/helpers';
// import { descriptions, getItems } from '../consts';
import { descriptions } from '../../plans/consts';
import { getItems } from '../../plans/helpers';
import { IPlan } from '../interfaces';
import { modal, modalChildren } from '../styles';
// import { TPlan } from '../types';

export const Plan = (props: IPlan) => {
  const {
    plan,
    isOpen,
    closeHandler,
  } = props;

  const {
    t, 
  } = useTranslation();

  if (!plan) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <WhatIncluded
        title={getTitle(plan.planName, t)}
        description={descriptions(plan.planName, t)}
        items={getItems(plan, t)}
      />
    </Modal>
  );
};
