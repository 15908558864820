import { IPlans } from '../interfaces';

export const getItems = (item: IPlans['items'][0], t: any) => {
  const smartBotCommissionPercent = item.smartBotCommissionPercent;
  const exchangeAccountsConnectedLimit = item.exchangeAccountsConnectedLimit;
  const activeSmartTradesLimit = item.activeSmartTradesLimit;
  const activeBotsLimit = item.activeBotsLimit;

  switch (true) {
  case item.planName === 'basic':
    const basicItems = t('modal.plans.items.basic.included.items', {
      returnObjects: true,
      smartBotCommissionPercent,
      exchangeAccountsConnectedLimit,
      activeSmartTradesLimit,
      activeBotsLimit,
    });

    return basicItems;
  case item.planName.includes('advanced'):
    const advancedItems = t('modal.plans.items.advanced.included.items', {
      returnObjects: true,
      smartBotCommissionPercent,
      exchangeAccountsConnectedLimit,
      activeSmartTradesLimit,
      activeBotsLimit,
    });

    return advancedItems;
  case item.planName.includes('pro'):
    const proItems = t('modal.plans.items.pro.included.items', {
      returnObjects: true,
      smartBotCommissionPercent,
      exchangeAccountsConnectedLimit,
      activeSmartTradesLimit,
      activeBotsLimit,
    });

    return proItems;
  default:
    return [];
  }
};
