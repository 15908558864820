import { Typography } from '@mui/material';
import { SmartTrade } from 'entities/new-terminal/model/types/new-terminal-schema';
import { CurrentSymbol } from 'entities/terminal/model/types/terminal-schema';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { FundsFormWrapper } from '../actions-modal-content/components/funds-form-wrapper/funds-form-wrapper';

interface TradeActionsContentProps {
  type: string;
  tradeSymbol: CurrentSymbol;
  trade: SmartTrade;
  exchangeAccount: any;
  onClose: () => void;
  t: any;
}

export const getTradeActionsContent = ({
  type,
  tradeSymbol,
  trade,
  exchangeAccount,
  onClose,
  t,
}: TradeActionsContentProps) => {
  const getTitle = () => {
    switch (type) {
    case 'addFunds': return t('modal.terminal.funds.add.title');
    case 'reduceFunds': return t('modal.terminal.funds.reduce.title');
    case 'closeByMarket': return t('modal.terminal.market.close.title');
    case 'closeByLimit': return t('modal.terminal.limit.close.title');
    case 'openByMarket': return t('modal.terminal.market.open.title');
    case 'openByLimit': return t('modal.terminal.limit.open.title');
    case 'edit': return t('modal.terminal.edit.title');
    case 'share': return t('modal.terminal.edit.share');
    case 'cancel': return t('modal.terminal.cancel.title');
    default: return '';
    }
  };

  const getDescription = () => {
    const price = currencyFormatter((+trade.currentExchangePrice || 0), 2);
    const quote = trade.quoteSymbol;
    const returnPercent = trade.returnPercent;
  
    switch (type) {
    case 'addFunds': return t('modal.terminal.funds.add.description');
    case 'closeByMarket':
    case 'closeByLimit': 
      if (trade.sideFirstStep === 'BUY') {
        return t('modal.terminal.close.description.buy', {
          price,
          quote,
          returnQuote: Number(trade?.returnQuote || 0).toFixed(2),
          returnPercent: Number(returnPercent || 0).toFixed(2),
        });
      }
  
      return t('modal.terminal.close.description.other', {
        price,
        quote,
        returnBase: Number(trade?.returnQuote || 0).toFixed(2),
        baseSymbol: trade.baseSymbol,
        returnPercent: Number(returnPercent || 0).toFixed(2),
      });
    case 'openByMarket':
    case 'openByLimit':
      return t('modal.terminal.open.description', {
        price,
        quote,
      });
    case 'edit': return t('modal.terminal.edit.description');
    case 'cancel': return t('modal.terminal.cancel.description');
    case 'share': return t('modal.terminal.share.description');
    default: return '';
    }
  };

  const description = getDescription();

  return (
    <>
      <Typography
        variant='h6'
        gutterBottom
      >
        {getTitle()}
      </Typography>
      
      {(type === 'addFunds' || type === 'reduceFunds') && (
        <FundsFormWrapper 
          isAddFunds={type === 'addFunds'}
          tradeSymbol={tradeSymbol}
          trade={trade}
          exchangeAccount={exchangeAccount}
          onClose={onClose}
        />
      )}

      {description && (
        <Typography
          variant='body1'
          sx={{
            my: 2, 
          }}
        >
          {description}
        </Typography>
      )}
    </>
  );
};
