import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { WebSocketHandlerParams } from '../types/chart-view.types';
import { WebSocketHandler } from '../types/chart-view.types';

export function createCryptoCompareHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        const endpoint = 'wss://data-streamer.cryptocompare.com/';
        ws = new WebSocket(endpoint);
    
        ws.onopen = () => {
          const subMsg = {
            action: 'SUBSCRIBE',
            type: 'spot_v1_latest_tick',
            groups: [
              'VALUE',
              'CURRENT_HOUR',
            ],
            market: params.exchangeName.toLowerCase(),
            instruments: [params.symbolInfo.pro_name],
          };
            
          if (ws && ws.readyState === WebSocket.OPEN) {
              ws!.send(JSON.stringify(subMsg));
          }
    
          // Heartbeat: каждые 30 сек
          pingInterval = window.setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify({
                action: 'Ping', 
              }));
            }
          }, 30000);
          //@ts-ignore
          resolve(ws);
        };
    
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
            
          if (data.TYPE === '959') {
            const bar = {
              time: data.PRICE_LAST_UPDATE_TS * 1000,
              open: +data.CURRENT_HOUR_OPEN,
              high: +data.CURRENT_HOUR_HIGH,
              low: +data.CURRENT_HOUR_LOW,
              close: +data.PRICE,
              volume: +data.CURRENT_HOUR_VOLUME,
            };
            params.dispatch(terminalActions.setLimitLastPrice(bar.close));
            params.onTick(bar);
          }
        };
    
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
    
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
        };
      }),
    disconnect: () => {
      if (ws) ws.close();
      if (pingInterval) clearInterval(pingInterval);
    },
  };
}
