import { useState } from 'react';
import { Eye } from 'shared/icons';
import { LabelInput } from '../../label-input';
import { IPasswordProps } from '../interfaces';
import { ICON } from '../styles';

export const Password = (props: IPasswordProps) => {
  const {
    value,
    onChange,
    label,
    placeholder,
  } = props;

  const [type, setType] = useState<'text' | 'password'>('password');

  const changeType = () => {
    setType((prev) => prev === 'password' ? 'text' : 'password');
  };

  return (
    <LabelInput
      type={type}
      label={label || 'Password'}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      icon={(
        <div
          onClick={changeType}
          style={ICON}
        >
          {Eye}
        </div>
      )}
    />
  );
};
