import { Box } from '@mui/material';
import { Button } from 'antd';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { useTranslation } from 'react-i18next';
import { inner, wrapper } from '../../../../../buy/styles';
import { useAddFundsMethods } from '../../hooks/add-funds-methods';
import { useReduceFundsMethods } from '../../hooks/reduce-funds-methods';
import { useFundsFormSubmission } from '../../hooks/use-funds-form-submission';
import { AddFundsBuyInputs } from '../add-funds/add-funds-buy-inputs';
import { AddFundsSellInputs } from '../add-funds/add-funds-sell-inputs';
import { FundsFormWalletInfo } from '../funds-form-wallet-info/funds-form-wallet-info';
import { ReduceFundsBuyInputs } from '../reduce-funds/reduce-funds-buy-inputs';
import { ReduceFundsSellInputs } from '../reduce-funds/reduce-funds-sell-inputs';

interface FundsFormProps {
  isAddFunds: boolean;
  isBuyTrade: boolean;
  currentSymbol: any;
  userWalletBaseAssetBalance: Partial<WalletData>;
  userWalletQuoteAssetBalance: WalletData;
  onClose: () => void;
}

export const FundsForm = (props: FundsFormProps) => {
  const {
    isAddFunds, 
    isBuyTrade,
    currentSymbol,
    userWalletBaseAssetBalance,
    userWalletQuoteAssetBalance,
    onClose,
  } = props;
  
  const {
    handleAddFundsBuyTradeTriggerPriceChange,
    handleAddFundsBuyTradeOrderPriceChange,
    handleAddFundsBuyTradeUnitsChange,
    handleAddFundsBuyTradeTotalChange,
    handleAddFundsBuyTradeSliderChange,
    handleAddFundsSellTradeTriggerPriceChange,
    handleAddFundsSellTradeOrderPriceChange,
    handleAddFundsSellTradeUnitsChange,
    handleAddFundsSellTradeTotalChange,
    handleAddFundsSellTradeSliderChange,
  } = useAddFundsMethods({
    currentSymbol,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance: userWalletBaseAssetBalance as WalletData,
  });
  
  const {
    handleReduceFundsBuyTradeUnitsChange,
    handleReduceFundsBuyTradeTotalChange,
    handleReduceFundsBuyTradeOrderPriceChange,
    handleReduceFundsBuyTradeSliderChange,
    handleReduceFundsSellTradeUnitsChange,
    handleReduceFundsSellTradeTotalChange,
    handleReduceFundsSellTradeOrderPriceChange,
    handleReduceFundsSellTradeSliderChange,
  } = useReduceFundsMethods({
    currentSymbol,
    userWalletBaseAssetBalance,
    userWalletQuoteAssetBalance,
  });
  
  const {
    handleFormSubmit,
    isValid,
    isSubmitting,
  } = useFundsFormSubmission({
    onClose,
    isAddFunds,
  });

  const {
    t, 
  } = useTranslation();
  
  return (
    <Box
      sx={wrapper}
      component='form'
      onSubmit={handleFormSubmit}
    >
      <Box sx={inner}>
        <FundsFormWalletInfo
          currentSymbol={currentSymbol}
          //чтобы менять текст в зависимости от сторон add/reduce
          isAddFunds={isAddFunds}
          isBuyTrade={isBuyTrade}
          userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
          userWalletBaseAssetBalance={userWalletBaseAssetBalance as WalletData}
        />
      </Box>

      {isAddFunds ? (
        isBuyTrade ? (
          <AddFundsBuyInputs
            userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
            onTriggerPriceChange={handleAddFundsBuyTradeTriggerPriceChange}
            onUnitsChange={handleAddFundsBuyTradeUnitsChange}
            onTotalChange={handleAddFundsBuyTradeTotalChange}
            onOrderPriceChange={handleAddFundsBuyTradeOrderPriceChange}
            onSliderChange={handleAddFundsBuyTradeSliderChange}
            currentSymbol={currentSymbol}
          />
        ) : (
          <AddFundsSellInputs
            userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
            onTriggerPriceChange={handleAddFundsSellTradeTriggerPriceChange}
            onUnitsChange={handleAddFundsSellTradeUnitsChange}
            onTotalChange={handleAddFundsSellTradeTotalChange}
            onOrderPriceChange={handleAddFundsSellTradeOrderPriceChange}
            onSliderChange={handleAddFundsSellTradeSliderChange}
            currentSymbol={currentSymbol}
          />
        )
      ) : (
        isBuyTrade ? (
          <ReduceFundsBuyInputs
            currentSymbol={currentSymbol}
            onUnitsChange={handleReduceFundsBuyTradeUnitsChange}
            onTotalChange={handleReduceFundsBuyTradeTotalChange}
            onOrderPriceChange={handleReduceFundsBuyTradeOrderPriceChange}
            onSliderChange={handleReduceFundsBuyTradeSliderChange}
          />
        ) : (
          <ReduceFundsSellInputs
            currentSymbol={currentSymbol}
            onUnitsChange={handleReduceFundsSellTradeUnitsChange}
            onTotalChange={handleReduceFundsSellTradeTotalChange}
            onOrderPriceChange={handleReduceFundsSellTradeOrderPriceChange}
            onSliderChange={handleReduceFundsSellTradeSliderChange}
          />
        )
      )}
      
      <Button
        htmlType='submit'
        type='primary'
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
      >
        {t('form.funds.button')}
      </Button>
    </Box>
  );
};
