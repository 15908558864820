import { Box } from '@mui/material';
//@TODO перенести импорты в рамках оптимизации в shared слой
import { Wallet, InfoIcon } from 'pages/manual-trading/trading-terminal/components/buy/icons';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useTranslation } from 'react-i18next';
import { Text, Title, Tooltip } from 'shared/ui';
//@TODO перенести импорты в рамках оптимизации
import { price, priceWrapper, title, tooltipText } from '../../../../../buy/styles';

interface FundsFormWalletInfoProps {
  isAddFunds: boolean;
  isBuyTrade: boolean;
  currentSymbol: {
    quoteAsset: string;
    baseAsset: string;
    chartPrecision: number;
    baseAssetPrecision: number;
    minNotional: string;
    maxNotional: string;
    lotMin: string;
    lotMax: string;
  };
  userWalletQuoteAssetBalance: {
    free: string;
  };
  userWalletBaseAssetBalance: {
    free: string;
  };
}

export const FundsFormWalletInfo = ({
  isAddFunds,
  currentSymbol,
  userWalletQuoteAssetBalance,
  userWalletBaseAssetBalance,
  isBuyTrade,
}: FundsFormWalletInfoProps) => {
  const {
    t, 
  } = useTranslation();
    
  const getActionTitle = () => {
    if (isAddFunds && isBuyTrade) return t('modal.addFunds.wallet.title.buy');
    if (isAddFunds && !isBuyTrade) return t('modal.addFunds.wallet.title.sell');
    if (!isAddFunds && isBuyTrade) return t('modal.addFunds.wallet.title.sell');
    return t('modal.addFunds.wallet.title.buy');
  };

  const getFormattedTitle = () => {
    const actionTitle = getActionTitle();
    const assetName = currentSymbol?.baseAsset;

    return `${actionTitle} ${assetName}`;
  };
  
  const getBalanceToShow = () => {
    if ((isAddFunds && isBuyTrade) || (!isAddFunds && !isBuyTrade)) {
      return {
        balance: userWalletQuoteAssetBalance?.free?.toString(),
        precision: currentSymbol?.chartPrecision,
        min: Number(currentSymbol.minNotional),
        max: Number(currentSymbol.maxNotional),
        asset: currentSymbol?.quoteAsset,
      };
    }
    return {
      balance: userWalletBaseAssetBalance?.free?.toString(),
      precision: currentSymbol?.baseAssetPrecision,
      min: Number(currentSymbol.lotMin),
      max: Number(currentSymbol.lotMax),
      asset: currentSymbol?.baseAsset,
    };
  };
  
  const balanceInfo = getBalanceToShow();
    
  return (
    <>
      <Title styles={title}>
        {getFormattedTitle()}
      </Title>

      <Box sx={priceWrapper}>
        <Box
          width={12}
          height={12}
          display='flex'
        >
          {Wallet}
        </Box>

        <Text
          type='success'
          styles={price}
        >
          {`${formatByPrecisionAndTrim(
            balanceInfo.balance,
            balanceInfo.precision,
            balanceInfo.min,
            balanceInfo.max,
          )} ${balanceInfo.asset}`}
        </Text>

        <Tooltip
          title={(
            <Box maxWidth={188}>
              <Title
                level={5}
                styles={tooltipText}
              >
                {t('modal.addFunds.wallet.tooltip', {
                  asset: balanceInfo.asset,
                })}
              </Title>
            </Box>
          )}
        >
          <Box
            width={13}
            height={13}
            display='flex'
          >
            {InfoIcon}
          </Box>
        </Tooltip>
      </Box>
    </>
  );
};
