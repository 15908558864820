interface IButton {
  title?: string;
  action: () => void;
}
interface IGetErrorParams {
  isOpened: boolean;
  title?: string;
  description?: string;
  button?: IButton;
  t: any;
}
export const getError = (params?: IGetErrorParams) => {
  const {
    isOpened,
    title,
    description,
    button,
    t,
  } = params || {};

  const error = {
    isOpened: isOpened,
    title: title || t?.('error.exchange.create.title'),
    description: description || t?.('error.exchange.create.message'),
    button: {
      title: button?.title || 'Okay',
      action: button?.action,
    },
  };

  return error;
};
