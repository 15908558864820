import { SxProps, Theme } from '@mui/material';

export const INNER: SxProps<Theme> = {
  flexDirection: {
    xs: 'column',
    md: 'row',
  },
  alignItems: 'center',
  flex: 1,
  width: '100%',
  gap: {
    xs: '30px',
    md: '60px',
  },
  justifyContent: 'space-between',
};
