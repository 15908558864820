import { SxProps, Theme } from '@mui/material';

export const BLOCKS: SxProps<Theme> = {
  display: 'grid',
  gap: '30px',
  // gridTemplateColumns: 'repeat(auto-fit, minmax(340px, 1fr))',
  gridTemplateColumns: {
    xs: '1fr',
    md: 'repeat(2, 1fr)',
  },
  flex: 1,
};
