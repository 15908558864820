import { Box, Stack } from '@mui/material';
import { getExchange, getMarket } from 'entities/choose-account';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCoins } from 'shared/consts';
import { Link, Title } from 'shared/ui';
import { COIN, INNER, LINK, TITLE, WRAPPER } from '../styles';

export const Pairs = () => {
  const exchange = useSelector(getExchange);
  const market = useSelector(getMarket);
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        {t('configurator.basic.pairs.preffix')} {' '}

        <Link
          type='success'
          styles={LINK}
          href='https://www.tradingview.com/crypto-screener/'
          target='_blank'
        >
          {t('configurator.basic.pairs.links.screener')}
        </Link>
        
        {' '} {t('configurator.basic.pairs.and')} {' '}

        <Link
          type='success'
          styles={LINK}
          href='https://www.tradingview.com/heatmap/crypto'
          target='_blank'
        >
          {t('configurator.basic.pairs.links.heatmap')}
        </Link>
      </Title>

      <Stack sx={INNER}>
        {getCoins(exchange, market).map((item, index) => (
          <Box
            key={index}
            sx={COIN}
          >
            <Link
              href={item.link}
              target='_blank'
            >
              {item.coin}
            </Link>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};
