import { Box } from '@mui/material';
import { Slider as Root } from 'antd';
import { ISlider } from '../interfaces';

export const Slider = (props: ISlider) => {
  const {
    defaultValue,
    value,
    onChange,
    reversed,
    max,
    disabled,
  } = props;

  return (
    <Box flex={1} width='100%'>
      <Root
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        reverse={reversed}
        max={max}
        disabled={disabled}
      />
    </Box>
  );
};
