import { getLimitLastPrice } from 'entities/terminal/model/selectors/get-limit-last-price/get-limit-last-price';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AdaptedSymbol } from 'shared/helpers/adapt-exchange-symbol';
import { mapSharedTradeToFormValues } from '../components/active-trades/components/active-trade/helpers/map-shared-trade';
import { calculatePriceValues } from '../components/buy/hooks/update-price-values';
import { SelectedExchange } from '../components/trading-panel/trading-panel';
import { calculateDefaultFormValues } from '../helpers/calculate-default-form-values';
import { DEFAULT_SYMBOL } from '../helpers/form-hook-helpers';
import { getFormDefaultValues } from '../helpers/get-form-default-values';
import { formatByPrecisionAndTrim } from '../helpers/helpers';

interface TradingFormProps {
  chartLastPrice: number;
  currentSymbol: AdaptedSymbol;
  selectedExchange?: SelectedExchange;
  userWalletBaseAssetBalance: WalletData;
  userWalletQuoteAssetBalance: WalletData;
  editingTrade?: any;
  sharedTrade?: any;
}

export const useTradingForm = ({
  chartLastPrice,
  currentSymbol,
  selectedExchange,
  userWalletBaseAssetBalance,
  userWalletQuoteAssetBalance,
  editingTrade,
  sharedTrade,
}: TradingFormProps) => {

  const [isPriceUpdatePending, setIsPriceUpdatePending] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: getFormDefaultValues({
      currentSymbol,
      userWalletQuoteAssetBalance,
    }),
  });

  const {
    watch, setValue, trigger,
  } = methods;
  
  useEffect(() => {
    setValue('userWalletQuoteAsset', userWalletQuoteAssetBalance);  
  }, [userWalletQuoteAssetBalance, setValue]);
  
  const selectedSide = watch('selectedSide');
  const orderType = watch('orderType');
  const isSkipBaseOrder = watch('skipBaseOrder');
  const lastChangedField = watch('lastChangedField');
  const limitLastPrice = useSelector(getLimitLastPrice);
  const symbolChangeRef = useRef(false);
  
  
  const isFirstRender = useRef(true);
  const prevSymbolRef = useRef(currentSymbol?.symbol);
  const prevSideRef = useRef(selectedSide);

  const prevChartPriceRef = useRef(chartLastPrice);
  
  // для чего этот useEffect?
  // только для skipBaseOrder?
  useEffect(() => {
    if (editingTrade || sharedTrade) {
      return;
    }

    if (chartLastPrice === 0) return;

    const priceValues = calculatePriceValues({
      price: orderType === 'market' ? limitLastPrice : chartLastPrice,
      currentSymbol,
      userWalletQuoteAssetBalance,
      userWalletBaseAssetBalance,
      selectedSide,
      orderType,
      isSkipBaseOrder,
      lastChangedField,
    });

    Object.entries(priceValues).forEach(([key, value]) => {
      setValue(key as any, value);
    });

    // Очищаем значения полей, которые не нужны при переключении
    if (isSkipBaseOrder) {
      setValue('orderPrice', '');
      setValue('triggerPrice', '');
      setValue('units', '');
      setValue('total', '');
      setValue('slider', 0);
    } else {
      setValue('skipBaseUnits', '');
      setValue('skipBaseTotal', '');
      setValue('skipBaseSlider', 0);
      setValue('boughtPrice', '');
    }

    trigger();
  }, [isSkipBaseOrder]);
  
  
  // для какого сценария используется?
  // для переключения orderType
  // где это заменить?
  useEffect(() => {
    if (editingTrade || sharedTrade) {
      return;
    }

    if (chartLastPrice === 0 || isSkipBaseOrder) return;

    const priceValues = calculatePriceValues({
      price: orderType === 'market' 
        ? (limitLastPrice && limitLastPrice !== 0 ? limitLastPrice : chartLastPrice) 
        : chartLastPrice,
      currentSymbol,
      userWalletQuoteAssetBalance,
      userWalletBaseAssetBalance,
      selectedSide,
      orderType,
      isSkipBaseOrder,
      lastChangedField,
    });

    Object.entries(priceValues).forEach(([key, value]) => {
      setValue(key as any, value);
    });

    // Очищаем triggerPrice при переключении с conditional на другие типы
    if (orderType !== 'conditional') {
      setValue('triggerPrice', '');
    }

    trigger();
  }, [orderType]);

  //// для корректного рендера skipBaseOrder sharedTrade
  /// TODO возможно будет интерферить при удалении sharedTrade после установки значений
  useEffect(() => {
    if (sharedTrade) {
      setValue('skipBaseOrder', sharedTrade.baseOrder.type === 'SKIP_FIRST_STEP');
    }
  }, [sharedTrade]);
  
  
  useEffect(() => {
    if (chartLastPrice === 0 || !sharedTrade || !selectedExchange) {
      return;
    }

    const sharedTradeValuesMappedToForm = mapSharedTradeToFormValues({
      sharedTradeData: sharedTrade,
      currentSymbol: currentSymbol,
      selectedExchange: selectedExchange,
    });
 
    Object.entries(sharedTradeValuesMappedToForm).forEach(([key, value]) => {
      setValue(key as any, value);
    });
    
    trigger();
    window.history.replaceState(null, '', window.location.pathname);

  }, [sharedTrade, chartLastPrice, currentSymbol, selectedExchange]);
  
  
  // Первый useEffect для установки skipBaseOrder
  useEffect(() => {

    if (!editingTrade) {
      return;
    }
    
    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
  
    // Если символ изменился, обновляем ref
    if (isSymbolChanged) {
      prevSymbolRef.current = currentSymbol?.symbol;
      return; // Выходим из useEffect, чтобы не применять значения до обновления символа
    }
  
    if (editingTrade) {
      setValue('skipBaseOrder', editingTrade.skipBaseOrder);
      symbolChangeRef.current = false;
    }
  }, [editingTrade, currentSymbol?.symbol, setValue]);

  // Второй useEffect для установки остальных значений
  useEffect(() => {
    
    if (!editingTrade) {
      return;
    }
    
    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
  
    // Если символ изменился, обновляем ref
    if (isSymbolChanged) {
      prevSymbolRef.current = currentSymbol?.symbol;
      return; // Выходим из useEffect, чтобы не применять значения до обновления символа
    }
  
    if (editingTrade) {
      Object.entries(editingTrade).forEach(([key, value]) => {
        setValue(key as any, value);
      });
    }
    
    const timer = setTimeout(() => {
      trigger();
    }, 2000);

    // Очищаем таймер при размонтировании компонента
    return () => clearTimeout(timer);
    
  }, [editingTrade, currentSymbol?.symbol, setValue]);
  
  // для какого сценария используется? - editing + share trade - разделить 
  // кажется используется для любого сценария:
  // стандартный флоу в котором мапятся editingTrade и sharedTrade если они есть
  // если нет то мапятся стандартные значения и идется по стандартному флоу
  
  useEffect(() => {
    if (chartLastPrice === 0 && !sharedTrade) {
      return;
    }
    
    trigger();
    
    if (editingTrade) {
      return;
    }
            
    const isSymbolChanged = prevSymbolRef.current !== currentSymbol?.symbol;
    const isSideChanged = prevSideRef.current !== selectedSide;
    const isValidPrice = chartLastPrice > 0;

    if (isSymbolChanged && !sharedTrade) {
      setIsPriceUpdatePending(true);
      const timer = setTimeout(() => {
        setIsPriceUpdatePending(false);
        
        if (isValidPrice) {
          setValue('currentSymbol', {
            ...DEFAULT_SYMBOL,
            ...currentSymbol,
          });
          
          if (!editingTrade) {
            setValue('orderPrice', 
              formatByPrecisionAndTrim(
                chartLastPrice.toString(), 
                currentSymbol?.quoteAssetPrecision || 8, 
                Number(currentSymbol?.priceMin) || 0, Number(currentSymbol?.priceMax) || 999999999));
            setValue('triggerPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol?.quoteAssetPrecision || 8, Number(currentSymbol?.priceMin) || 0, Number(currentSymbol?.priceMax) || 999999999));
          }
  
          const priceValues = calculatePriceValues({
            price: orderType === 'market' ? limitLastPrice : chartLastPrice,
            currentSymbol,
            userWalletQuoteAssetBalance,
            userWalletBaseAssetBalance,
            selectedSide,
            orderType,
            isSkipBaseOrder,
            lastChangedField,
          });

          Object.entries(priceValues).forEach(([key, value]) => {
            setValue(key as any, value);
          });
          
          trigger();
          
          prevSymbolRef.current = currentSymbol?.symbol;
          prevSideRef.current = selectedSide;
          prevChartPriceRef.current = chartLastPrice;
          isFirstRender.current = false;
        }
      }, 1000);
  
      return () => clearTimeout(timer);
    } 
    
    // почему столько рефов и в каком сценарии используется?
    if ((isFirstRender.current || isSymbolChanged || isSideChanged) && isValidPrice && !sharedTrade && !editingTrade) {
      setValue('currentSymbol', {
        ...DEFAULT_SYMBOL,
        ...currentSymbol,
      });
      
      if (!editingTrade) {
        setValue('orderPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol?.quoteAssetPrecision || 8, Number(currentSymbol?.priceMin) || 0, Number(currentSymbol?.priceMax) || 999999999));
        setValue('triggerPrice', formatByPrecisionAndTrim(chartLastPrice.toString(), currentSymbol?.quoteAssetPrecision || 8, Number(currentSymbol?.priceMin) || 0, Number(currentSymbol?.priceMax) || 999999999));
      }

      setValue('userWalletQuoteAsset', userWalletQuoteAssetBalance);
      
      const defaultFormValues = calculateDefaultFormValues({
        chartLastPrice,
        selectedSide,
        currentSymbol,
        userWalletQuoteAssetBalance,
      });
      
      Object.entries(defaultFormValues).forEach(([key, value]) => {
        setValue(key as any, value);
      });
      
      trigger();
      
      prevSymbolRef.current = currentSymbol?.symbol;
      prevSideRef.current = selectedSide;
      prevChartPriceRef.current = chartLastPrice;
      isFirstRender.current = false;
    }
    
    if (!isPriceUpdatePending) {
      prevSymbolRef.current = currentSymbol?.symbol;
      prevSideRef.current = selectedSide;
      if (isValidPrice) {
        prevChartPriceRef.current = chartLastPrice;
      }
      isFirstRender.current = false;
      return;
    }
    
    return;
  }, [
    userWalletBaseAssetBalance?.free,
    userWalletQuoteAssetBalance,
    currentSymbol,
    trigger,
    chartLastPrice,
    currentSymbol?.symbol,
    selectedSide,
    currentSymbol?.quoteAssetPrecision,
    currentSymbol?.priceMin,
    currentSymbol?.priceMax,
    setValue,
    isPriceUpdatePending,
    editingTrade,
  ]);
  
  return methods;
};
