import { Stack, Box } from '@mui/material';
import { Segmented } from 'antd';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { ErrorMessage } from 'pages/manual-trading/trading-terminal/components/error-message';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, LabelInput, SingleSelect, Text, Slider } from 'shared/ui';
import { segments, triggerPriceSegments } from '../../../../../fields/consts';
import { action, actionTitle, inner, sliderWrapper, wrapper, actions } from '../../../../../fields/styles';
import { OrderPriceField } from '../order-price-field/order-price-field';

interface AddFundsSellInputsProps {
    onUnitsChange: (value: string) => void;
    onTotalChange: (value: string) => void;
    onOrderPriceChange: (value: string) => void;
    onSliderChange: (value: number) => void;
    onTriggerPriceChange: (value: string) => void;
    currentSymbol?: any;
    userWalletQuoteAssetBalance: WalletData;
}

export const AddFundsSellInputs = (props: AddFundsSellInputsProps) => {
  const {
    onUnitsChange,
    onTotalChange,
    onOrderPriceChange,
    onSliderChange,
    onTriggerPriceChange,
    currentSymbol,
    userWalletQuoteAssetBalance,
  } = props;
    
  const {
    control,
    formState: {
      errors,
    },
    trigger,
    watch,
    setValue,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
    
  const changeSegmentHandler = (value: string) => {
    setValue('orderType', value);
  };

  const changeTriggerPriceSegmentHandler = (value: string) => {
    setValue('conditionalOrderType', value);
  };
  
  const orderType = watch('orderType');
  const conditionalOrderType = watch('conditionalOrderType');

  const renderOrderPriceField = () => {
    if (orderType === 'conditional' && conditionalOrderType === 'market') {
      return null;
    }

    return (
      <OrderPriceField
        currentSymbol={currentSymbol}
        onOrderPriceChange={onOrderPriceChange}
        disabled={orderType === 'market'}
        preventPriceUpdate={true}
      />
    );
  };
  
  
  const renderUnitsField = () => (
    <Controller
      name='onAddFundsUnits'
      control={control}
      rules={{
        required: t('validation.funds.units.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.funds.units.number');
          }

          if (numValue < Number(currentSymbol.lotMin)) {
            return t('validation.funds.units.min', {
              lotMin: getTrailedZeroCutted(currentSymbol.lotMin),
            });
          }

          if (numValue > Number(currentSymbol.lotMax)) {
            return t('validation.funds.units.max', {
              lotMax: getTrailedZeroCutted(currentSymbol.lotMax),
            });
          }

          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('form.funds.units.title')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onUnitsChange(value);
              }
              trigger('onAddFundsUnits');
              trigger('onAddFundsSlider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
              field.onChange(formattedValue);
              onUnitsChange(formattedValue);
              trigger('onAddFundsUnits');
              trigger('onAddFundsTotal');
              trigger('onAddFundsSlider');
            }}
            icon={currentSymbol.baseAsset}
            status={errors.onAddFundsUnits ? 'error' : undefined}
          />

          <ErrorMessage message={errors?.onAddFundsUnits?.message} />
        </Stack>
      )}
    />
  );
  
  const renderTotalField = () => (
    <Controller
      name='onAddFundsTotal'
      control={control}
      rules={{
        required: t('validation.funds.total.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (numValue > Number(userWalletQuoteAssetBalance?.free)) {
            return t('validation.funds.total.balance');
          }

          if (isNaN(numValue)) {
            return t('validation.funds.total.number');
          }

          if (numValue < Number(currentSymbol.minNotional)) {
            return t('validation.funds.total.min', {
              minNotional: getTrailedZeroCutted(currentSymbol.minNotional),
            });
          }

          if (numValue > Number(currentSymbol.maxNotional)) {
            return t('validation.funds.total.max', {
              maxNotional: getTrailedZeroCutted(currentSymbol.maxNotional),
            });
          }
          
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('form.funds.total.title')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onTotalChange(value);
              }
              trigger('onAddFundsTotal');
              trigger('onAddFundsSlider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
              field.onChange(formattedValue);
              onTotalChange(formattedValue);
              trigger('onAddFundsTotal');
              trigger('onAddFundsUnits');
              trigger('onAddFundsSlider');
            }}
            icon={currentSymbol.quoteAsset}
            status={errors.onAddFundsTotal ? 'error' : undefined}
          />

          <ErrorMessage message={errors?.onAddFundsTotal?.message} />
        </Stack>
      )}
    />
  );
  
  const renderSlider = () => (
    <Stack height={53}>
      <Box sx={sliderWrapper}>
        <Controller
          name='onAddFundsSlider'
          control={control}
          rules={{
            max: {
              value: 100,
              message: t('validation.funds.slider.max'),
            },
            min: {
              value: 1,
              message: t('validation.funds.slider.min'),
            },
          }}
          render={({
            field,
          }) => (
            <Slider
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                onSliderChange(value);
                trigger('onAddFundsSlider');
                trigger('onAddFundsTotal');
                trigger('onAddFundsUnits');
              }}
            />
          )}
        />
        
        <Controller
          name='onAddFundsSlider'
          control={control}
          render={({
            field,
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onSliderChange(parseFloat(value));
                  trigger('onAddFundsSlider');
                  trigger('onAddFundsTotal');
                  trigger('onAddFundsUnits');
                }
              }}
              placeholder='100%'
              maxWidth={58}
              icon='%'
              status={errors.onAddFundsSlider ? 'error' : undefined}
            />
          )}
        />
      </Box>

      <ErrorMessage message={errors?.onAddFundsSlider?.message} />
    </Stack>
  );
  
  const renderConditionalFields = () => (
    <>
      <Stack
        gap={0.5}
        height={81}
      >
        <Box sx={actions}>
          <Text
            type='secondary'
            styles={actionTitle}
          >
            {t('form.funds.price.trigger.title')}
          </Text>
          <Box sx={action}>
            <Controller
              name='onAddFundsTriggerPrice'
              control={control}
              rules={{
                required: t('validation.funds.price.trigger.required'),
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) {
                    return t('validation.funds.price.trigger.number');
                  }
                  
                  if (numValue < Number(currentSymbol.priceMin)) {
                    return t('validation.funds.price.trigger.min', {
                      priceMin: getTrailedZeroCutted(currentSymbol.priceMin),
                    });
                  }

                  if (numValue > Number(currentSymbol.priceMax)) {
                    return t('validation.funds.price.trigger.max', {
                      priceMax: getTrailedZeroCutted(currentSymbol.priceMax),
                    });
                  }

                  return true;
                },
              }}
              render={({
                field,
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onTriggerPriceChange(value);
                      trigger('onAddFundsTriggerPrice');
                    }
                  }}
                  onBlur={() => {
                    const formattedValue = formatByPrecisionAndTrim(
                      field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                    field.onChange(formattedValue);
                    onTriggerPriceChange(formattedValue);
                    trigger('onAddFundsTriggerPrice');
                  }}
                  icon={currentSymbol.quoteAsset}
                  status={errors.onAddFundsTriggerPrice ? 'error' : undefined}
                />
              )}
            />

            <Controller
              name='triggerPriceType'
              control={control}
              render={({
                field,
              }) => (
                <SingleSelect
                  maxWidth='max-content'
                  select={{
                    value: field.value,
                    placeholder: 'Last',
                    onChange: (value) => {
                      field.onChange(value);
                      trigger('triggerPriceType');
                    },
                  }}
                  options={[{
                    label: t('form.funds.price.trigger.items.last'),
                    value: 'last',
                  }, {
                    label: t('form.funds.price.trigger.items.bid'),
                    value: 'bid',
                  }, {
                    label: t('form.funds.price.trigger.items.ask'),
                    value: 'ask',
                  }]}
                />
              )}
            />
          </Box>
        </Box>
        
        <ErrorMessage message={errors?.onAddFundsTriggerPrice?.message} />
      </Stack>

      <Controller
        name='conditionalOrderType'
        control={control}
        render={({
          field,
        }) => (
          <Segmented
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              changeTriggerPriceSegmentHandler(value);
            }}
            options={triggerPriceSegments(t)}
            block={true}
          />
        )}
      />
    </>
  );
  
  return (
    <Box sx={wrapper}>
      <Segmented
        value={orderType}
        onChange={changeSegmentHandler}
        options={segments(t)}
        block={true}
      />

      {orderType === 'conditional' && renderConditionalFields()}

      <Box sx={inner}>
        <>
          {renderOrderPriceField()}
          {renderTotalField()}
          {renderUnitsField()}
          {renderSlider()}
        </>
      </Box>
    </Box>
  );
};
