import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Copy } from 'widgets';
import { Text } from 'shared/ui';
import { copyContent, copyLabelText, copyLabelWrapper } from 'shared/ui/modals/add-new-bot/styles';
import { closeTradeCodeItem, openTradeCodeItem } from '../../../consts';
import { ITab } from '../../../interfaces';

export const Indicator = (props: ITab) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Box sx={copyContent}>
      <Copy
        label={(
          <Box sx={copyLabelWrapper}>
            <Text
              type='secondary'
              styles={copyLabelText}
            >
              {t('modal.alertSource.indicator.title')}
            </Text>

            <Text
              type='secondary'
              styles={{
                ...copyLabelText,
                fontSize: 10,
              }}
            >
              {t('modal.alertSource.indicator.description')}
            </Text>
          </Box>
        )}
        value={openTradeCodeItem(props.sourceUuid, props.secretToken)}
      />

      <Copy
        label={t('modal.alertSource.indicator.copy.title')}
        value={closeTradeCodeItem(props.sourceUuid, props.secretToken)}
      />
    </Box>
  );
};
