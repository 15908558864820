import { WebSocketHandler, WebSocketHandlerParams } from '../types/chart-view.types';
import { createCryptoCompareHandler } from './cryptocompare-handler';

/**
 * Функция-обёртка для fallback‑подключения.
 * Если первичный handler падает, инициируется подключение через CryptoCompare.
 */
export function createFallbackHandler(
  originalHandlerCreator: (params: WebSocketHandlerParams) => WebSocketHandler,
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let primaryHandler: WebSocketHandler | null = null;
  let fallbackHandler: WebSocketHandler | null = null;
  let isUsingFallback = false;
  let activeWs: WebSocket | null = null;
  
  function handlePrimaryError(error: any) {
    console.log('handlePrimaryError', error);
    console.warn(
      `Primary WebSocket for ${params.exchangeName} failed, switching to CryptoCompare as fallback.`,
    );
    if (primaryHandler) {
      primaryHandler.disconnect();
      primaryHandler = null;
    }
    if (!isUsingFallback) {
      isUsingFallback = true;
      fallbackHandler = createCryptoCompareHandler(params);
      fallbackHandler
        .connect()
        .then((ws) => {
          activeWs = ws;
        })
        .catch((err) => {
          console.error('Fallback connection failed:', err);
          if (params.onError) params.onError(err);
        });
    }
  }
  
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        try {
          primaryHandler = originalHandlerCreator(params);
          primaryHandler
            .connect()
            .then((ws) => {
              activeWs = ws;
              // Переопределяем onerror, чтобы отлавливать ошибки и инициировать fallback
              ws.onerror = (error: any) => {
                handlePrimaryError(error);
              };
              // Если за 5 секунд соединение не установлено – инициируем fallback
              setTimeout(() => {
                if (activeWs && activeWs.readyState !== WebSocket.OPEN) {
                  handlePrimaryError(new Error('Connection timeout'));
                }
              }, 5000);
              resolve(ws);
            })
            .catch((err) => {
              handlePrimaryError(err);
              if (fallbackHandler) {
                fallbackHandler
                  .connect()
                  .then((ws) => {
                    activeWs = ws;
                    resolve(ws);
                  })
                  .catch((fallbackErr) => {
                    reject(fallbackErr);
                  });
              } else {
                reject(err);
              }
            });
        } catch (e) {
          handlePrimaryError(e);
          if (fallbackHandler) {
            fallbackHandler
              .connect()
              .then((ws) => {
                activeWs = ws;
                resolve(ws);
              })
              .catch((fallbackErr) => {
                reject(fallbackErr);
              });
          } else {
            reject(e);
          }
        }
      }),
    disconnect: () => {
      if (primaryHandler) {
        primaryHandler.disconnect();
        primaryHandler = null;
      }
      if (fallbackHandler) {
        fallbackHandler.disconnect();
        fallbackHandler = null;
      }
      isUsingFallback = false;
      activeWs = null;
    },
  };
}
