import { Box, Stack } from '@mui/material';
import { getCurrentLanguage } from 'entities/user/model/selectors/get-language/get-language';
import { userActions } from 'entities/user/model/slices/user-slice';
import { UserSchema } from 'entities/user/model/types/user-schema';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSkyrexUuid, setLanguage } from 'shared/helpers/storage-helper';
import { SingleSelect } from 'shared/ui';
import { updateLanguage } from '../api';
import { languages } from '../consts';
import { ICONS } from '../icons';
import { INNER, WRAPPER } from '../styles';

export const Language = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const userUuid = useSelector(getSkyrexUuid);
  
  const dispatch = useDispatch();

  const {
    i18n, 
  } = useTranslation();

  const changeLanguage = useCallback((value: UserSchema['language']) => {
    dispatch(userActions.setLanguage(value));
    setLanguage(value);
    i18n.changeLanguage(value);

    if (!userUuid) {
      return;
    }

    updateLanguage(value);
  }, [i18n, dispatch, userUuid]);

  const labelRenderHandler = useCallback((props: any) => {
    return (
      <Box sx={WRAPPER}>
        {ICONS[props.value as 'en' | 'ru']}
      </Box>
    );
  }, []);

  const optionRenderHandler = (props: any) => {
    return (
      <Box sx={INNER}>
        {ICONS[props.value as 'en' | 'ru']}
      </Box>
    );
  };

  return (
    <Stack maxWidth={65}>
      <SingleSelect
        options={languages}
        labelRender={labelRenderHandler}
        optionRender={optionRenderHandler}
        select={{
          value: currentLanguage,
          placeholder: '',
          onChange: changeLanguage,
        }}
        maxWidth={65}
      />
    </Stack>
  );
};
