import { Box, Stack } from '@mui/material';
import { Button } from 'antd';
import { newTerminalActions } from 'entities/new-terminal/model/slice/new-terminal-slice';
import { SideProps } from 'pages/manual-trading/trading-terminal/types/order-panel.types';
import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConfirmTransaction } from 'shared/ui';
import { useFormSubmission } from '../../../hooks/use-form-submission';
import { Fields } from '../../fields';
import { WalletInfo } from '../../wallet-info/wallet-info';
import { AdditionalEntry, StopLoss, TakeProfit } from '../components';
import { useBaseOrderInputsLogic } from '../hooks/base-order-inputs';
import { useAdditionalEntryInputsLogic } from '../hooks/use-additional-entry-inputs';
import { useEntryEffects } from '../hooks/use-entry-effects';
import { useErrorEffects } from '../hooks/use-error-effects';
import { useFormUpdates } from '../hooks/use-form-updates';
import { useSectionTogglers } from '../hooks/use-section-togglers';
import { useStopLossInputsLogic } from '../hooks/use-stop-loss-inputs';
import { useSymbolEffects } from '../hooks/use-symbol-effects';
import { useTakeProfitInputsLogic } from '../hooks/use-take-profit-inputs';
import { inner, wrapper } from '../styles';

interface AdditionalEntrySectionProps {
  setIsSkipAdditionalEntry: (value: boolean) => void;
  handleAdditionalOrderPriceChange: (value: string) => void;
  handleAdditionalUnitsChange: (value: string) => void;
  handleAdditionalTotalChange: (value: string) => void;
  handleAdditionalSliderChange: (value: number) => void;
  handleAdditionalTriggerPriceChange: (value: string) => void;
  handleAdditionalOrderPricePercentChange: (value: string) => void;
  handleAddEntry: () => void;
  handleRemoveEntry: (index: number) => void;
}

const AdditionalEntrySection = memo(({
  setIsSkipAdditionalEntry,
  handleAdditionalOrderPriceChange,
  handleAdditionalUnitsChange,
  handleAdditionalTotalChange,
  handleAdditionalSliderChange,
  handleAdditionalTriggerPriceChange,
  handleAdditionalOrderPricePercentChange,
  handleAddEntry,
  handleRemoveEntry,
}: AdditionalEntrySectionProps) => {

  // useEffect(() => {
  //   console.log('====handleAdditionalOrderPriceChange changed');
  // }, [handleAdditionalOrderPriceChange]);

  // useEffect(() => {
  //   console.log('====handleAdditionalUnitsChange changed');
  // }, [handleAdditionalUnitsChange]);

  // useEffect(() => {
  //   console.log('====handleAdditionalTotalChange changed');
  // }, [handleAdditionalTotalChange]);

  // useEffect(() => {
  //   console.log('====handleAdditionalSliderChange changed');
  // }, [handleAdditionalSliderChange]);

  // useEffect(() => {
  //   console.log('====handleAdditionalTriggerPriceChange changed');
  // }, [handleAdditionalTriggerPriceChange]);

  // useEffect(() => {
  //   console.log('==== handleAdditionalOrderPricePercentChange changed');
  // }, [handleAdditionalOrderPricePercentChange]);

  // useEffect(() => {
  //   console.log('==== handleAddEntry changed');
  // }, [handleAddEntry]);

  // useEffect(() => {
  //   console.log('==== handleRemoveEntry changed');
  // }, [handleRemoveEntry]);

  return (
    <AdditionalEntry
      setIsSkipAdditionalEntry={setIsSkipAdditionalEntry}
      onAdditionalOrderPriceChange={handleAdditionalOrderPriceChange}
      onAdditionalUnitsChange={handleAdditionalUnitsChange}
      onAdditionalTotalChange={handleAdditionalTotalChange}
      onAdditionalSliderChange={handleAdditionalSliderChange}
      onAdditionalTriggerPriceChange={handleAdditionalTriggerPriceChange}
      onAdditionalOrderPricePercentChange={handleAdditionalOrderPricePercentChange}
      onAddEntry={handleAddEntry}
      onRemoveEntry={handleRemoveEntry}
    />
  );
});

AdditionalEntrySection.displayName = 'AdditionalEntrySection';

export const Buy = (props: SideProps) => {
  const dispatch = useDispatch();
  const {
    currentSymbol = {
      quoteAsset: 'USDT',
      baseAsset: 'BTC',
      quoteAssetPrecision: 8,
      baseAssetPrecision: 8,
      minNotional: '0',
      maxNotional: '0',
      lotMin: '0',
      lotMax: '0',
      symbol: '',
    },
    userWalletQuoteAssetBalance = {
      free: '0',
    },
    userWalletBaseAssetBalance = {
      free: '0',
    },
    exchangeAccountUuid,
    editingTrade,
  } = props;

  const {
    watch,
    trigger,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const isSkipBaseOrder = watch('skipBaseOrder');

  const {
    formState: {
      isModalVisible, confirmationData, collectedData,
    },
    handlers: {
      handleFormSubmit, setIsModalVisible, isFormValidExceptAdditionalEntries,
    },
  } = useFormSubmission({
    isSkipBaseOrder,
    exchangeAccountUuid,
    editingTrade,
  });

  console.log(editingTrade, '=====editing trade');
  
  const {
    setIsSkipAdditionalEntry,
    setIsSkipTakeProfit,
    setIsSkipStopLoss,
  } = useSectionTogglers();

  // При изменении символа автоматически обновляет:
  // - базовый актив
  // - котируемый актив
  // - сбрасывает дополнительные настройки
  useSymbolEffects({
    currentSymbol,
  });

  const baseOrderInputsLogic = useBaseOrderInputsLogic(
    currentSymbol,
    userWalletQuoteAssetBalance,
    userWalletBaseAssetBalance,
  );

  const {
    handleTriggerPriceChange,
    handleOrderPriceChange,
    handleBoughtPriceChange,
    handleTotalChange,
    handleUnitsChange,
    handleSliderChange,
    handleSkipBaseTotalChange,
    handleSkipBaseUnitsChange,
    handleSkipBaseSliderChange,
  } = baseOrderInputsLogic;

  const additionalEntryInputsLogic = useAdditionalEntryInputsLogic(
    currentSymbol,
    userWalletQuoteAssetBalance,
  );

  const {
    handleAdditionalTriggerPriceChange,
    handleAdditionalOrderPriceChange,
    handleAdditionalOrderPricePercentChange,
    handleAdditionalUnitsChange,
    handleAdditionalTotalChange,
    handleAdditionalSliderChange,
    handleAddEntry,
    handleRemoveEntry,
    updateAdditionalEntryValues,
  } = additionalEntryInputsLogic;

  const takeProfitInputsLogic = useTakeProfitInputsLogic(
    currentSymbol,
  );

  const {
    handleTakeProfitOrderPricePercentChange,
    handleTakeProfitTriggerPriceChange,
    handleTakeProfitOrderPriceChange,
    handleAddTakeProfitEntry,
    handleRemoveTakeProfitEntry,
    updateTakeProfitValues,
  } = takeProfitInputsLogic;

  const stopLossInputsLogic = useStopLossInputsLogic(
    currentSymbol,
  );

  const {
    handleStopLossOrderPricePercentChange,
    handleStopLossTriggerPriceChange,
    handleStopLossOrderPriceChange,
    updateStopLossValues,
  } = stopLossInputsLogic;

  useEntryEffects();

  useEffect(() => {
    trigger();
  }, [currentSymbol, trigger]);

  useFormUpdates({
    updateAdditionalEntryValues,
    updateTakeProfitValues,
    updateStopLossValues,
  });

  useErrorEffects();

  const renderButtons = () => {
    if (!!editingTrade) {
      return (
        <>
          <Button
            htmlType='submit'
            type='primary'
            disabled={!isFormValidExceptAdditionalEntries()}
          >
            {t('terminal.panel.button.update')}
          </Button>

          <Button
            htmlType='button'
            type='default'
            onClick={() => {
              dispatch(newTerminalActions.resetEditingTrade());
            }}
          >
            {t('terminal.panel.button.cancel')}
          </Button>
        </>
      );
    }
    return (
      <Button
        htmlType='submit'
        type='primary'
        disabled={!isFormValidExceptAdditionalEntries()}
      >
        {t('terminal.panel.button.create')}
      </Button>
    );
  };
  
  return (
    <>
      <Box
        sx={wrapper}
        component='form'
        onSubmit={handleFormSubmit}
      >
        <Box sx={inner}>
          <WalletInfo
            isSkipBaseOrder={isSkipBaseOrder}
            currentSymbol={currentSymbol}
            userWalletQuoteAsset={userWalletQuoteAssetBalance}
            userWalletBaseAsset={userWalletBaseAssetBalance}
          />
        </Box>

        <Fields
          baseOrderCompleted={editingTrade?.baseOrderCompleted}
          isSkipBaseOrder={isSkipBaseOrder}
          onOrderPriceChange={handleOrderPriceChange}
          onTotalChange={handleTotalChange}
          onUnitsChange={handleUnitsChange}
          onSliderChange={handleSliderChange}
          onTriggerPriceChange={handleTriggerPriceChange}
          onSkipBaseSliderChange={handleSkipBaseSliderChange}
          onBoughtPriceChange={handleBoughtPriceChange}
          onSkipBaseUnitsChange={handleSkipBaseUnitsChange}
          onSkipBaseTotalChange={handleSkipBaseTotalChange}
          currentSymbol={currentSymbol}
          userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
        />

        <Stack gap='14px'>
          <AdditionalEntrySection
            setIsSkipAdditionalEntry={setIsSkipAdditionalEntry}
            handleAdditionalOrderPriceChange={handleAdditionalOrderPriceChange}
            handleAdditionalUnitsChange={handleAdditionalUnitsChange}
            handleAdditionalTotalChange={handleAdditionalTotalChange}
            handleAdditionalSliderChange={handleAdditionalSliderChange}
            handleAdditionalTriggerPriceChange={handleAdditionalTriggerPriceChange}
            handleAdditionalOrderPricePercentChange={handleAdditionalOrderPricePercentChange}
            handleAddEntry={handleAddEntry}
            handleRemoveEntry={handleRemoveEntry}
          />

          <TakeProfit
            setIsSkipTakeProfit={setIsSkipTakeProfit}
            onTakeProfitOrderPriceChange={handleTakeProfitOrderPriceChange}
            onTakeProfitTriggerPriceChange={handleTakeProfitTriggerPriceChange}
            onTakeProfitOrderPricePercentChange={handleTakeProfitOrderPricePercentChange}
            onAddEntry={handleAddTakeProfitEntry}
            onRemoveEntry={handleRemoveTakeProfitEntry}
          />

          <StopLoss
            setIsSkipStopLoss={setIsSkipStopLoss}
            onStopLossTriggerPriceChange={handleStopLossTriggerPriceChange}
            onStopLossOrderPriceChange={handleStopLossOrderPriceChange}
            onStopLossOrderPricePercentChange={handleStopLossOrderPricePercentChange}
          />

          {renderButtons()}
        </Stack>
      </Box>

      <ConfirmTransaction
        isOpened={isModalVisible}
        closeHandler={() => setIsModalVisible(false)}
        confirmationTableData={confirmationData}
        collectedDataForSubmit={collectedData}
        isEditing={!!editingTrade}
      />
    </>
  );
};

