import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { Text, Title } from 'shared/ui';
import { ICardProps } from '../interfaces';
import { ROW_WRAPPER, TEXT, TITLE, WRAPPER } from '../styles';

export const Card = (props: ICardProps) => {
  const {
    t, 
  } = useTranslation();

  const getContent = () => {
    if (props.value.length === 1) {
      return (
        <Stack
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <Text styles={TEXT}>
            {props.value}
          </Text>
        </Stack>
      );
    }

    return (
      <Stack
        sx={{
          flex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '12px',
        }}
      >
        <Stack sx={ROW_WRAPPER}>
          <Text styles={TEXT}>
            {props.value[0]}
          </Text>

          <Text type='secondary'>
            {t('invite.payments.level', {
              level: 1,
            })}
          </Text>
        </Stack>

        <Divider
          style={{
            margin: 0,
          }}
        />

        <Stack sx={ROW_WRAPPER}>
          <Text styles={TEXT}>
            {props.value[1]}
          </Text>

          <Text type='secondary'>
            {t('invite.payments.level', {
              level: 2,
            })}
          </Text>
        </Stack>
      </Stack>
    );
  };
  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        {props.title}
      </Title>
      
      {getContent()}
    </Stack>
  );
};
