import { CSSProperties } from 'react';

export const TITLE: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '24px',
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#585136',
  backgroundColor: 'rgba(46, 295, 153, .1)',
  borderRadius: '50%',
  width: '50px',
  height: '50px',
};
