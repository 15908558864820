import { getTradeActionsContent } from 'pages/manual-trading/trading-terminal/components/active-trades/components/modal-contents.ts/modal-contents';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalState } from './use-modal-state';

interface UseModalContentProps {
  modalState: ModalState;
  onClose: () => void;
}

export const useModalContent = ({ 
  modalState, 
  onClose, 
}: UseModalContentProps) => {
  const {
    t, 
  } = useTranslation();

  const getModalButtons = useCallback(() => {
    if (modalState.type === 'tradeActions') {
      // Для addFunds и reduceFunds кнопки не возвращаем
      if (modalState.additionalData?.actionType === 'addFunds' || 
          modalState.additionalData?.actionType === 'reduceFunds') {
        return undefined;
      }
      
      return [{
        title:  t('modal.terminal.button'),
        action: () => modalState.additionalData?.actionFn(),
      }];
    }
    
    return undefined;
  }, [modalState]);
  
  const renderModalContent = useCallback(() => {
    if (modalState.type === 'tradeActions') {
      return getTradeActionsContent({
        type: modalState.additionalData?.actionType,
        tradeSymbol: modalState.additionalData?.tradeSymbol,
        trade: modalState.additionalData?.trade,
        exchangeAccount: modalState.additionalData?.exchangeAccount,
        onClose,
        t,
      });
    }
    
    return null;
  }, [modalState, onClose]);
  
  return {
    renderModalContent, 
    modalButtons: getModalButtons(),
    isError: modalState.additionalData?.status === 'error',
  };
};
