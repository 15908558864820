import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseSecondaryIcon } from 'shared/icons';
import { Text } from 'shared/ui';
import { closeWrapper, inner, item, itemTitle, itemValue, wrapper } from '../styles';

export const SafetyOrders = ({
  takeProfitEntries,
  onRemoveEntry,
}: {
  takeProfitEntries: Array<{ price: number; volume: number; isCompleted?: boolean; }>;
  onRemoveEntry: (index: number) => void;
}) => {
  const {
    watch, 
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
  
  const currentSymbol = watch('currentSymbol');
  
  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <Box sx={item}>
          <Text styles={itemTitle}>
            {t('terminal.panel.takeProfit.columns.price')}
          </Text>

          <Text styles={itemTitle}>
            {t('terminal.panel.takeProfit.columns.volume')}
          </Text>
        </Box>

        {takeProfitEntries.map((entry, index) => (
          <Box
            key={index}
            sx={item}
          >
            <Text styles={itemValue}>
              {`${entry.price} ${currentSymbol.quoteAsset}`}
            </Text>
              
            <Text styles={itemValue}>
              {`${entry.volume}%`}
            </Text>

            {!entry?.isCompleted && (
              <div
                style={closeWrapper}
                onClick={() => onRemoveEntry(index)}
              >
                {CloseSecondaryIcon}
              </div>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
