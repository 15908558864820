import { 
  CURRENT_LAST_LOGIN_DATE,
  DEMO_MODE,
  EXCHANGE_ACCOUNT_UUID, 
  EXCHANGE_NAME, 
  LANGUAGE, 
  REFERRAL_CODE, 
  SKYREX_ACCESS_TOKEN, 
  SKYREX_REFRESH_TOKEN, 
  SKYREX_USER_EMAIL, 
  SKYREX_USER_UUID,
} from 'shared/consts/storage';

export function getAuthToken() {
  return localStorage.getItem(SKYREX_ACCESS_TOKEN);
}

export function setAuthToken(token: string) {
  return localStorage.setItem(SKYREX_ACCESS_TOKEN, token);
}

export function removeAuthToken() {
  return localStorage.removeItem(SKYREX_ACCESS_TOKEN);
}

export function getRefreshToken() {
  return localStorage.getItem(SKYREX_REFRESH_TOKEN);
}

export function setRefreshToken(token: string) {
  return localStorage.setItem(SKYREX_REFRESH_TOKEN, token);
}

export function setCurrentLastLoginDate(date: string) {
  return localStorage.setItem(CURRENT_LAST_LOGIN_DATE, date);
}

export function removeRefreshToken() {
  return localStorage.removeItem(SKYREX_REFRESH_TOKEN);
}

export function getUserEmail() {
  return localStorage.getItem(SKYREX_USER_EMAIL);
}

export function getCurrentLastLoginDate() {
  return localStorage.getItem(CURRENT_LAST_LOGIN_DATE);
}

export function setUserEmail(email: string) {
  return localStorage.setItem(SKYREX_USER_EMAIL, email);
}

export function removeUserEmail() {
  return localStorage.removeItem(SKYREX_USER_EMAIL);
}

export function getSkyrexUuid() {
  return localStorage.getItem(SKYREX_USER_UUID);
}

export function setSkyrexUuid(skyrexUuid: string) {
  return localStorage.setItem(SKYREX_USER_UUID, skyrexUuid);
}

export function removeSkyrexUuid() {
  return localStorage.removeItem(SKYREX_USER_UUID);
}

export function removeCurrentLastLoginDate() {
  return localStorage.removeItem(CURRENT_LAST_LOGIN_DATE);
}

export function setExchangeUuidBeingUpdated (exchangeUuid: string) {
  return localStorage.setItem(EXCHANGE_ACCOUNT_UUID, exchangeUuid);
}

export function getExchangeUuidBeingUpdated () {
  return localStorage.getItem(EXCHANGE_ACCOUNT_UUID);
}

export function removeExchangeAccountUuid () {
  return localStorage.removeItem(EXCHANGE_ACCOUNT_UUID);
}

export function setExchangeAccountNameBeingUpdated (exchangeName: string) {
  return localStorage.setItem(EXCHANGE_NAME, exchangeName);
}

export function getExchangeAccountNameBeingUpdated () {
  return localStorage.getItem(EXCHANGE_NAME);
}

export function removeExchangeAccountName () {
  return localStorage.removeItem(EXCHANGE_NAME);
}

export function setDemoMode(mode: string) {
  return localStorage.setItem(DEMO_MODE, mode);
}

export function getDemoMode() {
  return localStorage.getItem(DEMO_MODE);
}

export function removeDemoMode() {
  return localStorage.removeItem(DEMO_MODE);
}

export function getLanguage() {
  return localStorage.getItem(LANGUAGE);
}

export function setLanguage(language: string) {
  return localStorage.setItem(LANGUAGE, language);
}

export function getReferralCode() {
  return localStorage.getItem(REFERRAL_CODE);
}

export function setReferralCode(code: string) {
  return localStorage.setItem(REFERRAL_CODE, code);
}

export function removeLanguage() {
  return localStorage.removeItem(LANGUAGE);
}
