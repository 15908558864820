import { Stack } from '@mui/material';
import { IImageProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const Image = (props: IImageProps) => {
  return (
    <Stack sx={WRAPPER(props.maxWidth || '100%', !!props.shrink)}>
      <img src={props.src} alt={props.src.at(0)} />
    </Stack>
  );
};
