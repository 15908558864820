import { Box } from '@mui/material';
import { Modal } from 'antd';
import QRCode from 'qrcode.react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelInput, MainButton, Text, Title } from 'shared/ui';
import { enableTwoFactor } from '../api/enable-two-factor';
import { button, buttons, inner, modal, modalChildren, text, title, wrapper } from '../styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  generatedToken: string;
  qrImageSource: string;
  handleSetTwoFactorEnabled: () => void;
  handleSetTwoFactorDisabled: () => void;
  handleSetTwoFactorEnableFailedOpen: () => void;
}

export const TwoFactor = (props: Props) => {
  const {
    isOpen,
    onClose,
    generatedToken,
    qrImageSource,
    handleSetTwoFactorEnabled,
    handleSetTwoFactorEnableFailedOpen,
  } = props;
  
  const [otp, setOtp] = useState<string>('');

  const {
    t, 
  } = useTranslation();
  
  const handleSetOtp = (value: string) => {
    setOtp(value);    
  };
  
  const handleEnableTwoFactor = async () => {
    try {
      const responseFromEnableTwoFactor = await enableTwoFactor(otp);
      
      if (!responseFromEnableTwoFactor.success) {
        setOtp('');
        handleSetTwoFactorEnableFailedOpen();
        return;
      }
      
      onClose();
      setOtp('');
      handleSetTwoFactorEnabled();
    } catch (error) {
      handleSetTwoFactorEnableFailedOpen();
      setOtp('');
    }
  };
  
  const handleClose = () => {
    setOtp('');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={onClose}
      onOk={onClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>
          {t('modal.twoFactor.title')}
        </Title>

        <Box sx={inner}>
          <Text styles={text}>
            {t('modal.twoFactor.description')}: <br />
            {generatedToken}
          </Text>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <QRCode
              value={qrImageSource}
              size={200}
            />
          </div>
        </Box>

        <LabelInput
          label={t('modal.twoFactor.otp.title')}
          value={otp}
          placeholder={t('modal.twoFactor.otp.placeholder')}
          onChange={handleSetOtp}
        />

        <Box sx={buttons}>
          <MainButton
            type='primary'
            ghost={true}
            styles={button}
            onClick={handleClose}
          >
            {t('modal.twoFactor.button.cancel')}
          </MainButton>
          
          <MainButton
            type='primary'
            styles={button}
            onClick={handleEnableTwoFactor}
          >
            {t('modal.twoFactor.button.ok')}
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
