import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { dateSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';

export const paymentColumns = (t: any): TableColumnsType => {
  return [{
    title: t('invite.payments.columns.subscription'),
    dataIndex: 'subscription',
    key: 'subscription',
    width: 230,
    render: (value: string) => (
      <Text>
        {value}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.payments.columns.type'),
    dataIndex: 'type',
    key: 'type',
    width: 230,
    render: (value: string) => (
      <Text>
        {value}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.payments.columns.paid.amount'),
    dataIndex: 'paid',
    key: 'paid',
    width: 230,
    render: (value: string) => (
      <Text>
        {value}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.payments.columns.share'),
    dataIndex: 'referralShare',
    key: 'referralShare',
    width: 230,
    render: (value: string) => (
      <Text>
        {value}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.payments.columns.paid.date'),
    dataIndex: 'date',
    key: 'date',
    width: 230,
    sorter: (a, b) => dateSorter(a.date, b.date),
    render: (value: string) => (
      <Text>
        {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }];
};
