import { Stack } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Copy, Label } from 'widgets';
import { ENV } from 'shared/config/environment';
import { LabelInput, MainButton, Text } from 'shared/ui';
import { IShareLinkProps } from '../interfaces';
import { ACTIONS, CREATE_LINK, SHARE_LINK, SOCIAL_LINK } from '../styles';

export const ReferralLink = (props: IShareLinkProps) => {
  const {
    referralCode,
    onCreate,
    socials,
  } = props;

  const [referalCode, setReferalCode] = useState<string>('');

  const {
    t, 
  } = useTranslation();

  const changeInputHandler = (value: string) => {
    setReferalCode(value);
  };

  const openSocialLink = (social: string) => {
    return () => {
      window.open(social, '_blank', 'width=600,height=400');
    };
  };

  const shareLink = useMemo(() => {
    return `${ENV.APP_CLIENT_URL}/register?ref=${referralCode || 'code'}`;
  }, [referralCode]);

  if (!referralCode) {
    return (
      <Stack sx={CREATE_LINK}>
        <LabelInput
          label={(
            <Label
              title={t('invite.share.title.create')}
            />
          )}
          value={referalCode}
          placeholder={t('invite.share.placeholder')}
          onChange={changeInputHandler}
        />
      
        <MainButton
          type='primary'
          styles={{
            width: '100%',
            maxWidth: 220,
          }}
          onClick={onCreate?.(referalCode)}
        >
          {t('invite.share.button')}
        </MainButton>
      </Stack>
    );
  }

  return (
    <Stack sx={SHARE_LINK}>
      <LabelInput
        label={(
          <Label
            title={t('invite.share.title.origin')}
          />
        )}
        value={shareLink}
        suffix={(
          <Stack sx={ACTIONS}>
            <Copy
              value={shareLink}
              onlyCopy={true}
            />

            {socials.map((social, index) => {
              return  (
                <Text
                  key={index}
                  styles={SOCIAL_LINK}
                  onClick={openSocialLink(social.link(shareLink))}
                >
                  {social.icon}
                </Text>
              );
            })}
          </Stack>
        )}
        disabled={true}
      />
    </Stack>
  );
};
