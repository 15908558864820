import { SegmentedLabeledOption } from 'antd/es/segmented';

export const segments = (t: any): SegmentedLabeledOption<string>[] => {
  return [{
    label: t('terminal.panel.takeProfit.segments.limit'),
    value: 'limit',
  }, {
    label: t('terminal.panel.takeProfit.segments.market'),
    value: 'cond.market',
  }];
};
