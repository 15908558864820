import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const TEXT: CSSProperties = {
  fontSize: getFontSize(18, 20),
  fontWeight: 400,
  lineHeight: 'normal',
  color: '#000000',
  textAlign: 'center',
  display: 'block',
  maxWidth: 920,
};
