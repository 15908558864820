import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Text } from 'shared/ui';
import { Table } from '../../table';
import { INNER, LINK, WRAPPER } from '../styles';

export const BasicInfo = () => {
  const {
    t, 
  } = useTranslation();

  return (
    <Box sx={WRAPPER}>
      <Stack sx={INNER}>
        <Text>
          {t('configurator.source.basic.title')}
        </Text>

        <Link
          to='/trading-bots/introduction'
          style={LINK}
        >
          {t('configurator.source.basic.link')}
        </Link>
      </Stack>
      
      <Table
        headers={[
          t('configurator.source.basic.headers.field'),
          t('configurator.source.basic.headers.type'),
          t('configurator.source.basic.headers.description'),
        ]}
        data={[[
          'base',
          'String',
          t('configurator.source.basic.items.base'),
        ], [
          'quote',
          'String',
          t('configurator.source.basic.items.quote'),
        ], [
          'position',
          'String',
          t('configurator.source.basic.items.position'),
        ], [
          'price',
          'String',
          t('configurator.source.basic.items.price'),
        ], [
          'sourceUuid',
          'String',
          t('configurator.source.basic.items.sourceUuid'),
        ], [
          'secretToken',
          'String',
          t('configurator.source.basic.items.secretToken'),
        ], [
          'timestamp',
          'String',
          t('configurator.source.basic.items.timestamp'),
        ]]}
      />
    </Box>
  );
};
