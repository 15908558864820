import { Box, Stack } from '@mui/material';
import { Divider } from 'antd';
import { getStopLossIsOpened, getStopLossOrderType, getStopLossPriceChange, getTakeProfitOpened, getTakeProfitOrders, getTakeProfitOrdersPriceChangeScale, getTakeProfitOrdersSizeScale, getTakeProfitOrderType, getTakeProfitPriceChange, riskManagmentCloseActions } from 'entities/risk-managment-close';
import { IRiskManagmentClose } from 'entities/risk-managment-close/interfaces';
import { BlockTitle } from 'pages/trading-bots/configurator-v2/components/block-title';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'widgets';
import { Block, CustomSlider } from '../../../../../components';
import { CONTENT, WRAPPER } from '../styles';

export const Preset = () => {
  const dispatch = useDispatch();

  const isTakeProfitOpened = useSelector(getTakeProfitOpened);
  const isStopLossOpened = useSelector(getStopLossIsOpened);

  const takeProfitOrders = useSelector(getTakeProfitOrders);
  const takeProfitOrdersSizeScale = useSelector(getTakeProfitOrdersSizeScale);
  const takePriceChange = useSelector(getTakeProfitPriceChange);
  const takeOrderPriceChangeScale = useSelector(getTakeProfitOrdersPriceChangeScale);
  const takeOrderType = useSelector(getTakeProfitOrderType);
  
  const stopLossPriceChange = useSelector(getStopLossPriceChange);
  const stopLossOrderType = useSelector(getStopLossOrderType);
  const {
    t, 
  } = useTranslation();

  const isOneOrder = takeProfitOrders === 1;

  const takeProfitToggleHandler = (value: boolean) => {
    dispatch(riskManagmentCloseActions.toggleTakeProfit(value));
  };

  const stopLossToggleHandler = (value: boolean) => {
    dispatch(riskManagmentCloseActions.toggleStopLoss(value));
  };

  const changeTakeProfitOrdersHandler = (value: number) => {
    if (value > 5) {
      return;
    }

    if (value === 1) {
      changeTakeProfitOrderSizeScaleHandler(1);
      changeTakeProfitOrdersPriceChangeScaleHandler(1);
    }

    dispatch(riskManagmentCloseActions.setTakeProfitOrders(value));
  };

  const changeTakeProfitOrderSizeScaleHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setTakeProfitOrdersSizeScale(value));
  };

  const changeTakeProfitPriceChangeHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setTakeProfitPriceChange(value));
  };

  const changeTakeProfitOrdersPriceChangeScaleHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setTakeProfitOrdersPriceChangeScale(value));
  };

  const changeTakeProfitOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentCloseActions.setTakeProfitOrderType(value as IRiskManagmentClose['segment']['preset']['takeProfit']['orderType']));
  };

  const changeStopLossPriceChangeHandler = (value: number) => {
    dispatch(riskManagmentCloseActions.setStopLossPriceChange(value));
  };

  const changeStopLossOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentCloseActions.setStopLossOrderType(value as IRiskManagmentClose['segment']['preset']['stopLoss']['orderType']));
  };

  return (
    <Stack
      sx={{
        ...CONTENT,
        gap: '24px',
      }}
    >
      <Block
        title={(
          <BlockTitle
            title={t('configurator.managment.custom.take.title')}
            toggle={{
              toggled: isTakeProfitOpened,
              gap: '36px',
              change: takeProfitToggleHandler,
            }}
            segment={isTakeProfitOpened ? {
              selected: takeOrderType,
              options: [{
                label: t('form.configurator.orderType.items.market'),
                value: 'market',
              }, {
                label: t('form.configurator.orderType.items.limit'),
                value: 'limit',
              }],
              change: changeTakeProfitOrderTypeHandler,
            } : undefined}
          />
        )}
      >
        {isTakeProfitOpened && (
          <Stack
            sx={{
              gap: '25px',
            }}
          >
            <Box sx={WRAPPER}>
              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.orders')}
                  />
                )}
                slider={{
                  value: takeProfitOrders,
                  max: 5,
                  marks: {
                    1: 1,
                    2: 2,
                    5: 5,
                  },
                  onChange: changeTakeProfitOrdersHandler,
                }}
                input={{
                  value: takeProfitOrders,
                  placeholder: '100%',
                  onChange: changeTakeProfitOrdersHandler,
                }}
              />

              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.ordersSize')}
                  />
                )}
                slider={{
                  value: takeProfitOrdersSizeScale,
                  min: 0.1,
                  max: 2.5,
                  marks: {
                    0.1: 0.1,
                    1: 1,
                    2.5: 2.5,
                  },
                  onChange: changeTakeProfitOrderSizeScaleHandler,
                }}
                input={{
                  value: takeProfitOrdersSizeScale,
                  placeholder: '100%',
                  onChange: changeTakeProfitOrderSizeScaleHandler,
                }}
                step={0.1}
                disabled={isOneOrder}
              />
            </Box>

            <Divider
              style={{
                margin: 0, 
              }}
            />

            <Box sx={WRAPPER}>
              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.priceChange')}
                  />
                )}
                slider={{
                  value: takePriceChange,
                  max: 15,
                  marks: {
                    1: 1,
                    5: 5,
                    15: 15,
                  },
                  onChange: changeTakeProfitPriceChangeHandler,
                }}
                input={{
                  value: takePriceChange,
                  placeholder: '100%',
                  onChange: changeTakeProfitPriceChangeHandler,
                }}
                step={0.1}
              />

              <CustomSlider
                title={(
                  <Label
                    title={t('form.configurator.take.priceChangeScale')}
                  />
                )}
                slider={{
                  value: takeOrderPriceChangeScale,
                  min: 0.1,
                  max: 2.5,
                  marks: {
                    0.1: 0.1,
                    1: 1,
                    2.5: 2.5,
                  },
                  onChange: changeTakeProfitOrdersPriceChangeScaleHandler,
                }}
                input={{
                  value: takeOrderPriceChangeScale,
                  placeholder: '100%',
                  onChange: changeTakeProfitOrdersPriceChangeScaleHandler,
                }}
                step={0.1}
                disabled={isOneOrder}
              />
            </Box>
          </Stack>
        )}
      </Block>

      <Block
        title={(
          <BlockTitle
            title={t('configurator.managment.custom.stop.title')}
            toggle={{
              toggled: isStopLossOpened,
              gap: '40px',
              change: stopLossToggleHandler,
            }}
            segment={isStopLossOpened ? {
              selected: stopLossOrderType,
              options: [{
                label: t('form.configurator.orderType.items.market'),
                value: 'market',
              }, {
                label: t('form.configurator.orderType.items.limit'),
                value: 'limit',
              }],
              change: changeStopLossOrderTypeHandler,
            } : undefined}
          />
        )}
      >
        {isStopLossOpened && (
          <Box sx={WRAPPER}>
            <CustomSlider
              title={(
                <Label
                  title={t('form.configurator.stop.priceChange')}
                />
              )}
              slider={{
                value: stopLossPriceChange,
                max: 10,
                marks: {
                  1: -1,
                  3: -3,
                  10: -10,
                },
                onChange: changeStopLossPriceChangeHandler,
                reversed: true,
              }}
              input={{
                value: stopLossPriceChange,
                placeholder: '100%',
                onChange: changeStopLossPriceChangeHandler,
              }}
              step={0.1}
            />
          </Box>
        )}
      </Block>
    </Stack>
  );
};
