interface IButton {
  title?: string;
  action: () => void;
}
interface IGetModalParams {
  isOpened: boolean;
  isError: boolean;
  title?: string;
  description?: string;
  button?: IButton;
  t: any;
}
export const getModal = (params?: IGetModalParams) => {
  const {
    isOpened,
    isError,
    title,
    description,
    button,
    t,
  } = params || {};

  const modal = {
    isOpened: isOpened,
    isError: isError,
    title: title || t?.('error.exchange.update.title'),
    description: description || t?.('error.exchange.update.message'),
    button: {
      title: button?.title || 'Okay',
      action: button?.action,
    },
  };

  return modal;
};
