import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { mapOrderTypeToSegment } from 'pages/manual-trading/trading-terminal/components/active-trades/components/active-trade/helpers/type-mappers';
import { SelectedExchange } from 'pages/manual-trading/trading-terminal/components/trading-panel/trading-panel';
import { roundPercentage, formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { AdaptedSymbol } from 'shared/helpers/adapt-exchange-symbol';

type BaseOrderType = 'LIMIT' | 'MARKET' | 'CONDITIONAL_LIMIT' | 'CONDITIONAL_MARKET' | 'SKIP_FIRST_STEP';

// Маппинг типов ордеров
const orderTypeMap: Record<BaseOrderType, { orderType: string; conditionalOrderType?: string; }> = {
  'LIMIT': {
    orderType: 'limit', 
  },
  'MARKET': {
    orderType: 'market', 
  },
  'CONDITIONAL_LIMIT': { 
    orderType: 'conditional',
    conditionalOrderType: 'limit',
  },
  'CONDITIONAL_MARKET': { 
    orderType: 'conditional',
    conditionalOrderType: 'market',
  },
  'SKIP_FIRST_STEP': {
    orderType: 'limit', 
  },
};

export const mapPriceRecalculation = (value: string) => {
  switch (value?.toUpperCase()) {
  case 'FROM_AVERAGE':
    return 'average';
  case 'FIXED':
    return 'fixed';
  default:
    return 'fixed';
  }
};

interface SharedTradeProps {
  baseOrder: BaseOrder;
  additionalBaseOrders: AdditionalOrder[];
  takeProfits: TakeProfitOrder[];
  stopLoss: StopLossOrder;
  baseSymbol: string;
  quoteSymbol: string;
}

interface BaseOrder {
  status: string;
  type: BaseOrderType;
  side: string;
  viewType: string;
  orderUuid: string;
  triggerPrice: string;
  typeTriggerPrice: string;
  unitsBase: string;
  limitPrice: string;
  originalBase: string;
  executionPrice: string;
  executionBase: string;
  executionQuote: string;
}

interface AdditionalOrder {
  type: BaseOrderType;
  limitPrice: string;
  triggerPrice: string;
  typeTriggerPrice: string;
  unitsBase: string;
  priceRecalculation?: string;
}

interface TakeProfitOrder {
  type: BaseOrderType;
  limitPrice: string;
  triggerPrice: string;
  typeTriggerPrice: string;
  volume: string;
  priceRecalculation?: string;
}

interface StopLossOrder {
  type: BaseOrderType;
  limitPrice: string;
  triggerPrice: string;
  typeTriggerPrice: string;
  priceRecalculation?: string;
}

interface MapSharedTradeProps {
  sharedTradeData: SharedTradeProps;
  currentSymbol: AdaptedSymbol;
  selectedExchange: SelectedExchange;
}

// 1. Маппинг базового ордера
const mapBaseOrder = ({
  baseOrder,
  currentSymbol,
  selectedExchange,
}: {
  baseOrder: BaseOrder;
  currentSymbol: AdaptedSymbol;
  selectedExchange: SelectedExchange;
}) => {
  let orderPrice;
  let priceForCalculations;
  const {
    orderType, conditionalOrderType, 
  } = orderTypeMap[baseOrder.type] || {
    orderType: 'limit', 
  };

  let skipBaseOrder = false;
  let skipBaseUnits;
  let skipBaseTotal;
  
  if (baseOrder.type === 'SKIP_FIRST_STEP') {
    orderPrice = formatByPrecisionAndTrim(
      baseOrder.executionPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
    
    skipBaseUnits = formatByPrecisionAndTrim(
      baseOrder.unitsBase,
      currentSymbol.baseAssetPrecision,
      Number(currentSymbol.lotMin),
      Number(currentSymbol.lotMax),
    );

    skipBaseTotal = formatByPrecisionAndTrim(
      (parseFloat(skipBaseUnits) * parseFloat(orderPrice)).toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );
    
    priceForCalculations = orderPrice;
    skipBaseOrder = true;
  } else if (baseOrder.type === 'CONDITIONAL_MARKET') {
    priceForCalculations = formatByPrecisionAndTrim(
      baseOrder.triggerPrice,
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
    orderPrice = priceForCalculations;
  } else if (baseOrder.type === 'MARKET') {
    orderPrice = null;
    priceForCalculations = null;
  } else {
    orderPrice = formatByPrecisionAndTrim(
      baseOrder.limitPrice,
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    );
    priceForCalculations = orderPrice;
  }

  const units = formatByPrecisionAndTrim(
    baseOrder.unitsBase,
    currentSymbol.baseAssetPrecision,
    Number(currentSymbol.lotMin),
    Number(currentSymbol.lotMax),
  );

  const total = priceForCalculations 
    ? formatByPrecisionAndTrim(
      (parseFloat(priceForCalculations) * parseFloat(units)).toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    )
    : null;

  const userWalletQuoteAssetBalance = selectedExchange.balances?.find(
    (balance: WalletData) => balance.symbol === currentSymbol.quoteAsset,
  );
  
  const userWalletBaseAssetBalance = selectedExchange.balances?.find(
    (balance: WalletData) => balance.symbol === currentSymbol.baseAsset,
  );
  
  const slider = total && userWalletQuoteAssetBalance?.free
    ? roundPercentage((parseFloat(total) / parseFloat(userWalletQuoteAssetBalance.free)) * 100)
    : 10;

  const skipBaseSlider = skipBaseUnits && userWalletBaseAssetBalance?.free
    ? roundPercentage((parseFloat(skipBaseUnits) / parseFloat(userWalletBaseAssetBalance.free)) * 100)
    : 0;
  
  return {
    selectedSide: baseOrder.side.toLowerCase(),
    orderType,
    ...(conditionalOrderType && {
      conditionalOrderType, 
    }),
    skipBaseUnits,
    skipBaseTotal,
    boughtPrice: priceForCalculations,
    skipBaseSlider,
    skipBaseOrder,
    orderPrice,
    units,
    total,
    slider,
    triggerPrice: formatByPrecisionAndTrim(
      baseOrder.triggerPrice,
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    ),
    triggerPriceType: baseOrder.typeTriggerPrice?.toLowerCase() || 'last',
  };
};

// 2. Маппинг дополнительных ордеров
const mapAdditionalOrders = ({
  additionalOrders,
  currentSymbol,
  baseOrderPrice,
  selectedSide,
}: {
  additionalOrders: any[];
  currentSymbol: AdaptedSymbol;
  baseOrderPrice: string;
  selectedSide: string;
}) => {
  if (!additionalOrders?.length) return {
    addEntryEnabled: false, additionalEntries: [], 
  };

  const price = parseFloat(baseOrderPrice);
  const additionalMultiplier = selectedSide === 'buy' ? 0.95 : 1.05;
  const additionalOrderPrice = formatByPrecisionAndTrim(
    (price * additionalMultiplier).toString(),
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.priceMin),
    Number(currentSymbol.priceMax),
  );
  const additionalOrderPricePercent = roundPercentage(
    ((parseFloat(additionalOrderPrice) - price) / price) * 100,
  );
  
  const firstOrder = additionalOrders[0];
  
  // Расчет units и total на основе additionalOrderPrice
  const additionalUnits = formatByPrecisionAndTrim(
    firstOrder.unitsBase,
    currentSymbol.baseAssetPrecision,
    Number(currentSymbol.lotMin),
    Number(currentSymbol.lotMax),
  );
  
  const additionalTotal = formatByPrecisionAndTrim(
    (parseFloat(additionalOrderPrice) * parseFloat(additionalUnits)).toString(),
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.minNotional),
    Number(currentSymbol.maxNotional),
  );
  
  const additionalEntries = additionalOrders.map(order => ({
    isCompleted: false,
    orderUuid: '',
    additionalOrderType: order.type.toLowerCase(),
    total: formatByPrecisionAndTrim(
      (parseFloat(order.limitPrice || order.triggerPrice) * parseFloat(order.unitsBase || '0')).toString(),
      currentSymbol.chartPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    ),
    units: formatByPrecisionAndTrim(
      order.unitsBase,
      currentSymbol.baseAssetPrecision,
      Number(currentSymbol.lotMin),
      Number(currentSymbol.lotMax),
    ),
    price: formatByPrecisionAndTrim(
      order.limitPrice || order.triggerPrice,
      currentSymbol.chartPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    ),
    triggerPrice: order.triggerPrice ? formatByPrecisionAndTrim(
      order.triggerPrice,
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    ) : undefined,
    typeTriggerPrice: order.typeTriggerPrice?.toLowerCase() || 'last',
  }));

  
  return {
    addEntryEnabled: true,
    additionalEntries,
    additionalOrderType: 'limit',
    additionalOrderPrice,
    additionalOrderPricePercent,
    additionalTriggerPrice: additionalOrderPrice, // используем ту же цену для триггера
    additionalTriggerPricePercent: additionalOrderPricePercent,
    additionalSlider: 10,
    additionalUnits,
    additionalTotal,
  };
};

// 3. Маппинг take profit ордеров
const mapTakeProfitOrders = ({
  takeProfits,
  currentSymbol,
  baseOrderPrice,
}: {
  takeProfits: any[];
  currentSymbol: AdaptedSymbol;
  baseOrderPrice: string;
}) => {
  if (!takeProfits?.length) return {
    takeProfitEnabled: false, takeProfitEntries: [], 
  };

  const takeProfitEntries = takeProfits.map(tp => ({
    isCompleted: false,
    orderUuid: '',
    takeProfitOrderType: tp.type.toLowerCase(),
    price: formatByPrecisionAndTrim(
      tp.type === 'LIMIT' ? tp.limitPrice : tp.triggerPrice,
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    ),
    orderPricePercent: 100,
    volume: parseFloat(tp.percent),
    priceRecalculation: mapPriceRecalculation(tp.priceRecalculation),
    typeTriggerPrice: tp.typeTriggerPrice?.toLowerCase() || 'last',
  }));

  const basePrice = parseFloat(baseOrderPrice);
  
  const takeProfitPrice = formatByPrecisionAndTrim(
    (basePrice * 1.1).toString(),
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.priceMin),
    Number(currentSymbol.priceMax),
  );
  
  return {
    takeProfitEnabled: true,
    takeProfitPriceRecalculation: mapPriceRecalculation(takeProfits[0].priceRecalculation),
    takeProfitEntries,
    takeProfitTriggerPrice: takeProfitPrice,
    takeProfitOrderPrice: takeProfitPrice,
    takeProfitOrderPricePercent: roundPercentage(
      ((parseFloat(takeProfitPrice) - basePrice) / basePrice) * 100,
    ),
    takeProfitTriggerPricePercent: roundPercentage(
      ((parseFloat(takeProfitPrice) - basePrice) / basePrice) * 100,
    ),
  };
};

// 4. Маппинг stop loss
const mapStopLoss = ({
  stopLoss,
  currentSymbol,
  baseOrderPrice,
}: {
  stopLoss: any;
  currentSymbol: AdaptedSymbol;
  baseOrderPrice: string;
}) => {
  if (!stopLoss) return {
    stopLossEnabled: false, 
  };

  
  const basePrice = parseFloat(baseOrderPrice);

  const stopLossTriggerPrice = formatByPrecisionAndTrim(
    stopLoss.triggerPrice,
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.priceMin),
    Number(currentSymbol.priceMax),
  );
  
  // Если нет limitPrice, используем triggerPrice
  const stopLossOrderPrice = formatByPrecisionAndTrim(
    stopLoss.limitPrice || stopLoss.triggerPrice,
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.priceMin),
    Number(currentSymbol.priceMax),
  );

  // Расчет процентов изменения относительно базовой цены
  const stopLossOrderPricePercent = roundPercentage(
    ((parseFloat(stopLossOrderPrice) - basePrice) / basePrice) * 100,
  );

  const stopLossTriggerPricePercent = roundPercentage(
    ((parseFloat(stopLossTriggerPrice) - basePrice) / basePrice) * 100,
  );
  
  return {
    stopLossEnabled: true,
    stopLossOrderType: mapOrderTypeToSegment(stopLoss.type),
    stopLossTriggerPrice,
    stopLossOrderPrice,
    stopLossTriggerPriceType: stopLoss.typeTriggerPrice?.toLowerCase() || 'last',
    stopLossPriceRecalculation: mapPriceRecalculation(stopLoss.priceRecalculation),
    stopLossOrderPricePercent,
    stopLossTriggerPricePercent,
  };
};

// Основная функция маппинга
export const mapSharedTradeToFormValues = ({
  sharedTradeData,
  currentSymbol,
  selectedExchange,
}: MapSharedTradeProps) => {

  const baseOrderValues = mapBaseOrder({
    baseOrder: sharedTradeData.baseOrder,
    currentSymbol,
    selectedExchange,
  });

  const additionalOrdersValues = mapAdditionalOrders({
    additionalOrders: sharedTradeData.additionalBaseOrders,
    currentSymbol,
    baseOrderPrice: baseOrderValues.orderPrice || baseOrderValues.triggerPrice,
    selectedSide: baseOrderValues.selectedSide,
  });

  const takeProfitValues = mapTakeProfitOrders({
    takeProfits: sharedTradeData.takeProfits,
    currentSymbol,
    baseOrderPrice: baseOrderValues.orderPrice || baseOrderValues.triggerPrice,
  });

  const stopLossValues = mapStopLoss({
    stopLoss: sharedTradeData.stopLoss,
    currentSymbol,
    baseOrderPrice: baseOrderValues.orderPrice || baseOrderValues.triggerPrice,
  });

  return {
    sharedTrade: true,
    currentSymbol: {
      symbol: `${sharedTradeData.baseSymbol}${sharedTradeData.quoteSymbol}`,
      baseAsset: sharedTradeData.baseSymbol,
      quoteAsset: sharedTradeData.quoteSymbol,
    },
    ...baseOrderValues,
    ...additionalOrdersValues,
    ...takeProfitValues,
    ...stopLossValues,
    exchangeAccountUuid: selectedExchange.value,
    exchangeCode: selectedExchange.exchangeCode,
  };
};
