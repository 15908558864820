import { Stack } from '@mui/material';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { IOrderTypeProps } from '../interfaces';
import { WRAPPER } from '../styles';

export const OrderType = (props: IOrderTypeProps) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Label
        title={t('form.configurator.orderType.title')}
      />

      <Segmented
        value={props.value}
        options={[{
          label: t('form.configurator.orderType.items.market'),
          value: 'market',
        }, {
          label: t('form.configurator.orderType.items.limit'),
          value: 'limit',
        }]}
        onChange={props.onChange}
        block={true}
      />
    </Stack>
  );
};
