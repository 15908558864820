
interface IConvertDirectionParams {
  value: number;
  stratage: 'LONG' | 'SHORT';
  orderType: 'ADDITIONAL' | 'TAKE_PROFIT' | 'STOP_LOSS';
}
export const convertDirection =  (params: IConvertDirectionParams) => {
  const {
    value,
    stratage,
    orderType,
  } = params;

  if (stratage === 'LONG' && (orderType === 'ADDITIONAL' || orderType === 'STOP_LOSS')) {
    return -Math.abs(value);
  }

  return value;
};
