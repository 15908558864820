import { Stack } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { IValueProps } from '../interfaces';
import { DATE, TITLE, VALUE } from '../styles';

export const Value = (props: IValueProps) => {
  const getValue = () => {
    if (props.type === 'roi') {
      return +props.value > 0 ? `+${props.value}%` : `${props.value}%`;
    }

    return props.value;
  };

  return (
    <Stack gap='2px'>
      <Title styles={TITLE}>
        {props.title}
      </Title>

      <Text
        styles={VALUE({
          type: props.type as 'entry' | 'current' | 'roi',
          color: +props.value < 0 ? '#FB5454' : '#2ECD99',
        })}
      >
        {getValue()}
      </Text>

      {props.date && (
        <Text styles={DATE}>
          {props.date}
        </Text>
      )}
    </Stack>
  );
};
