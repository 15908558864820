import { Rule } from 'antd-mobile/es/components/form';

export const amountRules = (t: any, available: number): Rule[] => {
  return [{
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error(t('validation.withdraw.amount.required')));
      }

      if (!+value) {
        return Promise.reject(new Error(t('validation.withdraw.amount.number')));
      }

      if (+value < 10) {
        return Promise.reject(new Error(t('validation.withdraw.amount.greater')));
      }

      if (+value > available) {
        return Promise.reject(new Error(t('validation.withdraw.amount.lower')));
      }

      return Promise.resolve();
    },
  }];
};
