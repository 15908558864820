import { Box } from '@mui/material';
import { Modal } from 'antd';
import { ReactNode, useState } from 'react';
import { MainButton, Text, Title } from 'shared/ui';
import {
  button as buttonStyles,
  inner,
  modal,
  modalChildren,
  text,
  textWrapper,
  title,
  wrapper,
} from '../styles';

interface IButton {
  title: string;
  disabled?: boolean;
  action: () => void;
}

interface EmptySubscriptionProps {
  isOpen: boolean;
  handleClose: () => void;
  modalTitle: string;
  modalDescription?: ReactNode;
  modalButtonTitle?: string;
  modalButtonAction?: () => void;
  button?: IButton;
  isError?: boolean;
  children?: ReactNode;
}

export const EmptySubscription = (props: EmptySubscriptionProps) => {
  const {
    isOpen,
    handleClose,
    modalDescription,
    modalTitle,
    modalButtonTitle,
    modalButtonAction,
    isError,
    button,
    children,
  } = props;

  const [loading, setLoading] = useState<boolean>(false);

  const buttonClickHandler = async () => {
    setLoading(true);
    await (modalButtonAction?.() || button?.action?.());
    setLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <img src={`/images/subscriptions/robot-${isError ? 'reject' : 'success'}.png`} alt='robot' />

        <Box sx={inner}>
          <Box sx={textWrapper}>
            <Title styles={title}>
              {modalTitle}
            </Title>

            {modalDescription && (
              <Text styles={text}>
                {modalDescription}
              </Text>
            )}
          </Box>

          {children}
        
          <MainButton
            type='primary'
            styles={buttonStyles}
            onClick={buttonClickHandler}
            loading={loading}
            disabled={button?.disabled}
          >
            {modalButtonTitle || button?.title}
          </MainButton>
        </Box>
      </Box>
    </Modal>
  );
};
