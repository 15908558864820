import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  gap: '14px',
  position: 'relative',
  backgroundImage: 'url("/images/manual-trading/trade/bg_export.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  aspectRatio: '16/10',
  '&>span': {
    fontSize: 4,
    fontWeight: 600,
    color: '#585757',
    lineHeight: 'normal',
    position: 'absolute',
    right: '5%',
    bottom: '3.5%',
  },
};
