import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { calculateTotal, formatByPrecisionAndTrim, calculatePercentageWithCeil } from '../../../helpers/helpers';

interface UpdatePriceValuesParams {
  price: number;
  currentSymbol: any;
  userWalletQuoteAssetBalance: WalletData;
  userWalletBaseAssetBalance: WalletData;
  selectedSide: 'buy' | 'sell';
  orderType: string;
  isSkipBaseOrder: boolean;
  lastChangedField?: string | null;
}

interface FormValues {
  [key: string]: any;
}

export const calculatePriceValues = ({
  price,
  currentSymbol,
  userWalletQuoteAssetBalance,
  userWalletBaseAssetBalance,
  selectedSide,
  orderType,
  isSkipBaseOrder,
  lastChangedField,
}: UpdatePriceValuesParams): FormValues => {
  const values: FormValues = {};

  // Форматируем цену для всех случаев
  const formattedPrice = formatByPrecisionAndTrim(
    price.toString(),
    currentSymbol.quoteAssetPrecision,
    Number(currentSymbol.priceMin),
    Number(currentSymbol.priceMax),
  );

  // Обработка skipBaseOrder
  if (isSkipBaseOrder) {
    const initialSkipBaseUnits = formatByPrecisionAndTrim(
      userWalletBaseAssetBalance?.free?.toString(),
      currentSymbol.baseAssetPrecision,
      Number(currentSymbol.lotMin),
      Number(currentSymbol.lotMax),
    );
    
    const initialSkipBaseTotal = formatByPrecisionAndTrim(
      (parseFloat(initialSkipBaseUnits) * parseFloat(formattedPrice)).toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.minNotional),
      Number(currentSymbol.maxNotional),
    );
    
    return {
      boughtPrice: formattedPrice,
      skipBaseUnits: initialSkipBaseUnits,
      skipBaseTotal: initialSkipBaseTotal,
      skipBaseSlider: 100,
    };
  }

  // Установка базовых значений
  values.orderPrice = formattedPrice;
  if (orderType === 'conditional') {
    values.triggerPrice = formattedPrice;
  }

  // Обновление units и total в зависимости от стороны
  if (selectedSide === 'buy') {
    const total = parseFloat(userWalletQuoteAssetBalance.free) * 0.1;
    if (total > 0) {
      const newUnits = total / price;
      const formattedUnits = formatByPrecisionAndTrim(
        newUnits.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      values.units = formattedUnits;
      values.slider = 10;

      const newTotal = parseFloat(formattedUnits) * price;
      values.total = formatByPrecisionAndTrim(
        newTotal.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );
    } else {
      values.units = '0';
      values.total = '0';
      values.slider = 0;
    }
  } else {
    // Логика для sell
    const initialUnits = parseFloat(userWalletBaseAssetBalance.free) * 0.1;
    if (initialUnits > 0) {
      const formattedUnits = formatByPrecisionAndTrim(
        initialUnits.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      values.units = formattedUnits;
      values.slider = 10;

      const initialTotal = calculateTotal(
        parseFloat(formattedUnits),
        price,
        currentSymbol.quoteAssetPrecision,
      );
      values.total = formatByPrecisionAndTrim(
        initialTotal.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );
    } else {
      values.units = '0';
      values.total = '0';
      values.slider = 0;
    }
  }

  // Обработка market ордеров
  if (orderType === 'market') {
    const currentUnits = values.units;
    const currentTotal = values.total;

    if (lastChangedField === 'units' && !isNaN(+currentUnits)) {
      const newTotal = parseFloat(currentUnits) * price;
      values.total = formatByPrecisionAndTrim(
        newTotal.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      );

      // Обновляем slider только для buy
      if (selectedSide === 'buy') {
        const userWalletFree = parseFloat(userWalletQuoteAssetBalance.free);
        if (userWalletFree > 0) {
          values.slider = calculatePercentageWithCeil(newTotal, userWalletFree);
        }
      }
    } else if (!isNaN(+currentTotal)) {
      const newUnits = parseFloat(currentTotal) / price;
      values.units = formatByPrecisionAndTrim(
        newUnits.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
    }
  }

  return values;
};
