import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const TITLE: CSSProperties = {
  fontSize: getFontSize(18, 38),
  fontWeight: 400,
  lineHeight: 'normal',
  textAlign: 'center',
  color: '#000000',
};
