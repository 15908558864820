import { Rule } from 'antd/es/form';

export const tradingBalanceValidator = (minInvestment: number, t: any): Rule[] => {
  return [{
    required: true,
    message: t('validation.bot.update.balance.required'),
  }, {
    validator: (_, value) => {
      if (parseFloat(value) < minInvestment) {
        return Promise.reject(new Error(t('validation.bot.update.balance.less', {
          minInvestment,
        })));
      }
      return Promise.resolve();
    },
  }];
};
