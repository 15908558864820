import { Box } from '@mui/material';
import { Progress } from 'antd';
import { BaseType } from 'antd/es/typography/Base';
import { useTranslation } from 'react-i18next';
import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { formatValueListItems } from 'shared/helpers/format-value-list-items';
import { useMultiExchangeFormatter } from 'shared/hooks/use-multi-exchange-formatter';
import { Text } from 'shared/ui';
import { IWinRate } from '../interfaces';
import { background, left, progressText, right, row, text, title } from '../styles';

export const WinRate = (props: IWinRate) => {
  const {
    percent,
    items,
  } = props;

  const {
    formatters, 
  } = useMultiExchangeFormatter([EXCHANGE_NAMES.BINANCE]);
  const {
    t, 
  } = useTranslation();

  const renderListItem = (item: IWinRate['items'][0]) => {
    let type: BaseType = 'secondary';
    let value = `${item.value}`;

    const isFundsValue = item.type === 'funds';
    const isCalculatedItem = item.type === 'total' || item.type === 'unrealized';

    if (isCalculatedItem && item.currency) {
      if (+item.value > 0) {
        type = 'success';
        value = `+${formatValueListItems({
          value: +item.value,
          currency: item.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
        })}`;
      } else if (+item.value < 0) {
        type = 'danger';
        value = formatValueListItems({
          value: +item.value,
          currency: item.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
        });
      } else {
        type = 'secondary';
        value = `${value} ${item.currency.toUpperCase()}`;
      }
    }
    
    if (isFundsValue && item.currency) {
      value = formatValueListItems({
        value: +value,
        currency: item.currency,
        formatters,
        exchange: EXCHANGE_NAMES.BINANCE,
      });
    }

    return (
      <Box
        key={item.title}
        sx={row}
      >
        <Text
          type='secondary'
          styles={title}
        >
          {item.title}
        </Text>

        <Text
          styles={text}
          type={type}
        >
          {value ?? '-'}
        </Text>
      </Box>
    );
  };

  return (
    <Box sx={background}>
      <Box sx={left}>
        {items?.map((item: IWinRate['items'][0]) => (renderListItem(item)))}
      </Box>

      <Box sx={right}>
        <Progress
          percent={percent || 0}
          type='circle'
          status='normal'
          size={93}
        />

        <Text styles={progressText}>
          {t('terminal.diary.winRate.title')}
        </Text>
      </Box>
    </Box>
  );
};
