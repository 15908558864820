import { SxProps, Theme } from '@mui/material';

export const BLOCK: SxProps<Theme> = {
  gap: '14px',
  position: 'absolute',
  left: '7%',
  top: '25%',
  width: '100%',
  maxWidth: 300,
};
