import { Box } from '@mui/material';
import { Modal, Segmented } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'widgets';
import { getTitle } from 'shared/helpers';
import { Text } from 'shared/ui';
import { descriptions } from '../consts';
import { filterPlans, getButton, getItems, getPrice, sortItems } from '../helpers';
import { IPlans } from '../interfaces';
import { list, modal, modalChildren, title, titleWrapper, wrapper } from '../styles';

export const Plans = (props: IPlans) => {
  const {
    isOpen,
    closeHandler,
    items,
    subscription,
    choosePlan,
  } = props;

  const [period, setPeriod] = useState<string>('annually');

  const {
    t, 
  } = useTranslation();

  const changePeriod = (period: string) => {
    setPeriod(period);
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={titleWrapper}>
          <Text
            type='success'
            styles={title}
          >
            {t('modal.plans.title')}
          </Text>

          <Segmented
            value={period}
            onChange={changePeriod}
            options={[{
              label: t('modal.plans.segments.monthly'),
              value: 'monthly',
            }, {
              label: t('modal.plans.segments.annually', {
                percent: '-50',
              }),
              value: 'annually',
            }]}
            size='large'
          />
        </Box>

        <Box sx={list}>
          {sortItems(items.filter(filterPlans(period))).map((item: IPlans['items'][0]) => (
            <Card
              key={item.planUuid}
              title={getTitle(item.planName, t)}
              description={descriptions(item.planName, t)}
              price={getPrice(item.originalFee, period)}
              period='monthly'
              isBset={item.planName.includes('pro')}
              items={getItems(item, t)}
              button={getButton({
                subscription,
                plan: item,
                choosePlan,
                t,
              })}
            />
          ))}
        </Box>
      </Box>
    </Modal>
  );
};
