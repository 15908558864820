import { SxProps, Theme } from '@mui/material';

export const WRAPPER = (isColored: boolean) => {
  let styles: SxProps<Theme> = {
    flexDirection: {
      xs: 'column',
      md: 'row',
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '24px',
    textAlign: 'center',
  };

  if (isColored) {
    styles = {
      ...styles,
      padding : '40px',
      backgroundColor : '#EAFAF5',
      borderRadius : '24px',
      textAlign : 'initial',
      '&>div[class*="block"]': {
        alignItems: {
          xs: 'center',
          md: 'flex-start',
        },
      },
    };
  }

  return styles;
};
