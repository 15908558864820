import { userActions } from 'entities/user/model/slices/user-slice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CIS } from 'shared/consts';
import { getLanguage } from 'shared/helpers/storage-helper';

export const useLanguage = () => {
  const {
    i18n, 
  } = useTranslation();
  const dispatch = useDispatch();

  const currentLang = (navigator.language || navigator.languages[0] || 'en').split('-')[0];

  useEffect(() => {
    if (!currentLang) {
      return;
    }

    if (!CIS.includes(currentLang)) {
      return;
    }

    const language = (getLanguage() || currentLang) as 'en' | 'ru';

    i18n.changeLanguage(language);
    dispatch(userActions.setLanguage(language));
  }, [
    currentLang,
    i18n,
    dispatch,
  ]);

  return currentLang;
};
