import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { calculateTotal, formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface UsePriceEffectsProps {
    chartLastPrice: number;
    limitLastPrice: number;
    currentSymbol: any;
    userWalletBaseAssetBalance: WalletData;
  }
  
export const usePriceEffects = ({
  chartLastPrice,
  limitLastPrice,
  currentSymbol,
  userWalletBaseAssetBalance,
}: UsePriceEffectsProps) => {
    
  const {
    setValue, watch, 
  } = useFormContext();
    
  const isSkipBaseOrder = watch('skipBaseOrder');
  const orderType = watch('orderType');
  const lastChangedField = watch('lastChangedField');
    
  // Эффект для market ордеров
  useEffect(() => {
    
    if (orderType === 'market' && !isSkipBaseOrder) {
      const units = watch('units');
      const total = watch('total');
              
      setValue('orderPrice', formatByPrecisionAndTrim(
        limitLastPrice.toString(), 
        currentSymbol.quoteAssetPrecision, 
        Number(currentSymbol.priceMin), 
        Number(currentSymbol.priceMax),
      ));
        
      if (!isNaN(+total) && lastChangedField !== 'total') {
        const newTotal = +units * limitLastPrice;
        setValue('total', formatByPrecisionAndTrim(
          newTotal.toString(),
          currentSymbol.quoteAssetPrecision,
          Number(currentSymbol.minNotional),
          Number(currentSymbol.maxNotional),
        ));
      }
      
      // Затем обрабатываем units
      if (!isNaN(+units) && lastChangedField !== null && lastChangedField !== 'units') {
        const newUnits = +total / limitLastPrice;
        setValue('units', formatByPrecisionAndTrim(
          newUnits.toString(),
          currentSymbol.baseAssetPrecision,
          Number(currentSymbol.lotMin),
          Number(currentSymbol.lotMax),
        ));
      }
    }
  }, [orderType, limitLastPrice]);
  
  // Эффект для не-market ордеров
  useEffect(() => {
    if (isSkipBaseOrder) {
      setValue('boughtPrice', formatByPrecisionAndTrim(
        chartLastPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      ));
    }
      
    if (orderType !== 'market') {
      handleNonMarketOrderPriceUpdate();
    }
  }, [orderType, chartLastPrice, isSkipBaseOrder]);
  
  const handleNonMarketOrderPriceUpdate = () => {
    const newOrderPrice = chartLastPrice;
    setValue('orderPrice', formatByPrecisionAndTrim(
      newOrderPrice.toString(),
      currentSymbol.quoteAssetPrecision,
      Number(currentSymbol.priceMin),
      Number(currentSymbol.priceMax),
    ));
      
    if (orderType === 'conditional') {
      setValue('triggerPrice', formatByPrecisionAndTrim(
        newOrderPrice.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.priceMin),
        Number(currentSymbol.priceMax),
      ));
    }
      
    updateUnitsAndTotal(newOrderPrice);
  };
  
  const updateUnitsAndTotal = (orderPrice: number) => {
    
    const initialUnits = Number(userWalletBaseAssetBalance.free) * 0.1;
    
    if (initialUnits > 0) {
      const formattedUnits = formatByPrecisionAndTrim(
        initialUnits.toString(),
        currentSymbol.baseAssetPrecision,
        Number(currentSymbol.lotMin),
        Number(currentSymbol.lotMax),
      );
      setValue('units', formattedUnits);
      setValue('slider', 10);
      
      const initialTotal = calculateTotal(parseFloat(formattedUnits), orderPrice, currentSymbol.quoteAssetPrecision);
    
      setValue('total', formatByPrecisionAndTrim(
        initialTotal.toString(),
        currentSymbol.quoteAssetPrecision,
        Number(currentSymbol.minNotional),
        Number(currentSymbol.maxNotional),
      ));
    } else {
      setValue('units', '0');
      setValue('total', '0');
      setValue('slider', 0);
    }
  };
};
