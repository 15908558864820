import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Copy } from 'widgets';
import { Text } from 'shared/ui';
import { copyContent, copyLabelText, copyLabelWrapper } from 'shared/ui/modals/add-new-bot/styles';
import { strategyEntry, strategyExit } from '../../../consts';
import { ITab } from '../../../interfaces';

export const Strategy = (props: ITab) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Box sx={copyContent}>
      <Copy
        label={(
          <Box sx={copyLabelWrapper}>
            <Text
              type='secondary'
              styles={copyLabelText}
            >
              {t('modal.alertSource.strategy.title')}
            </Text>

            <Text
              type='secondary'
              styles={{
                ...copyLabelText,
                fontSize: 10,
              }}
            >
              {t('modal.alertSource.strategy.description')}
            </Text>
          </Box>
        )}
        value={strategyEntry(props.sourceUuid, props.secretToken)}
      />

      <Copy
        label={t('modal.alertSource.strategy.copy.items.function')}
        value={strategyExit(props.sourceUuid, props.secretToken)}
      />

      <Copy
        label={t('modal.alertSource.strategy.copy.items.message')}
        value='{{strategy.order.alert_message}}'
      />

      <Text>
        {t('modal.alertSource.strategy.note')}
      </Text>
    </Box>
  );
};
