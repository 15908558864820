import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { ResolutionString } from '../../charting_library/charting_library';
import { WebSocketHandler } from '../types/chart-view.types';
import { WebSocketHandlerParams } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';

export function createOkxHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        const endpoint = 'wss://ws.okx.com:8443/ws/v5/business';
        ws = new WebSocket(endpoint);
    
        ws.onopen = () => {
          // Формируем название канала (например, candle1m, candle5m и т.д.)
          const channel = `${resolutionToExchangeInterval(params.resolution as ResolutionString, 'okxws')}`;
          const subMsg = {
            op: 'subscribe',
            args: [{
              channel, instId: params.symbolInfo.full_name, 
            }],
          };
              ws!.send(JSON.stringify(subMsg));
    
              // Heartbeat: каждые 15 сек отправляем ping
              pingInterval = window.setInterval(() => {
                if (ws && ws.readyState === WebSocket.OPEN) {
                  ws.send(JSON.stringify({
                    op: 'ping', 
                  }));
                }
              }, 15000);
              //@ts-ignore
              resolve(ws);
        };
    
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
            
          if (data.data) {
            const kline = data.data[0];
            const bar = {
              time: +kline[0],
              open: +kline[1],
              high: +kline[2],
              low: +kline[3],
              close: +kline[4],
              volume: +kline[5],
            };
            params.dispatch(terminalActions.setLimitLastPrice(bar.close));
            params.onTick(bar);
          }
        };
    
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
    
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
        };
      }),
    disconnect: () => {
      if (ws) ws.close();
      if (pingInterval) clearInterval(pingInterval);
    },
  };
}
