import { ResolutionString, SubscribeBarsCallback } from '../../charting_library/datafeed-api';
import { LibrarySymbolInfo } from '../../charting_library/datafeed-api';
import { ExtendedLibrarySymbolInfo, WebSocketHandlerParams } from '../types/chart-view.types';
import { ConnectionManager } from '../websocket/connection-manager';
import { CreateDataFeedParams } from './datafeed-factory';

export const createSubscriptionMethods = (globalParams: CreateDataFeedParams) => {
  const manager = new ConnectionManager();

  return {
    subscribeBars: async (
      symbolInfo: LibrarySymbolInfo,
      resolution: ResolutionString,
      onTick: SubscribeBarsCallback,
      subscriberUID: string,
    ): Promise<void> => {
      //@ts-ignore
      const params: WebSocketHandlerParams = {
        exchangeName: symbolInfo.exchange,
        symbol: symbolInfo.full_name,
        symbolInfo: symbolInfo as ExtendedLibrarySymbolInfo,
        resolution,
        subscriberUID,
        onTick,
        dispatch: globalParams.dispatch,
      };
      try {
        await manager.subscribe(params);
      } catch (error) {
        console.error(`Error subscribing for ${subscriberUID}:`, error);
      }
    },
    unsubscribeBars: (subscriberUID: string): void => {
      manager.unsubscribe(subscriberUID);
    },
  };
};
