export const getTitle = (title: string, t: any) => {
  switch (true) {
  case title.includes('advanced'):
    return t('modal.plans.items.advanced.title');
  case title.includes('pro'):
    return t('modal.plans.items.pro.title');
  case title.includes('basic'):
    return t('modal.plans.items.basic.title');
  default:
    return title;
  }
};
