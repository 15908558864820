import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { chooseAccountActions, getMarket, getTradingAmount } from 'entities/choose-account';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';
import { currencyFormatter } from 'shared/helpers/currency-formatter';
import { LabelInput } from 'shared/ui';
import { TRADING_AMOUNT_INPUT } from '../../../styles';

export const TradingAmount = () => {
  const bot = useSelector(getCurrentBot);
  const tradingAmount = useSelector(getTradingAmount);
  const market = useSelector(getMarket);

  const [amount, setAmount] = useState<string>(tradingAmount || '');

  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();

  const debounceAmount = useDebouncedCallback((value: string) => {
    dispatch(chooseAccountActions.setTradingAmount(value));
  }, 300);

  const setTradingAmountHandler = (value: string) => {
    const numericValue = value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1');
    setAmount(numericValue);
    debounceAmount(numericValue);
  };

  useEffect(() => {
    const tradingAmount = +currencyFormatter(bot?.tradingAmount || (+market.name.split('_')[1]?.replace(' ', '') * 0.1), market.presition) || '';

    dispatch(chooseAccountActions.setTradingAmount(tradingAmount.toString()));
    setAmount(tradingAmount.toString());
  }, [bot, dispatch, market]);

  return (
    <LabelInput
      label={(
        <Label
          title={t('form.configurator.amount.title')}
        />
      )}
      value={amount}
      placeholder={t('form.configurator.amount.placeholder')}
      onChange={setTradingAmountHandler}
      style={TRADING_AMOUNT_INPUT}
    />
  );
};
