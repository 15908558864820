import { Box } from '@mui/material';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { SuccessPlanCheckIcon } from 'shared/icons';
import {  Text } from 'shared/ui';
import { ICard } from '../interfaces';
import {
  descriptionStyles,
  inner,
  list,
  listItem,
  listWrapper,
  titleStyles,
  titleWrapper,
  wrapper,
} from '../styles';

export const WhatIncluded = (props: ICard) => {
  const {
    title,
    description,
    items,
  } = props;
  
  const {
    t, 
  } = useTranslation();

  const renderItem = (item: (string | null), index: number) => {
    if (item === null) {
      return (
        <Divider
          key={index}
          style={{
            margin: 0,
            backgroundColor: '#E9E9E9',
          }}
        />
      );
    }

    return (
      <Box
        key={index}
        sx={listItem}
      >
        {SuccessPlanCheckIcon}
        
        {item}
      </Box>
    );
  };

  return (
    <Box sx={wrapper(false)}>
      <Box sx={inner}>
        <Box sx={titleWrapper}>
          <Text styles={titleStyles}>
            {title}
          </Text>

          <Text styles={descriptionStyles(false)}>
            {description}
          </Text>
        </Box>

        <Box sx={listWrapper}>
          <Text styles={descriptionStyles(false)}>
            {t('modal.plans.items.subTitle')}
          </Text>

          <Box sx={list}>
            {items.map((item: string | null, index: number) => {
              return renderItem(item, index);
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
