import { useFormContext } from 'react-hook-form';
import { Buy } from '../buy/ui/buy';
import { Sell } from '../sell/ui/sell';
import { TradingPanelProps } from '../trading-panel/trading-panel';

export const TradingContent = ({
  currentSymbol,
  userWalletBaseAssetBalance,
  userWalletQuoteAssetBalance,
  exchangeAccountUuid,
  editingTrade,
}: TradingPanelProps) => {
  const {
    watch, 
  } = useFormContext();
  const selectedSide = watch('selectedSide');
    
  if (selectedSide === 'buy') {
    return (
      <Buy 
        currentSymbol={currentSymbol} 
        userWalletBaseAssetBalance={userWalletBaseAssetBalance} 
        userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
        exchangeAccountUuid={exchangeAccountUuid}
        editingTrade={editingTrade}
      />
    );
  }
  
  return (
    <Sell
      currentSymbol={currentSymbol} 
      userWalletBaseAssetBalance={userWalletBaseAssetBalance}
      userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
      exchangeAccountUuid={exchangeAccountUuid}
      editingTrade={editingTrade}
    />
  );
};
