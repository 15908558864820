import { Box, Stack } from '@mui/material';
import { getExchange, getTradingAmount } from 'entities/choose-account';
import { IStepProps } from 'pages/trading-bots/configurator-v2/interfaces';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MainButton } from 'shared/ui';
import { BasicInfo } from '../../../components';
import { ActiveTradingPairs, Exchange, Market, TradingAmount } from '../components';
import { FIELDS, INNER, WRAPPER } from '../styles';

export const ChooseAccount = memo((props: IStepProps) => {
  const exchange = useSelector(getExchange);
  const tradingAmount = useSelector(getTradingAmount);
  const {
    t, 
  } = useTranslation();
  
  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Stack sx={FIELDS}>
          <Exchange exchange={exchange} />

          <Market exchange={exchange} />

          <TradingAmount />

          <ActiveTradingPairs />
        </Stack>

        <Box
          sx={{
            width: '100%',
            maxWidth: 240,
            alignSelf: 'center',
            '&>button': {
              width: '100%',
            },
          }}
        >
          <MainButton
            type='primary'
            onClick={props.changeCurrentStep}
            disabled={!tradingAmount}
          >
            {t('configurator.button.next')}
          </MainButton>
        </Box>
      </Stack>

      <BasicInfo
        title={t('configurator.basic.title')}
        links={[{
          title: t('configurator.basic.links.read'),
          type: 'router-link',
          url: '/trading-bots/introduction',
        }, {
          title: t('configurator.basic.links.use'),
          type: 'link',
          url: 'https://www.tradingview.com/u/Skyrexio/#published-scripts',
        }]}
      />
    </Stack>
  );
});

ChooseAccount.displayName = 'ChooseAccount';
