import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

export const updateLanguage = async (language: string) => {
  const response = await axios.request({
    url: '/user/changeLanguage',
    data: {
      skyrexUserUuid: getSkyrexUuid(),
      language,
    },
  });

  return response.data;
};
