import { Stack } from '@mui/material';
import { getCloseOrdersAlertOrderType, riskManagmentCloseActions } from 'entities/risk-managment-close';
import { IRiskManagmentClose } from 'entities/risk-managment-close/interfaces';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Copy, Label } from 'widgets';
import { secretToken, sourceUuid } from 'shared/consts';
import { generateCustomAlertSendRequest, generateIndicatorAlertMessage, generateStrategyEntryFunction } from 'shared/helpers';
import { Text } from 'shared/ui';
import { Actions, OrderType, TradingViewLink } from '../../../../../components';
import { IAlertProps } from '../interfaces';

export const Alerts = (props: IAlertProps) => {
  const alertType = useSelector(getCloseOrdersAlertOrderType);

  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();

  const changeOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentCloseActions.setAlertOrderType(value as IRiskManagmentClose['segment']['alert']['orderType']));
  };

  const getContent = () => {
    switch (props.alertSource) {
    case 'indicator':
      return (
        <Copy
          label={(
            <Label
              title={t('configurator.source.indicator.message.title')}
              desciption={(
                <Text>
                  {t('configurator.source.indicator.message.description')} {' '}

                  <TradingViewLink />
                </Text>
              )}
            />
          )}
          value={generateIndicatorAlertMessage(sourceUuid, secretToken(props.exchangeAccountUuid), 'close')}
          display='row'
        />
      );
    case 'strategy':
      return (
        <Copy
          label={(
            <Label
              title={t('configurator.source.strategy.script.title')}
              desciption={(
                <Text>
                  {t('configurator.source.strategy.script.description')} {' '}

                  <TradingViewLink />
                </Text>
              )}
            />
          )}
          value={generateStrategyEntryFunction(sourceUuid, secretToken(props.exchangeAccountUuid), 'close')}
          display='row'
        />
      );
    case 'custom':
      return (
        <Copy
          label={(
            <Label
              title={t('configurator.source.custom.jsonEntry.title')}
              desciption={t('configurator.source.custom.jsonEntry.description')}
            />
          )}
          value={generateCustomAlertSendRequest(sourceUuid, secretToken(props.exchangeAccountUuid), 'close')}
          display='row'
        />
      );
    default:
      return null;
    }
  };

  const button = useMemo(() => {
    if (props.alertSource === 'strategy') {
      return {
        title: t('configurator.button.next'),
        action: props.changeCurrentStep!,
      };
    }

    return {
      title: props.botUuid ? t('configurator.button.update') : t('configurator.button.create'),
      action: props.createBot!,
    };
  }, [props.alertSource]);

  return (
    <Stack
      sx={{
        gap: '20px',
        maxWidth: {
          xs: '100%',
          md: 400,
        },
      }}
    >
      {props.alertSource !== 'strategy' && (
        <Copy
          label={(
            <Label
              title={t('configurator.source.indicator.url.title')}
              desciption={(
                <Text>
                  {t('configurator.source.indicator.url.description')} {' '}
                  
                  <TradingViewLink />
                </Text>
              )}
            />
          )}
          value='https://panel.skyrexio.com/api/v1/deal/alert'
          display='row'
        />
      )}

      {getContent()}

      <OrderType
        value={alertType}
        onChange={changeOrderTypeHandler}
      />

      <Actions
        handleBack={props.handleBack!}
        button={button}
      />
    </Stack>
  );
};
