import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui';
import { QrCode, Value } from '../components';
import { BLOCK, WRAPPER } from '../styles';

export const Download = (props: any) => {
  const {
    currentRef,
    pair,
    roi,
    entry,
    current,
    link,
    referralCode,
  } = props;

  const {
    t, 
  } = useTranslation();

  return (
    <Stack
      ref={currentRef}
      sx={WRAPPER}
    >
      <Stack
        sx={{
          position: 'relative',
          flex: 1,
          '&>span': {
            fontSize: 12,
            fontWeight: 600,
            color: '#585757',
            lineHeight: 'normal',
            position: 'absolute',
            right: '5%',
            bottom: '3.5%',
          },
        }}
      >
        <Stack sx={BLOCK}>
          <Value
            title={t('modal.shareResult.pair')}
            value={pair}
            type='pair'
          />

          <Value
            title={t('modal.shareResult.roi')}
            value={roi}
            type='roi'
          />

          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <Value
              title={t('modal.shareResult.entry')}
              value={entry.value}
              type='entry'
              date={entry.date}
            />
                
            <Value
              title={t('modal.shareResult.current')}
              value={current.value}
              type='entry'
              date={current.date}
            />
          </Stack>
        </Stack>

        <Text>
          {t('modal.shareResult.subText')}
        </Text>

        <QrCode
          link={link}
          referralCode={referralCode}
        />
      </Stack>
    </Stack>
  );
};
