import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { ResolutionString } from '../../charting_library/charting_library';
import { createResolution } from '../constants/chart-constants';

export const exchangeSupportedResolutions = {
  [EXCHANGE_NAMES.BINANCE]: [
    '1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M',
  ].map(createResolution),
  
  [EXCHANGE_NAMES.BYBIT]: [
    '1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M',
  ].map(createResolution),
  
  [EXCHANGE_NAMES.OKX]: [
    '1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M',
  ].map(createResolution),

  [EXCHANGE_NAMES.GATEIO]: [
    '1', '5', '15', '30', '60', '240', '1D', '7D', '30D',
  ].map(createResolution),
  
  [EXCHANGE_NAMES.CRYPTO_COM]: [
    '1', '5', '15', '30', '60', '240', '1D', '7D', '30D',
  ].map(createResolution),
  
  [EXCHANGE_NAMES.BITMART]: [
    '1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M',
  ].map(createResolution),
  
  [EXCHANGE_NAMES.HTX]: [
    '1', '5', '15', '30', '60', '240', '1D', '1W', '1M',
  ].map(createResolution),

  [EXCHANGE_NAMES.KUCOIN]: [
    '1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M',
  ].map(createResolution),
} as const;

// Функция для получения поддерживаемых резолюций для конкретной биржи
export const getExchangeResolutions = (exchange: string): ResolutionString[] => {
  const normalizedExchange = exchange.toLowerCase();
  return exchangeSupportedResolutions[normalizedExchange as keyof typeof exchangeSupportedResolutions] || 
         exchangeSupportedResolutions.binance; // fallback to binance if exchange not found
};

// Функция для проверки поддержки резолюции биржей
export const isResolutionSupported = (exchange: string, resolution: ResolutionString): boolean => {
  const supportedResolutions = getExchangeResolutions(exchange);
  return supportedResolutions.includes(resolution);
};
