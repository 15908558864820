import { Stack } from '@mui/material';
import { Divider } from 'antd';
import { getActiveTradingPairs, getMarket, getTradingAmount } from 'entities/choose-account';
import { getAlertSource } from 'entities/choose-source';
import { getIsOpened, getPresetOrders, getPresetOrdersSizeScale } from 'entities/risk-managment-additional';
import { getSegment, getStopLossIsOpened, getStopLossPriceChange, getTakeProfitOrders, getTakeProfitOrdersPriceChangeScale, getTakeProfitOrdersSizeScale, getTakeProfitPriceChange as getTakeProfitPriceChangeAction, riskManagmentCloseActions } from 'entities/risk-managment-close';
import { getPanelContent, getTakeProfitPriceChange, getTakeProfitValume } from 'pages/trading-bots/configurator-v2/helpers';
import { getBaseOrder } from 'pages/trading-bots/configurator-v2/helpers/get-base-order';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Block } from '../../../../block';
import { INNER, WRAPPER } from '../../../styles';

const divider = (
  <Divider
    style={{
      margin: 0, 
    }}
  />
);

export const Close = () => {
  const segment = useSelector(getSegment);

  const isAdditionalOpened = useSelector(getIsOpened);
  const additionalOrders = useSelector(getPresetOrders);
  const additionalOrdersSizeScale = useSelector(getPresetOrdersSizeScale);

  const tradingAmount = useSelector(getTradingAmount);
  const activeTradingPairs = useSelector(getActiveTradingPairs);

  const stopLossPriceChange = useSelector(getStopLossPriceChange);
  const alertSource = useSelector(getAlertSource);

  const market = useSelector(getMarket);

  const orders = useSelector(getTakeProfitOrders);
  const takeProfitPriceChange = useSelector(getTakeProfitPriceChangeAction);
  const takeProfitOrdersSizeScale = useSelector(getTakeProfitOrdersSizeScale);
  const takeProfitOrdersPriceChangeScale = useSelector(getTakeProfitOrdersPriceChangeScale);

  const isAlert = segment === 'alert';
  const isStopLossOpened = useSelector(getStopLossIsOpened);

  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();

  const baseOrder = useMemo(() => {
    const result = getBaseOrder({
      additional: {
        isOpened: isAdditionalOpened,
        orders: additionalOrders,
        ordersSizeScale: additionalOrdersSizeScale,
      },
      chooseAccount: {
        tradingAmount: +tradingAmount,
        activeTradingPairs: activeTradingPairs,
      },
    });

    return result;
  }, [
    isAdditionalOpened,
    additionalOrders,
    additionalOrdersSizeScale,
    tradingAmount,
    activeTradingPairs,
  ]);

  const {
    formatedItems: items,
    result,
  } = useMemo(() => {
    const arraySettings = {
      length: orders || 1, 
    };
    const result = Array.from(arraySettings).map((_, index) => {
      const position = ++index;

      const priceChange = getTakeProfitPriceChange({
        orders: orders,
        priceChange: takeProfitPriceChange,
        ordersPriceChangeScale: takeProfitOrdersPriceChangeScale,
        position,
      });
      const volume = getTakeProfitValume({
        orders: orders,
        ordersSizeScale: takeProfitOrdersSizeScale,
        position,
      });

      return {
        position,
        priceChange,
        volume: (!isFinite(volume) || isNaN(volume)) ? 0 : volume,
      };
    });

    const formatedItems = result.map((item) => {
      return [{
        value: item.position,
      }, {
        value: `${item.volume.toFixed(2)}%`,
      }, {
        value:  `${item.priceChange.toFixed(2)}%`,
      }];
    });

    return {
      formatedItems,
      result,
    };
  }, [
    orders,
    takeProfitPriceChange,
    takeProfitOrdersPriceChangeScale,
    takeProfitOrdersSizeScale,
  ]);

  const isTakeProfitErrorHandler = useCallback(() => {
    for (const item of result) {
      const value = ((item.volume / 100) * (1 + (+item.priceChange / 100)) * baseOrder);
      if (value < market.minNotional) {
        return true;
      }

      continue;
    }

    return false;
  }, [result, baseOrder]);

  const isStopLossErrorHandler = useCallback(() => {
    console.log('baseOrder, stopLossPriceChange, market.minNotional', baseOrder, stopLossPriceChange, market.minNotional);
    return baseOrder * (1 + (-Math.abs(stopLossPriceChange) / 100)) < market.minNotional;
  }, [baseOrder, stopLossPriceChange, market.minNotional]);

  const error = isTakeProfitErrorHandler() ? 'Some of take profit orders are less than minimum order amount' : '';
  const stopLossError = isStopLossErrorHandler() ? 'Stop loss order is less than minimum order amount' : '';
  console.log('stopLossError', stopLossError);

  useEffect(() => {
    console.log('error || stopLossError', error || stopLossError);
    dispatch(riskManagmentCloseActions.setError(error || stopLossError));
  }, [error, stopLossError, dispatch]);
  
  return (
    <Stack sx={WRAPPER}>
      {getPanelContent({
        isAlert,
        source: alertSource,
      })}

      <Stack sx={INNER}>
        <Block
          title={t('configurator.managment.panel.close.title')}
          layout='row'
          content={[]}
        />

        <Block
          title=''
          layout='table'
          error={error}
          content={{
            headers: [{
              label: '№',
              key: 'order',
            }, {
              label: t('configurator.managment.panel.close.table.headers.volume'),
              key: 'volume',
            }, {
              label: t('configurator.managment.panel.close.table.headers.price'),
              key: 'price-change',
            }],
            prefixTitle: t('configurator.managment.panel.close.table.title'),
            items: items as any,
          }}
        />

        {divider}

        <Block
          layout='row'
          error={stopLossError}
          content={[{
            title: t('configurator.managment.panel.close.stop'),
            value: isStopLossOpened ? `${-Math.abs(stopLossPriceChange)}%` : '',
          }]}
        />
      </Stack>
    </Stack>
  );
};
