import { CSSProperties } from 'react';

interface IButtonStyles {
  maxWidth: number | string;
}
export const BUTTON = (params?: IButtonStyles) => {
  const styles: CSSProperties = {
    height: 'auto',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
    width: '100%',
    maxWidth: params?.maxWidth || 180,
    padding: '7px 12px',
  };

  return styles;
};
