import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

export const TITLE: CSSProperties = {
  fontSize: getFontSize(18, 40),
  fontWeight: 500,
  lineHeight: 'normal',
  color: '#000000',
  textAlign: 'center',
};
