import { Box } from '@mui/material';
import { Modal, Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChooseExchange, Exchanges } from 'widgets';
import { IExchanges } from 'widgets/exchanges/interfaces';
import { BackIcon, ClockIcon } from 'shared/icons';
import { Text } from 'shared/ui';
import { IOnboarding } from '../interfaces';
import { back, contentWrapper, modal, modalChildren, stepDescription, stepsWrapper, stepTitle } from '../styles';
import { StartTrading, Welcome } from '../views';

export const Onboarding = (props: IOnboarding) => {
  const {
    isOpen,
    closeHandler,
    isSuccess,
  } = props;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [platform, setPlatform] = useState<IExchanges['platform'] | null>(null);

  const {
    t, 
  } = useTranslation();

  const backHandler = () => {
    setCurrentStep(prev => prev - 1);
  };

  const getSecondStepContent = () => {
    if (!platform) {
      return (
        <ChooseExchange
          onChoose={(platform: string) => {
            setPlatform(platform as IExchanges['platform']);
          }}
          changeStep={(step: number) => {
            setCurrentStep(step);
          }}
        />
      );
    }

    return (
      <Exchanges
        platform={platform}
        returnToAllExchanges={() => {
          setPlatform(null);
        }}
        changeStep={() => {
          setCurrentStep((prev) => prev + 1);
        }}
      />
    );
  };

  const getContent = () => {
    if (!currentStep) {
      return (
        <Welcome
          skipHandler={closeHandler}
          changeStep={() => {
            setCurrentStep(prev => prev + 1);
          }}
        />
      );
    }

    if (currentStep === 1) {
      return getSecondStepContent();
    }

    return (
      <StartTrading />
    );
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentStep(0);
      setPlatform(null);

      return;
    }

    if (!isSuccess) {
      return;
    }

    setCurrentStep(2);
  }, [isSuccess, isOpen]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box>
        <Box sx={stepsWrapper}>
          {!!currentStep && (
            <div onClick={backHandler}>
              <Box sx={back}>
                {BackIcon}

                <Text type='success'>
                  {t('modal.onboarding.button')}
                </Text>
              </Box>
            </div>
          )}

          <Steps
            progressDot={true}
            current={currentStep}
            size='small'
            items={[{
              title: (
                <Text
                  styles={stepTitle}
                  type='success'
                >
                  {t('modal.onboarding.steps.summary')}
                </Text>
              ),
              description: (
                <Box sx={stepDescription}>
                  {ClockIcon}

                  <Text type='secondary'>
                    8s
                  </Text>
                </Box>
              ),
            }, {
              title: (
                <Text
                  styles={stepTitle}
                  type={currentStep > 0 ? 'success' : undefined}
                >
                  {t('modal.onboarding.steps.exchange')}
                </Text>
              ),
              description: (
                <Box sx={stepDescription}>
                  {ClockIcon}

                  <Text type='secondary'>
                    20s
                  </Text>
                </Box>
              ),
            }, {
              title: (
                <Text
                  styles={stepTitle}
                  type={currentStep === 2 ? 'success' : undefined}
                >
                  {t('modal.onboarding.steps.trading')}
                </Text>
              ),
              description: (
                <Box sx={stepDescription}>
                  {ClockIcon}

                  <Text type='secondary'>
                    10s
                  </Text>
                </Box>
              ),
            }]}
          />
        </Box>

        <Box sx={contentWrapper}>
          {getContent()}
        </Box>
      </Box>
    </Modal>
  );
};
