import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, Title } from 'shared/ui';
import { IPerfomanceProps } from '../interfaces';
import { INNER, ITEM, ITEM_TEXT, ITEM_TITLE, TITLE, WRAPPER } from '../styles';

export const Perfomance = (props: IPerfomanceProps) => {
  const {
    t, 
  } = useTranslation();
  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        {t('terminal.introduction.perfomance.title')}
      </Title>

      <Stack sx={INNER}>
        {props.items.map((item) => {
          return (
            <Stack
              key={item.title}
              sx={ITEM}
            >
              <Title styles={ITEM_TITLE}>
                {item.title}
              </Title>

              <Text styles={ITEM_TEXT}>
                {item.text}
              </Text>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};
