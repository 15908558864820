import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { StoreProvider } from 'app/providers/store-provider/ui/store-provider';
import App from './App';
import { theme } from './shared/api/consts';
import './shared/styles/index.css';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import 'dayjs/locale/ru';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <StoreProvider>
    <ConfigProvider theme={theme}>
      <BrowserRouter>
        <ToastContainer
          position='top-right'
          autoClose={3000}
          closeOnClick={true}
          rtl={false}
          pauseOnHover={true}
          hideProgressBar={true}
          theme='light'
        />
        
        <App />
      </BrowserRouter>
    </ConfigProvider>
  </StoreProvider>,
);
