import { SegmentedLabeledOption } from 'antd/es/segmented';

export const segments = (t: any): SegmentedLabeledOption<string>[] => {
  return [{
    label: t('terminal.panel.segments.limit'),
    value: 'limit',
  }, {
    label: t('terminal.panel.segments.market'),
    value: 'market',
  }, {
    label: t('terminal.panel.segments.conditional'),
    value: 'conditional',
  }];
};
