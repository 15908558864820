import { SegmentedLabeledOption } from 'antd/es/segmented';

export const triggerPriceSegments = (t: any): SegmentedLabeledOption<string>[] => {
  return [{
    label: t('terminal.panel.fields.triggerPrice.segments.limit'),
    value: 'limit',
  }, {
    label: t('terminal.panel.fields.triggerPrice.segments.market'),
    value: 'market',
  }];
};
