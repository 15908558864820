import { Stack } from '@mui/material';
import { Image } from 'widgets/image';
import { TextBlock } from 'widgets/introduction/text-block';
import { Title } from 'shared/ui';
import { IWelcomeProps } from '../interfaces';
import { BLOCKS, INNER, TITLE, WRAPPER } from '../styles';

export const Welcome = (props: IWelcomeProps) => {
  const {
    title,
    image,
    blocks,
  } = props;

  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        {title}
      </Title>

      <Stack sx={INNER}>
        <Image
          src={image}
          maxWidth='474px'
          shrink={true}
        />

        <Stack sx={BLOCKS}>
          {blocks.map((block) => {
            return (
              <TextBlock
                key={block.title}
                title={block.title}
                text={block.text}
              />
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};
