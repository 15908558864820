import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui';
import { IProps } from '../interfaces';

export const CopyRight = (props: IProps) => {
  const {
    styles,
  } = props;

  const {
    t, 
  } = useTranslation();

  return (
    <Text
      type='secondary'
      styles={styles}
    >
      &#169; {new Date().getFullYear()} Skyrexio LLC. {t('copyRight')}
    </Text>
  );
};
