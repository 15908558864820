import { BaseOptionType } from 'antd/es/select';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui';
import { Exchange as ExchangeView } from 'shared/ui/selects/single-select/views';

interface ExchangeSelectProps {
  exchanges: any[];
  selectedExchange: any;
  isLoading: boolean;
  onSelect: (value: string) => void;
}

export const ExchangeSelect = (props: ExchangeSelectProps) => {
  const { 
    exchanges, 
    selectedExchange, 
    onSelect, 
  } = props;

  const {
    t, 
  } = useTranslation();
  
  const exchangeView = useCallback(() => {
    if (!selectedExchange.value) {
      return null;
    }

    const balance = selectedExchange.balances.find((balance: any) => balance.symbol === 'USDT');
    const availableAmount = balance?.free || 0;
    
    return (
      <ExchangeView
        title={selectedExchange.label}
        code={selectedExchange.exchangeCode}
        label={selectedExchange.type || 'Spot'}
        balance={+availableAmount}
      />
    );
  }, [selectedExchange]);

  const exchangeOptionView = useCallback((option: BaseOptionType) => {
    if (!option.value) {
      return null;
    }
    
    const {
      label,
      exchangeCode,
    } = option.data;

    const balance = option.data.balances.find((balance: any) => balance.symbol === 'USDT');
    const availableAmount = balance?.free || 0;
    
    return (
      <ExchangeView
        title={label}
        code={exchangeCode}
        label={'Spot'}
        balance={+availableAmount}
      />
    );
  }, []);
  
  return (
    <SingleSelect
      label={(
        <Label
          title={t('terminal.select.exchange')}
        />
      )}
      options={exchanges}
      
      select={{
        //@ts-ignore
        value: {
          value: selectedExchange?.value,
          label: selectedExchange?.label,
          balance: selectedExchange?.balance,
        },
        placeholder: '',
        onChange: onSelect,
      }}
      labelRender={exchangeView}
      optionRender={exchangeOptionView}
      size='large'
      loading={!exchanges.length}
    />
  );
};
