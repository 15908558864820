import { Box, Stack } from '@mui/material';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { getAlertSource } from 'entities/choose-source';
import { getIsOpened, getSegment, riskManagmentAdditionalActions } from 'entities/risk-managment-additional';
import { Actions } from 'pages/trading-bots/configurator-v2/components';
import { getAdditionalEntryOrdersValue, getPanelContent } from 'pages/trading-bots/configurator-v2/helpers';
import { ReactNode, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Panel } from '../../configure-risk-managment/components';
import { WRAPPER } from '../styles';
import { Alerts, Preset } from '../tabs';

export const AdditionalEntry = (props: any) => {
  const bot = useSelector(getCurrentBot);
  const isOpened = useSelector(getIsOpened);
  const segment = useSelector(getSegment);
  const alertSource = useSelector(getAlertSource);

  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();

  const getContent = useCallback((): ReactNode => {
    if (!isOpened) {
      return null;
    }

    switch (segment) {
    case 'preset':
      return (
        <Preset />
      );
    case 'alert':
      return (
        <Alerts />
      );
    default:
      return null;
    }
  }, [isOpened, segment]);

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(riskManagmentAdditionalActions.init(getAdditionalEntryOrdersValue(bot)));
  }, [bot?.botUuid, dispatch]);

  return (
    <Stack
      sx={{
        ...WRAPPER,
        flexDirection: {
          xs: 'column',
          md: segment === 'preset' ? 'row' : 'column',
        },
        alignItems: {
          xs: 'initial',
          md: segment === 'preset' ? 'flex-start' : 'initial',
        },
      }}
    >
      {isOpened && getContent()}

      <Box
        sx={{
          display: 'flex',
          gap: '40px',
          width: '100%',
          maxWidth: {
            xs: '100%',
            md: segment === 'alert' ? '100%' : 386,
          },
          justifyContent: 'space-between',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        {isOpened && getPanelContent({
          isAlert: segment === 'alert',
          source: alertSource,
          url: `/videos/additional-${alertSource}.mp4`,
        })}

        <Stack
          sx={{
            gap: '24px',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidth: {
              xs: '100%',
              md: segment === 'alert' ? 386 : '100%',
            },
          }}
        >
          <Panel type='additional' />

          <Actions
            handleBack={props.handleBack}
            button={{
              title: t('configurator.button.next'),
              action: props.changeCurrentStep,
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );
};
