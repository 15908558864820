import { Box, Stack } from '@mui/material';
import { Segmented } from 'antd';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, LabelInput, SingleSelect, Slider, Text } from 'shared/ui';
import { ErrorMessage } from '../../error-message';
import { segments, triggerPriceSegments } from '../../fields/consts';
import { IFields } from '../../fields/interfaces';
import { action, actions, actionTitle, inner, sliderWrapper, wrapper } from '../../fields/styles';

//@TODO CREATE NEW COMMON TYPE
export const BaseOrderSell = (props: IFields) => {
  const {
    isSkipBaseOrder,
    onOrderPriceChange,
    onTotalChange,
    onUnitsChange,
    onSliderChange,
    onTriggerPriceChange,
    onSkipBaseSliderChange,
    onBoughtPriceChange,
    onSkipBaseUnitsChange,
    onSkipBaseTotalChange,
  } = props;

  const {
    control,
    formState: {
      errors,
    },
    trigger,
    watch,
    setValue,
  } = useFormContext();

  const {
    t, 
  } = useTranslation();

  const currentSymbol = watch('currentSymbol');
  const quoteAsset = watch('quoteAsset');
  const orderType = watch('orderType');
  const conditionalOrderType = watch('conditionalOrderType');

  const changeSegmentHandler = (value: string) => {
    setValue('orderType', value);
  };

  const changeTriggerPriceSegmentHandler = (value: string) => {
    setValue('conditionalOrderType', value);
  };

  const renderOrderPriceField = () => {
    if (orderType === 'conditional' && conditionalOrderType === 'market') {
      return null;
    }

    return (
      <Controller
        name='orderPrice'
        control={control}
        rules={{
          required: 'This field is required',
          validate: (value) => {
            const numValue = Number(value);
            if (isNaN(numValue)) return 'Value should be a number';
            if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
            if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
            return true;
          },
        }}
        render={({
          field,
        }) => (
          <Stack
            gap={0.5}
            height={81}
          >
            <LabelInput
              label={t('terminal.panel.fields.price.origin')}
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onOrderPriceChange(value);
                }
                trigger('orderPrice');
                trigger('total');
                trigger('units');
                trigger('slider');
              }}
              onBlur={() => {
                const formattedValue = formatByPrecisionAndTrim(
                  field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                field.onChange(formattedValue);
                onOrderPriceChange(formattedValue);
                trigger('orderPrice');
                trigger('slider');
                trigger('total');
                trigger('units');
              }}
              icon={currentSymbol.quoteAsset}
              disabled={orderType === 'market' && !isSkipBaseOrder}
              status={errors.orderPrice ? 'error' : undefined}
            />
            
            <ErrorMessage message={errors?.orderPrice?.message} />
          </Stack>
        )}
      />
    );
  };

  const renderBoughtPriceField = () => (
    <Controller
      name='boughtPrice'
      control={control}
      rules={{
        required: 'This field is required',
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) return 'Value should be a number';
          if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
          if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('terminal.panel.fields.price.skip.sell')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onBoughtPriceChange(value);
              }
              trigger('boughtPrice');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
              field.onChange(formattedValue);
              onBoughtPriceChange(formattedValue);
              trigger('boughtPrice');
            }}
            icon={currentSymbol.quoteAsset}
            status={errors.boughtPrice ? 'error' : undefined}
          />

          <ErrorMessage message={errors?.boughtPrice?.message} />
        </Stack>
      )}
    />
  );

  const renderSkipBaseUnitsField = () => (
    <Controller
      name='skipBaseUnits'
      control={control}
      rules={{
        required: 'This field is required',
      }}
      render={({
        field,
      }) => (
        <LabelInput
          label={t('terminal.panel.fields.units')}
          value={field.value}
          onChange={(value) => {
            if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
              field.onChange(value);
              onSkipBaseUnitsChange(value);
            }
            trigger('skipBaseUnits');
          }}
          onBlur={() => {
            const formattedValue = formatByPrecisionAndTrim(
              field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
            field.onChange(formattedValue);
            onSkipBaseUnitsChange(formattedValue);
            trigger('skipBaseUnits');
          }}
          icon={currentSymbol.baseAsset}
          status={errors.skipBaseUnits ? 'error' : undefined}
        />
      )}
    />
  );

  const renderUnitsField = () => (
    <Controller
      name='units'
      control={control}
      rules={{
        required: 'This field is required',
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) return 'Value should be a number';
          if (numValue < Number(currentSymbol.lotMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.lotMin)}`;
          if (numValue > Number(currentSymbol.lotMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.lotMax)}`;
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('terminal.panel.fields.units')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onUnitsChange(value);
              }
              trigger('total');
              trigger('units');
              trigger('slider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
              field.onChange(formattedValue);
              onUnitsChange(formattedValue);
              trigger('units');
              trigger('total');
              trigger('slider');
            }}
            icon={currentSymbol.baseAsset}
            status={errors.units ? 'error' : undefined}
          />

          <ErrorMessage message={errors?.units?.message} />
        </Stack>
      )}
    />
  );

  const renderSkipBaseTotalField = () => (
    <Controller
      name='skipBaseTotal'
      control={control}
      rules={{
        required: 'This field is required',
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) return 'Value should be a number';
          if (numValue < Number(currentSymbol.minNotional)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.minNotional)}`;
          if (numValue > Number(currentSymbol.maxNotional)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.maxNotional)}`;
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('terminal.panel.fields.total')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onSkipBaseTotalChange(value);
              }
              trigger('skipBaseTotal');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
              field.onChange(formattedValue);
              onSkipBaseTotalChange(formattedValue);
              trigger('skipBaseTotal');
            }}
            icon={currentSymbol.quoteAsset}
            status={errors.skipBaseTotal ? 'error' : undefined}
          />

          <ErrorMessage message={errors?.skipBaseTotal?.message} />
        </Stack>
      )}
    />
  );

  const renderTotalField = () => (
    <Controller
      name='total'
      control={control}
      rules={{
        required: 'This field is required',
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) return 'Value should be a number';
          if (numValue < Number(currentSymbol.minNotional)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.minNotional)}`;
          if (numValue > Number(currentSymbol.maxNotional)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.maxNotional)}`;
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('terminal.panel.fields.total')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onTotalChange(value);
              }
              trigger('total');
              trigger('slider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
              field.onChange(formattedValue);
              onTotalChange(formattedValue);
              trigger('total');
              trigger('units');
              trigger('slider');
            }}
            icon={quoteAsset}
            status={errors.total ? 'error' : undefined}
          />
          
          <ErrorMessage message={errors?.total?.message} />
        </Stack>
      )}
    />
  );

  const renderSliderForSkipBaseOrder = () => (
    <Box sx={sliderWrapper}>
      <Controller
        name='skipBaseSlider'
        control={control}
        render={({
          field,
        }) => (
          <Slider
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              onSkipBaseSliderChange(value);
              trigger('skipBaseSlider');
            }}
          />
        )}
      />

      <Controller
        name='skipBaseSlider'
        control={control}
        render={({
          field,
        }) => (
          <Input
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              onSkipBaseSliderChange(parseFloat(value));
              trigger('skipBaseSlider');
            }}
            placeholder='100%'
            maxWidth={58}
            icon='%'
          />
        )}
      />
    </Box>
  );

  const renderSlider = () => (
    <Stack height={53}>
      <Box sx={sliderWrapper}>
        <Controller
          name='slider'
          control={control}
          rules={{
            max: {
              value: 100,
              message: 'Cannot be more than 100%',
            },
            min: {
              value: 1,
              message: 'Should be more than 0%',
            },
          }}
          render={({
            field,
          }) => (
            <Slider
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                onSliderChange(value);
                trigger('slider');
                trigger('total');
                trigger('units');
              }}
            />
          )}
        />

        <Controller
          name='slider'
          control={control}
          render={({
            field,
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onSliderChange(parseFloat(value));
                  trigger('slider');
                  trigger('total');
                  trigger('units');
                }
              }}
              placeholder='100%'
              maxWidth={58}
              icon='%'
              status={errors.slider ? 'error' : undefined}
            />
          )}
        />
      </Box>

      <ErrorMessage message={errors?.slider?.message} />
    </Stack>
  );

  const renderConditionalFields = () => (
    <>
      <Stack
        gap={0.5}
        height={81}
      >
        <Box sx={actions}>
          <Text
            type='secondary'
            styles={actionTitle}
          >
            {t('terminal.panel.fields.triggerPrice.title')}
          </Text>

          <Box sx={action}>
            <Controller
              name='triggerPrice'
              control={control}
              rules={{
                required: 'This field is required',
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) return 'Value should be a number';
                  if (numValue < Number(currentSymbol.priceMin)) return `Minimum value is ${getTrailedZeroCutted(currentSymbol.priceMin)}`;
                  if (numValue > Number(currentSymbol.priceMax)) return `Maximum value is ${getTrailedZeroCutted(currentSymbol.priceMax)}`;
                  return true;
                },
              }}
              render={({
                field,
              }) => (
                <Input
                  value={field.value}
                  onChange={(value) => {
                    if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                      field.onChange(value);
                      onTriggerPriceChange(value);
                      trigger('triggerPrice');
                    }
                  }}
                  onBlur={() => {
                    const formattedValue = formatByPrecisionAndTrim(
                      field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.priceMin), Number(currentSymbol.priceMax));
                    field.onChange(formattedValue);
                    onTriggerPriceChange(formattedValue);
                    trigger('triggerPrice');
                  }}
                  icon={quoteAsset}
                  status={errors.triggerPrice ? 'error' : undefined}
                />
              )}
            />
            <Controller
              name='triggerPriceType'
              control={control}
              render={({
                field,
              }) => (
                <SingleSelect
                  maxWidth='max-content'
                  select={{
                    value: field.value,
                    placeholder: 'Last',
                    onChange: (value) => {
                      field.onChange(value);
                      trigger('triggerPriceType');
                    },
                  }}
                  options={[{
                    label: t('terminal.panel.fields.types.last'),
                    value: 'last',
                  }, {
                    label: t('terminal.panel.fields.types.bid'),
                    value: 'bid',
                  }, {
                    label: t('terminal.panel.fields.types.ask'),
                    value: 'ask',
                  }]}
                />
              )}
            />
          </Box>
        </Box>

        <ErrorMessage message={errors?.triggerPrice?.message} />
      </Stack>

      <Controller
        name='conditionalOrderType'
        control={control}
        render={({
          field,
        }) => (
          <Segmented
            value={field.value}
            onChange={(value) => {
              field.onChange(value);
              changeTriggerPriceSegmentHandler(value);
            }}
            options={triggerPriceSegments(t)}
            block={true}
          />
        )}
      />
    </>
  );

  const getContent = () => {
    if (isSkipBaseOrder) {
      return (
        <>
          {renderBoughtPriceField()}
          {renderSkipBaseUnitsField()}
          {renderSkipBaseTotalField()}
          {renderSliderForSkipBaseOrder()}
        </>
      );
    }
    
    return (
      <>
        {renderOrderPriceField()}
        {renderTotalField()}
        {renderUnitsField()}
        {renderSlider()}
      </>
    );
  };

  return (
    <Box sx={wrapper}>
      {!isSkipBaseOrder && (
        <Segmented
          value={orderType}
          onChange={changeSegmentHandler}
          options={segments(t)}
          block={true}
        />
      )}

      {orderType === 'conditional' && !isSkipBaseOrder && renderConditionalFields()}

      <Box sx={inner}>
        {getContent()}
      </Box>
    </Box>
  );
};

export default BaseOrderSell;
