import { SxProps, Theme } from '@mui/material';

export const STEP: SxProps<Theme> = {
  gap: '24px',
  position: 'relative',
  alignItems: 'center',
  textAlign: 'center',
  flex: 1,
  '&:not(:last-of-type):after': {
    content: '""',
    display: 'flex',
    width: '50%',
    height: '1px',
    backgroundColor: '#D2D2D2',
    position: 'absolute',
    right: '-50%',
    top: '25px',
    transform: 'translateX(-50%)',
  },
  '&:nth-child(even):after': {
    display: {
      xs: 'none',
      md: 'flex',
    },
  },
  '&:nth-child(odd):after': {
    display: {
      xs: 'none',
      sm: 'flex',
    },
  },
};
