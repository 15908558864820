import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { ResolutionString } from '../../charting_library/charting_library';
import { WebSocketHandler } from '../types/chart-view.types';
import { WebSocketHandlerParams } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';

const createBar = (klineData: any): any => {
  const [timestamp, open, high, low, close, volume] = klineData.candle;
      
  return {
    time: timestamp * 1000,
    open: parseFloat(open),
    high: parseFloat(high),
    low: parseFloat(low),
    close: parseFloat(close),
    volume: parseFloat(volume),
  };
};

export function createBitmartHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
  let inactivityTimeout: number | undefined; // таймер на отсутствие данных
  
  // Функция для сброса таймера активности
  const resetInactivityTimer = () => {
    if (inactivityTimeout) clearTimeout(inactivityTimeout);
    // Если в течение 15 секунд не придет новое сообщение, отправляем ping
    inactivityTimeout = window.setTimeout(() => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send('ping');
      }
    }, 15000); // примерное значение (можно настроить)
  };
    
    
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        const endpoint = 'wss://ws-manager-compress.bitmart.com/api?protocol=1.1';
        ws = new WebSocket(endpoint);
          
        ws.onopen = () => {
          const subMsg = {
            op: 'subscribe',
            args: [
              `spot/kline${resolutionToExchangeInterval(
                  params.resolution as ResolutionString,
                  'bitmartws',
              )}:${params.symbolInfo.full_name}`,
            ],
          };
            
          if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify(subMsg));
          }
    
          // Запускаем heartbeat: каждые 25 секунд отправляем ping, если соединение активно
          pingInterval = window.setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
              ws.send('ping');  // Отправляем просто текст "ping"
            }
          }, 25000);
    
          // Устанавливаем начальный таймер на отсутствие активности
          resetInactivityTimer();
    
          //@ts-ignore
          resolve(ws);
        };
    
        ws.onmessage = (event) => {
            
          if (event.data === 'pong') {
            // Например, сбрасываем таймер активности
            resetInactivityTimer();
            return;
          }
            
          const data = JSON.parse(event.data);
            
          // Если получаем pong, можно сбросить таймер
          if (typeof data === 'string' && data === 'pong') {
            resetInactivityTimer();
            return;
          }
      
          // Если сообщение содержит ошибку
          if (data.errorCode) {
            console.error('Bitmart WebSocket error:', data);
            return;
          }
            
          if (data.table?.startsWith('spot/kline')) {
            const bar = createBar(data.data[0]);
            params.dispatch(terminalActions.setLimitLastPrice(bar.close));
            params.onTick(bar);
          }
            
          resetInactivityTimer();
        };
    
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
    
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
          if (inactivityTimeout) clearTimeout(inactivityTimeout);
        };
      }),
    disconnect: () => {
      if (ws) ws.close();
      if (pingInterval) clearInterval(pingInterval);
      if (inactivityTimeout) clearTimeout(inactivityTimeout);
    },
  };
}
