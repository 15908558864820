import { Stack } from '@mui/material';
import { Space } from 'antd';
import { Text } from 'shared/ui';
import { IListItemProps } from '../interfaces';

export const ListItem = (props: IListItemProps) => {
  const {
    title,
    icon,
    handler,
  } = props;

  return (
    <Space
      onClick={handler}
      size={20}
      style={{
        cursor: 'pointer',
      }}
    >
      <Stack
        alignItems='center'
        direction='row'
      >
        {icon}
      </Stack>

      <Text>
        {title}
      </Text>
    </Space>
  );
};
