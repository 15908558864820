import { Box } from '@mui/material';
import { Form, Modal } from 'antd';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { LabelInput, MainButton, Text, Title } from 'shared/ui';
import { createProviderPayout, createReferalPayout, validateWallet } from '../api';
import { amountRules, walletRules } from '../consts';
import { IWithDrawRequest } from '../interfaces';
import { button, description, formItem, inner, modal, modalChildren, title, topWrapper, wrapper } from '../styles';

export const WithDrawRequest = (props: IWithDrawRequest) => {
  const {
    isOpen,
    available,
    handleClose,
    fetchData,
    isReferal,
  } = props;

  const [amount, setAmount] = useState<string>('');
  const [wallet, setWallet] = useState<string>('');

  const [form] = Form.useForm();
  const {
    t, 
  } = useTranslation();

  const inputHandler = (action: Dispatch<SetStateAction<string>>) => {
    return (value: string) => {
      action(value);
    };
  };

  const validateWalletHandler = async (value: string) => {
    const response = await validateWallet(value);
    if (!response?.success) {
      form.setFields([{
        name: 'wallet',
        errors: [t('validation.withdraw.wallet.existing')],
      }]);
      
      return false;
    }

    return true;
  };

  const submitHandler = async () => {
    const result = await form
      .validateFields()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });

    if (!result) {
      return;
    }

    if (wallet) {
      const result = await validateWalletHandler(wallet);
      if (!result) {
        return;
      }
    }

    const formattedAmount = formatByPrecisionAndTrim(amount, 8, +amount, +amount);
    const response = await (isReferal ? createReferalPayout(+formattedAmount, wallet) : createProviderPayout(+formattedAmount, wallet));
    if (!response.success) {
      toast.error(t('error.withdraw.balance') as string);
      return;
    }
    
    toast.success(t('success.withdraw.success') as string);

    handleClose();
    fetchData?.();

    setAmount('');
    setWallet('');
  };

  useEffect(() => {
    if (!isOpen) {
      setAmount('');
      setWallet('');
      form.resetFields();
    }
  }, [isOpen, form]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={handleClose}
      width='100%'
      centered={true}
    >
      <Form
        form={form}
        layout='vertical'
      >
        <Box sx={wrapper}>
          <Box sx={topWrapper}>
            <Title styles={title}>
              {t('modal.withdraw.title')}
            </Title>

            <Text styles={description}>
              {t('modal.withdraw.description')}
            </Text>
          </Box>

          <Box sx={inner}>
            <Form.Item
              name='amount'
              style={formItem}
              rules={amountRules(t, available)}
            >
              <LabelInput
                label={t('modal.withdraw.amount.title', {
                  available,
                })}
                value={amount}
                onChange={inputHandler(setAmount)}
                placeholder={t('modal.withdraw.amount.placeholder')}
              />
            </Form.Item>
          
            <Form.Item
              name='wallet'
              style={formItem}
              rules={walletRules(t)}
            >
              <LabelInput
                label={t('modal.withdraw.wallet.title')}
                value={wallet}
                onChange={inputHandler(setWallet)}
                placeholder={t('modal.withdraw.wallet.placeholder')}
                onPaste={validateWalletHandler}
              />
            </Form.Item>

            <MainButton
              type='primary'
              ghost={true}
              styles={button}
              onClick={submitHandler}
            >
              {t('modal.withdraw.button')}
            </MainButton>
          </Box>
        </Box>
      </Form>
    </Modal>
  );
};
