import { Stack } from '@mui/material';
import { MainButton, Text, Title } from 'shared/ui';
import { IEndingProps } from '../interfaces';
import { BUTTON, INNER, TEXT, TITLE, WRAPPER } from '../styles';

export const Ending = (props: IEndingProps) => {
  const {
    title,
    text,
    button,
  } = props;

  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Title styles={TITLE}>
          {title}
        </Title>

        <Text styles={TEXT}>
          {text}
        </Text>
      </Stack>

      <MainButton
        type='primary'
        onClick={button.action}
        styles={BUTTON}
      >
        {button.title}
      </MainButton>
    </Stack>
  );
};
