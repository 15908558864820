import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Copy, Label } from 'widgets';
import { generateCustomAlertSendRequest } from 'shared/helpers';
import { ITabProps } from '../../../interfaces';
import { WRAPPER } from '../styles';

export const Custom = (props: ITabProps) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Copy
        label={(
          <Label
            title={t('configurator.source.custom.url.title')}
            desciption={t('configurator.source.custom.url.description')}
          />
        )}
        value='https://panel.skyrexio.com/api/v1/deal/alert'
      />

      <Copy
        label={(
          <Label
            title={t('configurator.source.custom.json.title')}
            desciption={t('configurator.source.custom.json.description')}
          />
        )}
        value={generateCustomAlertSendRequest(props.sourceUuid, props.secretToken, props.position)}
        display='row'
      />
    </Stack>
  );
};
