import { ExchangeName } from 'shared/consts/available-exchanges';
import { currencyFormatter } from './currency-formatter';

interface Formatter {
  symbols?: Array<{
    quoteAsset: string;
    chartPrecision: number;
  }>;
}

interface Formatters {
  [key: string]: Formatter;
}

interface FormatValueListItemsParams {
  value: number;
  currency?: string;
  formatters: Formatters;
  exchange: ExchangeName;
  skipQuote?: boolean;
}

export const formatValueListItems = ({
  value,
  currency,
  formatters,
  exchange,
  skipQuote = false,
}: FormatValueListItemsParams) => {
  if (!currency) return String(value);
  
  const precision = formatters[exchange]?.symbols?.find(
    (element) => element.quoteAsset === currency.toUpperCase(),
  )?.chartPrecision ?? 4;
  
  const formattedValue = currencyFormatter(value, precision);
  
  return skipQuote ? formattedValue : `${formattedValue} ${currency.toUpperCase()}`;
};
