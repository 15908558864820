import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { ResolutionString } from '../../charting_library/charting_library';
import { WebSocketHandlerParams } from '../types/chart-view.types';
import { WebSocketHandler } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';

export function createCryptoComHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        const endpoint = 'wss://stream.crypto.com/exchange/v1/market';
        ws = new WebSocket(endpoint);
    
        ws.onopen = () => {
          const subMsg = {
            id: 1,
            method: 'subscribe',
            params: {
              channels: [
                `candlestick.${resolutionToExchangeInterval(
                    params.resolution as ResolutionString,
                    'crypto-com',
                )}.${params.symbolInfo.full_name}`,
              ],
            },
            nonce: Date.now(),
          };
              ws!.send(JSON.stringify(subMsg));
              
              pingInterval = window.setInterval(() => {
                if (ws && ws.readyState === WebSocket.OPEN) {
                  try {
                    ws.send(JSON.stringify({
                      method: 'ping', 
                    }));
                  } catch (e) {
                    console.error('Ping error:', e);
                  }
                }
              }, 30000);
              //@ts-ignore
              resolve(ws);
        };
    
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
            
          if (data.result) {
            const kline = data.result.data[0];
            const bar = {
              time: kline.t,
              open: parseFloat(kline.o),
              high: parseFloat(kline.h),
              low: parseFloat(kline.l),
              close: parseFloat(kline.c),
              volume: parseFloat(kline.v),
            };
            params.dispatch(terminalActions.setLimitLastPrice(bar.close));
            params.onTick(bar);
          }
        };
    
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
    
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
        };
      }),
    disconnect: () => {
      if (ws) ws.close();
      if (pingInterval) clearInterval(pingInterval);
    },
  };
}
