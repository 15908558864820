import { Stack } from '@mui/material';
import QRCodeRoot from 'qrcode.react';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui';
import { IQrCodeProps } from '../interfaces';
import { TEXT, TITLE, WRAPPER } from '../styles';

export const QrCode = (props: IQrCodeProps) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Stack sx={WRAPPER}>
      <Stack
        gap='2px'
        alignItems='center'
      >
        <QRCodeRoot
          value={props.link}
          size={60}
          bgColor='transparent'
          fgColor='#000'
        />

        <Text styles={TEXT}>
          {t('modal.shareResult.qrcode')}
        </Text>
      </Stack>

      <Text styles={TITLE}>
        {props.referralCode || 'Skyrexio'}
      </Text>
    </Stack>
  );
};
