import { ENV } from 'shared/config/environment';

export const getXShare = (params: any) => {
  const {
    roi,
    pair,
    referralCode,
  } = params;

  return `https://x.com/intent/post?text=${encodeURIComponent(`📢 Who else is using Skyrexio for automated trading?\n\n Just got a ${roi}% ROI on my latest ${pair} trade!\n\n 📉 No stress, just AI-powered execution.\n\n 🔗 Try it FREE for 7 days & level up your trades: ${`${ENV.APP_CLIENT_URL}/register?ref=${referralCode}`}`,
  )}`;
};
