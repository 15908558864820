import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { ResolutionString } from '../../charting_library/charting_library';
import { WebSocketHandler, WebSocketHandlerParams } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';

export function createBinanceHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
    
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        // Пример URL – настройте в соответствии с документацией и требуемым потоком (kline)
        const endpoint = `wss://stream.binance.com:9443/ws/${params.symbolInfo.name.toLowerCase()}@kline_${resolutionToExchangeInterval(params.resolution as ResolutionString, 'binance')}`;
        ws = new WebSocket(endpoint);
    
            
        ws.onopen = () => {
          pingInterval = window.setInterval(() => {
            if (ws && ws.readyState === WebSocket.OPEN) {
              ws.send('ping');
            }
          }, 30000);
          //@ts-ignore
          resolve(ws);
        };
  
    
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          const {
            k: kline, 
          } = data;
            
          if (kline) {
            const bar = {
              time: +kline.t,
              open: +kline.o,
              high: +kline.h,
              low: +kline.l,
              close: +kline.c,
              volume: +kline.v,
            };
            params.dispatch(terminalActions.setLimitLastPrice(bar.close));
            params.onTick(bar);
          }
        };
    
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
    
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
        };
      }),
    disconnect: () => {
      if (ws) ws.close();
      if (pingInterval) clearInterval(pingInterval);
    },
  };
}
    
