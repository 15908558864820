import { Box, Hidden, Stack } from '@mui/material';
import { formatReturnPercent, getFormattedAndTrimmedReturnValue, getTextColorByReturn, isReturnEmpty, showActionButtons } from 'pages/manual-trading/trading-terminal/helpers/active-trades-utils';
import { formatByPrecisionAndTrim } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { formatDate, formatTime } from 'pages/manual-trading/trading-terminal/helpers/trade-date-formatter';
import { capitalizeFirstLetter } from 'pages/trading-bots/trading-history/helpers/get-capitalized-first-letter';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { useModalContent } from 'shared/hooks/use-modal-content';
import { useModalState } from 'shared/hooks/use-modal-state';
import { Loader, ShareTrade, Text } from 'shared/ui';
import { ModalWrapper } from 'shared/ui/modal-wrapper/modal-wrapper';
import { useMenuItems } from '../../../hooks/use-menu-items';
import { useTradeActions } from '../../../hooks/use-trade-actions';
import { BriefTradeData } from '../../brief-trade-data/brief-trade-data';
import { TradeProgress } from '../../trade-progress/trade-progress';
import {
  text,
  wrapper,
} from '../styles';
import { ActiveTradeProps } from '../types/active-trade.types';
import { TradeActions } from './trade-actions';

export const ActiveTrade = ({
  trade,
  tradeSymbol,
  exchangeAccount,
}: ActiveTradeProps) => {
  const {
    modalState,
    openModal,
    closeModal,
  } = useModalState();
  const {
    t, 
  } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  
  const tradeActions = useTradeActions({
    tradeUuid: trade.smartTradeUuid,
    closeModal,
    setIsLoading,
  });

  const menuItems = useMenuItems({
    trade,
    tradeSymbol,
    exchangeAccount,
    openModal,
    ...tradeActions,
  });

  const {
    renderModalContent,
    modalButtons,
    isError,
  } = useModalContent({
    modalState,
    onClose: closeModal,
  });

  return (
    <Fragment>
      <ModalWrapper
        isOpen={modalState.additionalData?.actionType !== 'share_result' && modalState.isOpen}
        handleClose={closeModal}
        isError={isError}
        buttons={modalButtons}
        type={modalState.additionalData?.actionType}
      >
        {renderModalContent()}
      </ModalWrapper>

      <ShareTrade
        isOpen={modalState.additionalData?.actionType === 'share_result'}
        trade={modalState.additionalData?.trade}
        closeHandler={closeModal}
        tradeSymbol={tradeSymbol}
      />
      
      <Hidden mdDown={true}>
        <Box sx={wrapper}>
          {isLoading && (
            <Loader isContentOverflow={true} />
          )}

          <BriefTradeData
            trade={trade}
            tradeSymbol={tradeSymbol}
            menuItems={menuItems}
            onUpdate={tradeActions.handleUpdateTrade}
          />
        </Box>
      </Hidden>

      <Hidden mdUp={true}>
        <Stack gap='24px'>
          <Stack gap='14px'>
            <Stack
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              gap={{
                xs: '16px',
                sm: '34px',
              }}
            >
              <Stack
                flexDirection='row'
                alignItems='center'
                gap={{
                  xs: '20px',
                  sm: '40px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    '&>span': {
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: '17px',
                    },
                  }}
                >
                  <Text>
                    {trade?.baseSymbol}
                  </Text>

                  <Text type='success'>
                    {trade?.quoteSymbol}
                  </Text>
                </Box>

                <Box
                  sx={{
                    padding: '5px',
                    backgroundColor: '#F2F2F2',
                    borderRadius: '2.55px',
                  }}
                >
                  <Text
                    type='secondary'
                    styles={text}
                  >
                    {capitalizeFirstLetter(t(`terminal.trades.columns.status.items.${trade.status}`))}
                  </Text>
                </Box>
              </Stack>

              {showActionButtons(trade) && (
                <TradeActions
                  menuItems={menuItems}
                  onUpdate={tradeActions.handleUpdateTrade}
                />
              )}
            </Stack>

            <Stack
              flexDirection='row'
              gap='4px'
              alignItems='center'
            >
              <Box display='flex'>
                {exchangeIcons[(exchangeAccount?.exchangeCode) as keyof IExchangeIcons]}
              </Box>

              <Text>
                {exchangeAccount?.label}
              </Text>
            </Stack>
          </Stack>

          <Stack
            flexDirection='row'
            alignItems='center'
            justifyContent='space-between'
            gap='16px'
          >
            <Stack
              flexDirection='row'
              alignItems='center'
              justifyContent='space-between'
              gap='16px'
            >
              <Stack gap='8px'>
                <Text>
                  {formatDate(trade.createdDate)}
                </Text>

                <Text>
                  {formatTime(trade.createdDate)}
                </Text>
              </Stack>

              <Stack gap='8px'>
                <Text>
                  {formatByPrecisionAndTrim(trade.executedBaseFirst, tradeSymbol?.baseAssetPrecision, Number(tradeSymbol?.priceMin), Number(tradeSymbol?.priceMax))} {trade?.baseSymbol}
                </Text>

                <Text>
                  {formatByPrecisionAndTrim(trade?.executedQuoteFirst, tradeSymbol?.quoteAssetPrecision, Number(tradeSymbol?.priceMin), Number(tradeSymbol?.priceMax))} {trade?.quoteSymbol}
                </Text>
              </Stack>
            </Stack>

            {!isReturnEmpty(trade) && (
              <Stack gap='8px'>
                <Text type={getTextColorByReturn(trade?.returnPercent)}>
                  {getFormattedAndTrimmedReturnValue(trade, tradeSymbol)}
                </Text>

                <Text type={getTextColorByReturn(trade?.returnPercent)}>
                  {`${formatReturnPercent(trade?.returnPercent)}%`}
                </Text>
              </Stack>
            )}
          </Stack>

          <Stack>
            <TradeProgress
              trade={trade}
              tradeSymbol={tradeSymbol}
            />
          </Stack>
        </Stack>
      </Hidden>
    </Fragment>
  );
};
