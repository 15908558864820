import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EChart from 'widgets/echart/echart';
import { getGraphic, getSeries } from '../consts';
import { getTooltip } from '../consts/get-tooltip';
import { IPieChart } from '../interfaces';
import { chart, wrapper } from '../styles';

export const PieChart = (props: IPieChart) => {
  const {
    data,
    selectedCurrency,
    totalBtc,
    totalUsdt,
    usdtDailyChangePercent,
    btcDailyChangePercent,
  } = props;

  const {
    t, 
  } = useTranslation();

  const {
    total,
    change,
  } = useMemo(() => {
    const total = t('aggregateBalance.total');
    const change = t('aggregateBalance.change');

    return {
      total,
      change,
    };
  }, [t]);
  
  const EchartMemoized = useMemo(() => (
    <EChart
      data={data}
      style={chart}
      tooltip={getTooltip(selectedCurrency ?? 'USDT')}
      series={getSeries(data, selectedCurrency ?? 'USDT')}
      graphic={getGraphic({
        totalBtc,
        totalUsdt,
        usdtDailyChangePercent,
        btcDailyChangePercent,
        selectedCurrency: selectedCurrency ?? 'USDT',
        total,
        change,
      })
      }
    />
  ), [data, selectedCurrency]);
  
  return (
    <Box sx={wrapper}>
      {EchartMemoized}
    </Box>
  );
};
