import { Stack } from '@mui/material';
import { Fragment, ReactNode } from 'react';
import { Image } from 'widgets/image';
import { MainButton, Text, Title } from 'shared/ui';
import { IInfoProps } from '../interfaces';
import { BUTTON, INNER, TEXT, TEXT_WRAPPER, TITLE, WRAPPER } from '../styles';

export const Info = (props: IInfoProps) => {
  const {
    isReverse,
    image,
    title,
    text,
    button,
    isColored,
  } = props;

  const getContent = (textBlock: ReactNode) => {
    const img = (
      <Image
        src={image}
        maxWidth='459px'
      />
    );
    
    if (isReverse) {
      return (
        <Fragment>
          {textBlock}

          {img}
        </Fragment>
      );
    }

    return (
      <Fragment>
        {img}

        {textBlock}
      </Fragment>
    );
  };

  return (
    <Stack sx={WRAPPER(!!isColored)}>
      {getContent((
        <Stack
          className='block'
          sx={INNER}
        >
          <Stack sx={TEXT_WRAPPER}>
            <Title styles={TITLE}>
              {title}
            </Title>

            <Text styles={TEXT}>
              {text}
            </Text>
          </Stack>

          {button && (
            <MainButton
              onClick={button.action}
              styles={BUTTON}
              type='primary'
            >
              {button.title}
            </MainButton>
          )}
        </Stack>
      ))}
    </Stack>
  );
};
