import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Title } from 'shared/ui';
import { Card } from '../components';
import { items } from '../consts';
import {
  list,
  title,
  wrapper,
} from '../styles';

export const StartTrading = () => {
  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();

  const handleNavigate = (route: string) => {
    return () => {
      navigate(route);
    };
  };

  
  return (
    <Box sx={wrapper}>
      <Title styles={title}>
        {t('modal.onboarding.start.title')}
      </Title>

      <Box sx={list}>
        {items(t).map((currentItem) => (
          <Card
            key={currentItem.title}
            image={currentItem.icon}
            title={currentItem.title}
            description={currentItem.description}
            onClickHandler={handleNavigate(currentItem.link)}
          />
        ))}
      </Box>
    </Box>
  );
};
