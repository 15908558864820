import { Box, Stack } from '@mui/material';
import { ErrorMessage } from 'pages/manual-trading/trading-terminal/components/error-message';
import { formatByPrecisionAndTrim, getTrailedZeroCutted } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input, LabelInput, Slider } from 'shared/ui';
import { inner, sliderWrapper, wrapper } from '../../../../../fields/styles';
import { OrderPriceField } from '../order-price-field/order-price-field';

interface ReduceFundsSellInputsProps {
    onUnitsChange: (value: string) => void;
    onTotalChange: (value: string) => void;
    onOrderPriceChange: (value: string) => void;
    onSliderChange: (value: number) => void;
    currentSymbol: any;
}

export const ReduceFundsSellInputs = (props: ReduceFundsSellInputsProps) => {
  const {
    onUnitsChange,
    onTotalChange,
    onOrderPriceChange,
    onSliderChange,
    currentSymbol,
  } = props;
    
  const {
    control,
    formState: {
      errors,
    },
    trigger,
  } = useFormContext();
  const {
    t, 
  } = useTranslation();
    
  const renderOrderPriceField = () => {
    return (
      <OrderPriceField
        currentSymbol={currentSymbol}
        onOrderPriceChange={onOrderPriceChange}
        disabled={true}
        forceUpdating={false}
      />
    );
  };
  
  const renderUnitsField = () => (
    <Controller
      name='onReduceFundsUnits'
      control={control}
      rules={{
        required: t('validation.funds.units.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.funds.units.number');
          }

          if (numValue < Number(currentSymbol.lotMin)) {
            return t('validation.funds.units.min', {
              lotMin: getTrailedZeroCutted(currentSymbol.lotMin),
            });
          }

          if (numValue > Number(currentSymbol.lotMax)) {
            return t('validation.funds.units.max', {
              lotMax: getTrailedZeroCutted(currentSymbol.lotMax),
            });
          }

          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('form.funds.units.title')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onUnitsChange(value);
              }
              trigger('onReduceFundsTotal');
              trigger('onReduceFundsUnits');
              trigger('onReduceFundsSlider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
              field.onChange(formattedValue);
              onUnitsChange(formattedValue);
              trigger('onReduceFundsTotal');
              trigger('onReduceFundsUnits');
              trigger('onReduceFundsSlider');
            }}
            icon={currentSymbol.baseAsset}
            status={errors.onReduceFundsUnits ? 'error' : undefined}
          />

          <ErrorMessage message={errors?.onReduceFundsUnits?.message} />
        </Stack>
      )}
    />
  );
  
  const renderTotalField = () => (
    <Controller
      name='onReduceFundsTotal'
      control={control}
      rules={{
        required: t('validation.funds.total.required'),
        validate: (value) => {
          const numValue = Number(value);
          if (isNaN(numValue)) {
            return t('validation.funds.total.number');
          }

          if (numValue < Number(currentSymbol.minNotional)) {
            return t('validation.funds.total.min', {
              minNotional: getTrailedZeroCutted(currentSymbol.minNotional),
            });
          }

          if (numValue > Number(currentSymbol.maxNotional)) {
            return t('validation.funds.total.max', {
              maxNotional: getTrailedZeroCutted(currentSymbol.maxNotional),
            });
          }
          
          return true;
        },
      }}
      render={({
        field,
      }) => (
        <Stack
          gap={0.5}
          height={81}
        >
          <LabelInput
            label={t('form.funds.total.title')}
            value={field.value}
            onChange={(value) => {
              if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                field.onChange(value);
                onTotalChange(value);
              }
              trigger('onReduceFundsTotal');
              trigger('onReduceFundsSlider');
            }}
            onBlur={() => {
              const formattedValue = formatByPrecisionAndTrim(
                field.value, currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
              field.onChange(formattedValue);
              onTotalChange(formattedValue);
              trigger('onReduceFundsTotal');
              trigger('onReduceFundsUnits');
              trigger('onReduceFundsSlider');
            }}
            icon={currentSymbol.quoteAsset}
            status={errors.onReduceFundsTotal ? 'error' : undefined}
          />

          <ErrorMessage message={errors?.onReduceFundsTotal?.message} />
        </Stack>
      )}
    />
  );
  
  const renderSlider = () => (
    <Stack height={53}>
      <Box sx={sliderWrapper}>
        <Controller
          name='onReduceFundsSlider'
          control={control}
          rules={{
            max: {
              value: 100,
              message: t('validation.funds.slider.max'),
            },
            min: {
              value: 1,
              message: t('validation.funds.slider.min'),
            },
          }}
          render={({
            field,
          }) => (
            <Slider
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                onSliderChange(value);
                trigger('onReduceFundsSlider');
                trigger('onReduceFundsTotal');
                trigger('onReduceFundsUnits');
              }}
            />
          )}
        />
        
        <Controller
          name='onReduceFundsSlider'
          control={control}
          render={({
            field,
          }) => (
            <Input
              value={field.value}
              onChange={(value) => {
                if (/^[0-9]*\.?[0-9]*$/.test(value) || value === '') {
                  field.onChange(value);
                  onSliderChange(parseFloat(value));
                  trigger('onReduceFundsSlider');
                  trigger('onReduceFundsTotal');
                  trigger('onReduceFundsUnits');
                }
              }}
              placeholder='100%'
              maxWidth={58}
              icon='%'
              status={errors.onReduceFundsSlider ? 'error' : undefined}
            />
          )}
        />
      </Box>

      <ErrorMessage message={errors?.onReduceFundsSlider?.message} />
    </Stack>
  );

  return (
    <Box sx={wrapper}>
      <Box sx={inner}>
        <>
          {renderOrderPriceField()}
          {renderUnitsField()}
          {renderTotalField()}
          {renderSlider()}
        </>
      </Box>
    </Box>
  );
};
