import { CSSProperties } from 'react';

export const descriptionStyles = (isReverse: boolean) => {
  const styles: CSSProperties = {
    fontSize: 16,
    fontWeight: 400,
    maxWidth: 290,
    color: isReverse ? '#fff' : '#6A6A6A',
  };

  return styles;
};
