import { Box, Stack } from '@mui/material';
import { Divider } from 'antd';
import { getPresetOrders, getPresetOrdersPriceChangeScale, getPresetOrdersSizeScale, getPresetOrderType, getPresetPriceChange, riskManagmentAdditionalActions } from 'entities/risk-managment-additional';
import { IRiskManagmentAdditional } from 'entities/risk-managment-additional/interfaces';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';
import { CustomSlider, OrderType } from '../../../../../components';
import { INNER, WRAPPER } from '../styles';

export const Preset = () => {
  const dispatch = useDispatch();

  const orders = useSelector(getPresetOrders);
  const ordersSizeScale = useSelector(getPresetOrdersSizeScale);
  const priceChange = useSelector(getPresetPriceChange);
  const ordersPriceChangeScale = useSelector(getPresetOrdersPriceChangeScale);
  const orderType = useSelector(getPresetOrderType);
  const {
    t, 
  } = useTranslation();

  const [ordersValue, setOrdersValue] = useState<number>(orders);
  const [ordersSizeScaleValue, setOrdersSizeScaleValue] = useState<number>(ordersSizeScale);
  const [priceChangeValue, setPriceChangeValue] = useState<number>(priceChange);
  const [ordersPriceChangeScaleValue, setOrdersPriceChangeScaleValue] = useState<number>(ordersPriceChangeScale);
  
  const debouncedValue = useDebouncedCallback((action: any, value: number | string) => {
    dispatch(action(value));
  }, 300);

  const changePresetOrdersHandler = (value: number) => {
    if (value === 1) {
      changePresetOrdersPriceChangeScaleHandler(1);
    }

    setOrdersValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetOrders, value);
  };

  const changePresetOrdersSizeScaleHandler = (value: number) => {
    setOrdersSizeScaleValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetOrdersSizeScale, value);
  };

  const changePresetPriceChangeHandler = (value: number) => {
    setPriceChangeValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetPriceChange, value);
  };

  const changePresetOrdersPriceChangeScaleHandler = (value: number) => {
    setOrdersPriceChangeScaleValue(value);
    debouncedValue(riskManagmentAdditionalActions.setPresetOrdersPriceChangeScale, value);
  };

  const changePresetOrderTypeHandler = (value: string) => {
    dispatch(riskManagmentAdditionalActions.setPresetOrderType(value as IRiskManagmentAdditional['segment']['preset']['orderType']));
  };

  const isOneOrder = orders === 1;

  return (
    <Stack sx={INNER}>
      <Box sx={WRAPPER}>
        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.orders')}
            />
          )}
          slider={{
            value: ordersValue,
            max: 10,
            marks: {
              1: 1,
              4: 4,
              10: 10,
            },
            onChange: changePresetOrdersHandler,
          }}
          input={{
            value: ordersValue,
            placeholder: '100%',
            onChange: changePresetOrdersHandler,
          }}
        />

        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.ordersSize')}
            />
          )}
          slider={{
            value: ordersSizeScaleValue,
            min: 0.1,
            max: 2.5,
            marks: {
              0.1: 0.1,
              1: 1,
              2.5: 2.5,
            },
            onChange: changePresetOrdersSizeScaleHandler,
          }}
          input={{
            value: ordersSizeScaleValue,
            placeholder: '100%',
            onChange: changePresetOrdersSizeScaleHandler,
          }}
          step={0.1}
        />
      </Box>

      <Divider
        style={{
          margin: 0, 
        }}
      />

      <Box sx={WRAPPER}>
        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.priceChange')}
            />
          )}
          slider={{
            value: priceChangeValue,
            max: 10,
            marks: {
              1: -1,
              3: -3,
              10: -10,
            },
            reversed: true,
            onChange: changePresetPriceChangeHandler,
          }}
          input={{
            value: priceChangeValue,
            placeholder: '100%',
            onChange: changePresetPriceChangeHandler,
          }}
          step={0.1}
        />

        <CustomSlider
          title={(
            <Label
              title={t('form.configurator.additional.priceChangeScale')}
            />
          )}
          slider={{
            value: ordersPriceChangeScaleValue,
            min: 0.1,
            max: 2.5,
            marks: {
              0.1: 0.1,
              1: 1,
              2.5: 2.5,
            },
            onChange: changePresetOrdersPriceChangeScaleHandler,
          }}
          input={{
            value: ordersPriceChangeScaleValue,
            placeholder: '100%',
            onChange: changePresetOrdersPriceChangeScaleHandler,
          }}
          disabled={isOneOrder}
          step={0.1}
        />
      </Box>

      <Stack
        sx={{
          maxWidth: {
            xs: '100%',
            md: 425,
          },
        }}
      >
        <OrderType
          value={orderType}
          onChange={changePresetOrderTypeHandler}
        />
      </Stack>
    </Stack>
  );
};
