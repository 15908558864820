import { BaseOptionType } from 'antd/es/select';
import { chooseAccountActions, getExchanges } from 'entities/choose-account';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { convertExchange, getExchangeType } from 'pages/trading-bots/configurator-v2/helpers';
import { useGetExchanges } from 'pages/trading-bots/configurator-v2/hooks';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui';
import { Exchange as ExchangeView } from 'shared/ui/selects/single-select/views';
import { IExchangeProps } from '../interfaces';

export const Exchange = (props: IExchangeProps) => {
  const {
    exchange,
  } = props;

  const params = useParams();
  useGetExchanges(params.id);
  const {
    t, 
  } = useTranslation();

  const exchanges = useSelector(getExchanges);

  const dispatch = useDispatch();

  const selectExchangeHandler = useCallback((value: string) => {
    const exchange = exchanges.find((exchange) => exchange.exchangeAccountUuid === value);
    dispatch(chooseAccountActions.selectExchange(convertExchange(exchange)));
  }, [exchanges, dispatch]);

  const exchangeView = useCallback(() => {
    if (!exchange.id) {
      return null;
    }

    return (
      <ExchangeView
        title={exchange.name}
        code={exchange.code}
        label={exchange.type}
        balance={exchange.totalUsdt}
      />
    );
  }, [exchange]);

  const exchangeOptionView = useCallback((option: BaseOptionType) => {
    if (!exchange.id) {
      return null;
    }

    const [name, code, totalUsdt] = (option.label as string || '').split('_');
    return (
      <ExchangeView
        title={name}
        code={code}
        label={getExchangeType(code || '')}
        balance={+totalUsdt || 0}
      />
    );
  }, [exchange]);

  const exchangeOptions = useMemo(() => {
    return exchanges?.map((account: ExchangeAccount) => ({
      label: `${account.accountName}_${account.exchangeCode}_${account.totalUsdt}`,
      value: account.exchangeAccountUuid,
    }));
  }, [exchanges]);

  return (
    <SingleSelect
      label={(
        <Label
          title={t('form.configurator.exchange.title')}
        />
      )}
      options={exchangeOptions}
      select={{
        value: exchange?.id,
        placeholder: '',
        onChange: selectExchangeHandler,
      }}
      labelRender={exchangeView}
      optionRender={exchangeOptionView}
      size='large'
      loading={!exchanges.length}
    />
  );
};
