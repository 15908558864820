export const SKYREX_ACCESS_TOKEN = '@skyrex:access_token';
export const SKYREX_REFRESH_TOKEN = '@skyrex:refresh_token';
export const CURRENT_LAST_LOGIN_DATE = '@skyrex:current_last_login_date';
export const SKYREX_USER_UUID = '@skyrex:skyrex_user_uuid';
export const SKYREX_USER_EMAIL = '@skyrex:skyrex_user_email';
export const EXCHANGE_ACCOUNT_UUID = '@skyrex:exchange_account_uuid';
export const EXCHANGE_NAME = '@skyrex:exchange_name';
export const TWO_FACTOR = '@skyrex:two_factor';
export const DEMO_MODE = '@skyrex:demo_mode';
export const LANGUAGE = '@skyrex:language';
export const REFERRAL_CODE = '@skyrex:referral_code';
