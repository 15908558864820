import { SmartTrade } from 'pages/manual-trading/trading-terminal/types/smart-trade.types';

export const getEntryPrice = (trade: SmartTrade): number | null => {
  if (!trade) {
    return 0;
  }
  
  const baseOrder = trade.orders.find(order => order.viewType === 'base_order');
  const forceOrderStepFirst = trade.orders.find(order => order.viewType === 'force_order_step_first');

  if (!baseOrder) {
    return null;
  }

  if (baseOrder.orderType === 'MARKET' && (parseFloat(trade.executedQuoteFirst) === 0 || parseFloat(trade.executedBaseFirst) === 0)) {
    return null;
  }

  if (baseOrder.status === 'completed' || forceOrderStepFirst?.status === 'completed') {
    return parseFloat(trade.executedQuoteFirst) / parseFloat(trade.executedBaseFirst);
  }

  if (baseOrder.orderType === 'LIMIT' || baseOrder.orderType === 'CONDITIONAL_LIMIT') {
    return parseFloat(baseOrder.limitPrice);
  }

  return parseFloat(baseOrder.triggerPrice ?? '0');
};
