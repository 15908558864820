import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Text, Title } from 'shared/ui';
import { errorText } from '../styles';

type Props = {
  bybit: boolean;
};

export const Error = (props: Props) => {
  const {
    t, 
  } = useTranslation();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap={2}
      padding='28px 0'
    >
      <Title level={5}>
        {t('error.exchange.connected.empty.title')}
      </Title>
  
      <Text
        type='danger'
        styles={errorText}
      >
        {t(`error.exchange.connected.empty.text.${props.bybit ? 'bybit' : 'other'}`)}
      </Text>
    </Box>
  );
};
