import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { termService } from 'shared/consts';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { Link, MainButton, Title } from 'shared/ui';
import { SubscriptionMessage } from '../../subscription-message';
import { createCoinBaseCheckoutPayment, createSkyrexSubscription, createStripeTransaction } from '../api';
import { getSubscriptionPaymentInfo, openRedirectUrl } from '../helpers';
import { ICreateSkyrexSubscription, IPayment, ISubscriptionAction } from '../interfaces';
import { inner, modal, modalChildren, purchase, text, title, wrapper } from '../styles';
import { Skyrex } from '../views';

export const SubscriptionAction = (props: ISubscriptionAction) => {
  const {
    isOpen,
    contentType,
    subscription,
    isUpdate,
    closeHandler,
    plan,
    openCoinPaymentModal,
  } = props;

  const [promo, setPromo] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<IPayment['title']>('card');
  const [error, setError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const {
    t, 
  } = useTranslation();

  const resetError = () => {
    setError('');
  };

  const changePaymentMethod = (method: IPayment['title']) => {
    return () => {
      setPaymentMethod(method);
    };
  };

  const changePromo = (value: string) => {
    setPromo(value);
  };

  const createCoinBaseCheckoutPaymentHandler = async (subscriptionUuid: string, table?: string) => {
    const response = await createCoinBaseCheckoutPayment(subscriptionUuid, table);

    if (!response?.data?.statusUrl) {
      return;
    }

    openRedirectUrl(response?.data?.statusUrl, '_blank');
    closeHandler();
    openCoinPaymentModal?.();
  };

  const makePayemnt = async () => {
    setIsLoading(true);
    try {
      if (contentType === 'skyrex') {
        const {
          method,
          asset,
        } = getSubscriptionPaymentInfo(paymentMethod!);

        const params: ICreateSkyrexSubscription = {
          skyrexUserUuid: getSkyrexUuid()!,
          planName: plan?.planName! || subscription?.planName,
          paymentMethod: method,
          paymentAsset: asset,
        };

        if (promo) {
          params.promocode = promo;
        }

        const response = await createSkyrexSubscription(params);
        const skyrexSubscriptionFields = response.data?.fields;
        if (!skyrexSubscriptionFields || response.status === 403) {
          setError(response.data.message);

          return;
        }

        const [createdSubscription] = skyrexSubscriptionFields;

        if (paymentMethod === 'crypto') {
          createCoinBaseCheckoutPaymentHandler(createdSubscription.subscriptionUuid, 'skyrex_subscriptions');

          return;
        }

        const stripeResponseData = await createStripeTransaction({
          subscriptionUuid: createdSubscription.subscriptionUuid!,
        });

        openRedirectUrl(stripeResponseData?.data?.redirectUrl);
        return;
      }

      navigate(location.pathname);

      if (paymentMethod === 'card') {
        const stripeResponseData = await createStripeTransaction({
          subscriptionUuid: subscription?.subscriptionUuid!,
          table: 'bot_success_subscriptions',
          paymentAsset: 'usd',
        });

        openRedirectUrl(stripeResponseData?.data?.redirectUrl);
        return;
      }

      createCoinBaseCheckoutPaymentHandler(subscription?.subscriptionUuid!);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getModalContent = () => {
    return (
      <Skyrex
        subscription={subscription!}
        isUpdate={!!isUpdate}
        plan={plan!}
        promo={promo}
        changePromo={changePromo}
        selectedPayentMethod={paymentMethod}
        changePaymentMethod={changePaymentMethod}
      />
    );
  };

  useEffect(() => {
    if (!isOpen) {
      setPromo('');
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Title styles={title}>
          {t('modal.subscription.title')}
        </Title>

        {getModalContent()}

        <Box sx={inner}>
          <Box sx={text}>
            {t('modal.subscription.aggrement.prefix')} {' '}

            <Link
              href={termService}
              target='_blank'
              type='success'
            >
              {t('modal.subscription.aggrement.link.terms')}
            </Link> {' '}

            {t('modal.subscription.aggrement.and')}

            <Link
              href={termService}
              target='_blank'
              type='success'
            >
              {t('modal.subscription.aggrement.link.terms')}
            </Link>
          </Box>

          <MainButton
            type='primary'
            size='large'
            styles={purchase}
            disabled={isLoading || (contentType === 'skyrex' && !paymentMethod)}
            onClick={makePayemnt}
            loading={isLoading}
          >
            {t('modal.subscription.button')}
          </MainButton>
        </Box>
      </Box>

      <SubscriptionMessage
        isOpen={!!error}
        closeHandler={resetError}
        text={[
          t('error.subscription.downgrade.title'),
          t('error.subscription.downgrade.message'),
        ]}
      />
    </Modal>
  );
};
