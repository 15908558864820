
import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { ResolutionString } from '../../charting_library/charting_library';
import { exchangeResolutionMaps } from '../constants/chart-constants';
import { getExchangeResolutions } from './exchange-resolutions';

//@TODO переписать на тип ExchangeName
type Exchange = 'binance' | 'bybit' | 'okx' | 'gate' | 'bitmart' | 'htx' | string;

//@TODO переписать на тип ExchangeName
export const normalizeExchangeName = (exchangeName: Exchange): string => {
  const exchangeMap: Record<string, string> = {
    'gateio': 'gateio',
    'huobi': 'htx',
    'crypto-com': 'crypto-com',
  };
  
  return exchangeMap[exchangeName] || exchangeName;
};

export const resolutionToExchangeInterval = (
  resolution: ResolutionString, 
  exchange: Exchange,
): string => {
  const normalizedExchange = normalizeExchangeName(exchange);
  
  const exchangeMap = exchangeResolutionMaps[normalizedExchange];
  
  if (!exchangeMap) {
    //@TODO реализовать фоллбек на аггрегатор
    // Если биржа не найдена, используем маппинг Binance как дефолтный
    console.warn(`Exchange ${normalizedExchange} not found, using Binance mapping`);
    return exchangeResolutionMaps.binance[resolution] || resolution;
  }

  // Проверяем поддержку резолюции биржей
  const supportedResolutions = getExchangeResolutions(normalizedExchange);
  if (!supportedResolutions.includes(resolution)) {
    console.warn(`Resolution ${resolution} is not supported by ${normalizedExchange}`);
    return getFallbackInterval(resolution, normalizedExchange);
  }

  return exchangeMap[resolution] || resolution;
};

// Функция для получения ближайшего поддерживаемого интервала
const getFallbackInterval = (resolution: ResolutionString, exchange: string): string => {
  const supportedResolutions = getExchangeResolutions(exchange);
  
  // Мапинг для определения "веса" интервала для сравнения
  const resolutionWeight: Record<string, number> = {
    '1': 1, '3': 3, '5': 5, '15': 15, '30': 30,
    '60': 60, '120': 120, '240': 240, '360': 360, '720': 720,
    '1D': 1440, '1W': 10080, '1M': 43200,
  };

  const targetWeight = resolutionWeight[resolution];
  if (!targetWeight) return supportedResolutions[0];

  // Находим ближайший поддерживаемый интервал
  let closest = supportedResolutions[0];
  let minDiff = Math.abs(resolutionWeight[closest] - targetWeight);

  for (const res of supportedResolutions) {
    const diff = Math.abs(resolutionWeight[res] - targetWeight);
    if (diff < minDiff) {
      minDiff = diff;
      closest = res;
    }
  }

  const exchangeMap = exchangeResolutionMaps[exchange];
  return exchangeMap[closest] || closest;
};

export const isResolutionSupported = (
  resolution: ResolutionString, 
  exchange: Exchange,
): boolean => {
  const normalizedExchange = normalizeExchangeName(exchange);
  const supportedResolutions = getExchangeResolutions(normalizedExchange);
  return supportedResolutions.includes(resolution);
};

//@TODO переписать на тип ExchangeName
//! на вход в чарт приходит gate, не gateIo, поправить это в первую очередь
export const getExchangeCurrencyCode = (exchangeName: string, currentSymbol: any): string => {
  
  //@TODO выпилить нормальзацию
  const exchange = normalizeExchangeName(exchangeName);
  
  switch (exchange) {
  case EXCHANGE_NAMES.OKX:
  case EXCHANGE_NAMES.KUCOIN:
    return `${currentSymbol.baseAsset}-${currentSymbol.quoteAsset}`;
  case EXCHANGE_NAMES.BITMART:
  case EXCHANGE_NAMES.CRYPTO_COM:
  case EXCHANGE_NAMES.GATEIO:
    return `${currentSymbol.baseAsset}_${currentSymbol.quoteAsset}`;
  case EXCHANGE_NAMES.BINANCE:
  case EXCHANGE_NAMES.BYBIT:
    return `${currentSymbol.baseAsset}${currentSymbol.quoteAsset}`;

  case EXCHANGE_NAMES.HTX:
    return `${currentSymbol.baseAsset}${currentSymbol.quoteAsset}`.toLowerCase();
  default:
    return `${currentSymbol.baseAsset}${currentSymbol.quoteAsset}`;
  }
};
