import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import pako from 'pako';
import { ResolutionString } from '../../charting_library/charting_library';
import { WebSocketHandler } from '../types/chart-view.types';
import { WebSocketHandlerParams } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';
export function createHTXHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
      
  // Функция для формирования сообщения подписки согласно документации HTX
  const getSubscriptionMessage = () => {
    const interval = resolutionToExchangeInterval(params.resolution as ResolutionString, 'htx');
    return {
      sub: `market.${params.symbolInfo.full_name}.kline.${interval}`,
      id: `kline_${params.symbolInfo.full_name}_${interval}`,
    };
  };
    
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        const endpoint = 'wss://api.htx.com/ws';
        ws = new WebSocket(endpoint);
        // Для получения сжатых данных используем Blob
        ws.binaryType = 'blob';
      
        ws.onopen = () => {
          const subMsg = getSubscriptionMessage();
              ws!.send(JSON.stringify(subMsg));
      
              // Heartbeat: отправляем пинг каждые 30 секунд
              pingInterval = window.setInterval(() => {
                if (ws && ws.readyState === WebSocket.OPEN) {
                  ws.send(JSON.stringify({
                    ping: Date.now(), 
                  }));
                }
              }, 30000);
              //@ts-ignore
              resolve(ws);
        };
      
        ws.onmessage = async (event) => {
          try {
            let message: any;
            if (event.data instanceof Blob) {
              // Распаковываем Blob через pako
              const arrayBuffer = await event.data.arrayBuffer();
              const uint8Array = new Uint8Array(arrayBuffer);
              const decompressed = pako.inflate(uint8Array, {
                to: 'string', 
              });
              message = JSON.parse(decompressed);
            } else if (typeof event.data === 'string') {
              message = JSON.parse(event.data);
            } else if (event.data instanceof ArrayBuffer) {
              const uint8Array = new Uint8Array(event.data);
              const decompressed = pako.inflate(uint8Array, {
                to: 'string', 
              });
              message = JSON.parse(decompressed);
            } else {
              console.error('Неизвестный тип данных:', event.data);
              return;
            }
      
            // Если получено поле ping – отвечаем pong
            if (message.ping) {
              ws?.send(JSON.stringify({
                pong: message.ping, 
              }));
              return;
            }
      
            // Если получен ответ на подписку
            if (message.subbed) {
              console.log('Подписка подтверждена:', message.subbed);
              return;
            }
      
            // Если сервер сообщает об ошибке
            if (message.error) {
              console.error('HTX WebSocket error:', message.error);
              return;
            }
      
            // Если получены данные свечи
            if (message.tick) {
              const tick = message.tick;
              // Проверяем, что tick.id существует и является числом
              const tickId = Number(tick.id);
              if (!tick.id || isNaN(tickId)) {
                console.error('Некорректное значение времени (tick.id):', tick.id);
                return;
              }
      
              // Формируем объект бара для Charting Library
              const bar = {
                time: tickId * 1000, // преобразуем секунды в миллисекунды
                open: parseFloat(tick.open),
                high: parseFloat(tick.high),
                low: parseFloat(tick.low),
                close: parseFloat(tick.close),
                volume: parseFloat(tick.amount),
              };
              params.dispatch(terminalActions.setLimitLastPrice(bar.close));
              params.onTick(bar);
            }
          } catch (error) {
            console.error('Error decompressing/parsing HTX message', error);
          }
        };
      
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
      
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
        };
      }),
    disconnect: () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        // Если нужно отписаться – можно отправить сообщение отписки
        const interval = resolutionToExchangeInterval(params.resolution as ResolutionString, 'htx');
        ws.send(JSON.stringify({
          unsub: `market.${params.symbol}.kline.${interval}`,
          id: `kline_${params.symbol}_${interval}`,
        }));
      }
      if (pingInterval) clearInterval(pingInterval);
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
}
