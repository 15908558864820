import { Box, Hidden } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddNewBot } from 'shared/ui';
import { menuItems } from '../../../consts';
import { renderMenu } from '../helpers';

export const HorizontalMenu = () => {
  const [isOpened, setIsOpened] = useState(false);

  const {
    t, 
  } = useTranslation();

  const items = useMemo(() => {
    return menuItems(t);
  }, [t]);

  return (
    <Hidden lgDown>
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        {renderMenu({
          menuItems: items,
          setIsOpened,
        })}
      </Box>

      <AddNewBot
        isOpen={isOpened}
        closeModal={() => {
          setIsOpened(false);
        }}
      />
    </Hidden>
  );
};
