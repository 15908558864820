import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  maxWidth: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&>svg': {
    width: '100%',
    height: '100%',
  },
};
