import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { ResolutionString } from '../../charting_library/charting_library';
import { WebSocketHandler } from '../types/chart-view.types';
import { WebSocketHandlerParams } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';

export function createGateioHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        const endpoint = 'wss://api.gateio.ws/ws/v4/';
        ws = new WebSocket(endpoint);
    
        ws.onopen = () => {
          const subMsg = {
            time: Math.floor(Date.now() / 1000),
            channel: 'spot.candlesticks',
            event: 'subscribe',
            payload: [
              resolutionToExchangeInterval(params.resolution as ResolutionString, 'gateio'),
              params.symbolInfo.full_name,
            ],
          };
              ws!.send(JSON.stringify(subMsg));
    
              // Heartbeat: каждые 20 сек
              pingInterval = window.setInterval(() => {
                if (ws && ws.readyState === WebSocket.OPEN) {
                  ws.send(JSON.stringify({
                    method: 'ping', 
                  }));
                }
              }, 20000);
              //@ts-ignore
              resolve(ws);
        };
    
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
            
          if (data.event === 'update' && data.channel === 'spot.candlesticks') {
            const kline = data.result;
            const bar = {
              time: parseInt(kline.t, 10) * 1000,
              open: parseFloat(kline.o),
              high: parseFloat(kline.h),
              low: parseFloat(kline.l),
              close: parseFloat(kline.c),
              volume: parseFloat(kline.v),
            };
            params.dispatch(terminalActions.setLimitLastPrice(bar.close));
            params.onTick(bar);
          }
        };
    
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
    
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
        };
      }),
    disconnect: () => {
      if (ws) ws.close();
      if (pingInterval) clearInterval(pingInterval);
    },
  };
}
