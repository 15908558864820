import { Box } from '@mui/material';
import { getSortedActiveTrades } from 'entities/new-terminal/model/selectors/get-sorted-active-trades';
import { setActiveTrades } from 'entities/new-terminal/model/services/set-active-trades';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Text } from 'shared/ui';
import { header, wrapper } from '../styles';
import { TradeItem } from './components/trade-item';

export const ActiveTrades = memo((props: any) => {
  const {
    exchanges,
  } = props;

  const {
    activeTrades,
  } = useSelector(getSortedActiveTrades, shallowEqual);
  const {
    t, 
  } = useTranslation();

  const dispatch = useDispatch();
  
  const fetchManualTrades = useCallback(async () => {
    const accounts = exchanges.map((exchange: any) => exchange.value);
    dispatch(setActiveTrades(accounts));
  }, [dispatch, exchanges]);

  useEffect(() => {
    fetchManualTrades();
  }, [fetchManualTrades]);

  return (
    <Box sx={wrapper}>
      <Box sx={header}>
        <Box>
          <Text>
            {t('terminal.trades.header.pair')}
          </Text>
        </Box>
        
        <Box>
          <Text>
            {t('terminal.trades.header.account')}
          </Text>
        </Box>

        <Box>
          <Text>
            {t('terminal.trades.header.date')}
          </Text>
        </Box>

        <Box>
          <Text>
            {t('terminal.trades.header.volume')}
          </Text>
        </Box>

        <Box>
          <Text>
            {t('terminal.trades.header.progress')}
          </Text>
        </Box>

        <Box>
          <Text>
            {t('terminal.trades.header.status')}
          </Text>
        </Box>

        <Box>
          <Text>
            {t('terminal.trades.header.return')}
          </Text>
        </Box>

        <Box>
          <Text>
            {t('terminal.trades.header.actions')}
          </Text>
        </Box>
      </Box>

      {activeTrades?.map((trade, index) => (
        <TradeItem
          key={index}
          trade={trade}
          exchanges={exchanges}
        />
      ))}
    </Box>
  );  
});

ActiveTrades.displayName = 'ActiveTrades';
