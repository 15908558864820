import { Box } from '@mui/material';
import { Segmented } from 'antd';
import { getEditingTrade } from 'entities/new-terminal/model/selectors/get-editing-trade';
import { getSharedTrade } from 'entities/new-terminal/model/selectors/get-shared-trade';
import { getChartLastPrice } from 'entities/terminal/model/selectors/get-chart-last-price/get-chart-last-price';
import { WalletData } from 'entities/terminal/model/types/terminal-schema';
import { useEffect, useRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch, Text } from 'shared/ui';
import { useTradingForm } from '../../hooks/use-trading-form';
import { TradingContent } from '../trading-content/trading-content';
import { segments } from './consts';
import { inner, text, wrapper } from './styles';

export interface TradingPanelProps {
  userWalletBaseAssetBalance: WalletData;
  userWalletQuoteAssetBalance: WalletData;
  exchangeAccountUuid: string;
  currentSymbol?: any;
  selectedExchange?: SelectedExchange;
  editingTrade?: any;
}

export interface SelectedExchange {
  balances: WalletData[];
  exchangeCode: string;
  label: string;
  value: string;
}

export const TradingPanel = (props: TradingPanelProps) => {
  const {
    currentSymbol, 
    selectedExchange,
    userWalletBaseAssetBalance, 
    userWalletQuoteAssetBalance,
    exchangeAccountUuid,
  } = props;

  const chartLastPrice = useSelector(getChartLastPrice);
  const editingTrade = useSelector(getEditingTrade);
  const sharedTrade = useSelector(getSharedTrade);
  const {
    t, 
  } = useTranslation();

  const methods = useTradingForm({
    chartLastPrice, 
    currentSymbol, 
    selectedExchange,
    userWalletBaseAssetBalance,
    userWalletQuoteAssetBalance,
    editingTrade,
    sharedTrade,
  });
  
  const {
    setValue,
    watch,
  } = methods;
  
  const selectedSide = watch('selectedSide');
  const isSkipBaseOrder = watch('skipBaseOrder');
  
  const changeSegmentHandler = (value: string) => {
    setValue('selectedSide', value as 'buy' | 'sell');
  };

  const skipBaseOrderHandler = (value: boolean) => {
    setValue('skipBaseOrder', value);
  };

  const panelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editingTrade && panelRef.current) {
      panelRef.current.scrollIntoView({
        behavior: 'smooth', block: 'center',
      });
    }
  }, [editingTrade]);
  
  return (
    <FormProvider {...methods}>
      <Box
        sx={wrapper}
        ref={panelRef}
      >
        <Box sx={inner}>
          <Segmented
            value={selectedSide}
            onChange={changeSegmentHandler}
            options={segments(t)}
            block={true}
            disabled={!!editingTrade}
          />
        
          <Switch
            value={isSkipBaseOrder}
            onChange={skipBaseOrderHandler}
            suffixText={(
              <Text
                type='secondary'
                styles={text}
              >
                {t('terminal.panel.skip')}
              </Text>
            )}
            disabled={!!editingTrade}
            size='small'
          />
        </Box>

        <TradingContent
          currentSymbol={currentSymbol}
          userWalletBaseAssetBalance={userWalletBaseAssetBalance}
          userWalletQuoteAssetBalance={userWalletQuoteAssetBalance}
          exchangeAccountUuid={exchangeAccountUuid}
          editingTrade={editingTrade}
        />
      </Box>
    </FormProvider>
  );
};
