import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTIONS } from 'shared/consts';
import { Loader, Table } from 'shared/ui';
import { getSubscriptionsByBotUuid } from '../api';
import { columns } from '../consts';
import { ISubscriptions } from '../interfaces';
import { modal, modalChildren } from '../styles';

export const Subscriptions = (props: ISubscriptions) => {
  const {
    isOpen,
    closeHandler,
    botUuid,
  } = props;

  const [subscriptions, setSubscriptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();
  
  const closeModal = () => {
    if (!closeHandler) {
      return;
    }

    closeHandler();
  };

  const makePayment = (subscriptionUuid: string) => {
    return () => {
      navigate(`/${SUBSCRIPTIONS}?subscriptionUuid=${subscriptionUuid}`);
    };
  };

  const getSubscriptions = async () => {
    const botSubscriptionsData = await getSubscriptionsByBotUuid(botUuid);
    const botSubscriptions = botSubscriptionsData.data.subscriptions;
    
    const sortedSubscriptions = botSubscriptions.sort((a: any, b: any) => a.createdDate - b.createdDate);
    setSubscriptions(sortedSubscriptions);

    setIsLoading(false);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    getSubscriptions();
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
      centered={true}
    >
      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Table
          title={t('modal.bot.subscriptions.title')}
          columns={columns(makePayment, t)}
          items={subscriptions}
          itemsCount={5}
        />
      </Box>
    </Modal>
  );
};
