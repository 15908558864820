import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  width: 1000,
  backgroundImage: 'url("/images/manual-trading/trade/bg_export.png")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  aspectRatio: '16/10',
  position: 'fixed',
  zIndex: -1000,
  top: 10000,
};
