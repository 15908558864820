import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'shared/icons';
import { Text, Title } from 'shared/ui';
import { IInfoProps } from '../interfaces';
import { backWrapper, title } from '../styles';

export const Info = (props: IInfoProps) => {
  const {
    actionBlock,
    returnToAllExchanges,
  } = props;

  const {
    t, 
  } = useTranslation();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='center'
      gap='24px'
    >
      <Title
        level={4}
        styles={title}
      >
        {t('exchange.connect.text', {
          type: 'fast OAuth', 
        })}
      </Title>

      {actionBlock}

      {returnToAllExchanges && (
        <div
          style={backWrapper}
          onClick={returnToAllExchanges}
        >
          {ArrowLeft}
          
          <Text>
            {t('exchange.connect.back')}
          </Text>
        </div>
      )}
    </Box>
  );
};
