import { calculateUnits, calculateTotal, formatByPrecisionAndTrim, roundPercentage, calculatePercentDifference, calculatePercentageWithCeil } from 'pages/manual-trading/trading-terminal/helpers/helpers';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

export const useBaseOrderInputsLogic = (
  currentSymbol: any, 
  userWallet: any, 
  userWalletBaseAsset: any,
) => {
  const {
    watch, setValue, 
  } = useFormContext();
  
  const lastChangedField = watch('lastChangedField');
  
  const orderType = watch('orderType');
  const conditionalOrderType = watch('conditionalOrderType');
  
  const triggerPrice = watch('triggerPrice');
  const orderPrice = watch('orderPrice');
  const boughtPrice = watch('boughtPrice');
  const total = watch('total');
  const units = watch('units');
  
  const additionalOrderPrice = watch('additionalOrderPrice');
  const additionalTriggerPrice = watch('additionalTriggerPrice');
  
  const takeProfitOrderPrice = watch('takeProfitOrderPrice');
  const takeProfitTriggerPrice = watch('takeProfitTriggerPrice');
  
  const stopLossOrderPrice = watch('stopLossOrderPrice');
  const stopLossTriggerPrice = watch('stopLossTriggerPrice');
  
  const skipBaseUnits = watch('skipBaseUnits');
  const skipBaseTotal = watch('skipBaseTotal');
  
  const handleTriggerPriceChange = useCallback((value: string) => {
    if (conditionalOrderType === 'limit') {
      return;
    }
    
    const triggerPrice = parseFloat(value);
    if (!isNaN(triggerPrice) && orderType === 'conditional' && conditionalOrderType === 'market') {
      
      if (lastChangedField === null || lastChangedField === 'total') {
        const newUnits = calculateUnits(parseFloat(total), triggerPrice, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(units), triggerPrice, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('total', formattedTotal);
        
        const percentageValue = (parseFloat(newTotal) / userWallet.free) * 100;
        setValue('slider', roundPercentage(percentageValue));
      }
    }
  }, [orderType, conditionalOrderType, lastChangedField, triggerPrice, total, units, userWallet.free, calculateUnits, calculateTotal, setValue]);
  
  const handleOrderPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'total') {
        const newUnits = calculateUnits(parseFloat(total), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('units', formattedUnits);
      } else {
        const newTotal = calculateTotal(parseFloat(units), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('total', formattedTotal);
        const percentageValue = (parseFloat(newTotal) / userWallet.free) * 100;
        setValue('slider', roundPercentage(percentageValue));
      }
    }
    
    const additionalOrderPricePercentDifference = calculatePercentDifference(+additionalOrderPrice, price);
    setValue('additionalOrderPricePercent', additionalOrderPricePercentDifference.toString());

    const additionalTriggerPricePercentDifference = calculatePercentDifference(+additionalTriggerPrice, price);
    setValue('additionalTriggerPricePercent', additionalTriggerPricePercentDifference.toString());
    
    const stopLossOrderPricePercentDifference = calculatePercentDifference(+stopLossOrderPrice, price);
    setValue('stopLossOrderPricePercent', stopLossOrderPricePercentDifference.toString());
    
    const stopLossTriggerPricePercentDifference = calculatePercentDifference(+stopLossTriggerPrice, price);
    setValue('stopLossTriggerPricePercent', stopLossTriggerPricePercentDifference.toString());
    
    const takeProfitTriggerPricePercentDifference = calculatePercentDifference(+takeProfitTriggerPrice, price);
    setValue('takeProfitTriggerPricePercent', takeProfitTriggerPricePercentDifference.toString());
    
    const takeProfitOrderPricePercentDifference = calculatePercentDifference(+takeProfitOrderPrice, price);
    setValue('takeProfitOrderPricePercent', takeProfitOrderPricePercentDifference.toString());
  }, [lastChangedField, additionalOrderPrice, total, userWallet.free, units, stopLossTriggerPrice, takeProfitTriggerPrice, additionalTriggerPrice, takeProfitOrderPrice, setValue]);
  
  
  const handleTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'total');
    const newTotal = parseFloat(value);
    
    if (!isNaN(newTotal)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);
      
      const newUnits = calculateUnits(newTotal, priceToUse, currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('units', formattedUnits);
      
      const userWalletFree = parseFloat(userWallet.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = calculatePercentageWithCeil(newTotal, userWalletFree);
      }
      
      setValue('slider', percentageValue);
    }
  }, [
    userWallet.free, 
    orderPrice, 
    orderType, 
    conditionalOrderType, 
    triggerPrice, 
    lastChangedField, 
    setValue,
    currentSymbol.baseAssetPrecision,
    currentSymbol.lotMin,
    currentSymbol.lotMax,
  ]);
  
  
  const handleUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'units');
    const newUnits = parseFloat(value);
    
    if (!isNaN(newUnits)) {
      const priceToUse = orderType === 'conditional' && conditionalOrderType === 'market' 
        ? parseFloat(triggerPrice) 
        : parseFloat(orderPrice);
 
      const newTotal = calculateTotal(newUnits, priceToUse, currentSymbol.quoteAssetPrecision);
      // const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      
      let formattedTotal;
      
      if (newUnits === 0) {
        formattedTotal = '0';
      } else {
        formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      }
      
      setValue('total', formattedTotal);

      const userWalletFree = parseFloat(userWallet.free);
      let percentageValue = 0;
      
      if (userWalletFree > 0) {
        percentageValue = (parseFloat(formattedTotal) / userWalletFree) * 100;
      }
      setValue('slider', roundPercentage(percentageValue));
    }
  }, [
    orderPrice, 
    orderType, 
    conditionalOrderType, 
    userWallet, 
    triggerPrice, 
    lastChangedField, 
    setValue,
    currentSymbol.quoteAssetPrecision,
    currentSymbol.minNotional,
    currentSymbol.maxNotional,
  ]);
  
  const handleBoughtPriceChange = useCallback((value: string) => {
    const price = parseFloat(value);
    if (!isNaN(price)) {
      if (lastChangedField === null || lastChangedField === 'units') {
        const newTotal = calculateTotal(parseFloat(skipBaseUnits), price, currentSymbol.quoteAssetPrecision);
        const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
        setValue('skipBaseTotal', formattedTotal);
      } else {
        const newUnits = calculateUnits(parseFloat(skipBaseTotal), price, currentSymbol.baseAssetPrecision);
        const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
        setValue('skipBaseUnits', formattedUnits);
        // TODO: почему используется userWallet.free, а не userWalletBaseAsset.free?
        const percentageValue = (parseFloat(newUnits) / userWallet.free) * 100;
        setValue('skipBaseSlider', roundPercentage(percentageValue));
      }
    }
    
    const additionalOrderPricePercentDifference = calculatePercentDifference(+additionalOrderPrice, price);
    setValue('additionalOrderPricePercent', additionalOrderPricePercentDifference.toString());

    const additionalTriggerPricePercentDifference = calculatePercentDifference(+additionalTriggerPrice, price);
    setValue('additionalTriggerPricePercent', additionalTriggerPricePercentDifference.toString());
    
    const stopLossOrderPricePercentDifference = calculatePercentDifference(+stopLossOrderPrice, price);
    setValue('stopLossOrderPricePercent', stopLossOrderPricePercentDifference.toString());
    
    const stopLossTriggerPricePercentDifference = calculatePercentDifference(+stopLossTriggerPrice, price);
    setValue('stopLossTriggerPricePercent', stopLossTriggerPricePercentDifference.toString());
    
    const takeProfitTriggerPricePercentDifference = calculatePercentDifference(+takeProfitTriggerPrice, price);
    setValue('takeProfitTriggerPricePercent', takeProfitTriggerPricePercentDifference.toString());
    
    const takeProfitOrderPricePercentDifference = calculatePercentDifference(+takeProfitOrderPrice, price);
    setValue('takeProfitOrderPricePercent', takeProfitOrderPricePercentDifference.toString());
  }, [skipBaseTotal, 
    skipBaseUnits, 
    lastChangedField,
    userWalletBaseAsset.free, 
    setValue,
    additionalOrderPrice, 
    userWallet.free, 
    stopLossTriggerPrice, 
    takeProfitTriggerPrice, 
    additionalTriggerPrice, 
    takeProfitOrderPrice,
  ]);
  
  const handleSkipBaseTotalChange = useCallback((value: string) => {
    setValue('lastChangedField', 'total');
    const newTotal = parseFloat(value);
    if (!isNaN(newTotal)) {
      const newUnits = calculateUnits(newTotal, parseFloat(boughtPrice), currentSymbol.baseAssetPrecision);
      const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
      setValue('skipBaseUnits', formattedUnits);
      
      const percentageValue = (parseFloat(newUnits) / userWalletBaseAsset.free) * 100;
      setValue('skipBaseSlider', roundPercentage(percentageValue));
    }
  }, [skipBaseUnits, lastChangedField, setValue, userWalletBaseAsset.free, boughtPrice]);
  
  const handleSkipBaseUnitsChange = useCallback((value: string) => {
    setValue('lastChangedField', 'units');
    const newUnits = parseFloat(value);
    if (!isNaN(newUnits)) {
      const newTotal = calculateTotal(newUnits, parseFloat(boughtPrice), currentSymbol.quoteAssetPrecision);
      const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
      setValue('skipBaseTotal', formattedTotal);
      
      const percentageValue = ((newUnits) / userWalletBaseAsset.free) * 100;
      setValue('skipBaseSlider', roundPercentage(percentageValue));
    }
  }, [boughtPrice, lastChangedField, setValue, userWalletBaseAsset.free]);
  
  const handleSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'total');
    const newTotal = (userWallet.free * value) / 100;
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    setValue('total', formattedTotal);
    
    const newUnits = calculateUnits(newTotal, parseFloat(orderPrice), currentSymbol.baseAssetPrecision);
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    setValue('units', formattedUnits);
  }, [userWallet, lastChangedField, orderPrice, total, currentSymbol?.quoteAssetPrecision, calculateUnits, setValue]);

  const handleSkipBaseSliderChange = useCallback((value: number) => {
    setValue('lastChangedField', 'units');
    const newUnits = (userWalletBaseAsset.free * value) / 100;
    const formattedUnits = formatByPrecisionAndTrim(newUnits.toString(), currentSymbol.baseAssetPrecision, Number(currentSymbol.lotMin), Number(currentSymbol.lotMax));
    
    const newTotal = calculateTotal(newUnits, parseFloat(boughtPrice), currentSymbol.quoteAssetPrecision);
    const formattedTotal = formatByPrecisionAndTrim(newTotal.toString(), currentSymbol.quoteAssetPrecision, Number(currentSymbol.minNotional), Number(currentSymbol.maxNotional));
    
    setValue('skipBaseTotal', formattedTotal);
    setValue('skipBaseUnits', formattedUnits);
  }, [userWalletBaseAsset.free, boughtPrice, currentSymbol?.quoteAssetPrecision, calculateUnits, setValue]);
  
  
  return {
    handleTriggerPriceChange,
    handleOrderPriceChange,
    handleBoughtPriceChange,
    handleTotalChange,
    handleUnitsChange,
    handleSliderChange,
    handleSkipBaseTotalChange,
    handleSkipBaseUnitsChange,
    handleSkipBaseSliderChange,
  };
};
