
import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { ResolutionString } from '../../charting_library/charting_library';
import { WebSocketHandler, WebSocketHandlerParams } from '../types/chart-view.types';
import { resolutionToExchangeInterval } from '../utils/chart-utils';

const CORS_PROXY = process.env.NODE_ENV === 'development' ? 'https://cors-anywhere.herokuapp.com/' : '';
const KUCOIN_BULLET_PUBLIC_URL = `${CORS_PROXY}https://api.kucoin.com/api/v1/bullet-public`;

export function createKucoinHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;

  return {
    connect: () =>
      new Promise<WebSocket>(async (resolve, reject) => {
        try {
          // 1. Получаем публичный токен и список серверов через bullet-public.
          const response = await fetch(KUCOIN_BULLET_PUBLIC_URL, {
            method: 'POST',
          });
          const json = await response.json();
          if (json.code !== '200000') {
            return reject(new Error('Bullet API error: ' + json.msg));
          }
          const data = json.data;
          const token: string = data.token;
          const instanceServer = data.instanceServers[0]; // используем первый сервер
          
          // 2. Генерируем уникальный connectId.
          const connectId = Date.now().toString();
          // Формируем URL подключения (endpoint может быть динамическим, см. документацию)
          const wsEndpoint = `${instanceServer.endpoint}?token=${token}&connectId=${connectId}`;
          
          ws = new WebSocket(wsEndpoint);

          ws.onopen = () => {
            console.log('WebSocket connection opened, waiting for welcome message...');
            // Ничего не отправляем до получения welcome.
          };

          ws.onmessage = (event) => {
            try {
              const message = JSON.parse(event.data);
              
              // Если получено welcome-сообщение – обрабатываем подписку
              if (message.type === 'welcome') {
                console.log('Received welcome message:', message);
                const subMsg = {
                  id: Date.now().toString(), // уникальный идентификатор запроса
                  type: 'subscribe',
                  topic: `/market/candles:${params.symbolInfo.full_name}_${resolutionToExchangeInterval(params.resolution as ResolutionString, 'kucoinws')}`, // убедитесь, что тема соответствует документации
                  response: true,
                };
                ws!.send(JSON.stringify(subMsg));
                // Запускаем heartbeat
                const pingIntervalMs = instanceServer.pingInterval || 30000;
                pingInterval = window.setInterval(() => {
                  if (ws && ws.readyState === WebSocket.OPEN) {
                    ws!.send(JSON.stringify({
                      type: 'ping', 
                    }));
                  }
                }, pingIntervalMs);
                resolve(ws!);
              }
              // Если сообщение является обновлением свечей
              else if (message.type === 'message' &&
                       message.topic &&
                       message.topic.startsWith('/market/candles') &&
                       message.data &&
                       Array.isArray(message.data.candles)
              ) {
                const candle = message.data.candles; // массив со свечными данными
                // Преобразуем данные в bar для TradingView:
                const bar = {
                  time: Number(candle[0]) * 1000,       // время (секунды -> миллисекунды)
                  open: parseFloat(candle[1]),
                  close: parseFloat(candle[2]),
                  high: parseFloat(candle[3]),
                  low: parseFloat(candle[4]),
                  volume: parseFloat(candle[5]),
                };
                params.dispatch(terminalActions.setLimitLastPrice(bar.close));
                params.onTick(bar);
              }
              else {
                // Для других сообщений просто передаём данные дальше
                params.onTick(message);
              }
            } catch (error) {
              console.error('Error processing message from Kucoin', error);
            }
          };
          
          ws.onerror = (error) => {
            if (params.onError) params.onError(error);
            reject(error);
          };

          ws.onclose = () => {
            if (pingInterval) clearInterval(pingInterval);
          };
        } catch (err) {
          reject(err);
        }
      }),

    disconnect: () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        // Отправляем запрос на отписку (опционально)
        const unsubMsg = {
          id: Date.now().toString(),
          type: 'unsubscribe',
          topic: `/market/candles:${params.symbolInfo.full_name}_${resolutionToExchangeInterval(params.resolution as ResolutionString, 'kucoinws')}`,
          response: true,
        };
        try {
          ws.send(JSON.stringify(unsubMsg));
        } catch (e) {
          console.warn('Error sending unsubscribe message', e);
        }
      }
      if (pingInterval) clearInterval(pingInterval);
      if (ws) {
        ws.close();
        ws = null;
      }
    },
  };
}
