import { Box, Stack } from '@mui/material';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { chooseSourceActions, getAlertSource, getOrderType } from 'entities/choose-source';
import { IChooseSource } from 'entities/choose-source/interfaces';
import { getChooseSourceValue } from 'pages/trading-bots/configurator-v2/helpers';
import { IStepProps } from 'pages/trading-bots/configurator-v2/interfaces';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'widgets';
import { TabIcon } from 'shared/icons';
import { MainButton } from 'shared/ui';
import { Actions, OrderType, Player } from '../../../components';
import { BUTTON, INNER, TAB_LABEL, WRAPPER } from '../styles';
import { Custom, Indicator, Strategy } from '../tabs';
import { BasicInfo } from '../tabs/custom/components';

const defaultValue = {
  indicator: 0,
  strategy: 1,
  custom: 2,
};

export const ChooseSource = memo((props: IStepProps) => {
  const bot = useSelector(getCurrentBot);
  // const exchange = useSelector(getExchange);

  const alertSource = useSelector(getAlertSource);
  const orderType = useSelector(getOrderType);

  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();

  // const token = useMemo(() => {
  //   if (!exchange) {
  //     return '';
  //   }

  //   const generatedToken = secretToken(exchange.id);
  //   return generatedToken;
  // }, [exchange]);

  const selectAlertSourceHandler = useCallback((tab: number) => {
    let value = '';
    switch (tab) {
    case 0:
      value = 'indicator';
      break;
    case 1:
      value = 'strategy';
      break;
    case 2:
      value = 'custom';
      break;
    default:
      break;
    }

    dispatch(chooseSourceActions.selectAlertSource(value as IChooseSource['alertSource']));
  }, [dispatch]);

  const selectOrderTypeHandler = useCallback((value: string) => {
    dispatch(chooseSourceActions.selectOrderType(value as IChooseSource['orderType']));
  }, [dispatch]);

  const getContent = useCallback(() => {
    if (alertSource === 'custom') {
      return (
        <BasicInfo />
      );
    }

    if (alertSource === 'indicator') {
      return (
        <Stack
          gap='16px'
          alignItems='center'
        >
          <Player url={`/videos/${alertSource}.mp4`} />

          <Box
            width='100%'
            maxWidth={{
              xs: '100%',
              md: 260,
            }}
          >
            <MainButton
              type='primary'
              ghost={true}
              onClick={() => {
                window.open('https://www.tradingview.com/script/RzGuxful-Multi-indicator-Signal-Builder-Skyrexio/', '_blank');
              }}
              styles={BUTTON({
                maxWidth: '100%',
              })}
            >
              {t('configurator.button.builder')}
            </MainButton>
          </Box>
        </Stack>
      );
    }

    return (
      <Player url={`/videos/${alertSource}.mp4`} />
    );
  }, [alertSource]);

  const getTableLabel = useCallback((label: string) => {
    return (
      <Stack sx={TAB_LABEL}>
        {TabIcon}

        {label}
      </Stack>
    );
  }, []);

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(chooseSourceActions.init(getChooseSourceValue(bot)));
  }, [bot?.botUuid, dispatch]);

  return (
    <Stack sx={WRAPPER}>
      <Stack sx={INNER}>
        <Tabs
          defaultValue={defaultValue[alertSource]}
          tabs={[{
            label: getTableLabel(t('configurator.source.tabs.indicator')),
            value: 0,
          }, {
            label: getTableLabel(t('configurator.source.tabs.strategy')),
            value: 1,
          }, {
            label: t('configurator.source.tabs.custom'),
            value: 2,
          }]}
          getTab={selectAlertSourceHandler}
          tabsLabel={{
            value: t('configurator.source.tabs.label.title'),
            tooltiop: t('configurator.source.tabs.label.tooltip'),
          }}
          content={[(
            <Indicator
              secretToken={props.secretToken!}
              sourceUuid={props.sourceUuid!}
              position={1}
            />
          ), (
            <Strategy
              secretToken={props.secretToken!}
              sourceUuid={props.sourceUuid!}
              position={1}
            />
          ), (
            <Custom
              secretToken={props.secretToken!}
              sourceUuid={props.sourceUuid!}
              position={1}
            />
          )]}
          styles={{
            gap: '28px',
          }}
        />

        <OrderType
          value={orderType}
          onChange={selectOrderTypeHandler}
        />
          
        <Actions
          handleBack={props.handleBack}
          button={{
            title: t('configurator.button.next'),
            action: props.changeCurrentStep!,
          }}
        />
      </Stack>

      {getContent()}
    </Stack>
  );
});

ChooseSource.displayName = 'ChooseSource';
