import { ThemeConfig } from 'antd';

export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#2ECD99',
    colorError: '#FF5E5E',
    fontFamily: 'Poppins, Inter, sans-serif',
    fontWeightStrong: 400,
  },
  components: {
    Menu: {
      colorBgContainer: 'transparent',
      horizontalItemSelectedBg: '#F6F6F6',
      horizontalItemBorderRadius: 8,
      horizontalItemSelectedColor: '#3A3A3C',
      activeBarBorderWidth: 0,
      horizontalItemHoverBg: '#F6F6F6',
      horizontalLineHeight: 'normal',
      lineWidth: 0,
    },
    Typography: {
      colorSuccess: '#2ECD99',
      titleMarginBottom: 0,
      colorLinkHover: '#2ECD99',
      linkHoverDecoration: 'underline',
      colorLinkActive: '#2ECD99',
      titleMarginTop: 0,
    },
    Collapse: {
      headerPadding: 0,
      contentPadding: 0,
    },
    Input: {
      inputFontSizeLG: 12,
      // paddingInlineLG: 0,
      paddingInline: 0,
    },
    Table: {
      headerBg: 'transaprent',
      headerSplitColor: 'transaprent',
      rowHoverBg: 'transaprent',
      headerSortHoverBg: 'transaprent',
      colorBgContainer: 'transparent',
      borderColor: 'transparent',
      colorTextHeading: '#9E9E9E',
      stickyScrollBarBg: '#2ECD99',
      stickyScrollBarBorderRadius: 8,
    },
    Segmented: {
      trackPadding: 4,
      itemActiveBg: '#2ECD99',
      itemSelectedBg: '#2ECD99',
      itemSelectedColor: '#fff',
    },
    Select: {
      colorText: '#2ECD99',
      selectorBg: '#fff',
      multipleItemBg: 'transparent',
      multipleItemBorderColor: '#E9E9E9',
    },
    Tooltip: {
      colorTextLightSolid: '#3A3A3C',
      borderRadius: 8,
      paddingContentHorizontal: 14,
      paddingContentVertical: 14,
      colorBgSpotlight: '#fff',
    },
    Button: {
      colorLink: '#2ECD99',
      colorLinkHover: '#2ECD99',
    },
    DatePicker: {
      cellHeight: 48,
      cellWidth: 48,
    },
    Progress: {
      defaultColor: '#2ECD99',
      circleTextColor: '#2ECD99',
      circleTextFontSize: '15px',
    },
  },
};
