import { Box } from '@mui/material';
import { BaseType } from 'antd/es/typography/Base';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaChart, BarChart, ChartWrapper } from 'widgets';
import { EXCHANGE_NAMES } from 'shared/consts/available-exchanges';
import { formatValueListItems } from 'shared/helpers/format-value-list-items';
import { useMultiExchangeFormatter } from 'shared/hooks/use-multi-exchange-formatter';
import { SecondaryInfo, Update } from 'shared/icons';
import { Loader, Text, Title, Tooltip } from 'shared/ui';
import { ChartAction } from '../components';
import { ITradingStatistic } from '../interfaces';
import {
  item,
  itemInner,
  left,
  leftInner,
  result,
  text,
  titleWrapper,
  tooltip,
  wrapper,
} from '../styles';

export const TradingStatistic = (props: ITradingStatistic) => {
  const {
    loading,
    listItems,
    chartData,
    chartAction,
    handleUpdateStatistic,
    selectedSegment,
    setSelectedSegment,
    disableTitle,
    selectedQuote,
    isCopyBot,
    dates,
    customTitle,
  } = props;

  const {
    t, 
  } = useTranslation();
  
  const {
    formatters,
    loadAllSymbols,
  } = useMultiExchangeFormatter([EXCHANGE_NAMES.BINANCE]);

  useEffect(() => {
    loadAllSymbols();
  }, []);
  
  const getChart = (selectedSegment: string) => {
    if (selectedSegment === 'summary') {
      return (
        <AreaChart
          data={chartData}
          dates={dates}
          loading={loading}
          quoteAsset={selectedQuote}
          isCopyBot={isCopyBot}
        />
      );
    }

    return (
      <BarChart
        data={chartData}
        quoteAsset={selectedQuote}
        isCopyBot={isCopyBot}
      />
    );
  };

  const renderListItem = (listItem: ITradingStatistic['listItems'][0]) => {
    let type: BaseType = 'secondary';
    let value = `${listItem.value}`;

    const isFundsValue = listItem.type === 'funds';
    const isPercentageValue = listItem.type === 'roi' || listItem.type === 'max-drawdown';
    const isCalculatedItem = listItem.type === 'total' || listItem.type === 'unrealised' || listItem.type === 'today';
  
    const symbol = listItem.currency ?? '%';
    
    const isZero = +formatValueListItems({
      value: +value,
      currency: listItem.currency,
      formatters,
      exchange: EXCHANGE_NAMES.BINANCE,
    }) === 0;
  
    if (isCalculatedItem) {
      if (isZero) {
        type = 'secondary';
        value = `0 ${symbol}`;
      } else if (+listItem.value > 0) {
        type = 'success';
        value = `+${formatValueListItems({
          value: +value,
          currency: listItem.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
        })}`;
      } else if (+listItem.value < 0) {
        type = 'danger';
        value = `${formatValueListItems({
          value: +value,
          currency: listItem.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
        })}`;
      } else {      
        type = 'secondary';
        value = `${value} ${symbol}`;
      }
    }
    
    if (isFundsValue) {
      if (isZero) {
        type = 'secondary';
        value = `0 ${symbol}`;
      } else {
        value = `${formatValueListItems({
          value: +value,
          currency: listItem.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
        })}`;
      }
    }

    if (isPercentageValue) {
      if (isZero) {
        type = 'secondary';
        value = `0${symbol}`;
      } else if (+listItem.value > 0) {
        type = 'success';
        value = `+${formatValueListItems({
          value: +value,
          currency: listItem.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
          skipQuote: true,
        })}%`;
      } else if (+listItem.value < 0) {
        type = 'danger';
        value = `${formatValueListItems({
          value: +value,
          currency: listItem.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
          skipQuote: true,
        })}%`;
      } else {
        type = 'secondary';
        value = `${formatValueListItems({
          value: +value,
          currency: listItem.currency,
          formatters,
          exchange: EXCHANGE_NAMES.BINANCE,
          skipQuote: true,
        })}%`;
      }
    }

    return (
      <Box
        key={listItem.title}
        sx={item}
      >
        <Box sx={itemInner}>
          <Tooltip
            title={listItem.tooltip}
            styles={tooltip}
          >
            {SecondaryInfo}
          </Tooltip>

          <Text
            styles={text}
            type='secondary'
          >
            {listItem.title}
          </Text>
        </Box>

        <Text
          styles={result}
          type={type}
        >
          {value}
        </Text>
      </Box>
    );
  };

  return (
    <Box sx={wrapper}>
      {loading && (
        <Loader isContentOverflow={true} />
      )}

      <Box sx={left}>
        {customTitle}

        {(!customTitle && !disableTitle) && (
          <Box sx={titleWrapper}>
            <Title level={5}>
              {t('tradingStatistics.title')}
            </Title>
            
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={handleUpdateStatistic}
            >
              {Update}
            </div>
          </Box>
        )}

        <Box sx={leftInner}>
          {listItems.map((listItem: ITradingStatistic['listItems'][0]) => {
            return renderListItem(listItem);
          })}
        </Box>
      </Box>

      <ChartWrapper
        segments={[{
          label: t('tradingStatistics.segments.sum'),
          value: 'summary',
        }, {
          label: t('tradingStatistics.segments.day'),
          value: 'day',
        }, {
          label: t('tradingStatistics.segments.pair'),
          value: 'pair',
        }]}
        selectedSegment={selectedSegment}
        setSelectedSegment={setSelectedSegment}
        defaultSegment='summary'
        isEmpty={!chartData?.length}
        action={chartAction || (
          <ChartAction />
        )}
      >
        {(selectedSegment: string) => getChart(selectedSegment)}
      </ChartWrapper>
    </Box>
  );
};
