import { Box } from '@mui/material';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { ExchangeAccount } from 'entities/exchange/model/types/exchange-account';
import { CreateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { getIsDemoMode } from 'entities/user/model/selectors/get-is-demo-mode/get-is-demo-mode';
import { connectExchange } from 'features/connect-exchange/api/connect-exchange';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getExchanges, getExchangesIds } from 'widgets/exchanges/api/get-exchanges';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { getError } from 'widgets/exchanges/helpers';
import { exchangeConfigs } from 'widgets/exchanges/helpers/exchange-config-map';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import { Tabs } from 'widgets/tabs';
import { SUBSCRIPTIONS } from 'shared/consts';
import { getDemoAccount } from 'shared/helpers';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
} from 'shared/ui/modals';

export const CreateExchange: React.FC<CreateExchangeProps & { exchangeType: keyof typeof exchangeConfigs; }> = (props) => {
  const {
    isModalView,
    returnToAllExchanges,
    handleClose,
    exchangeType,
  } = props;

  const uuid = getSkyrexUuid();
  const dispatch = useDispatch();
  const config = exchangeConfigs[exchangeType];
  const navigate = useNavigate();
  const isDemoMode = useSelector(getIsDemoMode);
  const {
    t, 
  } = useTranslation();

  const [formData, setFormData] = useState<Record<string, string>>({});
  const [connectExchangeSuccessModalOpen, setConnectExchangeSuccessModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(getError());

  const handleCloseModal = () => {
    setConnectExchangeSuccessModalOpen(false);
    handleClose?.();
  };

  const connectExchangeWithApiKeys = async () => {
    setIsLoading(true);
    try {
      const dataForConnectOauth = {
        uuid: uuid ?? '',
        exchangeName: exchangeType === 'crypto' ? 'crypto-com' : exchangeType,
        ...formData,
      };

      const connectResponse = await connectExchange(dataForConnectOauth);

      if (!connectResponse.success) {
        setError(getError({
          isOpened: true,
          description: t(`error.exchange.connect.${connectResponse.data.message}`),
          button: {
            action: () => {
              if (connectResponse.data.message === 'Maximum number of active accounts reached. Please upgrade subscription') {
                navigate(SUBSCRIPTIONS);
                return;
              }

              setError(getError());
            },
          },
          t,
        }));
        return;
      }

      const exchangesIdsResponse = await getExchangesIds(uuid ?? '');
      if (!exchangesIdsResponse.success) {
        return;
      }

      const {
        accounts,
      } = exchangesIdsResponse.data;
      const accountsIds = getDemoAccount(accounts, isDemoMode).map((account: ExchangeAccount) => account.exchangeAccountUuid);

      const allAccountsData = await getExchanges(accountsIds);
      dispatch(exchangeActions.setAllExchanges(allAccountsData.data.accounts));

      setIsLoading(false);
      setConnectExchangeSuccessModalOpen(true);
    } catch (error) {
      setError(getError({
        isOpened: true,
        title: t('error.wrong.title'),
        description: t('error.wrong.message'),
        button: {
          action: () => {
            setError(getError());
          },
        },
        t,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev, [key]: value,
    }));
  };

  const connectExchangeOauth = () => {
    const skyrexUserUuid = getSkyrexUuid();
    let url: string;
    
    if (typeof config.oauthUrl === 'function') {
      if (exchangeType === 'htx') {
        url = config.oauthUrl({
          mode: 'create',
        });
      } else {
        const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
        url = config.oauthUrl({
          mode: 'create',
          userId: skyrexUserUuid ?? '',
          exchangeAccountUuid: '',
        }) + randomHash;
      }
    } else {
      const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
      url = `${config.oauthUrl}${randomHash}`;
    }

    window.open(url, '_self');
  };

  const infoForm = (
    <Info
      returnToAllExchanges={returnToAllExchanges}
      actionBlock={(
        <ActionBlock
          button={{
            label: `${t('exchange.connect.button')} ${config.name}`,
            action: connectExchangeOauth,
          }}
          createAccount={{
            label: t('exchange.connect.account.text', {
              name: config.name, 
            }),
            link: config.createAccountUrl,
          }}
        />
      )}
    />
  );

  const fields = useMemo(() => {
    return config.fields.map(field => {
      const input = t(`form.exchange.fields.${field.key}`, {
        returnObjects: true,
      }) as any;

      const memoInput = t('form.exchange.fields.memo', {
        returnObjects: true,
      }) as any;

      const isBitmart = field.label.toLowerCase() === 'memo';

      return {
        ...field,
        label: isBitmart ? memoInput.title : input.title,
        placeholder: isBitmart ? memoInput.placeholder : input.placeholder,
        value: formData[field.key] || '',
        onChange: (value: string) => handleInputChange(field.key, value),
      };
    });
  }, [config, formData, t]);

  const form = (
    <Form
      returnToAllExchanges={returnToAllExchanges}
      type={exchangeType}
      fields={fields}
      actionBlock={(
        <ActionBlock
          button={{
            label: `${t('exchange.connect.button')} ${config.name}`,
            action: connectExchangeWithApiKeys,
          }}
          createAccount={{
            label: t('exchange.connect.account.text', {
              name: config.name, 
            }),
            link: config.createAccountUrl,
          }}
        />
      )}
    />
  );

  return (
    <>
      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>
              {t('exchange.connect.title.connect')}
            </Title>

            <Box sx={platformLogo}>
              {config.logo}
            </Box>

            <Title>
              {t('exchange.connect.title.exchange')}
            </Title>
          </Box>

          <Tabs
            tabs={[{
              label: t('exchange.connect.tabs.fast'),
              value: 0,
              disabled: config.isOauthDisabled,
            }, {
              label: t('exchange.connect.tabs.api'),
              value: 1,
            }]}
            styles={{
              width: '100%',
              flex: 1,
              justifyContent: 'center',
            }}
            defaultValue={config.isOauthDisabled ? 1 : 0}
            maxWidth='475px'
            content={[
              infoForm,
              form,
            ]}
          />
        </Box>
      </Box>

      <EmptySubscription
        isOpen={connectExchangeSuccessModalOpen}
        handleClose={handleCloseModal}
        modalTitle={t('success.exchange.connect.title')}
        modalDescription={t('success.exchange.connect.title')}
        button={{
          title: t('success.exchange.connect.title'),
          action: handleCloseModal,
        }}
      />

      <EmptySubscription
        isOpen={error.isOpened}
        handleClose={() => {
          setError((prev: any) => ({
            ...prev,
            isOpened: false,
          }));
        }}
        modalTitle={error.title}
        modalDescription={error.description}
        isError={error.isOpened}
        button={{
          title: error.button.title,
          action: error.button.action,
        }}
      />
    </>
  );
};
