import { terminalActions } from 'entities/terminal/model/slices/terminal-slice';
import { WebSocketHandler } from '../types/chart-view.types';
import { WebSocketHandlerParams } from '../types/chart-view.types';

export function createBybitHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  let ws: WebSocket | null = null;
  let pingInterval: number | undefined;
  return {
    connect: () =>
      new Promise<WebSocket>((resolve, reject) => {
        const endpoint = 'wss://stream.bybit.com/v5/public/spot';
        ws = new WebSocket(endpoint);
    
        ws.onopen = () => {
          // Отправляем сообщение подписки – формат зависит от биржи
          const msg = {
            op: 'subscribe', args: [`kline.${params.resolution}.${params.symbolInfo.full_name}`], 
          };
            
            
              ws!.send(JSON.stringify(msg));
    
              // Запускаем heartbeat (пример: каждые 30 сек отправляем ping)
              pingInterval = window.setInterval(() => {
                if (ws && ws.readyState === WebSocket.OPEN) {
                  ws.send(JSON.stringify({
                    op: 'ping', 
                  }));
                }
              }, 30000);
              //@ts-ignore
              resolve(ws);
        };
    
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          // Пропускаем pong или обрабатываем специфичные ответы
          if (data.ret_msg === 'pong') return;
            
          if (data.topic === `kline.${params.resolution}.${params.symbolInfo.full_name}`) {
            const bar = {
              time: data.data[0].start,
              open: data.data[0].open,
              high: data.data[0].high,
              low: data.data[0].low,
              close: data.data[0].close,
              volume: data.data[0].volume,
            };
            params.dispatch(terminalActions.setLimitLastPrice(bar.close));
            params.onTick(bar);
          }
        };
    
        ws.onerror = (error) => {
          if (params.onError) params.onError(error);
          reject(error);
        };
    
        ws.onclose = () => {
          if (pingInterval) clearInterval(pingInterval);
        };
      }),
    disconnect: () => {
      if (ws) ws.close();
      if (pingInterval) clearInterval(pingInterval);
    },
  };
}
