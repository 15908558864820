import { TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { dateSorter, numberSorter, stringSorter } from 'shared/helpers/sorters';
import { SortIcon, Text } from 'shared/ui';

const statuses: any = {
  paid: 'success',
  pending: 'warning',
  deleted: 'danger',
};

export const withdrawalsColumns = (t: any): TableColumnsType => {
  return [{
    title: t('invite.withdrawals.columns.amount'),
    dataIndex: 'payoutAmount',
    key: 'payoutAmount',
    width: 195,
    sorter: (a, b) => numberSorter(a.payoutAmount, b.payoutAmount),
    render: (value: string) => (
      <Text>
        {value}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.withdrawals.columns.transaction'),
    dataIndex: 'paymentId',
    key: 'paymentId',
    width: 195,
    render: (value: string) => (
      <Text
        styles={{
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.withdrawals.columns.wallet'),
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    width: 195,
    render: (value: string) => (
      <Text
        styles={{
          display: 'block',
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {value}
      </Text>
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.withdrawals.columns.date.create'),
    dataIndex: 'createdDate',
    key: 'createdDate',
    width: 195,
    sorter: (a, b) => dateSorter(a.createdDate, b.createdDate),
    render: (value: string) => {
      if (!value) {
        return '-';
      }
  
      return (
        <Text>
          {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
        </Text>
      );
    },
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.withdrawals.columns.date.paid'),
    dataIndex: 'paymentDate',
    key: 'paymentDate',
    width: 195,
    sorter: (a, b) => dateSorter(a.paymentDate, b.paymentDate),
    render: (value: string) => {
      if (!value) {
        return '-';
      }
  
      return (
        <Text>
          {dayjs(value).format('DD.MM.YYYY, HH:mm:ss')}
        </Text>
      );
    },
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }, {
    title: t('invite.withdrawals.columns.status.title'),
    dataIndex: 'status',
    key: 'status',
    width: 195,
    sorter: (a, b) => stringSorter(a.status, b.status),
    render: (value: string) => (
      <Text
        styles={{
          textTransform: 'capitalize',
        }}
        type={statuses[value]}
      >
        {t(`invite.withdrawals.columns.status.items.${value}`)}
      </Text>
    ),
    sortIcon: (props) => (
      <SortIcon sortOrder={props.sortOrder} />
    ),
    showSorterTooltip: false,
  }];
};
