import { CSSProperties } from 'react';

export const BUTTON: CSSProperties = {
  height: 'auto',
  padding: '16px',
  width: '100%',
  maxWidth: '283px',
  textAlign: 'center',
  fontSize: 18,
  fontWeight: 400,
};
