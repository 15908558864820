import { WebSocketHandlerParams } from '../types/chart-view.types';
import { ExchangeName, WebSocketHandler } from '../types/chart-view.types';
import { createBinanceHandler } from './binance-handler';
import { createBitmartHandler } from './bitmart-handler';
import { createBybitHandler } from './bybit-handler';
import { createCryptoComHandler } from './crypto-com-handler';
import { createCryptoCompareHandler } from './cryptocompare-handler';
import { createFallbackHandler } from './fallback-handler';
import { createGateioHandler } from './gateio-handler';
import { createHTXHandler } from './htx-handler';
import { createKucoinHandler } from './kucoin-handler';
import { createOkxHandler } from './okx-handler';

function normalizeExchangeName(name: string): ExchangeName {
  if (name === 'Crypto.com') {
    return 'CRYPTO_COM';
  }
  
  if (name === 'Gate.io') {
    return 'GATEIO';
  }
  
  return name.toUpperCase() as ExchangeName;
}
  
const handlers: Record<
    ExchangeName,
    (params: WebSocketHandlerParams) => WebSocketHandler
  > = {
    BINANCE: createBinanceHandler,
    BYBIT: createBybitHandler,
    OKX: createOkxHandler,
    GATEIO: createGateioHandler,
    CRYPTO_COM: createCryptoComHandler,
    BITMART: createBitmartHandler,
    HTX: createHTXHandler,
    CRYPTO_COMPARE: createCryptoCompareHandler,
    KUCOIN: createKucoinHandler,
  };
  
export function createWebSocketHandler(
  params: WebSocketHandlerParams,
): WebSocketHandler {
  const exchange = normalizeExchangeName(params.exchangeName);
  const handlerCreator = handlers[exchange];
  if (!handlerCreator) {
    throw new Error(`Unsupported exchange: ${exchange}`);
  }
  // Оборачиваем в fallback‑логику
  return createFallbackHandler(handlerCreator, params);
}
