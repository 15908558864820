import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { chooseAccountActions, getActiveTradingPairs, getMarket, getTradingAmount } from 'entities/choose-account';
import { CustomSlider } from 'pages/trading-bots/configurator-v2/components';
import { getActiveTradingPairsError } from 'pages/trading-bots/configurator-v2/helpers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { Label } from 'widgets';

export const ActiveTradingPairs = () => {
  const bot = useSelector(getCurrentBot);

  const activeTradingPairs = useSelector(getActiveTradingPairs);
  const tradingAmount = useSelector(getTradingAmount);
  const market = useSelector(getMarket);
  
  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();

  const [value, setValue] = useState<number>(activeTradingPairs || 1);

  const debouncedValue = useDebouncedCallback((value: number) => {
    dispatch(chooseAccountActions.setActiveTradingPairs(value));
  }, 300);

  const setActiveTradingPairs = (value: number) => {
    setValue(value);
    debouncedValue(value);
  };

  useEffect(() => {
    if (!bot?.botUuid) {
      return;
    }

    dispatch(chooseAccountActions.setActiveTradingPairs(bot.maxActiveDeals));
    setValue(bot.maxActiveDeals);
  }, [bot?.botUuid]);

  const error = getActiveTradingPairsError({
    tradingAmount: +tradingAmount,
    activeTradingPairs,
    minNotional: market.minNotional,
  });
  
  return (
    <CustomSlider
      title={(
        <Label
          title={t('form.configurator.pairs.title')}
        />
      )}
      slider={{
        value: value,
        onChange: setActiveTradingPairs,
        max: 20,
        marks: {
          1: 1,
          5: 5,
          20: 20,
        },
      }}
      input={{
        value: value,
        placeholder: '100%',
        onChange: setActiveTradingPairs,
      }}
      error={error}
    />
  );
};
