import { Box } from '@mui/material';
import { setExchangeData } from 'entities/exchange/helpers/set-exchanges-data';
import { exchangeActions } from 'entities/exchange/model/slices/exchange-slice';
import { UpdateExchangeProps } from 'entities/exchange/model/types/exchange-connect-update.types';
import { updateExchange } from 'features/update-exchange/api/update-exchange';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ActionBlock, Form, Info } from 'widgets/exchanges/components';
import { getModal } from 'widgets/exchanges/helpers';
import { exchangeConfigs } from 'widgets/exchanges/helpers/exchange-config-map';
import { generateRandomHash } from 'widgets/exchanges/helpers/generate-hash-oauth-link';
import { getWrapperStyles, logo, platformLogo } from 'widgets/exchanges/styles';
import { Tabs } from 'widgets/tabs';
import {
  getSkyrexUuid,
  setExchangeAccountNameBeingUpdated,
  setExchangeUuidBeingUpdated,
} from 'shared/helpers/storage-helper';
import { Loader, Title } from 'shared/ui';
import {
  EmptySubscription,
} from 'shared/ui/modals';

interface IUpdateExchangeProps {
  exchangeType: keyof typeof exchangeConfigs;
}
type TUpdateExchangeProps = UpdateExchangeProps & IUpdateExchangeProps;
export const UpdateExchange = (params: TUpdateExchangeProps) => {
  const {
    isModalView,
    exchangeAccountUuid,
    currentExchangeTitle,
    closeExchangeModal,
    exchangeType,
  } = params;

  const skyrexUserUuid = getSkyrexUuid();
  const dispatch = useDispatch();
  const config = exchangeConfigs[exchangeType];
  const {
    t, 
  } = useTranslation();

  const [formData, setFormData] = useState<Record<string, string>>({
    exchangeTitle: currentExchangeTitle,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<any>(getModal());

  const onCloseErrorModal = () => {
    setModal((prev: any) => ({
      ...prev,
      isOpened: false,
    }));
  };

  const onCloseSuccessModal = () => {
    setModal((prev: any) => ({
      ...prev,
      isOpened: false,
    }));
    
    closeExchangeModal?.();
  };
  
  const updateExchangeWithOAuth = () => {
    const randomHash = generateRandomHash(exchangeType === 'okx' ? 16 : 48);
    
    let url: string;
    if (typeof config.oauthUrl === 'function') {
      url = config.oauthUrl({ 
        mode: 'update',
        userId: skyrexUserUuid ?? '',
        exchangeAccountUuid: exchangeAccountUuid ?? '',
      }) + randomHash;
    } else {
      url = `${config.oauthUrl}${randomHash}`;
    }
  
    setExchangeAccountNameBeingUpdated(currentExchangeTitle);
    setExchangeUuidBeingUpdated(exchangeAccountUuid);
    window.open(url, '_self');
  };

  const updateExchangeWithApiKeys = async () => {
    setIsLoading(true);

    try {
      const dataForUpdateOauth = {
        exchangeAccountUuid,
        accountName: currentExchangeTitle,
        exchangeCode: exchangeType,
        ...formData,
      };

      const updateResponse = await updateExchange(dataForUpdateOauth);

      if (!updateResponse.success) {
        setModal(getModal({
          isOpened: true,
          isError: true,
          description: t(`error.exchange.update.message.${updateResponse?.data?.message}`),
          button: {
            action: () => {
              setModal(getModal());
            },
          },
          t,
        }));
        return;
      }
      
      const exchangeAccounts = await setExchangeData(skyrexUserUuid ?? '');
      dispatch(exchangeActions.setAllExchanges(exchangeAccounts));

      setModal(getModal({
        isOpened: true,
        isError: false,
        title: t('success.exchange.update.title'),
        description: t('success.exchange.update.message'),
        button: {
          action: () => {
            setModal(getModal());
            closeExchangeModal();
          },
        },
        t,
      }));
      
      setModal((prev: any) => ({
        ...prev,
        isOpened: true,
        isError: false,
        title: t('success.exchange.update.title'),
        description: t('success.exchange.update.message'),
        button: {
          ...prev.button,
          action: () => {
            setModal((prev: any) => ({
              ...prev,
              isOpened: false,
              isError: false,
            }));

            closeExchangeModal();
          },
        },
      }));
    } catch (error) {
      setModal(getModal({
        isOpened: true,
        isError: true,
        button: {
          action: () => {
            setModal(getModal());
          },
        },
        t,
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev, [key]: value, 
    }));
  };

  const infoForm = (
    <Info
      actionBlock={(
        <ActionBlock
          button={{
            label: `${t('exchange.update.button')} ${config.name}`,
            action: updateExchangeWithOAuth,
          }}
        />
      )}
    />
  );

  const fields = useMemo(() => {
    return config.fields.map(field => {
      const input = t(`form.exchange.fields.${field.key}`, {
        returnObjects: true,
      }) as any;
  
      const memoInput = t('form.exchange.fields.memo', {
        returnObjects: true,
      }) as any;
  
      const isBitmart = field.label.toLowerCase() === 'memo';
  
      return {
        ...field,
        label: isBitmart ? memoInput.title : input.title,
        placeholder: isBitmart ? memoInput.placeholder : input.placeholder,
        disabled: field.key === 'exchangeTitle',
        value: formData[field.key] || '',
        onChange: (value: string) => handleInputChange(field.key, value),
      };
    });
  }, [config, formData, t]);

  const form = (
    <Form
      type={exchangeType}
      fields={fields}
      actionBlock={(
        <ActionBlock
          button={{
            label: `${t('exchange.update.button')} ${config.name}`,
            action: updateExchangeWithApiKeys,
          }}
        />
      )}
    />
  );

  return (
    <>
      <Box position='relative'>
        {isLoading && (
          <Loader isContentOverflow={true} />
        )}

        <Box sx={getWrapperStyles(!!isModalView)}>
          <Box sx={logo}>
            <Title>
              {t('exchange.update.title.update')}
            </Title>

            <Box sx={platformLogo}>
              {config.logo}
            </Box>

            <Title>
              {t('exchange.update.title.exchange')}
            </Title>
          </Box>

          <Tabs
            tabs={[{
              label: t('exchange.update.tabs.fast'),
              value: 0,
            }, {
              label: t('exchange.update.tabs.api'),
              value: 1,
            }]}
            styles={{
              width: '100%',
              flex: 1,
              justifyContent: 'center',
            }}
            maxWidth='475px'
            content={[
              infoForm,
              form,
            ]}
          />
        </Box>
      </Box>
      
      <EmptySubscription
        isOpen={modal.isOpened}
        handleClose={modal.isError ? onCloseErrorModal : onCloseSuccessModal}
        modalTitle={modal.title}
        modalDescription={modal.description}
        modalButtonTitle={modal.button.title}
        modalButtonAction={modal.button.action}
        isError={modal.isError}
      />
    </>
  );
};
