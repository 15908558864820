import { BaseOptionType } from 'antd/es/select';
import { getCurrentBot } from 'entities/bot/model/selectors/get-current-bot/get-current-bot';
import { chooseAccountActions, getMarket, getMarkets } from 'entities/choose-account';
import { convertMarket } from 'pages/trading-bots/configurator-v2/helpers';
import { useGetMarkets } from 'pages/trading-bots/configurator-v2/hooks/use-get-markets';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from 'widgets';
import { SingleSelect } from 'shared/ui';
import { Market as MarketView } from 'shared/ui/selects/single-select/views';
import { IMarketProps } from '../interfaces';

export const Market = (props: IMarketProps) => {
  const bot = useSelector(getCurrentBot);

  const {
    loading,
  } = useGetMarkets({
    exchange: props.exchange,
    bot: bot!,
  });

  const markets = useSelector(getMarkets);
  const market = useSelector(getMarket);

  const dispatch = useDispatch();
  const {
    t, 
  } = useTranslation();
  
  const selectMarketHandler = useCallback((value: string) => {
    const market = markets.find((market) => market.value === value);
    dispatch(chooseAccountActions.selectMarket(convertMarket(market)));
  }, [markets, dispatch]);

  const marketView = useCallback(() => {
    if (!market.name) {
      return null;
    }
    
    const [name, value] = market.name.split('_');

    return (
      <MarketView
        title={name}
        code={market.code}
        value={`${value}_${name}`}
      />
    );
  }, [market]);

  const marketOptionView = (option: BaseOptionType) => {
    if (!market.name) {
      return null;
    }
    
    const [name, value] = (option.label as string || '').split('_');
    return (
      <MarketView
        title={name}
        code={name}
        value={`${value}_${name}`}
      />
    );
  };
  
  return (
    <SingleSelect
      label={(
        <Label
          title={t('form.configurator.market.title')}
        />
      )}
      options={markets}
      select={{
        value: loading ? '' : market.value,
        placeholder: '',
        onChange: selectMarketHandler,
      }}
      labelRender={marketView}
      optionRender={marketOptionView}
      size='large'
      loading={loading}
    />
  );
};
