import { SxProps, Theme } from '@mui/material';

export const WRAPPER: SxProps<Theme> = {
  display: 'grid',
  gridTemplateColumns: {
    xs: 'repeat(2, 1fr)',
    md: 'repeat(4, 1fr)',
  },
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '24px',
};
