export const ENG = (
  <svg width='50' height='33' viewBox='0 0 50 33' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M49.1378 32.7586H0.862108C0.386034 32.7586 0 32.3726 0 31.8965V0.862107C0 0.386034 0.386034 0 0.862108 0H49.1379C49.614 0 50 0.386034 50 0.862107V31.8965C49.9999 32.3727 49.6139 32.7586 49.1378 32.7586Z' fill='#F5F5F5'/>
    <path d='M49.9999 2.52001H0V0.862107C0 0.386034 0.386034 0 0.862108 0H49.1379C49.614 0 50 0.386034 50 0.862107L49.9999 2.52001Z' fill='#FF4B55'/>
    <path d='M49.9999 10.0796H0V12.5995H49.9999V10.0796Z' fill='#FF4B55'/>
    <path d='M49.9999 5.03955H0V7.55947H49.9999V5.03955Z' fill='#FF4B55'/>
    <path d='M49.9999 17.6392H0.862108C0.386034 17.6392 0 17.2531 0 16.777V15.1191H49.9999V17.6392Z' fill='#FF4B55'/>
    <path d='M49.9999 25.1997H0V27.7196H49.9999V25.1997Z' fill='#FF4B55'/>
    <path d='M49.1378 32.7587H0.862108C0.386034 32.7587 0 32.3727 0 31.8966V30.2388H49.9999V31.8967C49.9999 32.3728 49.6139 32.7587 49.1378 32.7587Z' fill='#FF4B55'/>
    <path d='M49.9999 20.1594H0V22.6793H49.9999V20.1594Z' fill='#FF4B55'/>
    <path d='M22.4137 0H0.862108C0.386034 0 0 0.385936 0 0.86201V16.777C0 17.2531 0.386034 17.6391 0.862108 17.6391H22.4138C22.8899 17.6391 23.2759 17.2531 23.2759 16.777V0.86201C23.2758 0.385936 22.8898 0 22.4137 0Z' fill='#41479B'/>
    <path d='M2.42152 1.97892L2.61234 2.55118L3.21556 2.55577C3.29368 2.55646 3.32601 2.65606 3.26321 2.70245L2.77796 3.06085L2.95999 3.63595C2.98363 3.71036 2.89876 3.77208 2.83519 3.72657L2.34447 3.37589L1.85375 3.72667C1.79017 3.77208 1.7055 3.71046 1.72894 3.63604L1.91097 3.06095L1.42572 2.70255C1.36293 2.65606 1.39525 2.55655 1.47337 2.55587L2.0766 2.55128L2.26742 1.97902C2.29203 1.9048 2.39671 1.9048 2.42152 1.97892Z' fill='#F5F5F5'/>
    <path d='M2.42103 4.9725L2.61185 5.54476L3.21507 5.54935C3.29319 5.55004 3.32552 5.64964 3.26273 5.69603L2.77747 6.05443L2.9595 6.62953C2.98314 6.70394 2.89827 6.76566 2.8347 6.72015L2.34398 6.36947L1.85326 6.72025C1.78968 6.76566 1.70501 6.70404 1.72845 6.62962L1.91048 6.05453L1.42523 5.69613C1.36244 5.64965 1.39476 5.55013 1.47289 5.54945L2.07611 5.54486L2.26693 4.97259C2.29154 4.89847 2.39622 4.89847 2.42103 4.9725Z' fill='#F5F5F5'/>
    <path d='M2.42103 7.96591L2.61185 8.53817L3.21507 8.54276C3.29319 8.54344 3.32552 8.64305 3.26273 8.68944L2.77747 9.04784L2.9595 9.62293C2.98314 9.69735 2.89827 9.75907 2.8347 9.71356L2.34398 9.36287L1.85326 9.71365C1.78968 9.75906 1.70501 9.69744 1.72845 9.62303L1.91048 9.04793L1.42523 8.68954C1.36244 8.64305 1.39476 8.54354 1.47289 8.54286L2.07611 8.53827L2.26693 7.966C2.29154 7.89188 2.39622 7.89188 2.42103 7.96591Z' fill='#F5F5F5'/>
    <path d='M2.42103 10.9596L2.61185 11.5319L3.21507 11.5365C3.29319 11.5372 3.32552 11.6368 3.26273 11.6832L2.77747 12.0416L2.9595 12.6167C2.98314 12.6911 2.89827 12.7528 2.8347 12.7073L2.34398 12.3566L1.85326 12.7074C1.78968 12.7528 1.70501 12.6912 1.72845 12.6168L1.91048 12.0417L1.42523 11.6833C1.36244 11.6368 1.39476 11.5373 1.47289 11.5366L2.07611 11.532L2.26693 10.9597C2.29154 10.8855 2.39622 10.8855 2.42103 10.9596Z' fill='#F5F5F5'/>
    <path d='M2.42103 13.9531L2.61185 14.5254L3.21507 14.53C3.29319 14.5306 3.32552 14.6303 3.26273 14.6766L2.77747 15.035L2.9595 15.6101C2.98314 15.6846 2.89827 15.7463 2.8347 15.7008L2.34398 15.3501L1.85326 15.7009C1.78968 15.7463 1.70501 15.6847 1.72845 15.6102L1.91048 15.0351L1.42523 14.6767C1.36244 14.6303 1.39476 14.5307 1.47289 14.5301L2.07611 14.5255L2.26693 13.9532C2.29154 13.8789 2.39622 13.8789 2.42103 13.9531Z' fill='#F5F5F5'/>
    <path d='M4.74427 3.44663L4.93509 4.0189L5.53831 4.02349C5.61644 4.02417 5.64876 4.12378 5.58597 4.17016L5.10072 4.52856L5.28275 5.10366C5.30638 5.17807 5.22152 5.23979 5.15794 5.19428L4.66722 4.8435L4.1765 5.19428C4.11292 5.23969 4.02826 5.17807 4.05169 5.10366L4.23373 4.52856L3.74847 4.17016C3.68568 4.12368 3.718 4.02417 3.79613 4.02349L4.39935 4.0189L4.59017 3.44663C4.61468 3.37261 4.71956 3.37261 4.74427 3.44663Z' fill='#F5F5F5'/>
    <path d='M4.74427 6.44011L4.93509 7.01238L5.53831 7.01697C5.61644 7.01765 5.64876 7.11726 5.58597 7.16364L5.10072 7.52204L5.28275 8.09714C5.30638 8.17155 5.22152 8.23327 5.15794 8.18776L4.66722 7.83698L4.1765 8.18776C4.11292 8.23317 4.02826 8.17165 4.05169 8.09714L4.23373 7.52204L3.74847 7.16364C3.68568 7.11716 3.718 7.01765 3.79613 7.01697L4.39935 7.01238L4.59017 6.44011C4.61468 6.36599 4.71956 6.36599 4.74427 6.44011Z' fill='#F5F5F5'/>
    <path d='M4.74427 9.43376L4.93509 10.006L5.53831 10.0106C5.61644 10.0113 5.64876 10.1109 5.58597 10.1573L5.10072 10.5157L5.28275 11.0908C5.30638 11.1652 5.22152 11.2269 5.15794 11.1814L4.66722 10.8306L4.1765 11.1814C4.11292 11.2268 4.02826 11.1652 4.05169 11.0908L4.23373 10.5157L3.74847 10.1573C3.68568 10.1108 3.718 10.0113 3.79613 10.0106L4.39935 10.006L4.59017 9.43376C4.61468 9.35964 4.71956 9.35964 4.74427 9.43376Z' fill='#F5F5F5'/>
    <path d='M4.74427 12.4273L4.93509 12.9996L5.53831 13.0042C5.61644 13.0049 5.64876 13.1045 5.58597 13.1509L5.10072 13.5093L5.28275 14.0844C5.30638 14.1588 5.22152 14.2205 5.15794 14.175L4.66722 13.8242L4.1765 14.175C4.11292 14.2204 4.02826 14.1588 4.05169 14.0844L4.23373 13.5093L3.74847 13.1509C3.68568 13.1044 3.718 13.0049 3.79613 13.0042L4.39935 12.9996L4.59017 12.4273C4.61468 12.3533 4.71956 12.3533 4.74427 12.4273Z' fill='#F5F5F5'/>
    <path d='M7.068 1.97893L7.25882 2.55119L7.86204 2.55578C7.94017 2.55647 7.97249 2.65608 7.9097 2.70246L7.42445 3.06086L7.60648 3.63596C7.63011 3.71037 7.54525 3.77209 7.48167 3.72658L6.99095 3.3758L6.50023 3.72658C6.43666 3.77199 6.35199 3.71037 6.37542 3.63596L6.55746 3.06086L6.0722 2.70246C6.00941 2.65598 6.04173 2.55647 6.11986 2.55578L6.72308 2.55119L6.9139 1.97893C6.93851 1.90481 7.04329 1.90481 7.068 1.97893Z' fill='#F5F5F5'/>
    <path d='M7.068 4.97251L7.25882 5.54477L7.86204 5.54936C7.94017 5.55005 7.97249 5.64966 7.9097 5.69604L7.42445 6.05444L7.60648 6.62954C7.63011 6.70395 7.54525 6.76567 7.48167 6.72016L6.99095 6.36938L6.50023 6.72016C6.43666 6.76557 6.35199 6.70395 6.37542 6.62954L6.55746 6.05444L6.0722 5.69604C6.00941 5.64956 6.04173 5.55005 6.11986 5.54936L6.72308 5.54477L6.9139 4.97251C6.93851 4.89849 7.04329 4.89849 7.068 4.97251Z' fill='#F5F5F5'/>
    <path d='M7.068 7.96592L7.25882 8.53818L7.86204 8.54277C7.94017 8.54346 7.97249 8.64307 7.9097 8.68945L7.42445 9.04785L7.60648 9.62295C7.63011 9.69736 7.54525 9.75908 7.48167 9.71357L6.99095 9.36279L6.50023 9.71357C6.43666 9.75898 6.35199 9.69736 6.37542 9.62295L6.55746 9.04785L6.0722 8.68945C6.00941 8.64297 6.04173 8.54346 6.11986 8.54277L6.72308 8.53818L6.9139 7.96592C6.93851 7.89189 7.04329 7.89189 7.068 7.96592Z' fill='#F5F5F5'/>
    <path d='M7.068 10.9596L7.25882 11.5319L7.86204 11.5365C7.94017 11.5372 7.97249 11.6368 7.9097 11.6832L7.42445 12.0416L7.60648 12.6167C7.63011 12.6911 7.54525 12.7528 7.48167 12.7073L6.99095 12.3565L6.50023 12.7073C6.43666 12.7527 6.35199 12.6911 6.37542 12.6167L6.55746 12.0416L6.0722 11.6832C6.00941 11.6367 6.04173 11.5372 6.11986 11.5365L6.72308 11.5319L6.9139 10.9596C6.93851 10.8855 7.04329 10.8855 7.068 10.9596Z' fill='#F5F5F5'/>
    <path d='M7.068 13.9531L7.25882 14.5254L7.86204 14.53C7.94017 14.5307 7.97249 14.6303 7.9097 14.6767L7.42445 15.0351L7.60648 15.6102C7.63011 15.6846 7.54525 15.7463 7.48167 15.7008L6.99095 15.35L6.50023 15.7008C6.43666 15.7462 6.35199 15.6846 6.37542 15.6102L6.55746 15.0351L6.0722 14.6767C6.00941 14.6302 6.04173 14.5307 6.11986 14.53L6.72308 14.5254L6.9139 13.9531C6.93851 13.8789 7.04329 13.8789 7.068 13.9531Z' fill='#F5F5F5'/>
    <path d='M9.39173 3.44663L9.58255 4.0189L10.1858 4.02349C10.2639 4.02417 10.2962 4.12378 10.2334 4.17016L9.74818 4.52856L9.93021 5.10366C9.95384 5.17807 9.86898 5.23979 9.8054 5.19428L9.31468 4.8435L8.82396 5.19428C8.76039 5.23969 8.67572 5.17807 8.69916 5.10366L8.88119 4.52856L8.39593 4.17016C8.33314 4.12368 8.36546 4.02417 8.44359 4.02349L9.04681 4.0189L9.23763 3.44663C9.26214 3.37261 9.36702 3.37261 9.39173 3.44663Z' fill='#F5F5F5'/>
    <path d='M9.39173 6.44011L9.58255 7.01238L10.1858 7.01697C10.2639 7.01765 10.2962 7.11726 10.2334 7.16364L9.74818 7.52204L9.93021 8.09714C9.95384 8.17155 9.86898 8.23327 9.8054 8.18776L9.31468 7.83698L8.82396 8.18776C8.76039 8.23317 8.67572 8.17165 8.69916 8.09714L8.88119 7.52204L8.39593 7.16364C8.33314 7.11716 8.36546 7.01765 8.44359 7.01697L9.04681 7.01238L9.23763 6.44011C9.26214 6.36599 9.36702 6.36599 9.39173 6.44011Z' fill='#F5F5F5'/>
    <path d='M9.39173 9.43376L9.58255 10.006L10.1858 10.0106C10.2639 10.0113 10.2962 10.1109 10.2334 10.1573L9.74818 10.5157L9.93021 11.0908C9.95384 11.1652 9.86898 11.2269 9.8054 11.1814L9.31468 10.8306L8.82396 11.1814C8.76039 11.2268 8.67572 11.1652 8.69916 11.0908L8.88119 10.5157L8.39593 10.1573C8.33314 10.1108 8.36546 10.0113 8.44359 10.0106L9.04681 10.006L9.23763 9.43376C9.26214 9.35964 9.36702 9.35964 9.39173 9.43376Z' fill='#F5F5F5'/>
    <path d='M9.39173 12.4273L9.58255 12.9996L10.1858 13.0042C10.2639 13.0049 10.2962 13.1045 10.2334 13.1509L9.74818 13.5093L9.93021 14.0844C9.95384 14.1588 9.86898 14.2205 9.8054 14.175L9.31468 13.8242L8.82396 14.175C8.76039 14.2204 8.67572 14.1588 8.69916 14.0844L8.88119 13.5093L8.39593 13.1509C8.33314 13.1044 8.36546 13.0049 8.44359 13.0042L9.04681 12.9996L9.23763 12.4273C9.26214 12.3533 9.36702 12.3533 9.39173 12.4273Z' fill='#F5F5F5'/>
    <path d='M11.7151 1.97893L11.9059 2.55119L12.5091 2.55578C12.5872 2.55647 12.6196 2.65608 12.5568 2.70246L12.0715 3.06086L12.2535 3.63596C12.2772 3.71037 12.1923 3.77209 12.1287 3.72658L11.6379 3.3758L11.1472 3.72658C11.0836 3.77199 10.999 3.71037 11.0224 3.63596L11.2044 3.06086L10.7192 2.70246C10.6564 2.65598 10.6887 2.55647 10.7668 2.55578L11.3701 2.55119L11.5609 1.97893C11.5856 1.90481 11.6904 1.90481 11.7151 1.97893Z' fill='#F5F5F5'/>
    <path d='M11.7151 4.97251L11.9059 5.54477L12.5091 5.54936C12.5872 5.55005 12.6196 5.64966 12.5568 5.69604L12.0715 6.05444L12.2535 6.62954C12.2772 6.70395 12.1923 6.76567 12.1287 6.72016L11.6379 6.36938L11.1472 6.72016C11.0836 6.76557 10.999 6.70395 11.0224 6.62954L11.2044 6.05444L10.7192 5.69604C10.6564 5.64956 10.6887 5.55005 10.7668 5.54936L11.3701 5.54477L11.5609 4.97251C11.5856 4.89849 11.6904 4.89849 11.7151 4.97251Z' fill='#F5F5F5'/>
    <path d='M11.7151 7.96592L11.9059 8.53818L12.5091 8.54277C12.5872 8.54346 12.6196 8.64307 12.5568 8.68945L12.0715 9.04785L12.2535 9.62295C12.2772 9.69736 12.1923 9.75908 12.1287 9.71357L11.6379 9.36279L11.1472 9.71357C11.0836 9.75898 10.999 9.69736 11.0224 9.62295L11.2044 9.04785L10.7192 8.68945C10.6564 8.64297 10.6887 8.54346 10.7668 8.54277L11.3701 8.53818L11.5609 7.96592C11.5856 7.89189 11.6904 7.89189 11.7151 7.96592Z' fill='#F5F5F5'/>
    <path d='M11.7151 10.9596L11.9059 11.5319L12.5091 11.5365C12.5872 11.5372 12.6196 11.6368 12.5568 11.6832L12.0715 12.0416L12.2535 12.6167C12.2772 12.6911 12.1923 12.7528 12.1287 12.7073L11.6379 12.3565L11.1472 12.7073C11.0836 12.7527 10.999 12.6911 11.0224 12.6167L11.2044 12.0416L10.7192 11.6832C10.6564 11.6367 10.6887 11.5372 10.7668 11.5365L11.3701 11.5319L11.5609 10.9596C11.5856 10.8855 11.6904 10.8855 11.7151 10.9596Z' fill='#F5F5F5'/>
    <path d='M11.7151 13.9531L11.9059 14.5254L12.5091 14.53C12.5872 14.5307 12.6196 14.6303 12.5568 14.6767L12.0715 15.0351L12.2535 15.6102C12.2772 15.6846 12.1923 15.7463 12.1287 15.7008L11.6379 15.35L11.1472 15.7008C11.0836 15.7462 10.999 15.6846 11.0224 15.6102L11.2044 15.0351L10.7192 14.6767C10.6564 14.6302 10.6887 14.5307 10.7668 14.53L11.3701 14.5254L11.5609 13.9531C11.5856 13.8789 11.6904 13.8789 11.7151 13.9531Z' fill='#F5F5F5'/>
    <path d='M14.0387 3.44663L14.2295 4.0189L14.8327 4.02349C14.9109 4.02417 14.9432 4.12378 14.8804 4.17016L14.3952 4.52856L14.5772 5.10366C14.6008 5.17807 14.516 5.23979 14.4524 5.19428L13.9617 4.8435L13.4709 5.19428C13.4074 5.23969 13.3227 5.17807 13.3461 5.10366L13.5282 4.52856L13.0429 4.17016C12.9801 4.12368 13.0124 4.02417 13.0906 4.02349L13.6938 4.0189L13.8846 3.44663C13.9093 3.37261 14.014 3.37261 14.0387 3.44663Z' fill='#F5F5F5'/>
    <path d='M14.0387 6.44011L14.2295 7.01238L14.8327 7.01697C14.9109 7.01765 14.9432 7.11726 14.8804 7.16364L14.3952 7.52204L14.5772 8.09714C14.6008 8.17155 14.516 8.23327 14.4524 8.18776L13.9617 7.83698L13.4709 8.18776C13.4074 8.23317 13.3227 8.17165 13.3461 8.09714L13.5282 7.52204L13.0429 7.16364C12.9801 7.11716 13.0124 7.01765 13.0906 7.01697L13.6938 7.01238L13.8846 6.44011C13.9093 6.36599 14.014 6.36599 14.0387 6.44011Z' fill='#F5F5F5'/>
    <path d='M14.0387 9.43376L14.2295 10.006L14.8327 10.0106C14.9109 10.0113 14.9432 10.1109 14.8804 10.1573L14.3952 10.5157L14.5772 11.0908C14.6008 11.1652 14.516 11.2269 14.4524 11.1814L13.9617 10.8306L13.4709 11.1814C13.4074 11.2268 13.3227 11.1652 13.3461 11.0908L13.5282 10.5157L13.0429 10.1573C12.9801 10.1108 13.0124 10.0113 13.0906 10.0106L13.6938 10.006L13.8846 9.43376C13.9093 9.35964 14.014 9.35964 14.0387 9.43376Z' fill='#F5F5F5'/>
    <path d='M14.0387 12.4273L14.2295 12.9996L14.8327 13.0042C14.9109 13.0049 14.9432 13.1045 14.8804 13.1509L14.3952 13.5093L14.5772 14.0844C14.6008 14.1588 14.516 14.2205 14.4524 14.175L13.9617 13.8242L13.4709 14.175C13.4074 14.2204 13.3227 14.1588 13.3461 14.0844L13.5282 13.5093L13.0429 13.1509C12.9801 13.1044 13.0124 13.0049 13.0906 13.0042L13.6938 12.9996L13.8846 12.4273C13.9093 12.3533 14.014 12.3533 14.0387 12.4273Z' fill='#F5F5F5'/>
    <path d='M16.3624 1.97893L16.5533 2.55119L17.1565 2.55578C17.2346 2.55647 17.2669 2.65608 17.2041 2.70246L16.7189 3.06086L16.9009 3.63596C16.9245 3.71037 16.8397 3.77209 16.7761 3.72658L16.2854 3.3758L15.7947 3.72658C15.7311 3.77199 15.6464 3.71037 15.6699 3.63596L15.8519 3.06086L15.3666 2.70246C15.3038 2.65598 15.3362 2.55647 15.4143 2.55578L16.0175 2.55119L16.2083 1.97893C16.2328 1.90481 16.3377 1.90481 16.3624 1.97893Z' fill='#F5F5F5'/>
    <path d='M16.3624 4.97251L16.5533 5.54477L17.1565 5.54936C17.2346 5.55005 17.2669 5.64966 17.2041 5.69604L16.7189 6.05444L16.9009 6.62954C16.9245 6.70395 16.8397 6.76567 16.7761 6.72016L16.2854 6.36938L15.7947 6.72016C15.7311 6.76557 15.6464 6.70395 15.6699 6.62954L15.8519 6.05444L15.3666 5.69604C15.3038 5.64956 15.3362 5.55005 15.4143 5.54936L16.0175 5.54477L16.2083 4.97251C16.2328 4.89849 16.3377 4.89849 16.3624 4.97251Z' fill='#F5F5F5'/>
    <path d='M16.3624 7.96592L16.5533 8.53818L17.1565 8.54277C17.2346 8.54346 17.2669 8.64307 17.2041 8.68945L16.7189 9.04785L16.9009 9.62295C16.9245 9.69736 16.8397 9.75908 16.7761 9.71357L16.2854 9.36279L15.7947 9.71357C15.7311 9.75898 15.6464 9.69736 15.6699 9.62295L15.8519 9.04785L15.3666 8.68945C15.3038 8.64297 15.3362 8.54346 15.4143 8.54277L16.0175 8.53818L16.2083 7.96592C16.2328 7.89189 16.3377 7.89189 16.3624 7.96592Z' fill='#F5F5F5'/>
    <path d='M16.3624 10.9596L16.5533 11.5319L17.1565 11.5365C17.2346 11.5372 17.2669 11.6368 17.2041 11.6832L16.7189 12.0416L16.9009 12.6167C16.9245 12.6911 16.8397 12.7528 16.7761 12.7073L16.2854 12.3565L15.7947 12.7073C15.7311 12.7527 15.6464 12.6911 15.6699 12.6167L15.8519 12.0416L15.3666 11.6832C15.3038 11.6367 15.3362 11.5372 15.4143 11.5365L16.0175 11.5319L16.2083 10.9596C16.2328 10.8855 16.3377 10.8855 16.3624 10.9596Z' fill='#F5F5F5'/>
    <path d='M16.3624 13.9531L16.5533 14.5254L17.1565 14.53C17.2346 14.5307 17.2669 14.6303 17.2041 14.6767L16.7189 15.0351L16.9009 15.6102C16.9245 15.6846 16.8397 15.7463 16.7761 15.7008L16.2854 15.35L15.7947 15.7008C15.7311 15.7462 15.6464 15.6846 15.6699 15.6102L15.8519 15.0351L15.3666 14.6767C15.3038 14.6302 15.3362 14.5307 15.4143 14.53L16.0175 14.5254L16.2083 13.9531C16.2328 13.8789 16.3377 13.8789 16.3624 13.9531Z' fill='#F5F5F5'/>
    <path d='M18.6862 3.44663L18.877 4.0189L19.4802 4.02349C19.5583 4.02417 19.5907 4.12378 19.5279 4.17016L19.0426 4.52856L19.2246 5.10366C19.2483 5.17807 19.1634 5.23979 19.0998 5.19428L18.6091 4.8435L18.1184 5.19428C18.0548 5.23969 17.9702 5.17807 17.9936 5.10366L18.1756 4.52856L17.6904 4.17016C17.6276 4.12368 17.6599 4.02417 17.738 4.02349L18.3412 4.0189L18.5321 3.44663C18.5567 3.37261 18.6616 3.37261 18.6862 3.44663Z' fill='#F5F5F5'/>
    <path d='M18.6862 6.44011L18.877 7.01238L19.4802 7.01697C19.5583 7.01765 19.5907 7.11726 19.5279 7.16364L19.0426 7.52204L19.2246 8.09714C19.2483 8.17155 19.1634 8.23327 19.0998 8.18776L18.6091 7.83698L18.1184 8.18776C18.0548 8.23317 17.9702 8.17165 17.9936 8.09714L18.1756 7.52204L17.6904 7.16364C17.6276 7.11716 17.6599 7.01765 17.738 7.01697L18.3412 7.01238L18.5321 6.44011C18.5567 6.36599 18.6616 6.36599 18.6862 6.44011Z' fill='#F5F5F5'/>
    <path d='M18.6862 9.43376L18.877 10.006L19.4802 10.0106C19.5583 10.0113 19.5907 10.1109 19.5279 10.1573L19.0426 10.5157L19.2246 11.0908C19.2483 11.1652 19.1634 11.2269 19.0998 11.1814L18.6091 10.8306L18.1184 11.1814C18.0548 11.2268 17.9702 11.1652 17.9936 11.0908L18.1756 10.5157L17.6904 10.1573C17.6276 10.1108 17.6599 10.0113 17.738 10.0106L18.3412 10.006L18.5321 9.43376C18.5567 9.35964 18.6616 9.35964 18.6862 9.43376Z' fill='#F5F5F5'/>
    <path d='M18.6862 12.4273L18.877 12.9996L19.4802 13.0042C19.5583 13.0049 19.5907 13.1045 19.5279 13.1509L19.0426 13.5093L19.2246 14.0844C19.2483 14.1588 19.1634 14.2205 19.0998 14.175L18.6091 13.8242L18.1184 14.175C18.0548 14.2204 17.9702 14.1588 17.9936 14.0844L18.1756 13.5093L17.6904 13.1509C17.6276 13.1044 17.6599 13.0049 17.738 13.0042L18.3412 12.9996L18.5321 12.4273C18.5567 12.3533 18.6616 12.3533 18.6862 12.4273Z' fill='#F5F5F5'/>
    <path d='M21.0094 1.97893L21.2002 2.55119L21.8034 2.55578C21.8816 2.55647 21.9139 2.65608 21.8511 2.70246L21.3659 3.06086L21.5479 3.63596C21.5715 3.71037 21.4867 3.77209 21.4231 3.72658L20.9324 3.3758L20.4416 3.72658C20.3781 3.77199 20.2934 3.71037 20.3168 3.63596L20.4989 3.06086L20.0136 2.70246C19.9508 2.65598 19.9831 2.55647 20.0613 2.55578L20.6645 2.55119L20.8553 1.97893C20.8799 1.90481 20.9846 1.90481 21.0094 1.97893Z' fill='#F5F5F5'/>
    <path d='M21.0094 4.97251L21.2002 5.54477L21.8034 5.54936C21.8816 5.55005 21.9139 5.64966 21.8511 5.69604L21.3659 6.05444L21.5479 6.62954C21.5715 6.70395 21.4867 6.76567 21.4231 6.72016L20.9324 6.36938L20.4416 6.72016C20.3781 6.76557 20.2934 6.70395 20.3168 6.62954L20.4989 6.05444L20.0136 5.69604C19.9508 5.64956 19.9831 5.55005 20.0613 5.54936L20.6645 5.54477L20.8553 4.97251C20.8799 4.89849 20.9846 4.89849 21.0094 4.97251Z' fill='#F5F5F5'/>
    <path d='M21.0094 7.96592L21.2002 8.53818L21.8034 8.54277C21.8816 8.54346 21.9139 8.64307 21.8511 8.68945L21.3659 9.04785L21.5479 9.62295C21.5715 9.69736 21.4867 9.75908 21.4231 9.71357L20.9324 9.36279L20.4416 9.71357C20.3781 9.75898 20.2934 9.69736 20.3168 9.62295L20.4989 9.04785L20.0136 8.68945C19.9508 8.64297 19.9831 8.54346 20.0613 8.54277L20.6645 8.53818L20.8553 7.96592C20.8799 7.89189 20.9846 7.89189 21.0094 7.96592Z' fill='#F5F5F5'/>
    <path d='M21.0094 10.9596L21.2002 11.5319L21.8034 11.5365C21.8816 11.5372 21.9139 11.6368 21.8511 11.6832L21.3659 12.0416L21.5479 12.6167C21.5715 12.6911 21.4867 12.7528 21.4231 12.7073L20.9324 12.3565L20.4416 12.7073C20.3781 12.7527 20.2934 12.6911 20.3168 12.6167L20.4989 12.0416L20.0136 11.6832C19.9508 11.6367 19.9831 11.5372 20.0613 11.5365L20.6645 11.5319L20.8553 10.9596C20.8799 10.8855 20.9846 10.8855 21.0094 10.9596Z' fill='#F5F5F5'/>
    <path d='M21.0094 13.9531L21.2002 14.5254L21.8034 14.53C21.8816 14.5307 21.9139 14.6303 21.8511 14.6767L21.3659 15.0351L21.5479 15.6102C21.5715 15.6846 21.4867 15.7463 21.4231 15.7008L20.9324 15.35L20.4416 15.7008C20.3781 15.7462 20.2934 15.6846 20.3168 15.6102L20.4989 15.0351L20.0136 14.6767C19.9508 14.6302 19.9831 14.5307 20.0613 14.53L20.6645 14.5254L20.8553 13.9531C20.8799 13.8789 20.9846 13.8789 21.0094 13.9531Z' fill='#F5F5F5'/>
  </svg>
);
