import { Player } from '@lottiefiles/react-lottie-player';
import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from 'shared/ui';
import { Card } from '../components';
import { IAddNewBotProps } from '../interfaces';
import { inner, modal, modalContent, title, wrapper } from '../styles';

export const AddNewBot = (props: IAddNewBotProps) => {
  const {
    isOpen,
    closeModal, 
  } = props;

  const navigate = useNavigate();
  const {
    t, 
  } = useTranslation();

  const handleNavigate = (route: string) => {
    return () => {
      closeModal();
      navigate(route);
    };
  };

  return (
    <Modal
      open={isOpen}
      footer={null}
      closeIcon={false}
      style={modal}
      styles={modalContent}
      centered={true}
      closable={true}
      destroyOnClose={true}
      onCancel={closeModal}
      onOk={closeModal}
      width='100%'
    >
      <Box sx={wrapper}>
        <Text styles={title}>
          {t('modal.addBot.title')}
        </Text>

        <Box sx={inner}>
          <Card
            image='/images/create-bot/copy-bot.png'
            title={t('modal.addBot.copy.title')}
            description={t('modal.addBot.copy.description')}
            onClickHandler={handleNavigate('/trading-bots/marketplace')}
          />

          <Card
            image='/images/create-bot/alert-bot.png'
            title={t('modal.addBot.alert.title')}
            description={t('modal.addBot.alert.description')}
            onClickHandler={handleNavigate('/trading-bots/create')}
          >
            <Player
              autoplay
              loop
              src='/videos/alert-bot.json'
              style={{
                width: '100%',
                height: 165,
                maxWidth: 365,
              }}
            />
          </Card>
        </Box>
      </Box>
    </Modal>
  );
};
