import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'widgets';
import { Table } from 'shared/ui';
import { columns } from '../../consts/columns';

interface WalletTableProps {
  userWalletBalances: any[];
  chosenExchange: any;
  dailyChange: any;
}

export const WalletTable = (props: WalletTableProps) => {
  const {
    userWalletBalances,
    chosenExchange,
    dailyChange,
  } = props;

  const {
    t, 
  } = useTranslation();

  return (
    <Table
      itemsCount={5}
      columns={columns(t)}
      items={userWalletBalances ?? []}
      graphic={(
        <Box
          display='block'
          width={209}
        >
          <PieChart
            data={userWalletBalances?.map((balance) => ({
              value: balance.usdt.total,
              amountForTooltip: balance.total,
              name: balance.symbol,
              totalUsdt: balance.usdt.total,
              totalBtc: balance.btc.total,
              overallBtcValue: chosenExchange?.totalBtc,
              overallUsdtValue: chosenExchange?.totalUsdt,
              overallBtcShare: (+balance.btc.total / +chosenExchange?.totalBtc) * 100,
              overallUsdtShare: (+balance.usdt.total / +chosenExchange?.totalUsdt) * 100,
            })) ?? []}
            totalBtc={chosenExchange?.totalBtc}
            totalUsdt={chosenExchange?.totalUsdt}
            usdtDailyChangePercent={
              chosenExchange?.balances?.length 
                ? dailyChange?.usdtDailyChangePercent 
                : '0'
            }
            btcDailyChangePercent={
              chosenExchange?.balances?.length 
                ? dailyChange?.usdtDailyChangePercent 
                : '0'
            }
          />
        </Box>
      )}
    />
  );
};
