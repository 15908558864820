import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CloseSecondaryIcon } from 'shared/icons';
import { Text } from 'shared/ui';
import { closeWrapper, inner, item, itemTitle, itemValue, title, wrapper } from '../styles';

interface ISafetyOrdersProps {
  additionalEntries: any[];
  onRemoveEntry: (index: number) => void;
}
export const SafetyOrders = (props: ISafetyOrdersProps) => {
  const {
    additionalEntries,
    onRemoveEntry, 
  } = props;

  const {
    watch, 
  } = useFormContext();
  const {
    t, 
  } = useTranslation();

  const currentSymbol = watch('currentSymbol');
  
  return (
    <Box sx={wrapper}>
      <Text
        styles={title}
        type='success'
      >
        {t('terminal.panel.additional.table.title')}
      </Text>

      {additionalEntries.length > 0 && (
        <Box sx={inner}>
          <Box sx={item}>
            <Text styles={itemTitle}>
              {t('terminal.panel.additional.table.columns.price')}
            </Text>

            <Text styles={itemTitle}>
              {t('terminal.panel.additional.table.columns.total')}
            </Text>
          </Box>

          {additionalEntries?.map((entry, index) => (
            <Box
              sx={item}
              key={index}
            >
              <Text styles={itemValue}>
                {`${entry.price} ${currentSymbol.quoteAsset}`}
              </Text>
                
              <Text styles={itemValue}>
                {`${entry.total} ${currentSymbol.quoteAsset}`}
              </Text>

              {!entry?.isCompleted && (
                <div
                  style={closeWrapper}
                  onClick={() => onRemoveEntry(index)}
                >
                  {CloseSecondaryIcon}
                </div>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
