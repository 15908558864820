import { Stack } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { IStepsProps } from '../interfaces';
import { STEP, TEXT, TITLE, WRAPPER } from '../styles';

export const Steps = (props: IStepsProps) => {
  return (
    <Stack sx={WRAPPER}>
      {props.items.map((item, index) => {
        return (
          <Stack
            key={item}
            sx={STEP}
          >
            <Title styles={TITLE}>
              {++index}
            </Title>

            <Text styles={TEXT}>
              {item}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};
