import { Stack } from '@mui/material';
import { Text, Title } from 'shared/ui';
import { ITextBlockProps } from '../interfaces';
import { TEXT, TITLE, WRAPPER } from '../styles';

export const TextBlock = (props: ITextBlockProps) => {
  const {
    title,
    text,
  } = props;

  return (
    <Stack sx={WRAPPER}>
      <Title styles={TITLE}>
        {title}
      </Title>

      <Text styles={TEXT}>
        {text}
      </Text>
    </Stack>
  );
};
