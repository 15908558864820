import dayjs from 'dayjs';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          authTitle: {
            register: 'Great choice!',
            other: 'Pro-level trading made simple',
          },
          authDescription: 'Unlock your trading potential with trading bots and multi-order terminal on major exchanges',
          copyRight: 'All rights reserved',
          error: {
            login: {
              failed: {
                title: 'Activation failed',
                text: 'An error occurred during account activation. Please contact support',
              },
              'account is not activated': 'account is not activated',
              'incorrect login or password': 'incorrect login or password',
              'account is deleted': 'account is deleted',
            },
            register: {
              'Oops! Account already exists': 'Oops! Account already exists',
              'Oops! There is no such referral code': 'Oops! There is no such referral code',
              'You already have an account, Check email to activate it': 'You already have an account. Check email to activate it',
              'Oops, try again': 'Oops, try again',
            },
            twoFactor: {
              empty: {
                title: '',
                message: 'Email or password is missing. Try to login once again',
              },
              generate: {
                title: 'Failed to generate 2FA',
                message: 'Something went wrong. Try again or contact support',
                button: 'Okay',
              },
              disable: {
                title: 'Failed to disable 2FA',
                message: '',
                button: 'Okay',
              },
              enable: {
                title: 'Failed to enable 2FA',
                message: '',
                button: 'Okay',
              },
            },
            wrong: {
              title: 'Something went wrong',
              message: 'We will fix it soon, try again later or contact support',
              button: 'Ok',
              recover: {
                message: 'Ahh, the service is temporarily unavailable. The fix team is on the way! Take a deep breath and try to reload the page in few minutes',
              },
              account: {
                message: 'We will fix it soon, try again later or contact support',
              },
            },
            otp: {
              incorrect: {
                title: 'OTP is incorrect',
                message: 'Please check the code and try again',
              },
              ownership: {
                title: 'Verify account ownership',
                button: 'Okay',
                message: {
                  preffix: 'Contact support at',
                  suffix: 'to proceed with 2FA reset.',
                },
              },
            },
            exchange: {
              connect: {
                'Only one active demo account is available per user': 'Only one active demo account is available per user',
                'There is no active skyrex subscription': 'There is no active Skyrexio subscription',
                'Maximum number of active accounts reached. Please upgrade subscription': 'Maximum connected exchange accounts is reached',
                'failed to create account': 'failed to create account',
              },
              demo: {
                enable: {
                  title: 'Cannot enable demo mode',
                },
                disable: {
                  title: 'Failed to disable demo mode',
                },
                create: {
                  title: 'Cannot create demo account',
                },
                delete: {
                  title: 'Cannot delete account',
                },
                reset: {
                  title: 'Cannot reset demo account',
                },
              },
              create: {
                title: 'Failed to connect',
                message: 'Check API keys, permissions, IP whitelist or contact support',
              },
              update: {
                title: 'Failed to update',
                message: {
                  'Cannot update API keys because they belong to other exchange account': 'API keys belong to another exchange account',
                },
              },
              list: {
                failed: {
                  title: 'Connection failed',
                  message: {
                    'failed to create account': 'failed to create account',
                    'Maximum number of active accounts reached. Please upgrade subscription': 'Maximum number of active accounts reached. Please upgrade subscription',
                  },
                  button: 'Okay',
                },
                delete: {
                  title: 'Deletion failed',
                  message: {
                    'Cannot delete account because bots are not deleted': 'Cannot delete account because bots are not deleted',
                    'Cannot delete account because there are active positions': 'Cannot delete account because there are active positions',
                  },
                  button: 'Okay',
                },
              },
              connected: {
                empty: {
                  title: 'Account is empty',
                  text: {
                    bybit: 'Convert to Unified account, update API or deposit',
                    other: 'Update API keys or deposit funds to continue trading',
                  },
                },
              },
            },
            bots: {
              update: {
                title: 'Failed to update bot',
                description: 'We will fix it soon, try again later or contact support',
                button: 'Ok',
              },
              delete: {
                title: 'Failed to delete bot',
                description: 'We will fix it soon, try again later or contact support',
                button: 'Ok',
              },
              close: {
                deals: {
                  title: 'Failed to close deals',
                  description: 'We will fix it soon, try again later or contact support',
                  button: 'Ok',
                },
                trades: {
                  title: 'Failed to close trades',
                  description: 'We will fix it soon, try again later or contact support',
                  button: 'Ok',
                },
              },
              cancel: {
                trades: {
                  title: 'Failed to cancel trades',
                  description: 'We will fix it soon, try again later or contact support',
                  button: 'Ok',
                },
              },
              start: {
                title: 'Failed to start the bot',
                description: 'We will fix it soon, try again later or contact support',
                button: 'Ok',
              },
              stop: {
                title: 'Failed to stop the bot',
                description: 'We will fix it soon, try again later or contact support',
                button: 'Ok',
              },
              status: {
                'There are pending success fee payments. Please pay the fees to turn on the bot': {
                  title: 'Some of your bots await profit sharing',
                  description: 'Please complete all pending payments to turn on the bot',
                  button: 'Subscriptions',
                },
                'Sharing option is limited. Please upgrade subscription': {
                  title: 'Sharing is not available',
                  description: 'Edit bot settings or upgrade Skyrexio plan',
                  button: 'Upgrade',
                },
                'Maximum number of active bots reached. Please upgrade subscription': {
                  title: 'Maximum number of bots reached',
                  description: 'Please upgrade your Skyrexio subscription',
                  button: 'Upgrade',
                },
                'There is no active success subscription': {
                  title: 'There is no active subscription',
                  description: 'Please activate subscription to turn on the bot',
                  button: 'Subscribe',
                },
                'No skyrex subscription found': {
                  title: 'No Skyrexio subscription found',
                  description: '',
                  button: 'Subscribe',
                },
              },
              alert: {
                title: 'Oops! You have no alert bots',
                description: 'Create an alert bot, to publish it in the marketplace',
                button: 'Okay',
              },
            },
            copyBot: {
              demo: 'Bot has no demo mode',
            },
            subscription: {
              downgrade: {
                title: 'Can’t downgrade',
                message: 'Subscription can’t be downgraded please choose higher plan to upgrade',
                button: 'Ok',
              },
            },
            payment: {
              promo: {
                message: 'Wrong code',
              },
            },
            changePassword: {
              title: 'Failed to update password',
              message: '',
              button: 'Okay',
            },
            withdraw: {
              balance: 'Insufficient balance',
            },
            funds: {
              copy: 'Failed to copy link',
            },
            bot: {
              close: {
                title: 'Close Failed',
                description: 'Couldn\'t close trades. Try again or contact support',
                button: 'Ok',
              },
              delete: {
                title: 'Deletion Failed',
                description: 'Unable to delete bot. Please try again or contact support',
                button: 'Ok',
              },
              update: {
                title: 'Update Failed',
                description: 'Bot update unsuccessful. Please try again or contact support',
                button: 'Ok',
              },
            },
          },
          success: {
            login: {
              title: 'Account is activated',
              message: 'You can now log in to your account',
            },
            register: {
              title: 'We`ve sent you an email!',
              message: 'Almost there! Check your email to activate account',
            },
            recover: {
              title: 'We`ve sent you an email!',
              message: 'If there\'s an account associated with this email, you\'ll receive a recovery link shortly. Please check your inbox and follow the instructions to reset your password.',
            },
            changePassword: {
              title: 'Password is updated',
              message: 'Now you can login with new password',
              button: 'Okay',
            },
            exchange: {
              connect: {
                title: 'Account connected',
                message: 'Create or copy your first trading bot or smart terminal position',
                button: 'Okay',
              },
              update: {
                title: 'Exchange account updated successfully',
                message: 'Now you are in one click to start trading bot or create manual trade using your account',
              },
              list: {
                connected: {
                  title: 'Account connected',
                  message: 'Pro-level trading with limitless opportunities is just a click away!',
                  button: 'Ok',
                },
                delete: {
                  title: 'Account is deleted',
                  message: 'No worries, it\'s just a link to your account. Create a new one!',
                  button: 'Okay',
                },
                completed: {
                  title: 'Request completed',
                  message: 'Crypto.com API issued. Contact support if account isn’t linked',
                  button: 'Okay',
                },
              },
              demo: {
                create: {
                  title: 'Account is re-created',
                  button: 'Okay',
                },
              },
            },
            payment: {
              title: 'A bit patience',
              message: 'Your payment will be verified in few minutes after CoinPayments confirmation',
              button: 'Okay',
            },
            twoFactor: {
              disable: 'Google authenticator disabled',
              enable: 'Google authenticator enabled',
              button: 'Okay',
            },
            withdraw: {
              success: 'Payout created',
            },
            funds: {
              copy: 'Link copied to clipboard',
            },
          },
          validation: {
            email: {
              required: 'Please input your e-mail!',
              format: 'Email should be in a correct format.',
            },
            password: {
              required: 'Please input your Password!',
              format: 'Password should contain a minimum of 6 characters.',
            },
            otp: {
              reuqired: 'Please enter your OTP',
              format: 'OTP must be a 6-digit number',
            },
            withdraw: {
              amount: {
                required: 'Amount is required',
                number: 'Should be number',
                greater: 'Should be greater than 10 USDT',
                lower: 'Should be lower or equal than available',
              },
              wallet: {
                required: 'Wallet is required',
                existing: 'Your TRC-20 wallet address',
              },
            },
            changePassword: {
              match: 'Passwords don’t match!',
            },
            funds: {
              price: {
                trigger: {
                  required: 'This field is required',
                  number: 'Value should be a number',
                  min: 'Minimum value is {{priceMin}}',
                  max: 'Maximum value is {{priceMax}}',
                },
                origin: {
                  required: 'This field is required',
                  number: 'Value should be a number',
                  min: 'Minimum value is {{priceMin}}',
                  max: 'Maximum value is {{priceMax}}',
                },
              },
              units: {
                required: 'This field is required',
                number: 'Value should be a number',
                min: 'Minimum value is {{lotMin}}',
                max: 'Maximum value is {{lotMax}}',
              },
              total: {
                required: 'This field is required',
                balance: 'Insufficient balance',
                number: 'Value should be a number',
                min: 'Minimum value is {{minNotional}}',
                max: 'Maximum value is {{maxNotional}}',
              },
              slider: {
                min: 'Should be more than 0%',
                max: 'Cannot be more than 100%',
              },
            },
            bot: {
              update: {
                name: {
                  required: 'Please input the bot name',
                  more: 'Bot name cannot be more than 20 characters.',
                },
                balance: {
                  required: 'Please input the trading balance',
                  less: 'Trading balance cannot be less than {{minInvestment}}',
                },
              },
            },
          },
          modal: {
            exchange: {
              connect: {
                success: {
                  title: 'Account connected',
                  message: 'Create or copy your first trading bot or smart terminal position',
                  button: 'Okay',
                },
                error: {
                  connection: {
                    message: 'Maximum connected exchange accounts is reached',
                  },
                },
              },
              list: {
                delete: {
                  title: 'Delete account?',
                  message: 'Ensure related bots are deleted and trades are completed',
                  button: 'Delete',
                },
              },
            },
            terminal: {
              funds: {
                add: {
                  title: 'Add Funds',
                  description: '',
                },
                reduce: {
                  title: 'Reduce Funds',
                  description: '',
                },
              },
              close: {
                description: {
                  buy: 'Current price: {{price}} {{quote}} \n Return: {{returnQuote}} {{quote}} ({{returnPercent}}%)',
                  other: 'Current price: {{price}} {{quote}} \n Return: {{returnBase}} {{baseSymbol}} ({{returnPercent}}%)',
                },
              },
              open: {
                description: 'Current price: {{price}} {{quote}}',
              },
              market: {
                close: {
                  title: 'Close by market?',
                },
                open: {
                  title: 'Open by market?',
                },
              },
              limit: {
                close: {
                  title: 'Close by limit?',
                },
                open: {
                  title: 'Open by limit?',
                },
              },
              edit: {
                title: 'Edit position',
                description: 'Edit position',
              },
              share: {
                title: 'Share',
                description: 'Your share link',
              },
              cancel: {
                title: 'Cancel position?',
                description: 'Trade will be canceled on Skyrexio, while assets stay untouched on your exchange account',
              },
              button: 'Confirm',
            },
            bots: {
              confirm: {
                delete: {
                  title: 'Are you sure you want to delete the bot?',
                  description: 'All active trades will be closed',
                  button: 'Delete',
                },
                cancel: {
                  title: 'Are you sure you want to cancel all trades of the bot?',
                  description: 'All active trades will be canceled on Skyrexio, but assets will remain unaffected in exchange account',
                  button: 'Okay',
                },
                close: {
                  trades: {
                    title: 'Are you sure you want to close all trades of the bot by market orders?',
                    description: 'All active trades will be closed using market orders',
                    button: 'Okay',
                  },
                },
              },
              market: {
                title: 'Choose market from your alert bots',
                select: {
                  title: 'Market',
                  placeholder: 'Choose market for your item',
                },
                button: 'Next step',
              },
              parameters: {
                title: 'Set marketplace item parameters',
                bots: {
                  title: 'Choose bots',
                  palceholder: 'Each bot adds avilable exchange to your item',
                },
                amount: {
                  title: 'Set minimum amount',
                  palceholder: 'Minimum amount to copy the bot',
                },
                name: {
                  title: 'Marketplace item name',
                  palceholder: 'Name of the bot card',
                },
                description: {
                  title: 'Description (max 200 symbols)',
                  palceholder: 'Short description of the bot strategy',
                },
                author: {
                  title: 'Author',
                  palceholder: 'Name of creator',
                },
                profit: {
                  title: 'Profit sharing, %',
                  palceholder: 'Percentage of monthly earnings shared to you',
                },
                segments: {
                  paid: 'Paid',
                  free: 'Free',
                },
                actions: {
                  publish: 'Publish',
                  save: 'Save changes',
                },
              },
              eligible: {
                title: 'Bot does not meet all criteria to be shared at Marketplace',
                header: {
                  criteria: 'Criteria',
                  kpi: 'KPI',
                  now: 'Now',
                  status: 'Status',
                },
                button: 'Okay',
              },
            },
            copyBot: {
              exchange: {
                title: 'Choose your account',
                placeholder: 'Choose from connected accounts',
              },
              name: {
                title: 'Name your bot',
                placeholder: 'My perfect bot',
              },
              amount: {
                title: 'Trading amount (available: {{balance}} {{quote}})',
                placeholder: 'Set amount (minimum: {{minInvestment}} {{quote}})',
                tooltip: 'This amount will be managed by bot. Make sure you have that amount on selected exchange account',
              },
              button: 'Copy Bot',
              statistic: {
                title: 'Statistic',
                items: {
                  roi: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active',
                  drawdown: 'Currently open deals. Please note: this statistic is affected by any filters you have active',
                  trades: 'Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals. Please note: this statistic is affected by any filters you have active',
                  profit: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active',
                  amount: 'Current profit or loss total for any active deals your have open. Please note, this statistic is affected by any filters you have active',
                },
                table: {
                  title: 'Trading history',
                  search: {
                    placeholder: 'Search by pair',
                  },
                  columns: {
                    pair: 'Pair',
                    date: {
                      create: 'Create date',
                      close: 'Close date',
                    },
                    status: 'Status',
                    price: {
                      entry: 'Entry price',
                      close: 'Close price',
                    },
                    return: 'Return %',
                  },
                },
              },
            },
            trial: {
              title: '7-day trial activated',
              description: 'Thank you for choosing Skyrexio! During trial, you’ll enjoy:',
              items: {
                accounts: {
                  title: 'Unlimited exchange accounts',
                  description: 'Connect all accounts and track agregate porfolio',
                },
                bots: {
                  title: 'All type of bots',
                  description: 'Skyrexio AI, Community and Alert bots with Public mode',
                },
                terminal: {
                  title: 'Multi-order trading terminal',
                  description: 'Create, explore and edit  unlimited multi-order positions',
                },
              },
            },
            subscription: {
              title: 'Order details',
              aggrement: {
                prefix: 'By making purchase, you agree to',
                and: 'and',
                link: {
                  terms: 'Terms of use',
                  policy: 'Refund Policy',
                },
              },
              button: 'Purchase',
              confirm: {
                end: {
                  title: 'End subscription?',
                  description: 'Profit-sharing will be calculated at the current moment results',
                  button: 'Confirm',
                },
              },
            },
            payment: {
              title: '{{planName}} Plan',
              notify: 'Your {{finishedDate}} days left in {{planName}} plan will be converted into <strong>{{days}} days</strong> of {{planName}} plan after purchase',
              choose: 'Choose a payment method',
            },
            plans: {
              title: 'Choose your plan',
              segments: {
                monthly: 'Monthly',
                annually: 'Annually {{percent}}%',
              },
              items: {
                subTitle: 'What’s included',
                basic: {
                  title: 'basic',
                  description: 'Enjoy essential features with no subcription fee',
                  price: 'Free',
                  included: {
                    title: 'What’s included',
                    items: [
                      '{{smartBotCommissionPercent}}% sharing fee on Skyrexio bots',
                      '{{exchangeAccountsConnectedLimit}} exchange account connected',
                      '{{activeSmartTradesLimit}} active Smart Terminal position',
                      '{{activeBotsLimit}} active copy / alert bot',
                      'Bot configurator',
                      'Demo account',
                      'Real-time portfolio dashboard',
                      '24 hours response support',
                    ],
                  },
                },
                advanced: {
                  title: 'Advanced',
                  description: 'Extend usage limits and share your bots at Marketplace',
                  included: {
                    title: 'What’s included',
                    items: [
                      'Everything from basic',
                      null,
                      '{{exchangeAccountsConnectedLimit}} exchange accounts connected',
                      '{{activeSmartTradesLimit}} active Smart Terminal positions',
                      '{{activeBotsLimit}} active copy / alert bots',
                      'Access to sharing bots at Marketplace ',
                      'Real-time support',
                    ],
                  },
                },
                pro: {
                  title: 'Pro',
                  label: 'Best value',
                  description: 'Ultimate tools, reduced Skyrexio bot fee, private PineScript strategies',
                  included: {
                    items: [
                      'Everything from Basic and Advanced',
                      null,
                      '{{smartBotCommissionPercent}}% sharing fee on Skyrexio bots',
                      '{{exchangeAccountsConnectedLimit}} exchange accounts connected',
                      '{{activeSmartTradesLimit}} active Smart Terminal positions',
                      '{{activeBotsLimit}} active copy / alert bots',
                      'Access to Private TradingView strategies',
                      'Premium dedicated support',
                    ],
                  },
                },
              },
              button: {
                trial: 'Starts after Trial',
                current: 'Current Plan',
                upgrade: 'Upgrade Plan',
                subscribe: 'Subscribe',
              },
            },
            withdraw: {
              title: 'Withdrawal request',
              description: 'Provide TRC-20 wallet address only',
              amount: {
                title: 'Amount to withdraw (available: {{available}} USDT)',
                placeholder: 'Enter amount (min. 10)',
              },
              wallet: {
                title: 'TRC-20 wallet',
                placeholder: 'Your TRC-20 wallet address',
              },
              button: 'Submit',
            },
            twoFactor: {
              title: 'Connect Google authenticator',
              description: 'Don’t forget to back up your secret key',
              opt: {
                title: 'Enter OTP  to confirm connection',
                placeholder: '123456',
              },
              button: {
                cancel: 'Cancel',
                ok: 'Okay',
              },
            },
            twoFactorDisable: {
              title: 'Disable 2FA',
              opt: {
                title: 'Enter OTP  to confirm',
                placeholder: '',
              },
              button: {
                reset: 'Reset 2FA',
                ok: 'Okay',
              },
            },
            changePassword: {
              title: 'Change password',
              description: 'Use strong passwords to protect yourself',
              current: {
                title: 'Current password',
                placeholder: 'Enter current password',
              },
              new: {
                title: 'New password',
                placeholder: 'Enter new password',
              },
              confirm: {
                title: 'Password confirmation',
                placeholder: 'Enter password confirmation',
              },
              button: {
                change: 'Change password',
                cancel: 'Cancel',
              },
            },
            onboarding: {
              button: 'Back',
              steps: {
                summary: 'Onboarding summary',
                exchange: 'Connect an exchange',
                trading: 'Start trading',
              },
              welcome: {
                title: 'Welcome',
                description: 'Here is where your trading journey starts. Explore features with our onboarding',
                button: {
                  start: 'Start onboarding',
                  skip: 'Skip onboarding',
                },
              },
              start: {
                title: 'Choose your trading automation style',
                copy: {
                  title: 'Copy bot',
                  description: 'Copy top performing bots with protected profit sharing',
                },
                alert: {
                  title: 'Alert bot',
                  description: 'Turn webhook alerts into automated trades at exchanges',
                },
                terminal: {
                  title: 'Smart terminal',
                  description: 'Create multiple order trades and track live performance',
                },
              },
            },
            addBot: {
              title: 'Choose bot type',
              copy: {
                title: 'Copy bot',
                description: 'Copy top performing bots with protected profit sharing',
              },
              alert: {
                title: 'Alert bot',
                description: 'Turn webhook alerts into automated trades at exchanges',
              },
            },
            addFunds: {
              wallet: {
                title: {
                  buy: 'Buy',
                  sell: 'Sell',
                },
                tooltip: '{{asset}} amount currently \n available on Exchange account',
              },
            },
            shareResult: {
              title: 'Share your trade',
              pair: 'Trading pair',
              roi: 'ROI',
              entry: 'Entry',
              current: 'Current',
              qrcode: 'Referral code:',
              subText: 'Win with Skyrexio.com',
              button: 'Download',
            },
            bot: {
              subscriptions: {
                title: 'Subscriptions',
                columns: {
                  name: 'Name',
                  type: {
                    title: 'Type',
                    items: {
                      copy: 'Copy bot',
                    },
                  },
                  date: {
                    start: 'Date start',
                    end: 'Date end',
                  },
                  fee: 'Fee',
                  status: {
                    title: 'Status',
                    items: {
                      active: 'active',
                      pending: 'pending',
                    },
                  },
                  mark: 'High-water mark',
                  result: 'Result',
                  net: 'Net result',
                  commission: 'Commission',
                  actions: {
                    title: 'Actions',
                    items: {
                      pay: 'Pay',
                    },
                  },
                },
              },
              update: {
                title: 'Update copy bot',
                description: 'Trading amount change will affect only new positions',
              },
            },
            alertSource: {
              title: 'Alert messages',
              description: 'Control your bot with TradingView or other webhook alerts',
              copy: {
                title: 'Webhook URL',
              },
              tabs: {
                indicator: 'Indicator',
                strategy: 'Strategy',
                other: 'Other',
              },
              indicator: {
                title: 'Create alert with the message to open a trade',
                description: 'Replace "entry1" with "entry2", "entry3", etc. and set new alerts for additional entries',
                copy: {
                  title: 'Create alert with the message to close a trade',
                },
              },
              strategy: {
                title: 'Add the parameter to your strategy entry functions',
                description: 'Replace "entry1" with "entry2", "entry3", etc. in the parameter and put it to additional entries',
                copy: {
                  items: {
                    function: 'Add the parameter to your strategy exit or close functions',
                    message: 'Create alert with the message to trigger strategy actions',
                  },
                },
                note: 'Note: replace str.tostring with tostring for PineScript v4 strategies',
              },
              other: {
                table: {
                  headers: {
                    field: 'Field',
                    type: 'Type',
                    description: 'Description',
                  },
                  columns: {
                    base: 'Symbol to trade for example ADA',
                    quote: 'Market of your bot for example USDT',
                    position: '"entry1", "entry2", etc. for entries, "close" for closure',
                    price: 'Execution price, for Market orders use current price',
                    sourceUuid: 'Unique identifier of your bot, see below',
                    secretToken: 'Protection token for your bot, see below',
                    time: 'Current time in UNIX format',
                  },
                },
                copy: {
                  items: {
                    source: 'Source UUID',
                    secret: 'Secret Token',
                    json: {
                      title: 'Send a POST request with JSON body to execute bot action',
                      description: 'Replace "entry1" with "entry2", "entry3", etc. for additional entries, and with “close” to exit trade',
                    },
                  },
                },
                note: 'Note: send requests using your scripts or manually with Postman',
              },
            },
          },
          tradingStatistics: {
            title: 'Trading statistic',
            segments: {
              sum: 'Sum',
              day: 'Day',
              pair: 'Pair',
            },
          },
          aggregateBalance: {
            title: 'Aggregate balance',
            total: 'Total Assets',
            change: 'Change 24h',
          },
          withdrawal: {
            title: 'Create withdrawal request',
            text: 'Your request will be processed within 24 hours',
            button: 'Withdraw',
          },
          form: {
            login: {
              title: 'Welcome back',
              description: 'Login to your account',
              email: {
                label: 'E-mail',
                placeholder: '',
              },
              password: {
                label: 'Password',
                placeholder: '',
              },
              checkbox: 'Remember me',
              links: {
                forgot: 'Forgot your password?',
                register: {
                  label: 'Don\'t have an account?',
                  button: 'Register for a Free Trial',
                },
              },
              button: 'Login',
            },
            register: {
              title: 'Let\'s get started',
              text: 'Start with a FREE 7-day Trial of our PRO plan.',
              banner: ' Get a FREE 7-day Trial on our PRO plan and start automating your investments. ',
              email: {
                label: 'E-mail',
                placeholder: '',
              },
              password: {
                label: 'Password (6 or more characters)',
                placeholder: '',
              },
              referalCode: {
                label: 'Referral code (optional)',
                placeholder: '',
              },
              links: {
                prefix: 'By registering, you agree to Skyrexio',
                terms: 'Terms of Service',
                and: 'and',
                policy: 'Privacy Policy',
                login: {
                  label: 'Already have an account?',
                  button: 'Login',
                },
              },
              button: 'Start Free Trial',
              comments: {
                title: 'Here\'s what other people say on',
                items: [{
                  name: 'Karnel Jelli',
                  type: 'Beginner trader',
                  text: 'As a new trader, I found Smartbot to be incredibly user-friendly. The interface is intuitive, and the bot handles all the complex routine',
                }, {
                  name: 'Thomas Watson',
                  type: 'Strategy developer',
                  text: 'With Skyrexio alert bots feature I run PineScript strategy on my Binance account and share it with my folks',
                }, {
                  name: 'John Anderson',
                  type: 'Experienced trader',
                  text: 'SmartTrade terminal is a game-changer for me. Its intuitive interface and advanced features provide the control and flexibility for efficient trading',
                }],
              },
            },
            recover: {
              title: 'Recover password',
              description: 'Enter your e-mail and we\'ll send you a recovery link',
              email: {
                label: 'E-mail',
                placeholder: '',
              },
              button: 'Email me a recovery link',
              links: {
                login: 'Login',
                or: 'or',
                register: 'Register',
              },
            },
            change: {
              title: 'Recover password',
              description: 'Enter new password',
              password: {
                label: 'Password (6 or more characters)',
                placeholder: '',
              },
              button: 'Change password',
            },
            twoFactor: {
              title: '2FA confirmation',
              otp: {
                label: 'Enter OTP from the app',
                placeholder: '',
              },
              button: 'Submit',
              link: 'Reset 2FA',
            },
            exchange: {
              fields: {
                exchangeTitle: {
                  title: 'Enter any name',
                  placeholder: 'Name',
                },
                apiKey: {
                  title: 'Paste your API key',
                  placeholder: 'Key',
                },
                apiSecret: {
                  title: 'Paste your API secret',
                  placeholder: 'Secret',
                },
                memo: {
                  title: 'Memo',
                  placeholder: 'Input here',
                },
                passphrase: {
                  title: 'Passphrase',
                  placeholder: 'Input passphrase',
                },
              },
            },
            payment: {
              promo: {
                button: {
                  cancel: 'Cancel',
                  apply: 'Apply',
                },
                placeholder: 'Discount code',
              },
            },
            configurator: {
              exchange: {
                title: 'Exchange',
              },
              market: {
                title: 'Market',
              },
              amount: {
                title: 'Trading amount',
                placeholder: 'Set amount to continue',
              },
              pairs: {
                title: 'Active trading pairs',
              },
              orderType: {
                title: 'Order type',
                items: {
                  market: 'Market',
                  limit: 'Limit',
                },
              },
              additional: {
                orders: 'Orders',
                ordersSize: 'Orders size scale',
                priceChange: 'Price change, %',
                priceChangeScale: 'Orders price change scale',
              },
              take: {
                orders: 'Orders',
                ordersSize: 'Orders size scale',
                priceChange: 'Price change, %',
                priceChangeScale: 'Orders price change scale',
              },
              stop: {
                priceChange: 'Price change, %',
              },
            },
            date: {
              start: 'Start date',
              end: 'End date',
            },
            funds: {
              price: {
                origin: {
                  title: 'Order price',
                  placeholder: '',
                },
                trigger: {
                  title: 'Trigger price',
                  placeholder: '',
                  items: {
                    last: 'Last',
                    bid: 'Bid',
                    ask: 'Ask',
                  },
                },
              },
              units: {
                title: 'Units',
                placeholder: '',
              },
              total: {
                title: 'Total',
                placeholder: '',
              },
              button: 'Submit',
            },
            bot: {
              update: {
                name: {
                  title: 'Name',
                  placeholder: '',
                },
                balance: {
                  title: 'Trading amount',
                  placeholder: '',
                },
                button: 'Update',
              },
            },
          },
          header: {
            menu: {
              home: 'Home',
              accounts: 'Accounts',
              terminal: {
                title: 'Terminal',
                items: {
                  terminal: {
                    title: 'Smart terminal',
                    text: 'Create and manage advanced positions',
                  },
                  diary: {
                    title: 'Trading diary',
                    text: 'Track and export your statistic',
                  },
                  introduction: {
                    title: 'Introduction',
                    text: 'Explore benefits of Smart terminal',
                  },
                },
              },
              bots: {
                title: 'Bots',
                items: {
                  all: {
                    title: 'My Bots',
                    text: 'Aggregate statistic and bots list',
                  },
                  diary: {
                    title: 'Trading diary',
                    text: 'Track and export your statistic',
                  },
                  marketplace: {
                    title: 'Marketplace',
                    text: 'Copy top performing bots',
                  },
                  public: {
                    title: 'My public bots',
                    text: 'Track sales of your shared bots',
                  },
                  introduction: {
                    title: 'Introduction',
                    text: 'Explore benefits of trading bots',
                  },
                },
                button: 'New bot',
              },
              subscriptions: 'Subcriptions',
              invite: 'Invite friends',
            },
            button: 'Product tour',
            switch: 'Demo',
            popup: {
              twoFactor: '2FA authentication',
              changePassword: 'Change password',
              logOut: 'Log out',
              links: [
                'Rate us',
                'Chat',
                'Suggest feature',
                'Knowledge base',
              ],
              terms: 'Terms',
              and: 'and',
              policy: 'Privacy Policy',
            },
          },
          home: {
            statistics: {
              total: {
                title: 'Total P&L',
                tooltip: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active',
              },
              upl: {
                title: 'UP&L',
                tooltip: 'Current profit or loss total for any active deals your have open. Please note, this statistic is affected by any filters you have active',
              },
              today: {
                title: 'Today P&L',
                tooltip: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active',
              },
              trades: {
                title: 'Trades',
                tooltip: 'Current quantity of active trades / Quantity of all trades in selected date range',
              },
              inTrades: {
                title: 'In trades',
                tooltip: 'Total amount of funds locked in active deals or on limit orders in the exchange orderbooks for active deals. Please note: this statistic is affected by any filters you have active',
              },
              select: {
                all: 'All',
                manual: 'Manual',
                bots: 'Bots',
                tooltips: [
                  'Select the statistic you wish to view: manual trades, Bot deals, or a comprehensive summary',
                  'Select the market statistic you wish to view: All Markets of specific asset, like USDT',
                ],
                placeholder: 'Source',
              },
            },
            table: {
              title: 'Assets',
              columns: {
                token: 'Token',
                share: 'Share',
                change: 'Chg 24h',
                price: 'Price',
                amount: 'Amount',
                total: 'Total',
              },
              search: {
                placeholder: 'Search by asset name',
              },
            },
          },
          exchange: {
            list: {
              title: 'Select exchange',
              description: 'Connect your exchange account securely with fast OAuth or API keys',
              parnter: 'Skyrexio is an official exchanges partner with no withdrawal or transfer access',
              exchange: {
                title: 'Don\'t have an exchange?',
                create: 'Create {{account}} account',
                switch: 'Switch to demo account',
              },
              offer: {
                link: 'Sign up',
                text: 'at Gate.io with Skyrexio to get 15 USDT bonus and 500 USDT rebate',
              },
              review: {
                prefix: 'Don’t see your exchange?',
                link: 'Suggest us to add',
              },
            },
            connected: {
              title: 'My exchange accounts',
              button: 'Connect new exchange',
              total: 'Total',
              change: '24h changes',
              actions: {
                trade: 'Trade',
                view: 'View',
                reset: 'Reset',
                update: 'Update API Key',
                delete: 'Delete',
              },
              lessBalance: 'Balance is less than $10. Deposit funds to start trading',
            },
            connect: {
              title: {
                connect: 'Connect',
                exchange: 'exchange',
              },
              tabs: {
                fast: 'Fast OAuth',
                api: 'API keys',
              },
              text: 'Connect your exchange account securely with {{type}}',
              button: 'Connect',
              account: {
                prefix: 'Don’t have an account?',
                text: 'Sign up at {{name}} with Skyrexio and get bonuses',
              },
              back: 'Back to exchanges',
              steps: {
                title: {
                  prefix: 'Connect keys securely',
                  link: 'Full guide',
                },
                1: '1. Log in to your exchange account and go to',
                2: {
                  ip: '2. Turn on IP whitelisting and copy/paste these IP addresses:',
                  thirdParty: '2. Select third-party apps and choose Skyrexio:',
                },
                3: '3. Paste generated data in inputs on right',
              },
            },
            update: {
              title: {
                update: 'Update',
                exchange: 'exchange',
              },
              tabs: {
                fast: 'Fast OAuth',
                api: 'API keys',
              },
              button: 'Update',
            },
          },
          terminal: {
            select: {
              exchange: 'Exchange account',
              market: 'Market',
              pair: {
                title: 'Trading Pair',
                placeholder: 'Select trading pair',
              },
            },
            table: {
              columns: {
                token: 'Token',
                share: 'Share',
                change: 'Chg 24h',
                price: 'Price',
                amount: 'Amount',
                total: 'Total',
              },
            },
            panel: {
              switch: {
                buy: 'Buy',
                sell: 'Sell',
              },
              skip: 'Skip base order (use existing funds)',
              title: {
                buy: {
                  origin: 'Buy {{currency}}',
                  skip: 'Bought {{currency}}',
                  tooltip: '{{currency}} amount currently available on Exchange account',
                },
                sell: {
                  origin: 'Sell {{currency}}',
                  skip: 'Sold {{currency}}',
                  tooltip: '{{currency}} amount currently available on Exchange account',
                },
              },
              segments: {
                limit: 'Limit',
                market: 'Market',
                conditional: 'Conditional',
              },
              fields: {
                price: {
                  origin: 'Order price',
                  skip: {
                    buy: 'Bought price',
                    sell: 'Sold price',
                  },
                },
                units: 'Units',
                total: 'Total',
                triggerPrice: {
                  title: 'Trigger price',
                  segments: {
                    limit: 'Limit',
                    market: 'Market',
                  },
                },
                types: {
                  last: 'Last',
                  bid: 'Bid',
                  ask: 'ask',
                },
              },
              additional: {
                title: 'Additional entry',
                segments: {
                  limit: 'Limit',
                  market: 'Cond.market',
                  conditional: 'Cond.limit',
                },
                table: {
                  title: 'Safety orders',
                  columns: {
                    price: 'Price',
                    total: 'Total',
                  },
                },
                button: 'Add entry',
              },
              takeProfit: {
                title: 'Take profit',
                slider: {
                  title: 'Average',
                  tooltip: 'Automatically adjusts TP to maintain profit percentage when entry price is averaged. Example: entry is $100, TP set at $110 (+10%), if price is averaged to $95, TP becomes $104.5 which is still +10%',
                },
                segments: {
                  limit: 'Limit',
                  market: 'Cond.market',
                },
                columns: {
                  price: 'Price',
                  volume: 'Volume',
                },
                button: {
                  add: 'Add target',
                  split: 'Split target',
                },
                all: 'All targets are set',
              },
              stopLoss: {
                title: 'Stop loss',
                slider: {
                  title: 'Average',
                  tooltip: 'Automatically adjusts TP to maintain profit percentage when entry price is averaged. Example: entry is $100, TP set at $110 (+10%), if price is averaged to $95, TP becomes $104.5 which is still +10%',
                },
                segments: {
                  limit: 'Cond.Limit',
                  market: 'Cond.market',
                },
                columns: {
                  price: 'Price',
                  volume: 'Volume',
                },
              },
              button: {
                create: 'Create trade',
                update: 'Update trade',
                cancel: 'Cancel',
              },
            },
            trades: {
              header: {
                pair: 'Pair',
                account: 'Account',
                bot: 'Bot',
                date: 'Start date',
                volume: 'Volume',
                entries: 'Entries',
                progress: 'Progress',
                status: 'Status',
                return: 'Return',
                actions: 'Actions',
              },
              columns: {
                price: {
                  origin: 'Price',
                  conditional: 'Cond.price',
                },
                units: 'Units',
                total: 'Total',
                type: 'Type',
                status: {
                  title: 'Status',
                  items: {
                    active: 'active',
                    completed: 'completed',
                    new: 'pending',
                    await_send: 'pending',
                    request_active: 'pending',
                    cancel_after_active: 'pending',
                    request_cancel: 'pending',
                    cancelled: 'cancelled',
                    create_failed: 'failed',
                    cancel_failed: 'failed',
                    requested_cancelled: 'pending',
                    failed: 'failed',
                    request_completed: 'Pending',
                  },
                },
              },
              actions: {
                funds: {
                  add: 'Add funds',
                  reduce: 'Reduce funds',
                },
                market: {
                  close: 'Close by Market',
                  open: 'Open by Market',
                },
                limit: {
                  close: 'Close by Limit',
                  open: 'Open by Limit',
                },
                edit: 'Edit',
                share: {
                  origin: 'Share setup',
                  result: 'Share result',
                },
                cancel: 'Cancel',
              },
              progress: {
                marks: {
                  entry: 'Entry',
                  ae: 'AE',
                  sl: 'SL',
                  tp: 'TP',
                },
              },
            },
            diary: {
              title: 'Trading diary',
              button: 'Export',
              select: {
                exchange: {
                  title: 'Exchange account',
                  placeholder: 'Select exchange account',
                  items: {
                    all: 'All',
                  },
                },
                side: {
                  title: 'Side',
                  placeholder: 'Select side',
                  items: {
                    buy: 'Buy',
                    sell: 'Sell',
                  },
                },
                date: {
                  create: 'Create dates',
                  close: 'Close dates',
                },
                market: {
                  title: 'Market',
                  placeholder: 'Select market',
                  items: {
                    all: 'All',
                  },
                },
                currency: {
                  title: 'Base currency',
                  placeholder: 'Select base currency',
                  items: {
                    all: 'All',
                  },
                },
                status: {
                  title: 'Status',
                  placeholder: 'Select status',
                  items: {
                    all: 'All',
                    completed: 'Completed',
                    active: 'Active',
                    cancelled: 'Cancelled',
                  },
                },
              },
              winRate: {
                title: 'Win Rate',
                items: {
                  total: 'Total P&L',
                  upl: 'UP&L',
                  trades: 'Total trades',
                  inTrades: 'In trades',
                },
              },
              table: {
                search: {
                  placeholder: 'Search by pair',
                },
                columns: {
                  pair: 'Pair',
                  account: 'Account',
                  date: {
                    create: 'Create date',
                    close: 'Close date',
                  },
                  status: 'Status',
                  total: 'Total',
                  price: {
                    entry: 'Entry price',
                    close: 'Close price',
                  },
                  return: 'Return',
                },
              },
            },
            introduction: {
              button: {
                create: 'Create trade',
                open: 'Open terminal',
              },
              welcome: {
                title: 'Welcome to Smart terminal',
                automate: {
                  title: 'Automate routine',
                  text: 'Set up multiple entries, take profit targets, and concurrent stop loss with conditional orders',
                },
                manage: {
                  title: 'Manage positions',
                  text: 'Live trade control, quick edits, manual actions and real-time statistic',
                },
                share: {
                  title: 'Share trades',
                  text: 'Easily share and manage multi-order positions to your community',
                },
                control: {
                  title: 'Control downturns',
                  text: 'Auto-sell and rebuy at lower prices during drops even at spot markets',
                },
              },
              steps: {
                1: 'Choose trading pair',
                2: 'Set entry conditions',
                3: 'Set close conditions',
                4: 'Manage trades in real time',
              },
              positions: {
                title: 'Create positions and relax',
                text: 'Place multi-order positions across major exchanges using a powerful and convenient order panel—no need to track charts anymore!',
              },
              perfomance: {
                title: 'Performed by our users',
                items: {
                  trades: {
                    placed: 'Trades placed',
                    executed: 'Trades executed',
                  },
                  orders: 'Orders shared',
                  volume: 'Volume traded',
                },
              },
              control: {
                title: 'Smart control over trades',
                text: 'Full real time management of active positions with progress bar and action buttons: control size, adjust \n risk management, close or cancel trade any time',
              },
              effortless: {
                title: 'Effortless Trade Sharing',
                text: 'Share multi-order position to your community with a link, allowing them to copy preset in a single click',
              },
              ending: {
                title: 'Take control with Smart terminal and beat the market',
                text: 'Leverage powerful features like multi-order execution, real-time analytics, and dynamic trade management to stay ahead of market trends and maximize your profits',
              },
            },
          },
          bots: {
            title: 'My bots',
            button: 'Add new bot',
            table: {
              title: 'Bots',
              search: {
                placeholder: 'Search by bot',
              },
              columns: {
                name: 'Name',
                exchange: 'Exchange',
                market: 'Market',
                type: {
                  title: 'Type',
                  alert: 'Alert',
                  copy: 'Copy',
                },
                balance: 'Balance',
                trades: 'Trades',
                inTrades: 'In trades',
                upl: {
                  origin: 'UP&L',
                  month: '30d P&L',
                },
                actions: {
                  title: 'Actions',
                  items: {
                    close: 'Close trades by Market',
                    cancel: 'Cancel trades',
                    edit: 'Edit',
                    delete: 'Delete',
                  },
                },
              },
            },
            statistic: {
              items: {
                total: {
                  bots: {
                    title: 'Total bots',
                    tooltip: 'Number of unique enabled and disabled bots',
                  },
                  amount: {
                    title: 'Total amount',
                    tooltip: 'Trading balances sum of the current bots',
                  },
                  trades: {
                    title: 'Total trades',
                    tooltip: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active',
                  },
                },
                pl: {
                  total: {
                    title: 'Total P&L',
                    tooltip: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active',
                  },
                  today: {
                    title: 'Today P&L',
                    tooltip: 'Profit from completed and active transactions today (resets to zero every day at midnight UTC). This statistics is affected by active filters',
                  },
                },
              },
            },
            diary: {
              title: 'Trading diary',
              button: 'Export',
              select: {
                exchange: {
                  title: 'Exchange account',
                  placeholder: 'Select exchange account',
                  items: {
                    all: 'All',
                  },
                },
                bot: {
                  title: 'Bot',
                  placeholder: 'Select bot',
                  items: {
                    all: 'All',
                  },
                },
                date: {
                  create: 'Create dates',
                  close: 'Close dates',
                },
                market: {
                  title: 'Market',
                  placeholder: 'Select market',
                  items: {
                    all: 'All',
                  },
                },
                currency: {
                  title: 'Base currency',
                  placeholder: 'Select base currency',
                  items: {
                    all: 'All',
                  },
                },
                status: {
                  title: 'Status',
                  placeholder: 'Select status',
                  items: {
                    all: 'All',
                    completed: 'Completed',
                    active: 'Active',
                    cancelled: 'Cancelled',
                  },
                },
              },
              winRate: {
                title: 'Win Rate',
                items: {
                  total: 'Total P&L',
                  upl: 'UP&L',
                  trades: 'Total trades',
                  inTrades: 'In trades',
                },
              },
              table: {
                search: {
                  placeholder: '',
                },
                columns: {
                  pair: 'Pair',
                  bot: 'Bot',
                  date: {
                    create: 'Create date',
                    close: 'Close date',
                  },
                  status: 'Status',
                  total: 'Total',
                  price: {
                    entry: 'Entry price',
                    close: 'Close price',
                  },
                  entries: 'Entries',
                  return: 'Return',
                },
              },
            },
            public: {
              title: 'My public bots',
              button: 'Publish new bot',
              subscriptions: {
                title: 'Subscriptions',
                select: {
                  items: {
                    all: 'All',
                  },
                },
                items: {
                  total: 'Total',
                  active: 'Active',
                  pending: 'Pending',
                  unique: 'Unique users',
                },
              },
              sales: {
                title: 'Sales',
                items: {
                  total: 'Total',
                  withdrawn: 'Withdrawn',
                  available: 'Available',
                },
              },
              table: {
                title: 'Withdrawals',
                export: 'Export',
                columns: {
                  amount: 'Amount',
                  transaction: 'Transaction',
                  wallet: 'Wallet',
                  date: {
                    created: 'Created date',
                    paid: 'Paid date',
                  },
                  status: {
                    title: 'Status',
                    items: {
                      pending: 'pending',
                      paid: 'paid',
                      deleted: 'deleted' ,
                    },
                  },
                },
              },
              empty: {
                description: 'You don\'t have any shared bots yet. Create your own alert bot, share it with others to earn commissions and grow your community!',
              },
              card: {
                button: 'Edit item',
              },
            },
            introduction: {
              copy: {
                welcome: {
                  title: 'Welcome to Copy bots ',
                  start: {
                    title: 'Start for free',
                    text: 'Copy any bot for free — no credit card or hidden fees to start trading',
                  },
                  copy: {
                    title: 'Copy in a click',
                    text: 'Simply set trading amount you want to use, rest is handled by the source bot',
                  },
                  monthly: {
                    title: 'Monthly calculation',
                    text: 'Profit sharing is monthly, includes active positions — no fees per closed trade',
                  },
                  high: {
                    title: 'High-water mark protection',
                    text: 'Negative results carry over to future periods, protecting from double fees',
                  },
                },
                steps: {
                  1: 'Choose a bot at Marketplace',
                  2: 'Click copy bot',
                  3: 'Set trading amount',
                  4: 'Receive automated trades',
                },
                positions: {
                  title: 'Create positions and relax',
                  text: 'Place multi-order positions across major exchanges using a powerful and convenient order panel—no need to track charts anymore!',
                },
                perfomance: {
                  title: 'Performed by our users',
                  items: {
                    trades: 'Trades copied',
                    bots: 'Copy bots launched',
                    profit: 'Profit earned',
                    volume: 'Volume traded',
                  },
                },
                marketplaceTitle: 'Ready-made bots to automate your trading instantly',
                fair: {
                  title: 'Fair copy trading standard',
                  text: 'No upfront fees — only profit sharing based on monthly performance with \n high-water mark protection. Commissions are paid when your portfolio exceeds the starting amount, considering previous losses',
                },
                control: {
                  title: 'Smart control over trades',
                  text: 'Full real time management of active positions with progress bar and action buttons: control size, adjust \n risk management, close or cancel trade any time',
                },
                ending: {
                  title: 'Copy bots in a click with Skyrexio',
                  text: 'Get automated trades managed by professionals with no actions from your side under high-water mark and monthly profit sharing protection',
                },
              },
              alert: {
                welcome: {
                  title: 'Welcome to Alert bots',
                  universal: {
                    title: 'Universal automation',
                    text: 'Turn TradingView and other alerts into trades on 8 exchanges — supports strategies',
                  },
                  control: {
                    title: 'Control and analytics',
                    text: 'Manage trades in real time and analyze performance with charts and diary',
                  },
                  easy: {
                    title: 'Easy configuration',
                    text: 'Open and manage positions with script alerts or set risk management in the configurator',
                  },
                  share: {
                    title: 'Share and earn',
                    text: 'Boost your signals with seamless and scalable copy trading and unlock extra sales',
                  },
                },
                steps: {
                  1: 'Alerts triggered by your script',
                  2: 'Bot processes alerts',
                  3: 'Orders filled on exchange',
                  4: 'Manage trades in real time',
                },
                relax: {
                  title: 'Relax while bots handle routine',
                  text: 'Skyrexio enables traders to trade smarter by reducing emotional decision-making and automating routine tasks',
                },
                perfomance: {
                  title: 'Performed by our users',
                  items: {
                    alerts: 'Alerts executed',
                    bots: 'Bots launched',
                    trades: 'Trades created',
                    volume: 'Volume traded',
                  },
                },
                execution: {
                  title: 'Automated alerts execution',
                  text: 'Turn alerts from TradingView, Java and Python scripts into orders in your exchange account. Manage multiple pairs with a single bot',
                },
                control: {
                  title: 'Smart control over trades',
                  text: 'Full real time management of active positions with progress bar and action buttons: control size, adjust \n risk management, close or cancel trade any time',
                },
                configuratorTitle: 'All-in-one intuitive configurator for seamless setup',
                share: {
                  title: 'Share your bots',
                  text: 'Empower your trading community enabling to copy your bots with a single click. Trades and bot settings sync automatically across all members. Boost sales with Skyrexio Marketplace and earn profit-sharing rewards',
                },
                tradingView: {
                  title: 'TradingView integrated',
                  text: 'Skyrexio enables you to utilize all benefits of TradingView PineScript with webhook automated execution of any complexity Indicators and strategies',
                },
                ending: {
                  title: 'Execute scripts on major exchages with Skyrexio',
                  text: 'Take control of your trades with Alert Bot, which adapts to your preferences and handles the complexities—leaving emotions out of the equation',
                },
              },
              button: {
                create: {
                  trade: 'Create trade',
                  bot: 'Create Alert Bot',
                },
                choose: 'Choose bot',
                open: 'Open configurator',
              },
            },
          },
          bot: {
            status: {
              on: 'On',
              off: 'Off',
            },
            button: 'Add new bot',
            remote: {
              pl: {
                total: {
                  title: 'Total P&L',
                  tooltip: 'Total lifetime profit generated from all closed deals. Please note: this statistic is affected by any filters you have active.',
                },
                today: {
                  title: 'Today P&L',
                  tooltip: 'Profit taken from closed deals (resets at midnight UTC+0 each day). Please note: this statistic is affected by any filters you have active.',
                },
              },
              upl: {
                title: 'UP&L',
                tooltip: 'Unrealized P&L shows the current profit or loss for all open positions',
              },
              trades: {
                title: 'Trades',
                value: '{{activeTrades}} active / {{totalTrades}} total',
                tooltip: 'Current quantity of active trades / Quantity of all trades in selected date range',
              },
              funds: {
                title: 'Funds',
                tooltip: 'Amount of quote currency currently used in active trades / Total amount of quote currency allocated to the bot (percantage of currently used allocated funds)',
              },
              actions: {
                start: 'Start',
                stop: 'Stop',
                edit: 'Edit',
                delete: 'Delete',
              },
              links: {
                sales: 'Sales',
                subscriptions: 'Subscriptions',
                alert: 'Alert messages',
                signal: 'Signal source',
              },
            },
            chart: {
              segments: {
                sum: 'Sum',
                day: 'Day',
                pair: 'Pair',
              },
            },
            table: {
              title: 'Trading history',
              search: {
                placeholder: 'Search by pair',
              },
              columns: {
                pair: 'Pair',
                date: {
                  create: 'Create date',
                  close: 'Close date',
                },
                status: {
                  title: 'Status',
                  items: {
                    pending: 'Pending',
                    cancelled: 'Cancelled',
                    active: 'Active',
                    completed: 'Completed',
                    failed: 'Failed',
                  },
                },
                total: 'Total',
                price: {
                  entry: 'Entry price',
                  close: 'Close price',
                },
                entries: 'Entries',
                return: 'Return',
              },
            },
          },
          marketplace: {
            title: 'Ready-made bots to automate your trading instantly',
            select: {
              exchange: {
                title: 'Exchange',
                placeholder: 'Select exchange',
              },
              market: {
                title: 'Market',
                placeholder: 'Select market',
              },
              roi: {
                title: 'Minimum ROI',
                placeholder: 'For example: 1',
              },
              days: {
                title: 'Minimum days running',
                placeholder: 'For example: 30',
              },
            },
            card: {
              available: 'Available on:',
              roi: 'ROI',
              winRate: 'Win rate',
              drawdown: 'Max drawdown',
              runtime: 'Runtime',
              investment: 'Min investment',
              free: 'Fee',
              button: {
                copy: 'Copy bot',
                view: 'View bot',
              },
            },
          },
          subscriptions: {
            title: 'My plan',
            plan: {
              title: {
                no: 'No plan',
                payment: 'Payment today',
                current: '{{plan}} plan',
                left: ' {{days}} left',
              },
              text: {
                no: 'Get basic subscription for free',
                lifetime: 'Lifetime access, no expiration',
                trial: 'Enjoy Pro plan features for free, trial ends on {{countedPaymentDate}}',
                next: 'Next payment: {{fee}} {{countedPaymentDate}}',
              },
              included: 'What\’s included?',
              button: 'Change plan',
            },
            table: {
              title: 'Subscriptions',
              select: {
                all: 'All',
                skyrexio: 'Skyrexio',
                copy: 'Copy bot',
              },
              button: 'Export',
              columns: {
                name: 'Name',
                type: 'Type',
                date: {
                  start: 'Date start',
                  end: 'Date end',
                },
                fee: 'Fee',
                status: 'Status',
                actions: 'Actions',
              },
            },
            about: {
              title: 'About plans',
              plans: {
                basic: {
                  title: 'basic',
                  text: 'Essential tools, copy bot with a standard fee or create your own, all with no monthly fees — completely free',
                },
                advanced: {
                  title: 'advanced',
                  text: 'Unlock multiple connected accounts, active smart trades and bots, share at Marketplace and get real-time support',
                },
                pro: {
                  title: 'pro',
                  text: 'Ultimate features, reduced Skyrexio bots fee, private PineScript strategies and premium support',
                },
              },
            },
            questions: {
              title: 'Have questions?',
              items: [{
                title: 'Can I upgrade, downgrade or cancel my Skyrexio plan?',
                text: 'You can upgrade to higher or longer plan, unused time of current subscription will be converted and credited. Downgrades aren’t available, but you can cancel via support, switch to Basic, get a refund for unused time, and then start the desired plan',
              }, {
                title: 'What’s the difference between Skyrexio plan and copy bot subscription?',
                text: 'Skyrexio plan has a fixed payment for higher platform features usage limits and lower Skyrexio bots profit-sharing percent, while a copy bot subscription has performance-based fee for access to strategies, you only share a part of profits once period is finished, with losses tracked and counted via the high-water mark system',
              }, {
                title: 'Do I need to have paid Skyrexio plan and copy bot subscription?',
                text: 'Paid Skyrexio plans are designed for traders with multiple accounts, active bots and larger portfolios, offering higher features limits and lower profit-sharing fees at Skyrexio bots. Users with a single account and smaller trading amount can benefit from the free Basic plan, only paying profit-sharing fees for the bot they copy',
              }, {
                title: 'Can I enable recurring subscription payments?',
                text: 'You can enable recurring payments for Skyrexio plan subscriptions using Stripe, but Marketplace bots recurring subscriptions are not supported at the moment',
              }],
            },
          },
          invite: {
            share: {
              title: {
                create: 'Create your referral code',
                origin: 'Share your link:',
              },
              placeholder: 'Enter referral code you want',
              button: 'Create',
            },
            payments: {
              title: 'Payments',
              button: 'Export',
              items: {
                share: 'Share',
                cliks: 'Clicks',
                registrations: 'Registrations',
                payments: 'Payments',
              },
              income: {
                title: 'Income',
                items: {
                  total: 'Total',
                  withdrawan: 'Withdrawn',
                  available: 'Available',
                },
                text: 'Request will be processed in 24 hours',
                button: 'Withdraw',
              },
              columns: {
                subscription: 'Subscription',
                type: 'Type',
                paid: {
                  date: 'Paid date',
                  amount: 'Paid amount, USDT',
                },
                share: 'Your share, USDT',
              },
              level: '{{level}} level',
            },
            withdrawals: {
              title: 'Withdrawals',
              button: 'Export',
              columns: {
                amount: 'Amount',
                transaction: 'Transaction',
                wallet: 'Wallet',
                date: {
                  create: 'Created date',
                  paid: 'Paid date',
                },
                status: {
                  titl: 'Status',
                  items: {
                    pending: 'pending',
                    paid: 'paid',
                    deleted: 'deleted' ,
                  },
                },
              },
            },
            branding: {
              title: 'Branding materials',
              logos: {
                title: 'Skyrexio logos',
                button: 'Download logo',
              },
              banners: {
                title: 'Skyrexio banners',
                button: 'Download banner',
              },
            },
          },
          configurator: {
            steps: {
              1: 'Step 1 - Set account, market and amount',
              2: 'Step 2 - Configure entry order alerts',
              3: 'Step 3 - Set overlap and close orders',
              4: 'Step 4 - Configure close orders',
              5: 'Step 5 - Create alert message',
            },
            button: {
              next: 'Next step',
              back: 'Back',
              create: 'Create bot',
              update: 'Update bot',
              builder: 'Open signal builder',
            },
            basic: {
              title: 'Turn alerts from strategies and indicators into orders at your exchange accounts with alert bots',
              links: {
                read: 'Read articles',
                use: 'Ready-to-use scripts',
              },
              pairs: {
                preffix: 'Bot can manage multiple pairs — set alerts on recommended coins or pick from the',
                and: 'and',
                links: {
                  screener: 'Screener',
                  heatmap: 'Heatmap',
                },
              },
            },
            source: {
              tabs: {
                indicator: 'Indicator',
                strategy: 'Strategy',
                custom: 'Custom',
                label: {
                  title: 'Choose entry order alert source',
                  tooltip: '',
                },
              },
              indicator: {
                url: {
                  title: 'Webhook URL',
                  description: 'Copy and paste this URL into your Alert notifications tab on',
                },
                message: {
                  title: 'Alert message',
                  description: 'Copy and paste this text into alert message on',
                },
              },
              strategy: {
                function: {
                  title: 'Entry function parameter',
                  description: 'Add the parameter to your PineScript strategy entry function on',
                },
                sccipt: {
                  title: 'PineScript parameter',
                  description: 'AAdd the parameter to your strategy exit function on',
                },
              },
              custom: {
                url: {
                  title: 'Webhook URL',
                  description: 'Send a POST request with JSON on this adress',
                },
                json: {
                  title: 'JSON body to execute bot action',
                  description: 'Configure a POST request with JSON body to execute base entry',
                },
                jsonEntry: {
                  title: 'JSON body to execute base entry',
                  description: 'Configure a POST request with JSON body to execute base entry',
                },
              },
              basic: {
                title: 'Execute bot actions by sending JSON payload to webhook URL with your app or manually',
                link: 'How to use custom alerts',
                headers: {
                  field: 'Field',
                  type: 'Type',
                  description: 'Description',
                },
                items: {
                  base: 'Symbol to trade for example ADA',
                  quote: 'Market of your bot for example USDT',
                  position: '"entry1", "entry2", etc. for entries, "close" for closure',
                  price: 'Execution price, for Market orders use current price',
                  sourceUuid: 'Unique identifier of your bot, see below',
                  secretToken: 'Protection token for your bot, see below',
                  timestamp: 'Current time in UNIX format',
                },
              },
            },
            managment: {
              tabs: {
                auto: 'Auto',
                custom: 'Custom',
                label: {
                  title: 'Choose risk management type',
                  tooltip: '',
                },
              },
              auto: {
                presets: [{
                  title: 'Conservative',
                  settings: {
                    display: [{
                      title: 'Overlap',
                      value: '-30%',
                    }, {
                      title: 'Take profit',
                      value: '4%',
                    }, {
                      title: 'Stop loss',
                      value: 'Disabled',
                    }],
                  }, 
                  caption: 'Dollar-Cost Averaging with widening entries',
                  description: 'Larger buys at lower prices, perfect for low-risk position building in volatile markets',
                }, {
                  title: 'Aggressive',
                  settings: {
                    display: [{
                      title: 'Overlap',
                      value: 'Disabled',
                    }, {
                      title: 'Take profit',
                      value: '5%',
                    }, {
                      title: 'Stop loss',
                      value: '-2%',
                    }],
                  },
                  caption: 'Momentum trading with high reward-risk ratio',
                  description: 'Target strong uptrends with wide stop loss and take profit for maximum gains',
                }, {
                  title: 'Moderate',
                  settings: {
                    display: [{
                      title: 'Overlap',
                      value: '-10%',
                    }, {
                      title: 'Take profit',
                      value: '6%',
                    }, {
                      title: 'Stop loss',
                      value: '-4%',
                    }],
                  },
                  caption: 'Swing trading with distributed entries',
                  description: 'Capture market swings in sideways or moderate uptrends with natural corrections',
                }],
              },
              custom: {
                additional: {
                  title: 'Additional entries',
                  tabs: {
                    preset: 'Preset',
                    alert: 'Alerts',
                    tooltip: {
                      preset: 'Preset: Enables you to configure additional entries that activate when specific price levels are reached.',
                      alert: 'Alert: Allows you to dynamically open additional entries based on alerts from your indicators and strategies.',
                    },
                  },
                },
                close: {
                  title: 'Close orders',
                  tabs: {
                    preset: 'Preset',
                    alert: 'Alerts',
                    tooltip: {
                      preset: 'Preset: Enables you to configure close orders that activate when specific price levels are reached.',
                      alert: 'Alert: Allows you to dynamically close positions based on alerts from your and strategies.',
                    },
                  },
                },
                take: {
                  title: 'Take profit',
                },
                stop: {
                  title: 'Close orders',
                },
              },
              panel: {
                common: {
                  entry: {
                    title: 'Entry order',
                    item: {
                      title: 'Open order size',
                    },
                  },
                  additional: {
                    title: 'Additional entry orders',
                    headers: {
                      order: 'Order size',
                      price: 'Price change',
                    },
                  },
                  full: 'Full position per coin',
                  close: {
                    title: 'Close orders',
                    take: {
                      title: 'Take profit',
                    },
                    stop: {
                      title: 'Stop loss',
                    },
                  },
                },
                additional: {
                  entry: {
                    title: 'Entry order',
                    item: {
                      title: 'Open order size',
                    },
                  },
                  additional: {
                    title: 'Additional entry orders',
                    headers: {
                      order: 'Order size',
                      price: 'Price change',
                      alert: 'Alert',
                      script: 'PineScript',
                      json: 'JSON body',
                    },
                    full: 'Full position per coin',
                  },
                },
                close: {
                  title: 'Close orders',
                  table: {
                    title: 'Take profits',
                    headers: {
                      volume: 'Volume',
                      price: 'Price change',
                    },
                  },
                  stop: 'Stop loss',
                },
              },
            },
          },
          unknown: {
            title: 'Oh no! What have you done? \n You broke Skyrexio!',
            description: 'It’s a joke! Everything is fine but the page \n you were looking for can’t be found',
            back: 'Let’s go back to our Main page!',
            button: 'Go to the main page',
          },
        },
      },
      ru: {
        translation: {
          authTitle: {
            register: 'Отличный выбор!',
            other: 'Торгуйте как профи — просто',
          },
          authDescription: 'Раскройте потенциал: боты и мульти-ордерный терминал на лучших биржах',
          copyRight: 'Все права защищены', 
          error: {
            login: {
              failed: {
                title: 'Активация не удалась',
                text: 'Произошла ошибка активации. Обратитесь в поддержку',
              },
              'account is not activated': 'Аккаунт не активирован',
              'incorrect login or password': 'Неверный логин или пароль',
              'account is deleted': 'Аккаунт удалён',
            },
            register: {
              'Oops! Account already exists': 'Упс! Аккаунт уже существует',
              'Oops! There is no such referral code': 'Упс! Такого реферального кода не существует',
              'You already have an account, Check email to activate it': 'У вас уже есть аккаунт. Проверьте почту, чтобы активировать его',
              'Oops, try again': 'Упс, попробуйте снова',
            },
            twoFactor: {
              empty: {
                title: '',
                message: 'Отсутствует email или пароль. Повторите вход',
              },
              generate: {
                title: 'Не удалось сгенерировать 2FA',
                message: 'Ошибка. Повторите попытку или свяжитесь с поддержкой',
                button: 'Ок',
              },
              disable: {
                title: 'Не удалось отключить 2FA',
                message: '',
                button: 'Ок',
              },
              enable: {
                title: 'Не удалось включить 2FA',
                message: '',
                button: 'Ок',
              },
            },
            wrong: {
              title: 'Что-то пошло не так',
              message: 'Мы скоро всё исправим. Попробуйте позже или пишите в поддержку',
              button: 'Ок',
              recover: {
                message: 'Сервис временно недоступен. Попробуйте обновить страницу через пару минут',
              },
              account: {
                message: 'Мы скоро всё исправим. Повторите позже или свяжитесь с поддержкой',
              },
            },
            otp: {
              incorrect: {
                title: 'Неверный OTP',
                message: 'Проверьте код и повторите',
              },
              ownership: {
                title: 'Подтвердите владение аккаунтом',
                button: 'Ок',
                message: {
                  preffix: 'Обратитесь в поддержку по адресу',
                  suffix: 'для сброса 2FA.',
                },
              },
            },
            exchange: {
              connect: {
                'Only one active demo account is available per user':
                  'Допустим только один демо-аккаунт на пользователя',
                'There is no active skyrex subscription':
                  'Нет активной подписки на Skyrexio',
                'Maximum number of active accounts reached. Please upgrade subscription':
                  'Лимит аккаунтов достигнут. Обновите подписку',
                'failed to create account': 'Не удалось создать аккаунт',
              },
              demo: {
                enable: {
                  title: 'Невозможно включить демо-режим',
                },
                disable: {
                  title: 'Не удалось отключить демо-режим',
                },
                create: {
                  title: 'Не удалось создать демо-аккаунт',
                },
                delete: {
                  title: 'Не удалось удалить аккаунт',
                },
                reset: {
                  title: 'Не удалось сбросить демо-аккаунт',
                },
              },
              create: {
                title: 'Не удалось подключиться',
                message: 'Проверьте ключи API, разрешения, белый список IP или обратитесь в поддержку',
              },
              update: {
                title: 'Не удалось обновить',
                message: {
                  'Cannot update API keys because they belong to other exchange account':
                    'API-ключи принадлежат другому аккаунту',
                },
              },
              list: {
                failed: {
                  title: 'Ошибка подключения',
                  message: {
                    'failed to create account': 'Не удалось создать аккаунт',
                    'Maximum number of active accounts reached. Please upgrade subscription':
                      'Лимит аккаунтов достигнут. Обновите подписку',
                  },
                  button: 'Ок',
                },
                delete: {
                  title: 'Ошибка удаления',
                  message: {
                    'Cannot delete account because bots are not deleted':
                      'Нельзя удалить аккаунт: боты не удалены',
                    'Cannot delete account because there are active positions':
                      'Нельзя удалить аккаунт: есть активные позиции',
                  },
                  button: 'Ок',
                },
              },
              connected: {
                empty: {
                  title: 'Аккаунт пуст',
                  text: {
                    bybit: 'Перейдите на Unified, обновите API или пополните баланс',
                    other: 'Обновите ключи или пополните баланс для продолжения торговли',
                  },
                },
              },
            },
            bots: {
              update: {
                title: 'Не удалось обновить бота',
                description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
                button: 'Ок',
              },
              delete: {
                title: 'Не удалось удалить бота',
                description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
                button: 'Ок',
              },
              close: {
                deals: {
                  title: 'Не удалось закрыть сделки',
                  description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
                  button: 'Ок',
                },
                trades: {
                  title: 'Не удалось закрыть трейды',
                  description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
                  button: 'Ок',
                },
              },
              cancel: {
                trades: {
                  title: 'Не удалось отменить трейды',
                  description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
                  button: 'Ок',
                },
              },
              start: {
                title: 'Не удалось запустить бота',
                description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
                button: 'Ок',
              },
              stop: {
                title: 'Не удалось остановить бота',
                description: 'Мы скоро исправим. Повторите позже или обратитесь в поддержку',
                button: 'Ок',
              },
              status: {
                'There are pending success fee payments. Please pay the fees to turn on the bot': {
                  title: 'У ботов есть неоплаченные комиссии',
                  description: 'Оплатите все подписки, чтобы включить бота',
                  button: 'Подписки',
                },
                'haring option is limited. Please upgrade subscription': {
                  title: 'Шеринг недоступен',
                  description: 'Измените настройки бота или обновите план Skyrexio',
                  button: 'Обновить',
                },
                'Maximum number of active bots reached. Please upgrade subscription': {
                  title: 'Достигнут лимит ботов',
                  description: 'Обновите подписку Skyrexio',
                  button: 'Обновить',
                },
                'There is no active success subscription': {
                  title: 'Нет активной подписки бота',
                  description: 'Активируйте подписку, чтобы включить бота',
                  button: 'Подписаться',
                },
                'No skyrex subscription found': {
                  title: 'Подписка на Skyrexio не найдена',
                  description: '',
                  button: 'Подписаться',
                },
              },
              alert: {
                title: 'Упс! Нет алерт-ботов',
                description: 'Создайте алерт-бот и разместите его на маркетплейсе',
                button: 'Ок',
              },
            },
            copyBot: {
              demo: 'У бота нет демо-режима',
            },
            subscription: {
              downgrade: {
                title: 'Нельзя понизить',
                message: 'Подписку нельзя понизить. Выберите более высокий план',
                button: 'Ок',
              },
            },
            payment: {
              promo: {
                message: 'Неверный код',
              },
            },
            changePassword: {
              title: 'Не удалось обновить пароль',
              message: '',
              button: 'Ок',
            },
            withdraw: {
              balance: 'Недостаточный баланс',
            },
            funds: {
              copy: 'Не удалось скопировать ссылку',
            },
            'bot': {
              'close': {
                'title': 'Не удалось закрыть',
                'description': 'Не получилось закрыть сделки. Попробуйте снова или обратитесь в службу поддержки',
                'button': 'Ок',
              },
              'delete': {
                'title': 'Не удалось удалить',
                'description': 'Невозможно удалить бота. Попробуйте снова или обратитесь в службу поддержки',
                'button': 'Ок',
              },
              'update': {
                'title': 'Не удалось обновить',
                'description': 'Обновление бота прошло неудачно. Попробуйте снова или обратитесь в службу поддержки',
                'button': 'Ок',
              },
            },
          },
          success: {
            login: {
              title: 'Аккаунт активирован',
              message: 'Теперь можно войти в аккаунт',
            },
            register: {
              title: 'Мы отправили вам письмо!',
              message: 'Почти готово! Проверьте почту для активации',
            },
            recover: {
              title: 'Мы отправили вам письмо!',
              message:
                'Если аккаунт связан с этим email, вы получите ссылку для сброса. Проверьте почту и следуйте инструкциям',
            },
            changePassword: {
              title: 'Пароль обновлён',
              message: 'Теперь можно войти с новым паролем',
              button: 'Ок',
            },
            exchange: {
              connect: {
                title: 'Аккаунт подключён',
                message: 'Создайте или скопируйте первого бота либо смарт-позицию',
                button: 'Ок',
              },
              update: {
                title: 'Аккаунт обновлён',
                message: 'Теперь вы в одном клике от запуска бота или смарт-сделки!',
              },
              list: {
                connected: {
                  title: 'Аккаунт подключён',
                  message: 'Теперь вы в одном клике от запуска бота или смарт-сделки!',
                  button: 'Ок',
                },
                delete: {
                  title: 'Аккаунт удалён',
                  message: 'Не беда, привяжите новый аккаунт!',
                  button: 'Ок',
                },
                completed: {
                  title: 'Запрос выполнен',
                  message: 'API получены, если аккаунт не привязался, обратитесь в поддержку',
                  button: 'Ок',
                },
              },
              demo: {
                create: {
                  title: 'Аккаунт пересоздан',
                  button: 'Ок',
                },
              },
            },
            payment: {
              title: 'Немного терпения',
              message: 'Ваш платёж подтвердится через несколько минут на CoinPayments',
              button: 'Ок',
            },
            twoFactor: {
              disable: 'Google Auth отключён',
              enable: 'Google Auth включён',
              button: 'Ок',
            },
            withdraw: {
              success: 'Вывод создан',
            },
            funds: {
              copy: 'Ссылка скопирована',
            },
          },
          validation: {
            email: {
              required: 'Введите e-mail!',
              format: 'Некорректный формат e-mail.',
            },
            password: {
              required: 'Введите пароль!',
              format: 'Пароль не короче 6 символов.',
            },
            otp: {
              reuqired: 'Введите OTP',
              format: 'OTP должен состоять из 6 цифр',
            },
            withdraw: {
              amount: {
                required: 'Сумма обязательна',
                number: 'Должно быть число',
                greater: 'Должно быть больше 10 USDT',
                lower: 'Не должно превышать доступное',
              },
              wallet: {
                required: 'Необходим адрес кошелька',
                existing: 'Ваш TRC-20 кошелек',
              },
            },
            changePassword: {
              match: 'Пароли не совпадают!',
            },
            funds: {
              price: {
                trigger: {
                  required: 'Поле необходимо',
                  number: 'Должно быть числом',
                  min: 'Минимальное значение {{priceMin}}',
                  max: 'Максимальное значение {{priceMax}}',
                },
                origin: {
                  required: 'Поле необходимо',
                  number: 'Должно быть числом',
                  min: 'Минимальное значение {{priceMin}}',
                  max: 'Максимальное значение {{priceMax}}',
                },
              },
              units: {
                required: 'Поле необходимо',
                number: 'Должно быть числом',
                min: 'Минимальное значение {{lotMin}}',
                max: 'Максимальное значение {{lotMax}}',
              },
              total: {
                required: 'Поле необходимо',
                balance: 'Недостаточно средств',
                number: 'Должно быть числом',
                min: 'Минимальное значение {{minNotional}}',
                max: 'Максимальное значение {{maxNotional}}',
              },
              slider: {
                min: 'Должно быть больше 0%',
                max: 'Не может быть больше 100%',
              },
            },
            'bot': {
              'update': {
                'name': {
                  'required': 'Пожалуйста, введите название бота',
                  'more': 'Название бота не может содержать более 20 символов.',
                },
                'balance': {
                  'required': 'Пожалуйста, введите сумму для торговли',
                  'less': 'Торговая сумма не может быть меньше {{minInvestment}}',
                },
              },
            },
          },
          modal: {
            exchange: {
              connect: {
                success: {
                  title: 'Аккаунт подключён',
                  message: 'Создайте или скопируйте первого бота или смарт-позицию',
                  button: 'Ок',
                },
                error: {
                  connection: {
                    message: 'Достигнут лимит подключенных аккаунтов',
                  },
                },
              },
              list: {
                delete: {
                  title: 'Удалить аккаунт?',
                  message: 'Убедитесь, что боты удалены и сделки завершены',
                  button: 'Удалить',
                },
              },
            },
            terminal: {
              funds: {
                add: {
                  title: 'Добавить средства',
                  description: '',
                },
                reduce: {
                  title: 'Уменьшить средства',
                  description: '',
                },
              },
              close: {
                description: {
                  buy: 'Текущая цена: {{price}} {{quote}}\nДоход: {{returnQuote}} {{quote}} ({{returnPercent}}%)',
                  other:
                    'Текущая цена: {{price}} {{quote}}\nДоход: {{returnBase}} {{baseSymbol}} ({{returnPercent}}%)',
                },
              },
              open: {
                description: 'Текущая цена: {{price}} {{quote}}',
              },
              market: {
                close: {
                  title: 'Закрыть по рынку?',
                },
                open: {
                  title: 'Открыть по рынку?',
                },
              },
              limit: {
                close: {
                  title: 'Закрыть по лимиту?',
                },
                open: {
                  title: 'Открыть по лимиту?',
                },
              },
              edit: {
                title: 'Редактировать позицию',
                description: 'Редактировать позицию',
              },
              share: {
                title: 'Поделиться',
                description: 'Ваша ссылка',
              },
              cancel: {
                title: 'Отменить позицию?',
                description: 'Сделка отменится на Skyrexio, активы на бирже не пострадают',
              },
              button: 'Подтвердить',
            },
            bots: {
              confirm: {
                delete: {
                  title: 'Точно удалить бота?',
                  description: 'Все активные сделки закроются',
                  button: 'Удалить',
                },
                cancel: {
                  title: 'Точно отменить все сделки бота?',
                  description:
                    'Все сделки отменятся на Skyrexio, активы на бирже не затронутся',
                  button: 'Ок',
                },
                close: {
                  trades: {
                    title: 'Закрыть все сделки бота рыночными ордерами?',
                    description: 'Все активные сделки закроются маркет-ордерами',
                    button: 'Ок',
                  },
                },
              },
              market: {
                title: 'Выберите рынок из ваших алерт-ботов',
                select: {
                  title: 'Рынок',
                  placeholder: 'Выберите рынок для публикации',
                },
                button: 'Следующий шаг',
              },
              parameters: {
                title: 'Параметры элемента на маркетплейсе',
                bots: {
                  title: 'Выберите ботов',
                  palceholder: 'Каждый бот добавляет доступную биржу',
                },
                amount: {
                  title: 'Минимальная сумма',
                  palceholder: 'Минимальная сумма для копирования бота',
                },
                name: {
                  title: 'Название карточки',
                  palceholder: 'Название бота',
                },
                description: {
                  title: 'Описание (до 200 символов)',
                  palceholder: 'Коротко о стратегии бота',
                },
                author: {
                  title: 'Автор',
                  palceholder: 'Имя создателя',
                },
                profit: {
                  title: 'Доля прибыли, %',
                  palceholder: 'Процент месячной прибыли, который идёт вам',
                },
                segments: {
                  paid: 'Платный',
                  free: 'Бесплатный',
                },
                actions: {
                  publish: 'Опубликовать',
                  save: 'Сохранить изменения',
                },
              },
              eligible: {
                title: 'Бот не соответствует критериям для публикации',
                header: {
                  criteria: 'Критерии',
                  kpi: 'KPI',
                  now: 'Сейчас',
                  status: 'Статус',
                },
                button: 'Ок',
              },
            },
            copyBot: {
              exchange: {
                title: 'Выберите аккаунт',
                placeholder: 'Из подключённых аккаунтов',
              },
              name: {
                title: 'Назовите бота',
                placeholder: 'Мой идеальный бот',
              },
              amount: {
                title: 'Торговая сумма (доступно: {{balance}} {{quote}})',
                placeholder: 'Укажите сумму (мин: {{minInvestment}} {{quote}})',
                tooltip:
                  'Сумма, которой будет управлять бот. Убедитесь, что на аккаунте есть эти средства',
              },
              button: 'Скопировать бота',
              statistic: {
                title: 'Статистика',
                items: {
                  roi: 'Прибыль по закрытым сделкам (обновляется в полночь UTC+0). Статистика учитывает фильтры',
                  drawdown: 'Открытые сделки. Учитывает фильтры',
                  trades:
                    'Общая сумма средств в открытых сделках или лимитных ордерах. Учитывает фильтры',
                  profit:
                    'Суммарная прибыль за всё время по закрытым сделкам. Учитывает фильтры',
                  amount:
                    'Текущий совокупный профит/убыток по открытым сделкам. Учитывает фильтры',
                },
                table: {
                  title: 'История торговли',
                  search: {
                    placeholder: 'Поиск по паре',
                  },
                  columns: {
                    pair: 'Пара',
                    date: {
                      create: 'Дата создания',
                      close: 'Дата закрытия',
                    },
                    status: 'Статус',
                    price: {
                      entry: 'Цена входа',
                      close: 'Цена закрытия',
                    },
                    return: 'Доход %',
                  },
                },
              },
            },
            trial: {
              title: '7-дневный пробный период активирован',
              description:
                'Спасибо за выбор Skyrexio! На время триала вы получите:',
              items: {
                accounts: {
                  title: 'Неограниченно аккаунтов',
                  description: 'Подключите все и отслеживайте общий портфель',
                },
                bots: {
                  title: 'Все типы ботов',
                  description: 'Skyrexio AI, Маркетплейс и Алерт боты',
                },
                terminal: {
                  title: 'Мульти-ордерный терминал',
                  description: 'Создавайте, анализируйте и редактируйте без ограничений',
                },
              },
            },
            subscription: {
              title: 'Детали заказа',
              aggrement: {
                prefix: 'Оформляя покупку, вы соглашаетесь с',
                and: 'и',
                link: {
                  terms: 'Правилами использования',
                  policy: 'Политикой возврата',
                },
              },
              button: 'Оформить',
              confirm: {
                end: {
                  title: 'Завершить подписку?',
                  description: 'Комиссия за прибыль будет рассчитана по текущим результатам',
                  button: 'Подтвердить',
                },
              },
            },
            payment: {
              title: '{{planName}} План',
              notify:
                'Ваши {{finishedDate}} оставшиеся дни в {{planName}} плане будут конвертированы в <strong>{{days}} дней</strong> после покупки',
              choose: 'Выберите способ оплаты',
            },
            plans: {
              title: 'Выберите план',
              segments: {
                monthly: 'Ежемесячно',
                annually: 'Ежегодно {{percent}}%',
              },
              items: {
                subTitle: 'Что включено',
                basic: {
                  title: 'базовый',
                  description: 'Пользуйтесь основными функциями без подписки',
                  price: 'Бесплатно',
                  included: {
                    title: 'Что включено',
                    items: [
                      '{{smartBotCommissionPercent}}% комиссия на боты Skyrexio',
                      '{{exchangeAccountsConnectedLimit}} подключённый аккаунт',
                      '{{activeSmartTradesLimit}} активная позиция Smart Terminal',
                      '{{activeBotsLimit}} активный копи/алерт-бот',
                      'Бот-конфигуратор',
                      'Демо-аккаунт',
                      'Панель портфеля в реальном времени',
                      'Поддержка в течение 24 часов',
                    ],
                  },
                },
                advanced: {
                  title: 'продвинутый',
                  description: 'Расширьте лимиты и публикуйте ботов на Маркетплейсе',
                  included: {
                    title: 'Что включено',
                    items: [
                      'Всё из basic',
                      null,
                      '{{exchangeAccountsConnectedLimit}} аккаунтов',
                      '{{activeSmartTradesLimit}} активных позиций',
                      '{{activeBotsLimit}} активных ботов',
                      'Доступ к публикации на Маркетплейсе',
                      'Поддержка в реальном времени',
                    ],
                  },
                },
                pro: {
                  title: 'про',
                  label: 'Лучший выбор',
                  description:
                    'Максимум инструментов, сниженная комиссия Skyrexio, приватные PineScript-стратегии',
                  included: {
                    items: [
                      'Всё из Basic и Advanced',
                      null,
                      '{{smartBotCommissionPercent}}% комиссия на боты Skyrexio',
                      '{{exchangeAccountsConnectedLimit}} аккаунтов',
                      '{{activeSmartTradesLimit}} активных позиций',
                      '{{activeBotsLimit}} активных ботов',
                      'Доступ к приватным TradingView-скриптам',
                      'Премиум-поддержка',
                    ],
                  },
                },
              },
              button: {
                trial: 'Начнётся после пробного периода',
                current: 'Текущий план',
                upgrade: 'Улучшить план',
                subscribe: 'Подписаться',
              },
            },
            withdraw: {
              title: 'Запрос на вывод',
              description: 'Используйте только TRC-20 адрес',
              amount: {
                title: 'Сумма вывода (доступно: {{available}} USDT)',
                placeholder: 'Введите сумму (мин. 10)',
              },
              wallet: {
                title: 'TRC-20 кошелёк',
                placeholder: 'Ваш TRC-20 кошелек',
              },
              button: 'Отправить',
            },
            twoFactor: {
              title: 'Подключить Google Auth',
              description: 'Не забудьте сохранить свой секретный ключ',
              opt: {
                title: 'Введите OTP для подтверждения',
                placeholder: '123456',
              },
              button: {
                cancel: 'Отмена',
                ok: 'Ок',
              },
            },
            twoFactorDisable: {
              title: 'Отключить 2FA',
              opt: {
                title: 'Введите OTP для подтверждения',
                placeholder: '',
              },
              button: {
                reset: 'Сбросить 2FA',
                ok: 'Ок',
              },
            },
            changePassword: {
              title: 'Изменить пароль',
              description: 'Используйте сложные пароли ради безопасности',
              current: {
                title: 'Текущий пароль',
                placeholder: 'Введите текущий пароль',
              },
              new: {
                title: 'Новый пароль',
                placeholder: 'Введите новый пароль',
              },
              confirm: {
                title: 'Подтверждение пароля',
                placeholder: 'Повторите новый пароль',
              },
              button: {
                change: 'Изменить пароль',
                cancel: 'Отмена',
              },
            },
            onboarding: {
              button: 'Назад',
              steps: {
                summary: 'Итоги онбординга',
                exchange: 'Подключите биржу',
                trading: 'Начните торговать',
              },
              welcome: {
                title: 'Добро пожаловать',
                description:
                  'Здесь начинается ваш путь в трейдинге. Исследуйте функции в нашем онбординге',
                button: {
                  start: 'Начать онбординг',
                  skip: 'Пропустить',
                },
              },
              start: {
                title: 'Выберите стиль торговли',
                copy: {
                  title: 'Копи-бот',
                  description: 'Копируйте лучших ботов с high-water марк защитой',
                },
                alert: {
                  title: 'Алерт-бот',
                  description: 'Торгуйте автоматически по своим стратегиям с помощью вебхук алертов',
                },
                terminal: {
                  title: 'Умный терминал',
                  description: 'Создавайте мульти ордерные сделки и управляйте ими в реальном времени',
                },
              },
            },
            addBot: {
              title: 'Выберите тип бота',
              copy: {
                title: 'Копи-бот',
                description: 'Копируйте лучших ботов с защищённой моделью прибыли',
              },
              alert: {
                title: 'Алерт-бот',
                description: 'Преобразуйте вебхуки в автотрейды на бирже',
              },
            },
            addFunds: {
              wallet: {
                title: {
                  buy: 'Купить',
                  sell: 'Продать',
                },
                tooltip: '{{asset}} доступно на аккаунте',
              },
            },
            shareResult: {
              title: 'Поделиться сделкой',
              pair: 'Торговая пара',
              roi: 'ROI',
              entry: 'Вход',
              current: 'Сейчас',
              qrcode: 'Реф. код:',
              subText: 'Побеждай со Skyrexio.com',
              button: 'Скачать',
            },
            'bot': {
              'subscriptions': {
                'title': 'Подписки',
                'columns': {
                  'name': 'Название',
                  'type': {
                    'title': 'Тип',
                    'items': {
                      'copy': 'Копи-бот',
                    },
                  },
                  'date': {
                    'start': 'Дата начала',
                    'end': 'Дата окончания',
                  },
                  'fee': 'Комиссия',
                  'status': {
                    'title': 'Статус',
                    'items': {
                      'active': 'активна',
                      'pending': 'ожидает',
                    },
                  },
                  'mark': 'High-water mark',
                  'result': 'Результат',
                  'net': 'Чистый результат',
                  'commission': 'Комиссия',
                  'actions': {
                    'title': 'Действия',
                    'items': {
                      'pay': 'Оплатить',
                    },
                  },
                },
              },
              'update': {
                'title': 'Обновить копи бот',
                'description': 'Изменение торговой суммы повлияет только на новые позиции',
              },
            },
            'alertSource': {
              'title': 'Сообщения сигналов',
              'description': 'Управляйте ботом с помощью сигналов из TradingView или других вебхуков',
              'copy': {
                'title': 'Webhook URL',
              },
              'tabs': {
                'indicator': 'Индикатор',
                'strategy': 'Стратегия',
                'other': 'Другое',
              },
              'indicator': {
                'title': 'Создайте сигнал с сообщением для открытия сделки',
                'description': 'Замените "entry1" на "entry2", "entry3" и т.д., и установите новые сигналы для дополнительных входов',
                'copy': {
                  'title': 'Создайте сигнал с сообщением для закрытия сделки',
                },
              },
              'strategy': {
                'title': 'Добавьте параметр в функции входа вашей стратегии',
                'description': 'Замените "entry1" на "entry2", "entry3" и т.д. в параметре и примените его к дополнительным входам',
                'copy': {
                  'items': {
                    'function': 'Добавьте параметр в функции выхода или закрытия вашей стратегии',
                    'message': 'Создайте сигнал с сообщением для активации действий стратегии',
                  },
                },
                'note': 'Примечание: замените str.tostring на tostring для стратегий PineScript v4',
              },
              'other': {
                'table': {
                  'headers': {
                    'field': 'Поле',
                    'type': 'Тип',
                    'description': 'Описание',
                  },
                  'columns': {
                    'base': 'Торговый символ, например ADA',
                    'quote': 'Рынок бота, например USDT',
                    'position': '"entry1", "entry2" и т.д. для входов, "close" для закрытия',
                    'price': 'Цена исполнения, для рыночных ордеров используйте текущую цену',
                    'sourceUuid': 'Уникальный идентификатор вашего бота, см. ниже',
                    'secretToken': 'Защитный токен для вашего бота, см. ниже',
                    'time': 'Текущее время в формате UNIX',
                  },
                },
                'copy': {
                  'items': {
                    'source': 'Источник (Source UUID)',
                    'secret': 'Защитный токен (Secret Token)',
                    'json': {
                      'title': 'Отправьте POST-запрос с телом в формате JSON для выполнения действия бота',
                      'description': 'Замените "entry1" на "entry2", "entry3" и т.д. для дополнительных входов и на "close" для выхода из сделки',
                    },
                  },
                },
                'note': 'Примечание: отправляйте запросы, используя ваши скрипты или вручную через Postman',
              },
            },
          },
          tradingStatistics: {
            title: 'Статистика торговли',
            segments: {
              sum: 'Сумма',
              day: 'День',
              pair: 'Пара',
            },
          },
          aggregateBalance: {
            title: 'Совокупный баланс',
            total: 'Всего активов',
            change: 'Изм. 24ч',
          },
          withdrawal: {
            title: 'Создать запрос на вывод',
            text: 'Ваша заявка будет обработана в течение 24 часов',
            button: 'Вывести',
          },
          form: {
            login: {
              title: 'С возвращением',
              description: 'Войдите в свой аккаунт',
              email: {
                label: 'E-mail',
                placeholder: '',
              },
              password: {
                label: 'Пароль',
                placeholder: '',
              },
              checkbox: 'Запомнить меня',
              links: {
                forgot: 'Забыли пароль?',
                register: {
                  label: 'Нет аккаунта?',
                  button: 'Регистрация',
                },
              },
              button: 'Войти',
            },
            register: {
              title: 'Начнём',
              text: 'Начните с БЕСПЛАТНОГО 7-дневного PRO плана',
              banner:
                'Получите БЕСПЛАТНЫЙ 7-дневный PRO план и торгуйте на максимуме.',
              email: {
                label: 'E-mail',
                placeholder: '',
              },
              password: {
                label: 'Пароль (6+ символов)',
                placeholder: '',
              },
              referalCode: {
                label: 'Рефкод (необязательно)',
                placeholder: '',
              },
              links: {
                prefix: 'Регистрируясь, вы соглашаетесь с',
                terms: 'Условиями Skyrexio',
                and: 'и',
                policy: 'Политикой конфиденциальности',
                login: {
                  label: 'Уже есть аккаунт?',
                  button: 'Войти',
                },
              },
              button: 'Начать бесплатный пробный период',
              comments: {
                title: 'Вот что говорят другие на',
                items: [
                  {
                    name: 'Karnel Jelli',
                    type: 'Новичок',
                    text: 'Как новичку, мне понравилась простота. Интерфейс понятен, а боты берут на себя всю рутину',
                  },
                  {
                    name: 'Thomas Watson',
                    type: 'Разработчик стратегий',
                    text: 'С алерт-ботами Skyrexio я запустил PineScript-стратегии на Binance и поделился ими с друзьями',
                  },
                  {
                    name: 'John Anderson',
                    type: 'Опытный трейдер',
                    text: 'Терминал — реальный прорыв. Управлять сделками удобно, функционал продвинутый',
                  },
                ],
              },
            },
            recover: {
              title: 'Восстановить пароль',
              description: 'Введите e-mail и мы вышлем ссылку для восстановления',
              email: {
                label: 'E-mail',
                placeholder: '',
              },
              button: 'Отправить ссылку',
              links: {
                login: 'Войти',
                or: 'или',
                register: 'Зарегистрироваться',
              },
            },
            change: {
              title: 'Восстановление пароля',
              description: 'Введите новый пароль',
              password: {
                label: 'Пароль (6+ символов)',
                placeholder: '',
              },
              button: 'Изменить пароль',
            },
            twoFactor: {
              title: '2FA подтверждение',
              otp: {
                label: 'Введите OTP из приложения',
                placeholder: '',
              },
              button: 'Отправить',
              link: 'Сбросить 2FA',
            },
            exchange: {
              fields: {
                exchangeTitle: {
                  title: 'Введите любое название',
                  placeholder: 'Название',
                },
                apiKey: {
                  title: 'Вставьте API ключ',
                  placeholder: 'Публичный ключ',
                },
                apiSecret: {
                  title: 'Вставьте секретный ключ',
                  placeholder: 'Секретный ключ',
                },
                memo: {
                  title: 'Memo',
                  placeholder: 'Введите Memo',
                },
                passphrase: {
                  title: 'Passphrase',
                  placeholder: 'Введите Passphrase',
                },
              },
            },
            payment: {
              promo: {
                button: {
                  cancel: 'Отмена',
                  apply: 'Применить',
                },
                placeholder: 'Промокод',
              },
            },
            configurator: {
              exchange: {
                title: 'Биржа',
              },
              market: {
                title: 'Рынок',
              },
              amount: {
                title: 'Торговая сумма',
                placeholder: 'Введите сумму для продолжения',
              },
              pairs: {
                title: 'Активные торговые пары',
              },
              orderType: {
                title: 'Тип ордера',
                items: {
                  market: 'Маркет',
                  limit: 'Лимит',
                },
              },
              additional: {
                orders: 'Ордеры',
                ordersSize: 'Размер ордера',
                priceChange: 'Изменение цены, %',
                priceChangeScale: 'Коэф. изменения цены',
              },
              take: {
                orders: 'Ордеры',
                ordersSize: 'Размер ордера',
                priceChange: 'Изменение цены, %',
                priceChangeScale: 'Коэф. изменения цены',
              },
              stop: {
                priceChange: 'Изменение цены, %',
              },
            },
            date: {
              start: 'Начальная дата',
              end: 'Конечная дата',
            },
            funds: {
              price: {
                origin: {
                  title: 'Ордер цена',
                  placeholder: '',
                },
                trigger: {
                  title: 'Триггер цена',
                  placeholder: '',
                  items: {
                    last: 'Ласт',
                    bid: 'Бид',
                    ask: 'Аск',
                  },
                },
              },
              units: {
                title: 'Количество',
                placeholder: '',
              },
              total: {
                title: 'Всего',
                placeholder: '',
              },
              button: 'Подтвердить',
            },
            'bot': {
              'update': {
                'name': {
                  'title': 'Название',
                  'placeholder': '',
                },
                'balance': {
                  'title': 'Торговая сумма',
                  'placeholder': '',
                },
                'button': 'Обновить',
              },
            },
          },
          header: {
            menu: {
              home: 'Главная',
              accounts: 'Аккаунты',
              terminal: {
                title: 'Терминал',
                items: {
                  terminal: {
                    title: 'Смарт-терминал',
                    text: 'Создавайте и управляйте продвинутыми позициями',
                  },
                  diary: {
                    title: 'Торговый дневник',
                    text: 'Отслеживайте и экспортируйте статистику',
                  },
                  introduction: {
                    title: 'Введение',
                    text: 'Изучите преимущества Смарт-терминала',
                  },
                },
              },
              bots: {
                title: 'Боты',
                items: {
                  all: {
                    title: 'Мои боты',
                    text: 'Сводная статистика и список ботов',
                  },
                  diary: {
                    title: 'Торговый дневник',
                    text: 'Отслеживайте и экспортируйте статистику',
                  },
                  marketplace: {
                    title: 'Маркетплейс',
                    text: 'Копируйте лучшие стратегии',
                  },
                  public: {
                    title: 'Мои публичные боты',
                    text: 'Следите за продажами своих ботов',
                  },
                  introduction: {
                    title: 'Введение',
                    text: 'Узнайте о возможностях ботов',
                  },
                },
                button: 'Новый бот',
              },
              subscriptions: 'Подписки',
              invite: 'Пригласить друзей',
            },
            button: 'Тур по платформе',
            switch: 'Демо',
            popup: {
              twoFactor: '2FA аутентификация',
              changePassword: 'Изменить пароль',
              logOut: 'Выйти',
              links: ['Оцените нас', 'Чат', 'Предложить идею', 'База знаний'],
              terms: 'Условия',
              and: 'и',
              policy: 'Политика конфиденциальности',
            },
          },
          home: {
            statistics: {
              total: {
                title: 'Общий P&L',
                tooltip:
                  'Суммарная прибыль по всем закрытым сделкам. Учитываются активные фильтры',
              },
              upl: {
                title: 'UP&L',
                tooltip:
                  'Текущая совокупная прибыль/убыток по открытым сделкам. Учитываются фильтры',
              },
              today: {
                title: 'Сегодняшний P&L',
                tooltip:
                  'Прибыль по закрытым сделкам за сегодня (сброс в полночь UTC+0). Учитываются фильтры',
              },
              trades: {
                title: 'Сделки',
                tooltip:
                  'Текущее число активных сделок / Всего сделок за выбранный период',
              },
              inTrades: {
                title: 'В сделках',
                tooltip:
                  'Сумма средств в активных сделках или лимитных ордерах. Учитываются фильтры',
              },
              select: {
                all: 'Все',
                manual: 'Ручные',
                bots: 'Боты',
                tooltips: [
                  'Выберите статистику по ручным сделкам, ботам или общую',
                  'Выберите рынок: все или конкретный актив (например, USDT)',
                ],
                placeholder: 'Источник',
              },
            },
            table: {
              title: 'Активы',
              columns: {
                token: 'Токен',
                share: 'Доля',
                change: 'Изм. 24ч',
                price: 'Цена',
                amount: 'Кол-во',
                total: 'Сумма',
              },
              search: {
                placeholder: 'Поиск по названию актива',
              },
            },
          },
          exchange: {
            list: {
              title: 'Выберите биржу',
              description:
                'Подключите биржевой аккаунт безопасно через OAuth или API ключи',
              parnter:
                'Skyrexio — официальный партнёр бирж без доступа к выводу или переводам',
              exchange: {
                title: 'Нет аккаунта?',
                create: 'Создать {{account}} аккаунт',
                switch: 'Переключиться на демо-аккаунт',
              },
              offer: {
                link: 'Зарегистрируйтесь',
                text: 'на Gate.io через Skyrexio и получите 15 USDT на баланс и 500 USDT скидку на комиссию',
              },
              review: {
                prefix: 'Нет нужной биржи?',
                link: 'Предложите добавить',
              },
            },
            connected: {
              title: 'Мои биржевые аккаунты',
              button: 'Подключить новую биржу',
              total: 'Всего',
              change: 'Изм 24ч',
              actions: {
                trade: 'Торговать',
                view: 'Просмотр',
                reset: 'Сброс',
                update: 'Обновить API',
                delete: 'Удалить',
              },
              lessBalance:
                'Баланс меньше $10. Пополните счёт, чтобы начать торговлю',
            },
            connect: {
              title: {
                connect: 'Подключить',
                exchange: 'биржу',
              },
              tabs: {
                fast: 'Быстрый OAuth',
                api: 'API ключи',
              },
              text: 'Подключите биржу безопасно с помощью {{type}}',
              button: 'Подключить',
              account: {
                prefix: 'Нет аккаунта?',
                text: 'Зарегистрируйтесь на {{name}} через Skyrexio и получите бонусы',
              },
              back: 'Назад к списку бирж',
              steps: {
                title: {
                  prefix: 'Безопасное подключение ключей',
                  link: 'Полное руководство',
                },
                1: '1. Войдите на биржу и откройте',
                2: {
                  ip: '2. Включите белый список IP и вставьте эти адреса:',
                  thirdParty: '2. Выберите сторонние приложения и отметьте Skyrexio:',
                },
                3: '3. Вставьте сгенерированные данные в поля справа',
              },
            },
            update: {
              title: {
                update: 'Обновить',
                exchange: 'биржу',
              },
              tabs: {
                fast: 'Быстрый OAuth',
                api: 'API ключи',
              },
              button: 'Обновить',
            },
          },
          terminal: {
            select: {
              exchange: 'Биржевой аккаунт',
              market: 'Рынок',
              pair: {
                title: 'Торговая пара',
                placeholder: 'Выберите торговую пару',
              },
            },
            table: {
              columns: {
                token: 'Токен',
                share: 'Доля',
                change: 'Изм. 24ч',
                price: 'Цена',
                amount: 'Кол-во',
                total: 'Сумма',
              },
            },
            panel: {
              switch: {
                buy: 'Купить',
                sell: 'Продать',
              },
              skip: 'Пропустить вход (исп. имеющиеся средства)',
              title: {
                buy: {
                  origin: 'Купить {{currency}}',
                  skip: 'Куплено {{currency}}',
                  tooltip: '{{currency}} доступно на биржевом аккаунте',
                },
                sell: {
                  origin: 'Продать {{currency}}',
                  skip: 'Продано {{currency}}',
                  tooltip: '{{currency}} доступно на биржевом аккаунте',
                },
              },
              segments: {
                limit: 'Лимит',
                market: 'Маркет',
                conditional: 'Условный',
              },
              fields: {
                price: {
                  origin: 'Цена ордера',
                  skip: {
                    buy: 'Цена покупки',
                    sell: 'Цена продажи',
                  },
                },
                units: 'Единицы',
                total: 'Итого',
                triggerPrice: {
                  title: 'Триггер цена',
                  segments: {
                    limit: 'Лимит',
                    market: 'Маркет',
                  },
                },
                types: {
                  last: 'Ласт',
                  bid: 'Бид',
                  ask: 'Аск',
                },
              },
              additional: {
                title: 'Дополнительный вход',
                segments: {
                  limit: 'Лимит',
                  market: 'Усл. маркет',
                  conditional: 'Усл. лимит',
                },
                table: {
                  title: 'Доп. входы',
                  columns: {
                    price: 'Цена',
                    total: 'Всего',
                  },
                },
                button: 'Добавить вход',
              },
              takeProfit: {
                title: 'Тейк профит',
                slider: {
                  title: 'Средний',
                  tooltip:
                    'Автоматическая корректировка TP при усреднении цены входа (пример: вход $100, TP +10%, усреднение до $95 => TP скорректируется к +10%)',
                },
                segments: {
                  limit: 'Лимит',
                  market: 'Усл. маркет',
                },
                columns: {
                  price: 'Цена',
                  volume: 'Объем',
                },
                button: {
                  add: 'Добавить цель',
                  split: 'Разделить цели',
                },
                all: 'Все цели заданы',
              },
              stopLoss: {
                title: 'Стоп лосс',
                slider: {
                  title: 'Средний',
                  tooltip:
                    'Автоматическая корректировка SL при усреднении входа (пример аналогичен TP)',
                },
                segments: {
                  limit: 'Усл. лимит',
                  market: 'Усл. маркет',
                },
                columns: {
                  price: 'Цена',
                  volume: 'Объем',
                },
              },
              button: {
                create: 'Создать сделку',
                update: 'Обновить сделку',
                cancel: 'Отмена',
              },
            },
            trades: {
              header: {
                pair: 'Пара',
                account: 'Аккаунт',
                bot: 'Бот',
                date: 'Дата начала',
                volume: 'Объём',
                entries: 'Входы',
                progress: 'Прогресс',
                status: 'Статус',
                return: 'Доход',
                actions: 'Действия',
              },
              columns: {
                price: {
                  origin: 'Цена',
                  conditional: 'Усл. цена',
                },
                units: 'Единицы',
                total: 'Итого',
                type: 'Тип',
                status: {
                  title: 'Статус',
                  items: {
                    active: 'активен',
                    completed: 'завершен',
                    new: 'обработка',
                    await_send: 'обработка',
                    request_active: 'обработка',
                    cancel_after_active: 'обработка',
                    request_cancel: 'обработка',
                    cancelled: 'отменен',
                    create_failed: 'ошибка',
                    cancel_failed: 'ошибка',
                    requested_cancelled: 'обработка',
                    failed: 'ошибка',
                    request_completed: 'обработка',
                  },
                },
              },
              actions: {
                funds: {
                  add: 'Добавить средства',
                  reduce: 'Убавить средства',
                },
                market: {
                  close: 'Закрыть по Market',
                  open: 'Открыть по Market',
                },
                limit: {
                  close: 'Закрыть по Limit',
                  open: 'Открыть по Limit',
                },
                edit: 'Редактировать',
                share: {
                  origin: 'Поделиться',
                  result: 'Поделиться Результатом',
                },
                cancel: 'Отменить',
              },
              progress: {
                marks: {
                  entry: 'Вход',
                  ae: 'ДВ',
                  sl: 'СЛ',
                  tp: 'ТП',
                },
              },
            },
            diary: {
              title: 'Торговый дневник',
              button: 'Экспорт',
              select: {
                exchange: {
                  title: 'Биржевой аккаунт',
                  placeholder: 'Выберите аккаунт',
                  items: {
                    all: 'Все',
                  },
                },
                side: {
                  title: 'Сторона',
                  placeholder: 'Выберите сторону',
                  items: {
                    buy: 'Купить',
                    sell: 'Продать',
                  },
                },
                date: {
                  create: 'Даты создания',
                  close: 'Даты закрытия',
                },
                market: {
                  title: 'Рынок',
                  placeholder: 'Выберите рынок',
                  items: {
                    all: 'Все',
                  },
                },
                currency: {
                  title: 'Базовая валюта',
                  placeholder: 'Выберите базовую валюту',
                  items: {
                    all: 'Все',
                  },
                },
                status: {
                  title: 'Статус',
                  placeholder: 'Выберите статус',
                  items: {
                    all: 'Все',
                    completed: 'Завершенные',
                    active: 'Активные',
                    cancelled: 'Отмененные',
                  },
                },
              },
              winRate: {
                title: 'Успешных',
                items: {
                  total: 'Всего P&L',
                  upl: 'UP&L',
                  trades: 'Всего сделок',
                  inTrades: 'В сделках',
                },
              },
              table: {
                search: {
                  placeholder: 'Поиск по паре',
                },
                columns: {
                  pair: 'Пара',
                  account: 'Аккаунт',
                  date: {
                    create: 'Дата создания',
                    close: 'Дата закрытия',
                  },
                  status: 'Статус',
                  total: 'Итого',
                  price: {
                    entry: 'Цена входа',
                    close: 'Цена закрытия',
                  },
                  return: 'Доход',
                },
              },
            },
            introduction: {
              button: {
                create: 'Создать сделку',
                open: 'Открыть терминал',
              },
              welcome: {
                title: 'Добро пожаловать в Умный терминал',
                automate: {
                  title: 'Автоматизируйте рутину',
                  text: 'Настраивайте множественные входы, цели по прибыли и одновременные стоп-лоссы с условными ордерами',
                },
                manage: {
                  title: 'Управляйте позициями',
                  text: 'Контроль сделок в реальном времени, редактирование на лету, ручное управление и мгновенная статистика',
                },
                share: {
                  title: 'Делитесь сделками',
                  text: 'Легко делитесь и управляйте мультиордерными позициями в своём сообществе',
                },
                control: {
                  title: 'Контролируйте просадки',
                  text: 'Автоматически продавайте и покупайте дешевле во время падений, даже на спотовых рынках',
                },
              },
              steps: {
                1: 'Выберите торговую пару',
                2: 'Задайте условия входа',
                3: 'Задайте условия закрытия',
                4: 'Управляйте сделками в реальном времени',
              },
              positions: {
                title: 'Создавайте позиции и отдыхайте',
                text: 'Размещайте мультиордерные позиции на ведущих биржах через мощную и удобную панель ордеров — больше не нужно следить за графиками!',
              },
              perfomance: {
                title: 'Результаты наших пользователей',
                items: {
                  trades: {
                    placed: 'Создано сделок',
                    executed: 'Исполнено сделок',
                  },
                  orders: 'Поделились ордерами',
                  volume: 'Торговый объём',
                },
              },
              control: {
                title: 'Умный контроль сделок',
                text: 'Полный контроль над активными позициями в реальном времени: следите за прогрессом, управляйте размером, настраивайте риски, закрывайте или отменяйте сделки в любой момент.',
              },
              effortless: {
                title: 'Лёгкий шаринг сделок',
                text: 'Делитесь мультиордерными позициями через ссылку, позволяя вашему сообществу копировать настройки в один клик',
              },
              ending: {
                title: 'Держите рынок под контролем с Умным терминалом',
                text: 'Используйте мощные функции: мультиордерное исполнение, аналитику в реальном времени и динамическое управление сделками, чтобы опережать рынок и увеличивать прибыль',
              },
            },
          },
          bots: {
            title: 'Мои боты',
            button: 'Добавить бота',
            table: {
              title: 'Боты',
              search: {
                placeholder: 'Поиск по боту',
              },
              columns: {
                name: 'Название',
                exchange: 'Биржа',
                market: 'Рынок',
                type: {
                  title: 'Тип',
                  alert: 'Алерт бот',
                  copy: 'Копи бот',
                },
                balance: 'Баланс',
                trades: 'Сделки',
                inTrades: 'В сделках',
                upl: {
                  origin: 'UP&L',
                  month: '30д P&L',
                },
                actions: {
                  title: 'Действия',
                  items: {
                    close: 'Закрыть сделки по Маркету',
                    cancel: 'Отменить сделки',
                    edit: 'Редактировать',
                    delete: 'Удалить',
                  },
                },
              },
            },
            statistic: {
              items: {
                total: {
                  bots: {
                    title: 'Всего ботов',
                    tooltip: 'Количество уникальных включенных и выключенных ботов',
                  },
                  amount: {
                    title: 'Общий баланс',
                    tooltip: 'Сумма торговых балансов текущих ботов',
                  },
                  trades: {
                    title: 'Всего сделок',
                    tooltip: 'Прибыль, полученная от закрытых сделок (обнуляется каждый день в полночь по UTC). На эту статистику влияют активные фильтры',
                  },
                },
                pl: {
                  total: {
                    title: 'Всего P&L',
                    tooltip: 'Общая прибыль за выбранный период, полученная от всех закрытых сделок. На эту статистику влияют активные фильтры',
                  },
                  today: {
                    title: 'Текущий P&L',
                    tooltip: 'Прибыль от завершенных сегодня и активных сделок (обнуляется каждый день в полночь по UTC). На эту статистику влияют активные фильтры',
                  },
                },
              },
            },
            diary: {
              title: 'Торговый дневник',
              button: 'Экспорт',
              select: {
                exchange: {
                  title: 'Биржевой аккаунт',
                  placeholder: 'Выберите аккаунт',
                  items: {
                    all: 'Все',
                  },
                },
                bot: {
                  title: 'Бот',
                  placeholder: 'Выберите бота',
                  items: {
                    all: 'Все',
                  },
                },
                date: {
                  create: 'Даты создания',
                  close: 'Даты закрытия',
                },
                market: {
                  title: 'Рынок',
                  placeholder: 'Выберите рынок',
                  items: {
                    all: 'Все',
                  },
                },
                currency: {
                  title: 'Базовая валюта',
                  placeholder: 'Выберите валюту',
                  items: {
                    all: 'Все',
                  },
                },
                status: {
                  title: 'Статус',
                  placeholder: 'Выберите статус',
                  items: {
                    all: 'Все',
                    completed: 'Завершенные',
                    active: 'Активные',
                    cancelled: 'Отмененные',
                  },
                },
              },
              winRate: {
                title: 'Успешных',
                items: {
                  total: 'Всего P&L',
                  upl: 'UP&L',
                  trades: 'Всего сделок',
                  inTrades: 'В сделках',
                },
              },
              table: {
                search: {
                  placeholder: '',
                },
                columns: {
                  pair: 'Пара',
                  bot: 'Бот',
                  date: {
                    create: 'Дата создания',
                    close: 'Дата закрытия',
                  },
                  status: 'Статус',
                  total: 'Итого',
                  price: {
                    entry: 'Цена входа',
                    close: 'Цена закрытия',
                  },
                  entries: 'Входы',
                  return: 'Доход',
                },
              },
            },
            public: {
              title: 'Мои публичные боты',
              button: 'Опубликовать нового бота',
              subscriptions: {
                title: 'Подписки',
                select: {
                  items: {
                    all: 'Все',
                  },
                },
                items: {
                  total: 'Всего',
                  active: 'Активные',
                  pending: 'Ожидают',
                  unique: 'Уник. пользователи',
                },
              },
              sales: {
                title: 'Продажи',
                items: {
                  total: 'Всего',
                  withdrawn: 'Выведено',
                  available: 'Доступно',
                },
              },
              table: {
                title: 'Выводы',
                export: 'Экспорт',
                columns: {
                  amount: 'Сумма',
                  transaction: 'Транзакция',
                  wallet: 'Кошелёк',
                  date: {
                    created: 'Дата создания',
                    paid: 'Дата выплаты',
                  },
                  status: {
                    title: 'Статус',
                    items: {
                      pending: 'обработка',
                      paid: 'оплачен',
                      deleted: 'удален' ,
                    },
                  },
                },
              },
              empty: {
                description:
                  'У вас нет опубликованных ботов. Создайте алерт-бот, поделитесь им и зарабатывайте на комиссиях!',
              },
              card: {
                button: 'Изменить элемент',
              },
            },
            introduction: {
              copy: {
                welcome: {
                  title: 'Добро пожаловать в Копи боты',
                  start: {
                    title: 'Начните бесплатно',
                    text: 'Копируйте любых ботов бесплатно — без карты и скрытых комиссий',
                  },
                  copy: {
                    title: 'Копируйте в один клик',
                    text: 'Просто укажите желаемый торговый объём, остальное возьмёт на себя мастер бот',
                  },
                  monthly: {
                    title: 'Ежемесячный расчёт',
                    text: 'Распределение прибыли происходит ежемесячно, включая активные позиции — без сиюминутных комиссии за закрытые сделки',
                  },
                  high: {
                    title: 'Защита по принципу high-water mark',
                    text: 'Отрицательные результаты переносятся на будущие периоды, защищая от двойных комиссий',
                  },
                },
                steps: {
                  1: 'Выберите бота в Маркетплейсе',
                  2: 'Нажмите «Копировать бота»',
                  3: 'Укажите торговый объём',
                  4: 'Получайте автоматические сделки',
                },
                positions: {
                  title: 'Получайте трейды без усилий',
                  text: 'Мультиордерные позиции на ведущих биржах, открываются ботами автоматически — больше не нужно следить за графиками!',
                },
                perfomance: {
                  title: 'Результаты наших пользователей',
                  items: {
                    trades: 'Скопировано сделок',
                    bots: 'Запущено Копи ботов',
                    profit: 'Заработано прибыли',
                    volume: 'Объём торговли',
                  },
                },
                marketplaceTitle: 'Готовые боты для мгновенной автоматизации торговли',
                fair: {
                  title: 'Справедливый стандарт копи-трейдинга',
                  text: 'Никаких авансовых платежей — только разделение прибыли по итогам месяца с защитой high-water mark. Комиссии взимаются, когда ваш портфель превышает изначальную сумму, учитывая предыдущие убытки',
                },
                control: {
                  title: 'Умный контроль над сделками',
                  text: 'Полное управление активными позициями в реальном времени с прогресс-баром и кнопками действий: контролируйте объём, настраивайте риск-менеджмент, закрывайте или отменяйте сделку в любое время',
                },
                ending: {
                  title: 'Копируйте боты одним кликом вместе со Skyrexio',
                  text: 'Получайте автоматические сделки под управлением профессионалов без вашего участия с защитой high-water mark и ежемесячным разделением прибыли',
                },
              },              
              'alert': {
                'welcome': {
                  'title': 'Добро пожаловать в Алерт боты',
                  'universal': {
                    'title': 'Универсальная автоматизация',
                    'text': 'Преобразуйте сигналы из TradingView и других источников в сделки на крупнейших биржах — с поддержкой стратегий',
                  },
                  'control': {
                    'title': 'Контроль и аналитика',
                    'text': 'Управляйте сделками в реальном времени и анализируйте результаты с помощью графиков и журнала',
                  },
                  'easy': {
                    'title': 'Простая настройка',
                    'text': 'Открывайте и управляйте позициями с помощью алертов от скриптов или задавайте риск-менеджмент в конфигураторе',
                  },
                  'share': {
                    'title': 'Делитесь и зарабатывайте',
                    'text': 'Увеличьте прибыль с помощью простого и масштабируемого копитрейдинга на Маркетплейсе',
                  },
                },
                'steps': {
                  '1': 'Сигналы генерируются вашим скриптом',
                  '2': 'Бот обрабатывает сигналы',
                  '3': 'Ордеры исполняются на бирже',
                  '4': 'Управляйте сделками в реальном времени',
                },
                'relax': {
                  'title': 'Отдыхайте, пока боты занимаются рутиной',
                  'text': 'Skyrexio помогает трейдерам торговать умнее, снижая эмоциональное влияние и автоматизируя рутинные задачи',
                },
                'perfomance': {
                  'title': 'Достижения наших пользователей',
                  'items': {
                    'alerts': 'Исполнено алертов',
                    'bots': 'Запущено ботов',
                    'trades': 'Создано сделок',
                    'volume': 'Объём торгов',
                  },
                },
                'execution': {
                  'title': 'Автоматическое исполнение сигналов',
                  'text': 'Преобразуйте сигналы из TradingView, Java и Python-скриптов в ордера на вашем биржевом аккаунте. Управляйте несколькими парами с помощью одного бота',
                },
                'control': {
                  'title': 'Умный контроль над сделками',
                  'text': 'Полное управление активными позициями в реальном времени с прогресс-баром и кнопками действий: контролируйте объём, регулируйте \nриск-менеджмент, закрывайте или отменяйте сделку в любой момент',
                },
                'configuratorTitle': 'Универсальный и удобный конфигуратор для быстрой настройки',
                'share': {
                  'title': 'Делитесь своими ботами',
                  'text': 'Расширьте возможности своего трейдинг-сообщества, позволяя копировать ваших ботов одним кликом. Сделки и настройки синхронизируются автоматически у всех участников. Повышайте продажи с помощью Маркетплейса Skyrexio и получайте вознаграждения от прибыли',
                },
                'tradingView': {
                  'title': 'Интеграция с TradingView',
                  'text': 'Skyrexio позволяет использовать все преимущества PineScript от TradingView с автоматическим выполнением через вебхук индикаторов и стратегий любой сложности',
                },
                'ending': {
                  'title': 'Исполняйте скрипты на ведущих биржах вместе со Skyrexio',
                  'text': 'Возьмите под контроль свои сделки с помощью Alert Bot, который подстраивается под ваши предпочтения и решает сложные задачи, убирая эмоции из процесса',
                },
              },
              button: {
                create: {
                  trade: 'Создать сделку',
                  bot: 'Создать алерт бота',
                },
                choose: 'Выбрать бота',
                open: 'Открыть конфигуратор',
              },
            },
          },
          'bot': {
            'status': {
              'on': 'Вкл.',
              'off': 'Выкл.',
            },
            'button': 'Добавить нового бота',
            'remote': {
              'pl': {
                'total': {
                  'title': 'Общий P&L',
                  'tooltip': 'Общая прибыль за всё время, полученная от всех закрытых сделок. Обратите внимание: данная статистика зависит от активных фильтров.',
                },
                'today': {
                  'title': 'Сегодняшний P&L',
                  'tooltip': 'Прибыль от закрытых сделок за текущий день (обнуляется в полночь UTC+0). Обратите внимание: данная статистика зависит от активных фильтров.',
                },
              },
              'upl': {
                'title': 'UP&L',
                'tooltip': 'Нереализованный P&L показывает текущую прибыль или убыток по всем открытым позициям',
              },
              'trades': {
                'title': 'Сделки',
                'value': '{{activeTrades}} активных / {{totalTrades}} всего',
                'tooltip': 'Текущее количество активных сделок / общее количество сделок за выбранный период',
              },
              'funds': {
                'title': 'Фонды',
                'tooltip': 'Сумма в котируемой валюте, используемая в активных сделках / общая сумма котируемой валюты, выделенная боту (процент уже задействованных средств)',
              },
              'actions': {
                'start': 'Запустить',
                'stop': 'Остановить',
                'edit': 'Редактировать',
                'delete': 'Удалить',
              },
              'links': {
                'sales': 'Продажи',
                'subscriptions': 'Подписки',
                'alert': 'Сообщения сигналов',
                'signal': 'Источник сигналов',
              },
            },
            'chart': {
              'segments': {
                'sum': 'Сумма',
                'day': 'День',
                'pair': 'Пара',
              },
            },
            'table': {
              'title': 'История торговли',
              'search': {
                'placeholder': 'Поиск по паре',
              },
              'columns': {
                'pair': 'Пара',
                'date': {
                  'create': 'Дата создания',
                  'close': 'Дата закрытия',
                },
                'status': {
                  'title': 'Статус',
                  'items': {
                    'pending': 'В ожидании',
                    'cancelled': 'Отменено',
                    'active': 'Активна',
                    'completed': 'Завершена',
                    'failed': 'Неудачна',
                  },
                },
                'total': 'Итого',
                'price': {
                  'entry': 'Цена входа',
                  'close': 'Цена выхода',
                },
                'entries': 'Входы',
                'return': 'Доход',
              },
            },
          },
          marketplace: {
            title: 'Готовые боты для мгновенной автоматизации торговли',
            select: {
              exchange: {
                title: 'Биржа',
                placeholder: 'Выберите биржу',
              },
              market: {
                title: 'Рынок',
                placeholder: 'Выберите рынок',
              },
              roi: {
                title: 'Мин. ROI',
                placeholder: 'Напр.: 1',
              },
              days: {
                title: 'Мин. дней работы',
                placeholder: 'Напр.: 30',
              },
            },
            card: {
              available: 'Доступно на:',
              roi: 'ROI',
              winRate: 'Успешных',
              drawdown: 'Макс. просадка',
              runtime: 'В работе',
              investment: 'Мин. вложение',
              free: 'Комиссия',
              button: {
                copy: 'Копировать',
                view: 'Посмотреть',
              },
            },
          },
          subscriptions: {
            title: 'Мой план',
            plan: {
              title: {
                no: 'Нет плана',
                payment: 'Оплата сегодня',
                current: '{{plan}} план',
                left: ' {{days}} осталось',
              },
              text: {
                no: 'Бесплатный Базовый план',
                lifetime: 'Бессрочный доступ навсегда',
                trial: 'Бесплатные возможности Pro плана до {{countedPaymentDate}}',
                next: 'Следующая оплата: {{fee}} {{countedPaymentDate}}',
              },
              included: 'Что входит?',
              button: 'Сменить план',
            },
            table: {
              title: 'Подписки',
              select: {
                all: 'Все',
                skyrexio: 'Skyrexio',
                copy: 'Копи-боты',
              },
              button: 'Экспорт',
              columns: {
                name: 'Название',
                type: 'Тип',
                date: {
                  start: 'Дата начала',
                  end: 'Дата окончания',
                },
                fee: 'Плата',
                status: 'Статус',
                actions: 'Действия',
              },
            },
            about: {
              title: 'О планах',
              plans: {
                basic: {
                  title: 'базовый',
                  text: 'Базовые инструменты, копи-бот со стандартной комиссией или собственный бот — без подписки',
                },
                advanced: {
                  title: 'продвинутый',
                  text: 'Увеличьте кол-во аккаунтов, активных сделок и ботов, публикуйте ботов, получайте поддержку в реальном времени',
                },
                pro: {
                  title: 'про',
                  text: 'Полный функционал, сниженная комиссия ботов Skyrexio, приватные PineScript-стратегии и премиум-поддержка',
                },
              },
            },
            questions: {
              title: 'Есть вопросы?',
              items: [
                {
                  title: 'Можно ли улучшить, понизить или отменить план Skyrexio?',
                  text: 'Вы можете перейти на более высокий или длительный план, неиспользованное время конвертируется. Понизить нельзя, но можно отменить через поддержку, перейти на Basic, вернуть средства за остаток и заново выбрать нужный план',
                },
                {
                  title: 'Чем отличается план Skyrexio от подписки на копи-бот?',
                  text: 'План Skyrexio — фиксированная оплата за расширенные лимиты и уменьшенный процент комиссии ботов, а подписка на копи-бот оплачивается по результату: доля прибыли списывается по окончании периода, убытки учитываются через high-water mark',
                },
                {
                  title: 'Нужен ли мне платный план Skyrexio и подписка на копи-бот одновременно?',
                  text: 'Платные планы удобны для нескольких аккаунтов, больших портфелей и низкой комиссии ботов. Один аккаунт с небольшим депозитом может обойтись бесплатным Basic-планом и лишь платить процент за копи-бот',
                },
                {
                  title: 'Можно ли включить автоплатёж подписки?',
                  text: 'Для планов Skyrexio через Stripe можно, но для подписок на боты (Marketplace) автоплатёж пока недоступен',
                },
              ],
            },
          },
          invite: {
            share: {
              title: {
                create: 'Создайте ваш реферальный код',
                origin: 'Поделитесь ссылкой:',
              },
              placeholder: 'Введите код, который хотите',
              button: 'Создать',
            },
            payments: {
              title: 'Выплаты',
              button: 'Экспорт',
              items: {
                share: 'Доля',
                cliks: 'Клики',
                registrations: 'Регистрации',
                payments: 'Оплаты',
              },
              income: {
                title: 'Доход',
                items: {
                  total: 'Всего',
                  withdrawan: 'Выведено',
                  available: 'Доступно',
                },
                text: 'Заявка обработается за 24 часа',
                button: 'Вывести',
              },
              columns: {
                subscription: 'Подписка',
                type: 'Тип',
                paid: {
                  date: 'Дата оплаты',
                  amount: 'Оплачено, USDT',
                },
                share: 'Ваша доля, USDT',
              },
              level: '{{level}} уровень',
            },
            withdrawals: {
              title: 'Выводы',
              button: 'Экспорт',
              columns: {
                amount: 'Сумма',
                transaction: 'Транзакция',
                wallet: 'Кошелёк',
                date: {
                  create: 'Дата создания',
                  paid: 'Дата выплаты',
                },
                status: {
                  title: 'Статус',
                  items: {
                    pending: 'обработка',
                    paid: 'оплачен',
                    deleted: 'удален' ,
                  },
                },
              },
            },
            branding: {
              title: 'Бренд-материалы',
              logos: {
                title: 'Логотипы Skyrexio',
                button: 'Скачать лого',
              },
              banners: {
                title: 'Баннеры Skyrexio',
                button: 'Скачать баннер',
              },
            },
          },
          configurator: {
            steps: {
              1: 'Шаг 1 - Выберите аккаунт, рынок и сумму',
              2: 'Шаг 2 - Настройте алерты для ордеров входа',
              3: 'Шаг 3 - Укажите усреднение и закрытия',
              4: 'Шаг 4 - Настройте ордера закрытия',
              5: 'Шаг 5 - Создайте текст алерта',
            },
            button: {
              next: 'Следующий шаг',
              back: 'Назад',
              create: 'Создать бота',
              update: 'Обновить бота',
              builder: 'Открыть конфигуратор сигналов',
            },
            basic: {
              title:
                'Алерт-боты превращают сигналы индикаторов и стратегий в ордера на ваших биржевых аккаунтах',
              links: {
                read: 'Читать статьи',
                use: 'Готовые скрипты',
              },
              pairs: {
                preffix:
                  'Бот может работать с несколькими парами — настройте алерты на рекомендуемые монеты или используйте',
                and: 'и',
                links: {
                  screener: 'Скринер',
                  heatmap: 'Карта валют',
                },
              },
            },
            source: {
              tabs: {
                indicator: 'Индикатор',
                strategy: 'Стратегия',
                custom: 'Своё',
                label: {
                  title: 'Выберите источник алерта для ордера входа',
                  tooltip: '',
                },
              },
              indicator: {
                url: {
                  title: 'Webhook URL',
                  description:
                    'Скопируйте и вставьте в окно настройки уведомлений на',
                },
                message: {
                  title: 'Alert message',
                  description:
                    'Скопируйте и вставьте этот текст в сообщение алерта на',
                },
              },
              strategy: {
                function: {
                  title: 'Параметр функции entry',
                  description:
                    'Добавьте этот параметр в функцию входа PineScript на',
                },
                sccipt: {
                  title: 'Параметр PineScript',
                  description:
                    'Добавьте этот параметр в функцию выхода PineScript на',
                },
              },
              custom: {
                url: {
                  title: 'Webhook URL',
                  description:
                    'Отправьте POST-запрос с JSON на этот адрес',
                },
                json: {
                  title: 'JSON для действия бота',
                  description:
                    'Настройте POST-запрос с JSON, чтобы выполнить базовый вход',
                },
                jsonEntry: {
                  title: 'JSON для выполнения базового входа',
                  description:
                    'Настройте POST с JSON для выполнения базового входа',
                },
              },
              basic: {
                title:
                  'Выполняйте действия бота, отправляя JSON на webhook URL из своего приложения или вручную',
                link: 'Как использовать кастомные алерты',
                headers: {
                  field: 'Поле',
                  type: 'Тип',
                  description: 'Описание',
                },
                items: {
                  base: 'Торговый символ, напр. ADA',
                  quote: 'Рынок бота, напр. USDT',
                  position: '"entry1", "entry2" и т.д. для входа, "close" для выхода',
                  price: 'Цена исполнения (для Market-ордеров текущая)',
                  sourceUuid: 'Уникальный идентификатор бота (см. ниже)',
                  secretToken: 'Токен защиты бота (см. ниже)',
                  timestamp: 'Текущее время в UNIX формате',
                },
              },
            },
            managment: {
              tabs: {
                auto: 'Авто',
                custom: 'Свое',
                label: {
                  title: 'Выберите тип риск-менеджмента',
                  tooltip: '',
                },
              },
              auto: {
                presets: [
                  {
                    title: 'Консервативный',
                    settings: {
                      display: [
                        {
                          title: 'Overlap', value: '-30%', 
                        },
                        {
                          title: 'Take profit', value: '4%', 
                        },
                        {
                          title: 'Stop loss', value: 'Disabled', 
                        },
                      ],
                    },
                    caption: 'Усреднение (DCA) с широкими точками входа',
                    description:
                      'Крупные покупки при снижении цены, снижая риск в волатильных рынках',
                  },
                  {
                    title: 'Агрессивный',
                    settings: {
                      display: [
                        {
                          title: 'Overlap', value: 'Disabled', 
                        },
                        {
                          title: 'Take profit', value: '5%', 
                        },
                        {
                          title: 'Stop loss', value: '-2%', 
                        },
                      ],
                    },
                    caption: 'Торговля на импульсе с высоким отношением риск/прибыль',
                    description:
                      'Работает на сильных восходящих трендах, широкий SL и TP для макс. прибыли',
                  },
                  {
                    title: 'Умеренный',
                    settings: {
                      display: [
                        {
                          title: 'Overlap', value: '-10%', 
                        },
                        {
                          title: 'Take profit', value: '6%', 
                        },
                        {
                          title: 'Stop loss', value: '-4%', 
                        },
                      ],
                    },
                    caption: 'Свинг-трейдинг с распределёнными входами',
                    description:
                      'Улавливает колебания в боковом рынке или плавном ап-тренде',
                  },
                ],
              },
              custom: {
                additional: {
                  title: 'Доп. входы',
                  tabs: {
                    preset: 'Пресет',
                    alert: 'Алерт',
                    tooltip: {
                      preset:
                        'Пресет: настройка дополнительных входов при достижении цены',
                      alert:
                        'Алерт: динамический вход с алертами индикаторов/стратегий',
                    },
                  },
                },
                close: {
                  title: 'Закрывающие ордеры',
                  tabs: {
                    preset: 'Пресет',
                    alert: 'Алерт',
                    tooltip: {
                      preset:
                        'Пресет: настройка закрытий при достижении цены',
                      alert:
                        'Алерт: динамическое закрытие через алерты индикаторов/стратегий',
                    },
                  },
                },
                take: {
                  title: 'Тейк профит',
                },
                stop: {
                  title: 'Закрывающие ордеры',
                },
              },
              panel: {
                common: {
                  entry: {
                    title: 'Открывающий ордер',
                    item: {
                      title: 'Размер ордера входа',
                    },
                  },
                  additional: {
                    title: 'Доп.ордера входа',
                    headers: {
                      order: 'Размер ордера',
                      price: 'Изменение цены',
                    },
                  },
                  full: 'Полная позиция на монету',
                  close: {
                    title: 'Ордера закрытия',
                    take: {
                      title: 'Тейк профит',
                    },
                    stop: {
                      title: 'Стоп лосс',
                    },
                  },
                },
                additional: {
                  entry: {
                    title: 'Ордер входа',
                    item: {
                      title: 'Размер ордера входа',
                    },
                  },
                  additional: {
                    title: 'Доп.ордера входа',
                    headers: {
                      order: 'Размер ордера',
                      price: 'Изменение цены',
                      alert: 'Алерт',
                      script: 'PineScript',
                      json: 'JSON',
                    },
                    full: 'Полная позиция на монету',
                  },
                },
                close: {
                  title: 'Закрывающие ордеры',
                  table: {
                    title: 'Тейк профиты',
                    headers: {
                      volume: 'Объем',
                      price: 'Изменение цены',
                    },
                  },
                  stop: 'Стоп лосс',
                },
              },
            },
          },
          unknown: {
            title: 'О нет! Что вы наделали? \n Вы сломали Skyrexio!',
            description: 'Шутка! Все в порядке, но страница, которую вы ищете не найдена',
            back: 'Давайте вернемся на Главную страницу!',
            button: 'Вернуться на Главную',
          },
        },
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', () => {
  dayjs.locale(i18n.language);
});
export default i18n;
