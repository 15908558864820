import { Stack } from '@mui/material';
import { Text } from 'shared/ui';
import { ISocialProps } from '../interfaces';
import { ICON, TEXT, WRAPPER } from '../styles';

export const Social = (props: ISocialProps) => {
  const {
    icon,
    text,
    action,
  } = props;

  return (
    <Stack
      sx={WRAPPER}
      onClick={action}
    >
      <Stack sx={ICON}>
        {icon}
      </Stack>

      <Text styles={TEXT}>
        {text}
      </Text>
    </Stack>
  );
};
