import { WebSocketHandlerParams } from '../types/chart-view.types';
import { WebSocketHandler } from '../types/chart-view.types';
import { createWebSocketHandler } from './method-selector';

export class ConnectionManager {
  private connections: Map<string, WebSocketHandler> = new Map();
  /**
     * Подписка: перед подпиской автоматически производится отписка (если уже существует),
     * затем создаётся новое подключение и сохраняется в менеджере.
     */
  async subscribe(params: WebSocketHandlerParams): Promise<WebSocket> {
    // Если ранее существовало подключение для этого subscriberUID – отписываемся
    this.unsubscribe(params.subscriberUID);
    const handler = createWebSocketHandler(params);
    const ws = await handler.connect();
    this.connections.set(params.subscriberUID, handler);
    return ws;
  }
  
  /**
     * Отписка по subscriberUID – закрывает подключение и удаляет его из менеджера
     */
  unsubscribe(subscriberUID: string): void {
    const handler = this.connections.get(subscriberUID);
    if (handler) {
      handler.disconnect();
      this.connections.delete(subscriberUID);
    }
  }
}
