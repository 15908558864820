import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { authTemplates } from 'shared/consts';
import { useLanguage } from 'shared/hooks';
import { BaseLayout } from 'app/layouts';
import AppRouter from 'app/providers/router/ui/app-router';

function App() {
  const location = useLocation();

  const withLayout = !authTemplates.includes(location.pathname);

  useLanguage();

  useEffect(() => {
    const app = (window as any).Telegram?.WebApp;

    app?.ready();
  }, []);
  
  return (
    withLayout ? (
      <BaseLayout>
        <AppRouter />
      </BaseLayout>
    ) : (
      <AppRouter />
    )
  );
}

export default App;
