import { Box } from '@mui/material';
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';
import { IMenuItem, IMenuItemChildren } from 'widgets/header/interfaces';
import { MainButton, TextButton } from 'shared/ui';
import { itemChildrenLabel, itemChildrenText } from '../styles';

interface IRenderMenu {
  menuItems: IMenuItem[];
  closePopupHandler?: () => void;
}
export const renderMenu = (params: IRenderMenu) => {
  const {
    menuItems,
    closePopupHandler,
  } = params;

  const items = menuItems.map((item: IMenuItem) => {
    if (item.children) {
      return (
        <Collapse
          key={item.key}
          ghost={true}
          items={[{
            key: item.key,
            showArrow: false,
            label: (
              <MainButton
                size='large'
                styles={itemChildrenLabel}
              >
                {item.label}
              </MainButton>
            ),
            children: (
              <Box
                display='flex'
                flexDirection='column'
                gap={0.5}
              >
                {item.children?.map((children: IMenuItemChildren) => (
                  <Link
                    key={children.label}
                    to={children.link}
                    onClick={closePopupHandler}
                  >
                    <TextButton styles={itemChildrenText}>
                      {children.label}
                    </TextButton>
                  </Link>
                ))}
              </Box>
            ),
          }]}
        />
      );
    }

    return (
      <Link
        key={item.key}
        to={item.link}
        onClick={closePopupHandler}
      >
        <MainButton
          size='large'
          styles={itemChildrenLabel}
        >
          {item.label}
        </MainButton>
      </Link>
    );
  });

  return items;
};
