import { SxProps, Theme } from '@mui/material';

export const ICON: SxProps<Theme> = {
  width: 50,
  height: 50,
  placeItems: 'center',
  backgroundColor: 'rgba(157, 157, 157, .1)',
  borderRadius: '50%',
  '&>svg': {
    width: '100%',
    height: '100%',
    maxWidth: 18,
  },
};
