import { CSSProperties } from 'react';
import { getFontSize } from 'shared/helpers';

interface IValue {
  type: 'entry' | 'current' | 'roi';
  color?: string;
}
export const VALUE = (params: IValue) => {
  const styles: CSSProperties = {
    fontSize: params.type === 'entry' || params.type === 'current' ? getFontSize(9, 14) : getFontSize(16, 24),
    fontWeight: 600,
    lineHeight: 'normal',
    color: '#001C22',
  };

  if (params.type === 'roi') {
    styles.color = params.color;
  }

  return styles;
};
