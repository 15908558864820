export const items = (t: any) => {
  return [{
    icon: '/images/onboarding/copy-bot.png',
    title: t('modal.onboarding.start.copy.title'),
    description: t('modal.onboarding.start.copy.description'),
    link: '/trading-bots/marketplace',
  }, {
    icon: '/images/onboarding/alert-bot.png',
    title: t('modal.onboarding.start.alert.title'),
    description: t('modal.onboarding.start.alert.description'),
    link: '/trading-bots/create',
  }, {
    icon: '/images/onboarding/terminal.png',
    title: t('modal.onboarding.start.terminal.title'),
    description: t('modal.onboarding.start.terminal.description'),
    link: '/manual-trading/trading-terminal',
  }];
};
