import { Box } from '@mui/material';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { Text } from 'shared/ui';
import {
  All,
  ConfettiLeft,
  ConfettiRight,
  Multi,
  Unlimited,
} from '../icons';
import { ITrial } from '../interfaces';
import {
  content,
  description,
  icon,
  list,
  listItem,
  listItemDescription,
  listItemTitle,
  listItemWrapper,
  modal,
  modalChildren,
  title,
  titleWrapper,
  topWrapper,
  wrapper,
} from '../styles';

export const Trial = (props: ITrial) => {
  const {
    isOpened,
    closeHandler,
  } = props;

  const {
    t, 
  } = useTranslation();

  return (
    <Modal
      open={isOpened}
      footer={null}
      style={modal}
      styles={modalChildren}
      closable={true}
      destroyOnClose={true}
      onCancel={closeHandler}
      onOk={closeHandler}
      width='100%'
      centered={true}
    >
      <Box sx={wrapper}>
        <Box sx={topWrapper}>
          {ConfettiLeft}

          <Box sx={titleWrapper}>
            <Text
              type='success'
              styles={title}
            >
              {t('modal.trial.title')}
            </Text>

            <Text styles={description}>
              {t('modal.trial.description')}
            </Text>
          </Box>

          {ConfettiRight}
        </Box>

        <Box sx={content}>
          <Box sx={icon}>
            <img src='/images/subscriptions/robot.jpg' alt='robot' />
          </Box>

          <Box sx={list}>
            <Box sx={listItem}>
              <Box>
                {Unlimited}
              </Box>

              <Box sx={listItemWrapper}>
                <Text styles={listItemTitle}>
                  {t('modal.trial.items.accounts.title')}
                </Text>

                <Text styles={listItemDescription}>
                  {t('modal.trial.items.accounts.description')}
                </Text>
              </Box>
            </Box>

            <Box sx={listItem}>
              <Box>
                {All}
              </Box>

              <Box sx={listItemWrapper}>
                <Text styles={listItemTitle}>
                  {t('modal.trial.items.bots.title')}
                </Text>

                <Text styles={listItemDescription}>
                  {t('modal.trial.items.bots.description')}
                </Text>
              </Box>
            </Box>

            <Box sx={listItem}>
              <Box>
                {Multi}
              </Box>

              <Box sx={listItemWrapper}>
                <Text styles={listItemTitle}>
                  {t('modal.trial.items.terminal.title')}
                </Text>
                
                <Text styles={listItemDescription}>
                  {t('modal.trial.items.terminal.description')}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
