import {
  HOME,
  INTRODUCTION,
  INVITE_FRIENDS,
  MANUAL_TRADING,
  MARKETPLACES,
  MY_ACCOUNTS,
  MY_BOTS,
  SALES,
  SUBSCRIPTIONS,
  TRADING_BOTS,
  TRADING_HISTORY,
  TRADING_TERMNIAL,
} from '../../../shared/consts';
import {
  Bot,
  Candles,
  Friends,
  History as HistoryIcon,
  Info,
  PublicBots,
} from '../../../shared/icons';
import { IMenuItem } from '../interfaces';

export const menuItems = (t: any): IMenuItem[] => {
  const items: IMenuItem[] = [{
    key: 'home',
    label: t('header.menu.home'),
    link: HOME,
  }, {
    key: 'my-accounts',
    label: t('header.menu.accounts'),
    link: MY_ACCOUNTS,
  }, {
    key: 'manual-trading',
    label: t('header.menu.terminal.title'),
    link: MANUAL_TRADING,
    children: [{
      icon: Candles,
      label: t('header.menu.terminal.items.terminal.title'),
      description: t('header.menu.terminal.items.terminal.text'),
      link: `/${MANUAL_TRADING}/${TRADING_TERMNIAL}`,
    }, {
      icon: HistoryIcon,
      label: t('header.menu.terminal.items.diary.title'),
      description: t('header.menu.terminal.items.diary.text'),
      link: `/${MANUAL_TRADING}/${TRADING_HISTORY}`,
    }, {
      icon: Info,
      label: t('header.menu.terminal.items.introduction.title'),
      description: t('header.menu.terminal.items.introduction.text'),
      link: `/${MANUAL_TRADING}/${INTRODUCTION}`,
    }],
  }, {
    key: 'trading-bots',
    label: t('header.menu.bots.title'),
    link: TRADING_BOTS,
    children: [{
      icon: Bot,
      label: t('header.menu.bots.items.all.title'),
      description: t('header.menu.bots.items.all.text'),
      link: `/${TRADING_BOTS}/${MY_BOTS}`,
    }, {
      icon: HistoryIcon,
      label: t('header.menu.bots.items.diary.title'),
      description: t('header.menu.bots.items.diary.text'),
      link: `/${TRADING_BOTS}/${TRADING_HISTORY}`,
    }, {
      icon: Friends,
      label: t('header.menu.bots.items.marketplace.title'),
      description: t('header.menu.bots.items.marketplace.text'),
      link: `/${TRADING_BOTS}/${MARKETPLACES}`,
    }, {
      icon: PublicBots,
      label: t('header.menu.bots.items.public.title'),
      description: t('header.menu.bots.items.public.text'),
      link: `/${TRADING_BOTS}/${SALES}`,
    }, {
      icon: Info,
      label: t('header.menu.bots.items.introduction.title'),
      description: t('header.menu.bots.items.introduction.text'),
      link: `/${TRADING_BOTS}/${INTRODUCTION}`,
    }, {
      label: t('header.menu.bots.button'),
      key: 'add',
      description: '',
      link: `/${TRADING_BOTS}/create`,
    }],
  }, {
    key: 'subscriptions',
    label: t('header.menu.subscriptions'),
    badge: 'Sale',
    link: SUBSCRIPTIONS,
  }, {
    key: 'invite-friends',
    label: t('header.menu.invite'),
    link: INVITE_FRIENDS,
  }];

  return items;
};
