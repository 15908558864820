import { toast } from 'react-toastify';
import createAxiosInstance from 'shared/api/axios/axios';
import { HttpMethod } from 'shared/api/axios/types/http-method';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

const axios = createAxiosInstance({
  method: HttpMethod.Post,
});

interface ICreateReferralLinkParams {
  referralCode: string;
  onResult?: (user: any) => void;
}
export const createReferralLink = async (params: ICreateReferralLinkParams) => {
  const {
    referralCode,
    onResult,
  } = params;

  if (!referralCode) {
    toast.error('Code is required');
    return;
  }

  const response = await axios.request({
    url: '/referral/createCode',
    data: {
      skyrexUserUuid: getSkyrexUuid()!,
      referralCode,
    },
  });
  if (!response.data?.data?.user) {
    toast.error(response.data?.data?.message);
    return;
  }

  onResult?.(response.data?.data);
};
