import { SxProps, Theme } from '@mui/material';

export const WRAPPER = (maxWidth: string, shrink: boolean) => {
  const styles: SxProps<Theme> = {
    alignItems: 'center',
    justifyContent: 'center',
    // flex: 1,
    width: '100%',
    maxWidth: maxWidth || 400,
    '&>img': {
      width: '100%',
      height: '100%',
    },
  };

  if (shrink) {
    styles.flex = 1;
  }

  return styles;
};
