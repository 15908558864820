export const DOWNLOAD_SECONDARY = (
  <svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_10953_17708)'>
      <path d='M4.63198 6.82429H5.41919V3.28186H6.6L5.02559 1.31384L3.45117 3.28186H4.63198V6.82429Z' fill='#9E9E9E'/>
      <path d='M2.27158 9.18594H7.78203C8.21618 9.18594 8.56924 8.83288 8.56924 8.39873V4.8563C8.56924 4.42215 8.21618 4.06909 7.78203 4.06909H6.20762V4.8563H7.78203V8.39873H2.27158V4.8563H3.846V4.06909H2.27158C1.83744 4.06909 1.48438 4.42215 1.48438 4.8563V8.39873C1.48438 8.83288 1.83744 9.18594 2.27158 9.18594Z' fill='#9E9E9E'/>
    </g>
    <defs>
      <clipPath id='clip0_10953_17708'>
        <rect width='9.44649' height='9.44649' fill='white' transform='translate(0.302734 0.526672)'/>
      </clipPath>
    </defs>
  </svg>
);
