import { ISkyrexSubscription } from 'pages/subscriptions/subscription/interfaces';
import { ICard } from 'widgets/card/interfaces';
import { IPlans } from '../interfaces';

interface IGetButtonParams {
  subscription: ISkyrexSubscription;
  plan: IPlans['items'][0];
  choosePlan: (plan: IPlans['items'][0]) => void;
  t: any;
}
export const getButton = (params: IGetButtonParams): (ICard['button'] | null) => {
  const {
    subscription,
    plan,
    choosePlan,
    t,
  } = params;

  const subscriptionFee = subscription?.originalFee || 0;
  const isBasic = plan.planName === 'basic';
  const planFee = plan.originalFee;
  if (!subscription) {
    return {
      title: t('modal.plans.button.subscribe'),
      handler: () => {
        choosePlan(plan);
      },
    };
  }
  
  switch (true) {
  case (subscription.planName === 'trial') && isBasic:
    return {
      title: t('modal.plans.button.trial'),
      type: 'text',
    };
  case plan.planName === subscription.planName:
    return {
      title: t('modal.plans.button.current'),
      disabled: true,
    };
  case (subscriptionFee > 0 && subscriptionFee < planFee) && !isBasic:
    return {
      title: t('modal.plans.button.upgrade'),
      handler: () => {
        choosePlan(plan);
      },
    };
  case (subscriptionFee <= planFee && planFee !== null):
    return {
      title: t('modal.plans.button.subscribe'),
      handler: () => {
        choosePlan(plan);
      },
    };
  default:
    return null;
  }
};
