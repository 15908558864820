import { Rule } from 'antd/es/form';

export const botNameValidator = (t: any) => {
  const rules: Rule[] = [{
    required: true,
    message: t('validation.bot.update.name.required'),
  }, {
    validator: (_, value) => {
      if (value && value.length > 20) {
        return Promise.reject(new Error(t('validation.bot.update.name.more')));
      }
      return Promise.resolve();
    },
  }];

  return rules;
};
