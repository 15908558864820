//@ts-nocheck
import { createAsyncThunk } from '@reduxjs/toolkit';
import { calculateMarketOptions, getFilteredTradingPairs, setDefaultTradingPairAndSymbol } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getUniqueSymbolCodes } from 'pages/manual-trading/trading-terminal/helpers/trading-data-symbols-utils';
import { getTerminalSnapshotsData } from 'pages/trading-terminal-page/order-panel/ExchangeForm/helpers/get-snapshots-terminal-page';
import { getExchangeSymbols } from 'pages/trading-terminal-page/trading-chart/helpers/get-symbols';
import { getExchanges } from 'widgets/exchanges/api/get-exchanges';
import { getSkyrexUuid } from 'shared/helpers/storage-helper';

export const updateExchangeData = createAsyncThunk(
  'trading/updateExchangeData',
  async ({ 
    uuid, 
    exchangeCode, 
    previousMarket,
    selectedExchange,
    tradeSymbol,
    selectedTradingPair,
  }: { 
      uuid: string; 
      exchangeCode: string;
      previousMarket?: any;
      selectedExchange?: any;
      tradeSymbol?: { quote: string; base: string; };
      selectedTradingPair?: any;
    }, {
    rejectWithValue, 
  }) => {
    try {
      
      const skyrexUserUuid = getSkyrexUuid();
        
      //@TODO переписать на утилиту с кешированием
      const exchangeSymbols = await getExchangeSymbols(exchangeCode);
      const uniqueSymbolCodes = getUniqueSymbolCodes(exchangeSymbols);
  
      const financeData = await getExchanges([uuid]);
        
      const chosenExchange = financeData?.data?.accounts[0];
      
      const userWalletBalances = chosenExchange.balances;
      
      const marketOptions = calculateMarketOptions(
        chosenExchange.balances,
        uniqueSymbolCodes,
      );
      
      
      //@TODO REFACTOR ASAP
      // Логика для нового функционала
      let defaultMarket;
      let tradingPairs;
      // @ts-ignore
      let defaultTradingPair;
      let currentSymbol;

      if (tradeSymbol) {
        // Если tradeSymbol передан, используем его для выбора рынка и пары
        defaultMarket = marketOptions.find((option: any) => option.label === tradeSymbol.quote) || marketOptions[0];
        tradingPairs = getFilteredTradingPairs(
          exchangeSymbols,
          defaultMarket,
          chosenExchange.balances,
        );
        defaultTradingPair = tradingPairs.find((pair: any) => pair.formattedPair === `${tradeSymbol.base}${tradeSymbol.quote}`) || 
        tradingPairs.find((pair: any) => pair.formattedPair === 'BTCUSDT') ||
        tradingPairs[0];
        // @ts-ignore
        currentSymbol = exchangeSymbols.find((el: any) => el.symbol === defaultTradingPair?.formattedPair || `${el.baseAsset}${el.quoteAsset}` === defaultTradingPair?.formattedPair);
        
      } else {
        // Определяем defaultMarket
        defaultMarket = previousMarket ? 
          marketOptions.find((option: any) => option.label === previousMarket.label) :
          marketOptions.find((el: any) => el.label === 'USDT') || 
          marketOptions[0];
      
        tradingPairs = getFilteredTradingPairs(
          exchangeSymbols,
          defaultMarket,
          chosenExchange.balances,
        );
      
        // Проверяем существует ли текущая торговая пара в новой бирже
        let pairExists = false;
        if (selectedTradingPair) {
          const existingPair = exchangeSymbols.find(
            (el: any) => el.symbol === selectedTradingPair.formattedPair,
          );
          if (existingPair) {
            defaultTradingPair = selectedTradingPair;
            currentSymbol = existingPair;
            pairExists = true;
          }
        }
      
        // Если пара не существует, используем стандартную логику
        if (!pairExists) {
          const {
            defaultTradingPair: oldDefaultTradingPair, 
            currentSymbol: oldCurrentSymbol, 
          } = setDefaultTradingPairAndSymbol(
            tradingPairs,
            exchangeSymbols,
          );
          defaultTradingPair = oldDefaultTradingPair;
          currentSymbol = oldCurrentSymbol;
        }
      }
      
      const userWalletQuoteAssetBalance = userWalletBalances.find((el: any) => el.symbol === currentSymbol?.quoteAsset);
      const userWalletBaseAssetBalance = userWalletBalances.find((el: any) => el.symbol === currentSymbol?.baseAsset);
      
      const dailyChange = await getTerminalSnapshotsData(chosenExchange, skyrexUserUuid ?? '', chosenExchange.exchangeAccountUuid);

      return {
        selectedExchange: selectedExchange,
        financeData,
        dailyChange,
        symbolCodes: uniqueSymbolCodes,
        marketOptions,
        selectedMarket: defaultMarket,
        tradingPairs,
        selectedTradingPair: defaultTradingPair,
        chartSymbol: currentSymbol,
        currentSymbol,
        allExchangeSymbols: exchangeSymbols,
        userWalletBalances,
        userWalletQuoteAssetBalance,
        userWalletBaseAssetBalance,
        chosenExchange,
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
