import { Box, Stack } from '@mui/material';
import { checkSkyrexCopyBots } from 'pages/trading-bots/marketplace/api/check-skyrex-copy-bots';
import { getCalculatedWinRate } from 'pages/trading-bots/marketplace/helpers/get-calculated-winrate';
import { getRuntimePeriod } from 'pages/trading-bots/marketplace/helpers/get-runtime-period';
import { Bot } from 'pages/trading-bots/marketplace/types/bots.types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exchangeIcons, IExchangeIcons } from 'shared/consts/exchange-icons';
import { IMarketIcons, marketIcons } from 'shared/consts/market-icon';
import { getCapitalizedExchangeTitle } from 'shared/helpers/get-capitalized-exchange-title';
import { Account } from 'shared/icons';
import { ConnectNewExchange, CopyBot, MainButton, Text, Title, Tooltip } from 'shared/ui';
import { LineChart } from '../components/line-chart/ui/line-chart';
import {
  background,
  bottomInner,
  bottomWrapper,
  button as buttonStyles,
  copyBotsWrapper,
  description,
  echangeIcon,
  echangeTitle,
  middleInner,
  middleItem,
  middleItems,
  middleItemTitle,
  middleItemValue,
  middleValue,
  middleValueTitle,
  middleValueWrapper,
  middleWrapper,
  tip,
  tips,
  titleInner,
  title as titleStyle,
  titleWrapper,
  topWrapper,
} from '../styles';

interface CardProps {
  bots: Bot[];
  isSignalSource?: boolean;
  subscription?: any;
  button?: {
    title: string;
    action: () => void;
  };
}

export const Card: React.FC<CardProps> = (props: CardProps) => {
  const { 
    bots, 
    isSignalSource,
    subscription,
    button,
  } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSkyrexBot, setIsSkyrexBot] = useState<boolean>(false);
  const [isConnectExchangeModalOpen, setIsConnectExchangeModalOpen] = useState<boolean>(false);

  const {
    t, 
  } = useTranslation();

  const bot = useMemo(() => {
    const currentBot = bots.find((bot) => Math.max(bot.activeCopyBots));
    return currentBot || bots[0];
  }, [bots]) as Bot;

  const uniqueBotExchangeCodes = useMemo(() => {
    const filteredBots = bots.filter((bot, index, self) => {
      return index === self.findIndex((currnetBot) => currnetBot.exchangeCode === bot.exchangeCode);
    });

    return filteredBots;
  }, [bots]);

  const getActiveCopyBots = () => {
    return bots.reduce((acc, next) => acc + next.activeCopyBots, 0);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getExchangeContent = () => {
    return uniqueBotExchangeCodes.map((bot) => (
      <Box key={bot.botUuid} sx={echangeIcon}>
        <Tooltip title={getCapitalizedExchangeTitle(bot.exchangeCode)}>
          {exchangeIcons[bot.exchangeCode as keyof IExchangeIcons]}
        </Tooltip>
      </Box>
    ));
  };

  const checkBot = async () => {
    const bots = await checkSkyrexCopyBots(bot.botUuid);
    setIsSkyrexBot(!!bots?.length);
  };

  useEffect(() => {
    checkBot();
  }, []);

  return (
    <Box sx={background}>
      <Box sx={topWrapper}>
        <Stack gap='14px'>
          <Stack gap='2px'>
            <Box sx={titleWrapper}>
              <Box>
                <Title styles={titleStyle}>
                  {bot.itemName || bot.name}
                </Title>

                <Tooltip title={bot.quote}>
                  <Box sx={tip}>
                    {marketIcons[bot.quote.toLowerCase() as keyof IMarketIcons]}
                  </Box>
                </Tooltip>
              </Box>

              <Stack sx={copyBotsWrapper}>
                <Box sx={titleInner}>
                  {Account}
                
                  <Text type='success'>
                    {getActiveCopyBots()}
                  </Text>
                </Box>
              </Stack>
            </Box>

            <Text type='secondary'>
              By {bot.author}
            </Text>
          </Stack>

          <Text
            type='secondary'
            styles={description}
          >
            {bot.description}
          </Text>
        </Stack>
        
        <Stack gap='10px'>
          <Text
            type='secondary'
            styles={echangeTitle}
          >
            {t('marketplace.card.available')}
          </Text>
          
          <Box sx={tips}>
            {getExchangeContent()}
          </Box>
        </Stack>
      </Box>

      <Box sx={middleWrapper}>
        <Box sx={middleInner}>
          <Box sx={middleValueWrapper}>
            <Text
              type='secondary'
              styles={middleValueTitle}
            >
              {t('marketplace.card.roi')}
            </Text>

            <Text
              type={bot?.statistics.roi < 0 ? 'danger' : 'success'}
              styles={middleValue}
            >
              {bot?.statistics.roi ? `${bot?.statistics.roi.toFixed(2)}%` : '0%'}
            </Text>
          </Box>

          <LineChart data={bot?.statistics.sumChartData || []} totalPnl={bot.statistics.totalPnl} roi={bot.statistics.roi} />
        </Box>

        <Box sx={middleItems}>
          <Box sx={middleItem}>
            <Text styles={middleItemTitle}>
              {t('marketplace.card.winRate')}
            </Text>

            <Text
              type='secondary'
              styles={middleItemValue}
            >
              {(getCalculatedWinRate(bot.statistics?.totalTrades || 0, bot.statistics?.positiveTrades || 0))}
            </Text>
          </Box>

          <Box sx={middleItem}>
            <Text styles={middleItemTitle}>
              {t('marketplace.card.drawdown')}
            </Text>

            <Text
              styles={middleItemValue}
              type='danger'
            >
              {bot?.statistics?.maxDrawdown?.toFixed(2)}%
            </Text>
          </Box>

          <Box sx={middleItem}>
            <Text styles={middleItemTitle}>
              {t('marketplace.card.runtime')}
            </Text>

            <Text
              type='secondary'
              styles={middleItemValue}
            >
              {getRuntimePeriod(bot.createdDate)}
            </Text>
          </Box>
        </Box>
      </Box>

      <Box sx={bottomWrapper}>
        <Box sx={bottomInner}>
          <Text styles={middleItemTitle}>
            {t('marketplace.card.investment')}
          </Text>

          <Text
            styles={middleItemValue}
            type='secondary'
          >
            {`$${bot.minInvestment || 0}`}
          </Text>
        </Box>

        <Box sx={bottomInner}>
          <Text styles={middleItemTitle}>
            {t('marketplace.card.free')}
          </Text>

          <Text
            styles={middleItemValue}
            type='secondary'
          >
            {`${(isSkyrexBot ? subscription?.smartBotCommissionPercent : bot.copyCommissionPercent) || 0}%`}
          </Text>
        </Box>

        <MainButton
          type='primary'
          styles={buttonStyles}
          onClick={button?.action || handleOpenModal}
          size='large'
        >
          {button?.title || (isSignalSource ? t('marketplace.card.button.view') : t('marketplace.card.button.copy'))}
        </MainButton>
      </Box>
      
      {isModalOpen && (
        <CopyBot
          bot={bot}
          bots={bots}
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
          isSignalSource={isSignalSource}
          fee={`${(isSkyrexBot ? subscription?.smartBotCommissionPercent : bot.copyCommissionPercent) || 0}%`}
          connectExchange={setIsConnectExchangeModalOpen}
        />
      )}

      <ConnectNewExchange 
        isModalOpen={isConnectExchangeModalOpen} 
        setIsModalOpen={setIsConnectExchangeModalOpen} 
      />
    </Box>
  );
};
